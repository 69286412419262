<template>
  <v-skeleton-loader
      v-if="loadingSkeleton"
      type="image"
      width="100%"
      height="40px"
      class="ml-1 mr-8"
  />
  <v-card v-else color="rgb(247, 247, 247)" class="card d-flex align-center" style="width: 100%; height: 48px;" elevation="0" dark id="categoriaDocumentos">
    <v-card-text>
      <v-row class="mr-1" style="border: 1px solid #E7E7E8; border-radius: 8px; background-color: #FFFFFF;width: 100%;  height: 48px;">
        <v-menu
            fixed
            :absolute="false"
            :position-x="10"
            :position-y="10"
            color="rgb(247, 247, 247)"
            attach="#categoriaDocumentos"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" elevation="0" style="align-items: center;width: 100%;background-color: #FFFFFF; border-radius: 8px;">
              <div style="text-align: start">
                <table style="width: 100%;padding-top: 9px;">
                  <tr>
                    <td style="width: 90%;">
                      <span class="texto-categoria">Categoria do Cliente </span>
                    </td>
                    <td style="padding-left: 36px">
                      <v-icon color="#686c71">mdi-chevron-down</v-icon>
                    </td>
                  </tr>
                </table>
              </div>
            </v-card>
          </template>
          <div style="max-height: 300px;width:100%;overflow-y: auto;">
            <v-list style="cursor: pointer;background-color: #FFFFFF;">
              <v-list-item
                  style="min-height: 36px; height: 36px;"
                  class="tipo-hover"
                  v-for="(item, index) in items"
                  :key="index"
                  @click="selecionaCategoria(item)"
              >
                <v-list-item-title :class="item.valor === categoriaSelecionada.valor ? 'item-selecionado' : 'item-sem-selecao'">{{ item.nome }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import session from '../../plugins/session'

  export default {
    name: 'CardCategoria',
    props: {
    },
    data: () => ({
      loadingSkeleton: false,
      categoriaSelecionada: '',
      items: [
        { nome: 'Assalariado', valor: 'assalariado_renda_variavel' }, { nome: 'Funcionário público', valor: 'funcionario_publico' },
        { nome: 'Aposentado / Pensionista', valor: 'aposentado_pensionista_inss' }, { nome: 'Autônomo', valor: 'autonomo' },
        { nome: 'Profissional liberal', valor: 'profissional_liberal' }, { nome: 'Locação de imóveis (proprietário)', valor: 'proprietario_imoveis' },
        { nome: 'Produtor rural', valor: 'produtor_rural' }, { nome: 'Motorista de táxi, Motorista de transporte escolar, Motorista de passageiros', valor: 'motorista_taxi' },
        { nome: 'Motorista de caminhão', valor: 'motorista_caminhao' }, { nome: 'Empresário / Proprietário', valor: 'empresario' }
      ]
    }),
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      }
    },
    mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
      }, 6000)
      if (!this.isPf()) {
        this.srcDocumentos = '/DocumentacoesPJ.pdf'
      }

      const unidade = session.get('unidade')

      if (unidade.idUnidade === 44) {
        this.srcCriterios = '/CriteriosdeAnalise_Stara.pdf'
        this.srcDocumentos = '/CriteriosdeAnalise_Stara.pdf'
        this.documentoFichaCadastralPF = '/FichaCadastralPF_Stara.pdf'
        this.documentoFichaCadastralPJ = '/FichaCadastralPJ_Stara.pdf'
        this.isStara = true
      }
    },
    methods: {
      isPf () {
        return this.cliente?.cpfCnpj?.replaceAll('.', '').replace('-', '').length === 11
      },
      selecionaCategoria (valor) {
        this.categoriaSelecionada = valor
        this.$emit('categoria', valor)
      }
    }
  }
</script>

<style scoped>

.texto-categoria {
  color: #191E26;
  /* Texto/Texto corrido */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 14px;
}

.item-sem-selecao {
  color: #191E26;
  /* Texto/Texto corrido */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 14px;
}

.item-selecionado {
  color: var(--primaria);
  font-size: 16px;
  font-weight: bold;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  padding-left: 14px;
}

@media screen and (min-width: 960px){
  .tipo-hover:hover {
    height: 20px!important;
    background-color: var(--primariaTransparent)!important;
    color: white!important;
  }
}
</style>
