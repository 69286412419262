<template>
  <v-dialog
      persistent
      width="400"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="exibir"
  >
    <v-card class="pb-3">
      <v-card-title>
        <span>Recusar nota fiscal</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-actions>
        <v-form>
          <v-row>
            <v-col class="mt-4 d-flex flex-column">
              <h4>Motivo da recusa</h4>
              <v-textarea
                  placeholder="Descreva o motivo da recusa..."
                  outlined
                  tile
                  cols="100"
                  v-model="motivoRecusa"
                  max="255"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <Button
                outlined
                block
                @click="fechar"
            >
              Cancelar
            </Button>
          </v-col>
          <v-col cols="6">
            <Button
                block
                @click="recusarNota"
            >
              Confirmar
            </Button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Button from '../Button.vue'
  import { recusarNotaFiscal } from '@/service/notasFiscais'

  export default {
    name: 'DialogRecusarNota',
    components: { Button },
    props: {
      exibir: {
        type: Boolean,
        default: false
      },
      idNota: Number
    },
    data: () => ({
      motivoRecusa: ''
    }),
    watch: {
      exibir () {
        this.motivoRecusa = undefined
      }
    },
    methods: {
      async recusarNota () {
        if (this.motivoRecusa && this.motivoRecusa !== '') {
          try {
            await recusarNotaFiscal(this.idNota, this.motivoRecusa)
            this.$toast.success('Nota fiscal recusada!')
            this.fechar()
            this.$emit('proxima')
          } catch {
            this.$toast.error('Algo inesperado aconteceu. Tente novamente em instantes.')
          }
        }
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>
