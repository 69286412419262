<template>
  <div>
    <label v-if="label" :class="{ 'label-error': hasError }">{{ label }}</label>
    <v-select
        ref="field"
        v-bind="$attrs"
        v-on="$listeners"
        outlined
        dense
        append-icon='pc-calendario'
        class="text-field transition width-default"
    />
  </div>
</template>

<script>
  export default {
    name: 'YearPicker',
    props: {
      label: String
    },
    data: () => ({
      isMounted: false
    }),
    computed: {
      hasError () { return this.isMounted && this.$refs.field.validationState === 'error' }
    },
    mounted () {
      this.isMounted = true
    }
  }
</script>

<style lang="scss" scoped>

  label {
    color: var(--labelcolor);
    &.label-error {
      color: var(--secundaria);
    }
  }

</style>

<style lang="scss">
  .text-field.v-input {
    fieldset {
      border-color: var(--gray);
      background: var(--white);
    }
    &.v-input--is-focused fieldset {
      border-width: 1px;
      border-color: #048ABF;
      filter: drop-shadow(0 0 4px #55AFFC)
    }
    .v-input__slot {
      min-height: 38px !important;
      padding: 0 20px !important;
    }
    input {
      color: var(--inputcolor);
      &::placeholder {
        color: var(--placeholdercolor);
      }
    }

    &.error--text {
      fieldset {
        border-width: 1px;
        border-color: var(--secundaria);
      }
    }
  }
  .v-application .text-field .error--text {
    color: var(--secundaria) !important;
  }

  .theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
    display: none;
  }

  .theme--light.v-list-item--active:hover
  .v-icon {
    color: var(--primaria) !important;
  }

  .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none;
  }
</style>
