<template>
  <Loading :visible="true" />
</template>

  <script>
  import session from '@/plugins/session'
  export default {
    mounted () {
      this.logout()
    },
    methods: {
      async logout () {
        const perfil = session.get('usuario').perfil

        if (this.$route.params.logout) {
          try {
            if (!this.$store.state.unidade.sso) {
              if (perfil === 'MASTER' || perfil === 'GESTOR') {
                await this.$msalInstance.handleRedirectPromise()
                await this.$msalInstance.logoutRedirect()
              } else {
                this.force()
              }
            } else {
              // window.location.href = this.$store.state.unidade.linkSso + this.$store.state.unidade.identificadorLogoutSso

              // ESTA URL FOI FIXADA NO CÓDIGO POR CONTA DA URGENCIA DE LIBERAÇÃO DO LOGOUT DA UNICRED
              if (this.$store.state.unidade.idUnidade === 92) {
                window.location.href = 'https://adfs.e-unicred.com.br/adfs/ls/idpinitiatedsignon/?wa=wsignout1.0'
              }
            }
          } catch (erro) {
            console.error(erro)
          }
        }

        let idUnidade = this.$session.get('unidade').idUnidade

        if (this.$session.get('unidadeAnterior').idUnidade !== '') {
          idUnidade = this.$session.get('unidadeAnterior').idUnidade
        }

        this.$session.destroy()

        this.$router.push({
          name: 'Login',
          params: { idUnidade }
        })
      },
      force () {
        this.$session.destroy()
        window.location.href = `https://login.microsoftonline.com/logout.srf`
      }
    }
  }

  </script>
