<template>
  <v-dialog
      persistent
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="exibir"
  >
    <v-card class="pb-3">
      <v-card-title>
        <span>Nova Parceria</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-actions>
        <v-form v-model="valido">
          <v-row>
            <v-col cols="6">
              <SelectField
                  v-model="coligada"
                  label="Coligada"
                  item-text="nome"
                  item-value="id"
                  :items="$session.get('configuracoes').coligadas"
              />
            </v-col>
            <v-col cols="6">
              <TextField
                  label="CPF/CNPJ"
                  v-model="cpfCnpj"
                  v-mask="['###.###.###-##','##.###.###/####-##']"
                  :rules="[this.$rules.required, this.$rules.documento]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            outlined
            @click="fechar"
        >
          Cancelar
        </Button>
        <Button
            small
            :disabled="!valido"
            @click="continuar"
        >
          Continuar
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      valido: false,
      coligada: undefined,
      cpfCnpj: undefined
    }),
    watch: {
      exibir () {
        this.coligada = this.$session.get('configuracoes').coligadas[0].id
        this.cpfCnpj = undefined
      }
    },
    methods: {
      continuar () {
        this.$router.push({
          name: 'CadastroParceria',
          params: {
            cnpj: this.cpfCnpj.replace(/(\.|-|\/)/g, '')
          },
          query: {
            coligada: this.coligada
          }
        })
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>
