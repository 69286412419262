<template>
  <v-container fluid>
    <h1 class="titulo">Dashboard</h1>
    <v-card flat>
      <v-card-text class="lightgray py-5 px-5 rounded">
        <v-row>
          <v-col
              cols="12"
              md="6"
          >
            <label class="texto-header">E-mail:</label>
            <label class="valor-header">{{ $session.get('usuario').email }}</label>
          </v-col>
          <v-col
              cols="12"
              md="6"
          >
            <label class="texto-header">Nome de usuário:</label>
            <label class="valor-header">{{ $session.get('usuario').nome }}</label>
          </v-col>
          <v-col
              cols="12"
              md="6"
          >
            <label class="texto-header">Telefone:</label>
            <label class="valor-header">{{ $session.get('usuario').telefone }}</label>
          </v-col>
          <v-col
              cols="12"
              md="6"
          >
            <label class="texto-header">Código da revenda:</label>
            <label class="valor-header">{{ $session.get('usuario').codigoRevenda }}</label>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mt-5">
      <v-col
          cols="6"
          md="3"
      >
        <v-card class="text-center text-sm-left card-dashboard">
          <v-card-text class="text--white">
            <div v-if="!carregando">
              <v-icon
                  color="mediumgray"
                  size="35"
              >
                pc-maleta
              </v-icon>
              <div class="valor-dashboard">{{ numeroComissionados }}</div>
              <div class="texto-dashboard">Parceiros ativos</div>
            </div>
            <div
                v-else
                class="text-center"
            >
              <Spinner
                  :tamanho="22"
                  :cor="$vuetify.theme.themes.light.white"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="6"
          md="3"
      >
        <v-card class="text-center text-sm-left card-dashboard">
          <v-card-text class="text--white">
            <div v-if="!carregando">
              <v-icon
                  color="mediumgray"
                  size="35"
              >
                pc-trofeu
              </v-icon>
              <div class="valor-dashboard">{{ numeroSubLoginsAtivos }}</div>
              <div class="texto-dashboard">Sub logins ativos</div>
            </div>
            <div
                v-else
                class="text-center"
            >
              <Spinner
                  :tamanho="22"
                  :cor="$vuetify.theme.themes.light.white"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="6"
          md="3"
      >
        <v-card class="text-center text-sm-left card-dashboard">
          <v-card-text class="text--white">
            <div v-if="!carregando">
              <v-icon
                  color="mediumgray"
                  size="35"
              >
                pc-pessoas
              </v-icon>
              <div class="valor-dashboard">{{ numeroSubLoginsInativos }}</div>
              <div class="texto-dashboard">Sub logins inativos</div>
            </div>
            <div
                v-else
                class="text-center"
            >
              <Spinner
                  :tamanho="22"
                  :cor="$vuetify.theme.themes.light.white"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="6"
          md="3"
      >
        <v-card class="text-center text-sm-left card-dashboard">
          <v-card-text class="text--white">
            <div v-if="!carregando">
              <v-icon
                  color="mediumgray"
                  size="35"
              >
                pc-cadeado
              </v-icon>
              <div class="valor-dashboard">{{ numeroSubLoginsBloqueados }}</div>
              <div class="texto-dashboard">Sub logins bloqueados</div>
            </div>
            <div
                v-else
                class="text-center"
            >
              <Spinner
                  :tamanho="22"
                  :cor="$vuetify.theme.themes.light.white"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import Spinner from '@/components/Spinner'
  import { buscarDashboard } from '@/service/dashboard'

  export default {
    name: 'TelaInicial',
    components: { Spinner },
    data: () => ({
      carregando: true,
      numeroComissionados: 0,
      numeroSubLoginsAtivos: 0,
      numeroSubLoginsInativos: 0,
      numeroSubLoginsBloqueados: 0
    }),
    async mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)

      try {
        const estatisticas = await buscarDashboard()

        this.numeroComissionados = estatisticas.numeroComissionados ? estatisticas.numeroComissionados : 0
        this.numeroSubLoginsAtivos = estatisticas.numeroSubLoginsAtivos ? estatisticas.numeroSubLoginsAtivos : 0
        this.numeroSubLoginsInativos = estatisticas.numeroSubLoginsInativos ? estatisticas.numeroSubLoginsInativos : 0
        this.numeroSubLoginsBloqueados = estatisticas.numeroSubLoginsBloqueados ? estatisticas.numeroSubLoginsBloqueados : 0
      } catch (erro) {
        this.$toast.error(erro)
      }

      this.carregando = false
    }
  }
</script>

<style scoped>
  .valor-header {
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
  }

  .texto-header {
    font-size: 14px;
    color: var(--black);
  }

  .card-dashboard {
    height: 100%;
    background: var(--darkgray);
    box-shadow: 0px 3.25074px 6.50147px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 20px 10px 20px 10px;
  }

  .valor-dashboard {
    font-size: 24px;
    font-weight: 800;
    margin-top: 16px;
  }

  .texto-dashboard {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
  }
</style>
