export let state
state = {
  idCota: '',
  cpfCnpj: '',
  idComissionado: '',
  cota: '',
  dataAtualizacao: '',
  email: '',
  grupo: '',
  telefone: '',
  status: '',
  nomeStatus: '',
  nome: '',
  idDocumento: '',
  idTipoDocumento: '',
  idEmpresa: '',
  idUnidade: '',
  tipo: '',
  login: '',
  documentosCliente: [],
  faseAnalise: {},
  urlFiltroMeusClientes: '',
  extratoCota: {},
  filtroMeusClientes: {
    idComissionadoPrincipal: '',
    idEmpresa: '',
    filtrado: false,
    login: '',
    tipo: '',
    idUnidade: '',
    dataInicial: '',
    dataFinal: '',
    dates: [],
    tipoSelecionado: '',
    textoFiltroDocumento: '',
    textoFiltroProposta: '',
    grupo: '',
    cota: '',
    listaStatus: [],
    subloginSelecionado: {},
    subloginSelecionadoFiltro: ''
  }
}
export const getters = {
  getMeusClientes: state => {
    return state
  },
  getDocumentosMeusClientes: state => {
    return state.documentosCliente
  },
  getFaseAnaliseMeusClientes: state => {
    return state.faseAnalise
  },
  getFiltroMeusClientes: state => {
    return state.filtroMeusClientes
  },
  getUrlFiltro: state => {
    return state.urlFiltroMeusClientes
  }
}

export const mutations = {
  setMeuscliente (state, content) {
    state.idCota = content.idCota
    state.cpfCnpj = content.cpfCnpj
    state.idComissionado = content.idComissionado
    state.cota = content.cota
    state.dataAtualizacao = content.dataAtualizacao
    state.email = content.email
    state.grupo = content.grupo
    state.telefone = content.telefone
    state.status = content.status
    state.nomeStatus = content.nomeStatus
    state.nome = content.nome
    state.idDocumento = content.idDocumento
    state.idTipoDocumento = content.idTipoDocumento
    state.idEmpresa = content.idEmpresa
    state.idUnidade = content.idUnidade
    state.documentosCliente = content.documentosCliente
    state.faseAnalise = content.faseAnalise
    state.extratoCota = {}
    if (content.extratoCota) {
      state.extratoCota = content.extratoCota
    }
  },
  clearMeusClientes (state) {
    state.idCota = ''
    state.cpfCnpj = ''
    state.idComissionado = ''
    state.cota = ''
    state.dataAtualizacao = ''
    state.email = ''
    state.grupo = ''
    state.telefone = ''
    state.status = ''
    state.nomeStatus = ''
    state.nome = ''
    state.idDocumento = ''
    state.idTipoDocumento = ''
    state.idEmpresa = ''
    state.idUnidade = ''
    state.tipo = ''
    state.login = ''
    state.urlFiltroMeusClientes = ''
    state.documentosCliente = []
    state.faseAnalise = {}
    state.extratoCota = {}
  },
  setDocumentos (state, documentos) {
    state.documentosCliente = documentos
  },
  addDocumentos (state, documento) {
    state.documentosCliente.push(documento)
  },
  removeDocumentos (state, id) {
    state.documentosCliente = state.documentosCliente.filter(doc => doc.id !== id)
  },
  setFaseAnalise (state, fase) {
    state.faseAnalise = fase
  },
  setFiltro (state, content) {
    state.filtroMeusClientes.login = content.login
    state.filtroMeusClientes.tipo = content.tipo
    state.filtroMeusClientes.idUnidade = content.idUnidade
    state.filtroMeusClientes.idEmpresa = content.idEmpresa
    state.filtroMeusClientes.idComissionadoPrincipal = content.idComissionadoPrincipal

    if (content.hasOwnProperty('filtrado')) {
      state.filtroMeusClientes.filtrado = content.filtrado
    } else {
      state.filtroMeusClientes.filtrado = false
    }

    if (content.hasOwnProperty('dataInicial')) {
      state.filtroMeusClientes.dataInicial = content.dataInicial
    } else {
      state.filtroMeusClientes.dataInicial = ''
    }

    if (content.hasOwnProperty('dataFinal')) {
      state.filtroMeusClientes.dataFinal = content.dataFinal
    } else {
      state.filtroMeusClientes.dataFinal = ''
    }

    if (content.hasOwnProperty('dates')) {
      state.filtroMeusClientes.dates = content.dates
    } else {
      state.filtroMeusClientes.dates = []
    }

    if (content.hasOwnProperty('tipoSelecionado')) {
      state.filtroMeusClientes.tipoSelecionado = content.tipoSelecionado
    } else {
      state.filtroMeusClientes.tipoSelecionado = ''
    }

    if (content.hasOwnProperty('textoFiltroDocumento')) {
      state.filtroMeusClientes.textoFiltroDocumento = content.textoFiltroDocumento
    } else {
      state.filtroMeusClientes.textoFiltroDocumento = ''
    }

    if (content.hasOwnProperty('textoFiltroProposta')) {
      state.filtroMeusClientes.textoFiltroProposta = content.textoFiltroProposta
    } else {
      state.filtroMeusClientes.textoFiltroProposta = ''
    }

    if (content.hasOwnProperty('grupo')) {
      state.filtroMeusClientes.grupo = content.grupo
    } else {
      state.filtroMeusClientes.grupo = ''
    }

    if (content.hasOwnProperty('cota')) {
      state.filtroMeusClientes.cota = content.cota
    } else {
      state.filtroMeusClientes.cota = ''
    }

    if (content.hasOwnProperty('listaStatus')) {
      state.filtroMeusClientes.listaStatus = content.listaStatus
    } else {
      state.filtroMeusClientes.listaStatus = []
    }

    if (content.hasOwnProperty('subloginSelecionado')) {
      state.filtroMeusClientes.subloginSelecionado = content.subloginSelecionado
    } else {
      state.filtroMeusClientes.subloginSelecionado = {}
    }

    if (content.hasOwnProperty('subloginSelecionadoFiltro')) {
      state.filtroMeusClientes.subloginSelecionadoFiltro = content.subloginSelecionadoFiltro
    } else {
      state.filtroMeusClientes.subloginSelecionadoFiltro = ''
    }
  },
  clearFiltro (state) {
    state.filtroMeusClientes.idEmpresa = ''
    state.filtroMeusClientes.idComissionadoPrincipal = ''
  },
  setUrlFiltro (state, url) {
    state.urlFiltroMeusClientes = url
  }
}

export const actions = {
  async inicializaClientes ({ commit }, payload) {
    commit('setMeuscliente', payload)
  },
  async clearMeusClientes ({ commit }) {
    commit('clearMeusClientes')
  },
  async setDocumentos ({ commit }, payload) {
    commit('setDocumentos', payload)
  },
  async addDocumentos ({ commit }, payload) {
    commit('addDocumentos', payload)
  },
  async removeDocumentos ({ commit }, payload) {
    commit('removeDocumentos', payload)
  },
  async setFaseAnalise ({ commit }, payload) {
    commit('setFaseAnalise', payload)
  },
  async setFiltroMeusClientes ({ commit }, payload) {
    commit('setFiltro', payload)
  },
  async clearFiltro ({ commit }) {
    commit('clearFiltro')
  },
  async setUrlFiltro ({ commit }, payload) {
    commit('setUrlFiltro', payload)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
