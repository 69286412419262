<template>
  <v-dialog
      persistent
      max-width="450"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="exibir"
  >
    <v-card class="pb-3">
      <v-card-title>
        <span>Filtrar Fornecedor</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="secundaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-actions>
        <v-form
            v-model="valido"
            @submit.prevent="filtrar"
        >
          <v-row>
            <v-col cols="12">
              <TextField
                  label="Nome"
                  v-model="nome"
                  maxlength="30"
              />
            </v-col>
            <v-col cols="12">
              <TextField
                  label="CPF/CNPJ"
                  v-model="cpfCnpj"
                  v-mask="['###.###.###-##','##.###.###/####-##']"
                  :rules="[this.$rules.documento]"
              />
            </v-col>
            <v-col cols="12">
              <TextField
                  label="Usuario"
                  v-model="login"
                  maxlength="30"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <Button
                  small
                  outlined
                  block
                  @click="limpar"
              >
                Limpar
              </Button>
            </v-col>
            <v-col cols="6">
              <Button
                  small
                  block
                  type="submit"
                  :disabled="!valido"
              >
                Filtrar
              </Button>
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      valido: false,
      nome: undefined,
      cpfCnpj: undefined,
      login: undefined
    }),
    watch: {
      exibir () {
        this.nome = undefined
        this.cpfCnpj = undefined
        this.login = undefined
      }
    },
    methods: {
      filtrar () {
        let filtro = '?filtro=true'
        filtro += this.nome ? `&nome=${this.nome}` : ''
        filtro += this.cpfCnpj ? `&cpfCnpj=${this.cpfCnpj}` : ''
        filtro += this.login ? `&login=${this.login}` : ''
        this.$emit('filtro', filtro)
        this.fechar()
      },
      limpar () {
        this.nome = undefined
        this.cpfCnpj = undefined
        this.login = undefined
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>
