<template>
  <v-card color="transparent" elevation="0" width="auto">
    <v-row class="pt-5">
      <v-col :cols="isMobile ? 12 : 12">
        <CardInformacoesCliente :dados="dados" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import CardInformacoesCliente from '@/components/JornadaDaCota/CardInformacoesCliente.vue'

  export default {
    name: 'CardTopoInformacoes',
    components: {
      CardInformacoesCliente
    },
    props: {
      dados: Object
    },
    data: () => ({
      loadingSkeleton: false
    }),
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      }
    },
    mounted () {}
  }
</script>
