<template>
  <div class="position-relative">
    <a class="d-block btn-toggle">
      <div class="tooltip">
        <span class="tooltiptext direcao">{{ mensagem }}</span>
        <v-icon :size="tamanho" color="iconcolor">pc-ajuda</v-icon>
      </div>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'AjudaTooltip',
    props: {
      mensagem: String,
      tamanho: String
    },
    data: () => ({
    })
  }
</script>

<style scoped>
@media (max-width: 970px) {
  .direcao {
    right: -10px;
  }
  .direcao::after {
      right: 8px;
  }
}

@media (min-width: 970px) {
  .direcao {
     left: 50%;
  }
  .direcao::after {
    left: 175px;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 360px;
  background-color: var(--primaria);
  color: var(--white);
  text-align: left;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  z-index: 999;
  top: 40px;
  margin-left: -185px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
  white-space: pre-line;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  margin-left: -5px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent var(--primaria) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
