<template>
  <v-dialog
      max-width="400"
      persistent
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>
        <span>Declaração de responsabilidade</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>Declaro que assumo a responsabilidade pelas vendas realizadas pelo meu sub login.</v-card-text>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            outlined
            @click="fechar"
        >
          Cancelar
        </Button>
        <Button
            small
            @click="cadastrar"
        >
          Concordar
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    props: {
      login: String,
      exibir: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      fechar () {
        this.$emit('exibir', false)
      },
      cadastrar () {
        this.$emit('cadastrar')
      }
    }
  }

</script>
