<template>
  <v-card color="#FFFFFF" elevation="1" width="auto" style="border-radius: 8px;height: 1069px;">
    <!-- <v-card-title class="d-flex flex-column align-start pr-0 ml-1 espacamento-titulo">
    </v-card-title> -->
    <CardExtratoTipoClienteMobile :executa-skeleton="executaSkeleton" :dados="dados" />
  </v-card>
</template>

<script>
  import CardExtratoTipoClienteMobile from '@/components/JornadaDaCota/CardExtratoTipoClienteMobile.vue'

  export default {
    name: 'CardExtratoClienteMobile',
    components: {
      CardExtratoTipoClienteMobile
    },
    props: {
      dados: Object
    },
    data: () => ({
      loadingSkeleton: false,
      executaSkeleton: true
    }),
    computed: {
      isMobile () {
        // return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        return false
      }
    },
    mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
        this.executaSkeleton = false
      }, 6000)
    },
    methods: {
      // alterarTipoSelecionado (tipo) {
      //   this.tipoSelecionado = tipo
      // }
    }
  }
</script>

<style scoped>

.espacamento-titulo {
  padding-left: 16px!important;
}

.espacamento-esquerda {
  padding-left: 11px!important;
}

.botoesTopoSelecionado {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: var(--primaria);
  width: 100px;
  color: white;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopoSelecionado{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-top: 2px;
}

.botoesTopo {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: white;
  width: 80px;
  color: #7E8185;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopo{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
}

@media screen and (max-width: 960px) {
  .espacamento-titulo {
    padding-left: 0px!important;
  }
  .espacamento-esquerda {
    padding-left: 0px!important;
  }
}
</style>
