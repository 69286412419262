<template>
  <v-app>
    <NavigationDrawer />
    <v-main>
      <v-system-bar
          v-if="$session.get('visaoAdministrativa').idUsuario"
          color="roxo"
          :height="this.$vuetify.breakpoint.mobile ? 55 : 35 "
          class="d-flex"
      >
        <v-icon
            size="25"
            color="white"
            class="mr-2"
        >
          pc-senha-on
        </v-icon>
        <span class="white--text">
          Visão administrativa ativa do usuário: <u>{{ $session.get('visaoAdministrativa').login }}</u>
        </span>
        <v-spacer />
        <v-btn
            icon
            class="ml-2"
        >
          <v-icon
              size="10"
              color="white"
              @click="fecharVisaoAdministrativa"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-system-bar>
      <v-container fluid class="c">
        <router-view />
      </v-container>
      <DialogAlertaVisaoAdministrativa />
    </v-main>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  import { pushRoute } from '@/plugins/helpers'
  import { TipoPerfil } from '@/plugins/constants'
  import NavigationDrawer from '@/components/NavigationDrawer'
  import session from '@/plugins/session'
  import router from '@/plugins/router'
  import DialogAlertaVisaoAdministrativa from '@/components/dialog/DialogAlertaVisaoAdministrativa'

  export default {
    components: { NavigationDrawer, DialogAlertaVisaoAdministrativa },
    data: () => ({
      configuracoes: { },
      unidade: {},
      drawer: true,
      verificarInatividade: null,
      ultimaInteracao: new Date()
    }),
    computed: {
      ...mapState(['perfil']),
      tempoMaximoInatividade () {
        // Em minutos
        return 60
      }
    },
    watch: {
      drawer (value) {
        this.$store.commit('setDrawer', value)
      }
    },
    beforeDestroy () {
      clearInterval(this.verificarInatividade)
    },
    created () {
      const perfil = session.get('usuario').perfil
      this.verificarInatividade = setInterval(() => {
        const now = new Date()
        // Quanto tempo já passou desde a última interação em minutos
        const tempo_passado = Math.floor((now - this.ultimaInteracao) / 1000) / 60

        if (tempo_passado >= this.tempoMaximoInatividade) {
          this.$session.destroy()
          if (perfil === TipoPerfil.MASTER || perfil === TipoPerfil.GESTOR) {
            router.push({
              name: 'login'
            })

            this.$toast.error('Sessão expirada por inatividade. Acesse novamente para continuar utilizando a plataforma')
            console.info(`Usuário deslogado após ${this.tempoMaximoInatividade} minutos de inatividade`)
          }
        }
      }, 1000)

      document.addEventListener('click', () => {
        // eslint-disable-next-line no-console
        this.ultimaInteracao = new Date()
      })
    },
    mounted () {
      this.unidade = session.get('unidade')
      this.configuracoes = session.get('configuracoes')
    },
    methods: {
      fecharVisaoAdministrativa () {
        if (this.$session.get('unidadeAnterior').idUnidade !== '') {
          session.set({
            unidade: {
              urlLogotipo: this.$session.get('unidadeAnterior').urlLogotipo,
              idUnidade: this.$session.get('unidadeAnterior').idUnidade,
              nomeUnidade: this.$session.get('unidadeAnterior').nomeUnidade,
              logotipo: this.$session.get('unidadeAnterior').logotipo,
              corPrimaria: this.$session.get('unidadeAnterior').corPrimaria,
              corSecundaria: this.$session.get('unidadeAnterior').corSecundaria,
              corDestaque: this.$session.get('unidadeAnterior').corDestaque,
              corTextoPrimario: this.$session.get('unidadeAnterior').corTextoPrimario,
              corTextoSecundario: this.$session.get('unidadeAnterior').corTextoSecundario,
              corTextoDestaque: this.$session.get('unidadeAnterior').corTextoDestaque,
              autenticacaoMultifator: this.$session.get('unidadeAnterior').autenticacaoMultifator
            }
          })
          this.$vuetify.theme.themes.light.primaria = this.$session.get('unidadeAnterior').corPrimaria || '#FFFFFF'
          this.$vuetify.theme.themes.light.secundaria = this.$session.get('unidadeAnterior').corSecundaria || '#000000'
          this.$vuetify.theme.themes.light.destaque = this.$session.get('unidadeAnterior').corDestaque || '#F0F0F0'
          this.$vuetify.theme.themes.light.textoprimario = this.$session.get('unidadeAnterior').corTextoPrimario || '#000000'
          this.$vuetify.theme.themes.light.textosecundario = this.$session.get('unidadeAnterior').corTextoSecundario || '#F0F0F0'
          this.$vuetify.theme.themes.light.textodestaque = this.$session.get('unidadeAnterior').corTextoDestaque || '#0F0F0F'
          this.$session.set({
            unidadeAnterior: {
              idUnidade: '',
              nomeUnidade: '',
              logotipo: '',
              corPrimaria: '',
              corSecundaria: '',
              corDestaque: '',
              corTextoPrimario: '',
              corTextoSecundario: '',
              corTextoDestaque: '',
              autenticacaoMultifator: true
            }
          })
        }

        session.set({
          visaoAdministrativa: {
            login: '',
            idUsuario: '',
            perfil: '',
            centroCusto: ''
          }
        })

        this.$router.push({
          name: 'Inicio'
        })

        this.$router.go()
      },
      routePush (name) {
        pushRoute(name)
      },
      menuRoute (name) {
        const route = this.$router.resolve({ name }).route
        return { text: route.meta.title, to: route.path }
      },
      isMenuActive (rotaAtual) {
        return this.$router.currentRoute.path === rotaAtual
      }
    }
  }
  </script>

    <style scoped>

    .c {
      padding: 0px;
    }

    .c > div {
        display: block;
    }
      .container-icone {
        width: 50px;
        text-align: center;
      }

    .v-list__group__header--active .v-list__group__header__append-icon .v-icon {
      color: #FFFFFF;
    }

    .v-list__group__header .v-list__group__header__append-icon .v-icon {
      color: #FFFFFF;
    }
  </style>

  <style lang="scss" scoped>
    .v-icon {
      margin: 0;
    }

    .theme--light.v-expansion-panels .v-expansion-panel {
      background-color: transparent;
    }

    .v-expansion-panel::before {
      box-shadow: none;
    }

    .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
      height: 0;
      border-color: transparent;
    }

    //noinspection CssInvalidPseudoSelector
    .v-expansion-panel-header, .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap, .menu-item {
      padding: 8px 0 8px 6px;
      line-height: 1;
      min-height: 0;
      font-size: 16px;
      color: var(--white);
      border-radius: 0;
    }

    .v-expansion-panel-header, .menu-item {
    text-decoration: none;

      &:hover {
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
      margin-top: 0;
    }

    //noinspection CssInvalidPseudoSelector
    .v-expansion-panel-header::v-deep .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
      transform: rotate(90deg);
      color: var(--white);
    }

    //noinspection CssInvalidPseudoSelector
    .v-expansion-panel--active > .v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
      transform: rotate(0deg);
    }

    .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
      flex: none;
      margin-right: 6px;
    }

    .menu-item.menu-active {
      .menu-icone {
        color: var(--secundaria) !important;
      }
      background: var(--white);
      color: var(--secundaria);
    }

    .logo-menu {
      width: 90px;
    }
  </style>
