import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function transferirGestaoUnificada (idSublogin, userNovoComissionado, idUnidade) {
  try {
    let dataObject = {
      idSublogin: idSublogin,
      userNovoComissionado: userNovoComissionado
    }
    const data = await http.post(`/gestao-unificada/sublogin/transferir/${idUnidade}`, dataObject)
    console.warn(data)
    return data.data
  } catch (error) {
    throw get(error, 'response.data.messagem', Mensagens.ERRO)
  }
}
