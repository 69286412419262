<template>
  <v-dialog
      :scrollable="true"
      width="667"
      content-class="app-content"
      v-model="dialog"
      persistent
  >
    <v-card>
      <v-card-title>
        <span class="titulo-dialog">Cadastro de versão do documento empresa: {{ unidade.nomeUnidade }} </span>
        <v-btn
            id="ButtonDialogFecharNovaVersaoDocumento"
            icon
            class="ml-auto"
            @click="dialog = false"
        >
          <v-icon color="primaria" size="10">pc-fechar</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-form
          :key="chaveFormulario"
          v-model="formularioValido"
          ref="formVersaoDocumento"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <TextField
                  id="TextFieldIdDocumento"
                  label="ID do documento"
                  v-model="documento.id"
                  v-uppercase
                  disabled
              />
            </v-col>
            <v-col cols="12" md="9">
              <TextField
                  id="TextFieldTituloDocumento"
                  label="Título do documento"
                  v-model="documento.tituloDocumento"
                  v-uppercase
                  disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <TextField
                  id="TextFieldIdUnidade"
                  label="ID da Unidade"
                  v-model="documento.idUnidade"
                  v-uppercase
                  disabled
              />
            </v-col>
            <v-col cols="12" md="9">
              <img :src="session.get('unidade').urlLogotipo" :alt="session.get('unidade').nomeUnidade" width="120" height="45">
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <TextField
                  id="TextFieldVersaoArquivo"
                  label="Versão do arquivo"
                  v-model="documento.versaoDocumento"
                  v-uppercase
                  disabled
              />
            </v-col>
            <v-col cols="12" md="9">
              <FileInput
                  id="FileInputDocumento"
                  label="Arquivo"
                  v-model="documento.rotulo"
                  :accept="['application/pdf']"
                  @arquivo="anexarArquivo"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <Button
              id="ButtonCancelarNovoDocumento"
              small
              outlined
              color="primaria"
              class="ml-auto"
              @click="dialog = false"
          >
            CANCELAR
          </Button>
          <Button
              id="ButtonConfirmarNovoDocumento"
              small
              class="primaria"
              @click="cadastrarNovoDocumento()"
          >
            CONFIRMAR
          </Button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { editarDocumentoPolitica } from '@/service/politica'

  export default {
    props: {
      documento: Object,
      unidade: Object
    },
    data: () => ({
      dialog: false,
      formularioValido: false,
      chaveFormulario: 0
    }),
    watch: {
      dialog () {
        this.documento.rotulo = ''
        this.chaveFormulario++
        this.documento.versaoDocumento++
      }
    },
    methods: {
      show () {
        this.dialog = true
      },
      anexarArquivo (file) {
        this.documento.rotulo = file
      },
      async cadastrarNovoDocumento () {
        try {
          this.$refs.formVersaoDocumento.validate()

          if (this.formularioValido) {
            if (this.documento.rotulo) {
              const formData = new FormData()
              formData.append('idDocumento', this.documento.id)
              formData.append('arquivo', this.documento.rotulo)
              await editarDocumentoPolitica(formData, false)
              this.dialog = false
              this.$emit('close', true)
              return
            }
          }

          this.$toast.error('Preencha os campos obrigatórios!')
        } catch (erro) {
          this.$toast.error(erro)
        }
      }
    }
  }
</script>

<style scoped>
  .subtitulo-dialog {
    font-size: 14px;
    color: var(--black);
    margin: 20px 0;
  }
</style>
