import { BrowserCacheLocation } from '@azure/msal-browser'

export const persistentes = {
  politicasPendentes: [],
  usuario: {
    id: '',
    nome: '',
    login: '',
    token: '',
    perfil: '',
    email: '',
    telefone: '',
    codigoRevenda: '',
    principal: false
  },
  unidadeAnterior: {
    idUnidade: '',
    nomeUnidade: '',
    logotipo: '',
    corPrimaria: '',
    corSecundaria: '',
    corDestaque: '',
    corTextoPrimario: '',
    corTextoSecundario: '',
    corTextoDestaque: '',
    autenticacaoMultifator: true,
    urlLogotipo: ''
  },
  unidade: {
    idUnidade: '',
    nomeUnidade: '',
    logotipo: '',
    corPrimaria: '',
    corSecundaria: '',
    corDestaque: '',
    corTextoPrimario: '',
    corTextoSecundario: '',
    corTextoDestaque: '',
    autenticacaoMultifator: true
  },
  configuracoes: {
    newconUrl: '',
    uceUrl: '',
    areaAdmUrl: '',
    segundosRefreshNotas: undefined,
    coligadas: [],
    versao: ''
  },
  visaoAdministrativa: {
    nome: '',
    login: '',
    idUsuario: '',
    perfil: '',
    centroCusto: ''
  },
  urlConvertMais: '#',
  solicitacaoMapaComissao: {
    id: 0,
    statusNotificacao: '',
    nomeDataBase: '',
    anoReferencia: ''
  },
  reloadInicial: false
}
export const nao_persistentes = {
  loading: false,
  recarregar: false,
  drawer: true,
  carregandoSilenciosamente: false,
  rotaAtual: '',
  exibirDialogVisao: false,
  msalConfig: {
    auth: {
      clientId: process.env.VUE_APP_AZURE_APP_ID,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_TENANT_ID}`,
      redirectUri: '/auth/callback',
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false
    }
  },
  msalAuthRequest: {
    prompt: 'select_account'
  }
}
