<template>
  <v-dialog v-on="$listeners" :scrollable="true" content-class="app-content" v-model="visible" persistent>
    <v-card>
      <v-card-title>
        <slot name="title">{{ options.title }}</slot>
        <v-btn icon class="ml-auto" @click="visible = false">
          <v-icon dark size="12">pc-fechar</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text id="content">
        <slot>{{ options.text }}</slot>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <div ref="alert_actions" class="alert_actions">
          <slot name="buttons">
            <Button class="ml-5"
                    small
                    v-bind:key="i"
                    v-for="(btn, text, i) in options.buttons"
                    :outlined="btn.outlined || (i == Object.keys(options.buttons).length - 1 ? false : true)"
                    :autofocus="btn.focus"
                    :class="btn.focus ? 'focus' : ''"
                    :color="btn.color || (i == Object.keys(options.buttons).length - 1 ? 'secundaria' : 'secundaria')"
                    @click="handle(btn)">
              {{ text }}
            </Button>
          </slot>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Vue from 'vue'
  import { isObject } from '@/plugins/helpers'

  const alertTemplate = { title: 'Atenção!', text: '', buttons: {} }

  export default {
    data: () => ({
      visible: false,
      options: alertTemplate
    }),
    methods: {
      show (options) {
        if (!isObject(options)) {
          options = Object.assign({}, alertTemplate, {
            text: options, buttons: { 'OK': { focus: true } }
          })
        }

        if (options.buttons) {
          Object.keys(options.buttons).forEach(key => {
            let btn = options.buttons[key]

            if (!isObject(btn)) {
              btn = options.buttons[key] = {
                handler: btn
              }
            }

            if (!btn.handler) {
              btn.handler = () => true
            }
          })
        }

        this.options = Object.assign({}, alertTemplate, options)
        this.visible = true

        Vue.nextTick(() => {
          const el = this.$refs.alert_actions.querySelector('.focus')
          if (el) {
            el.focus()
          }
        })
      },
      dismiss () {
        this.visible = false
      },
      handle (h) {
        if (h && h.handler(this) !== false) {
          this.dismiss()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #content {
    max-height: 300px;
    white-space: pre-wrap;
  }

  //noinspection CssInvalidPseudoSelector
  .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar) {
    border-top-left-radius: 80px;
    border-top-right-radius: 8px;
  }

  .v-sheet.v-card {
    border-radius: 8px;
  }

  .v-dialog > .v-card > .v-card__title {
    font-size: 16px;
    padding: 24px;
  }

  .v-btn--icon.v-size--default {
    width: 11px;
    height: 11px;
  }

  .v-card__title {
    line-height: 19px;
  }

  .v-dialog > .v-card > .v-card__text, .v-dialog > .v-card > .v-card__actions {
    padding: 20px 16px;
    line-height: 1;
  }
</style>

<style>
  .v-dialog {
    width: auto;
    min-width: 360px;
  }
</style>
