<template>
  <v-dialog
      persistent
      width="700px"
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>
        <span>Download em lote</span>
      </v-card-title>
      <v-card-text>
        <v-container class="text-center" v-if="loading == true">
          <v-card-text>
            <span>
              <Carregando :texto="textoCarregando" v-if="concluido == false" />
            </span>
            <div v-if="concluido === true & erros.length === 0">
              <h3 style="font-weight: bolder;"> Arquivo concluido sem erros.</h3>
            </div>
          </v-card-text>
        </v-container>
      </v-card-text>
      <v-card-actions class="notas-erro-container">
        <CardNotasErros :notas="erros" v-if="erros.length > 0" />
      </v-card-actions>
      <v-card-actions>
        <v-spacer />
        <Button
            v-if="concluido === true"
            @click="concluirLote"
        >
          Concluir lote
        </Button>
        <Button
            v-if="concluido === true"
            @click="fechar"
        >
          Manter lote
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
    .notas-erro-container{
        display: flex;
        justify-content: center;
    }
</style>
  <script>

  import { buscarMultiplasUrls, conferirMultiplasNotas } from '@/service/notasFiscais'
  import axios from 'axios'
  import Carregando from '@/components/Carregando'
  import CardNotasErros from '@/components/card/CardNotasErros'
  import JSZip from 'jszip'

  export default {
    components: {
      Carregando,
      CardNotasErros
    },
    props: {
      login: String,
      listaIds: Array,

      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      loading: false,
      concluido: false,
      lodingText: '',
      erros: [],
      nome: '',
      files: [],
      textoCarregando: ''
    }),
    watch: {
      exibir (valor) {
        if (valor) {
          this.buscarUrlDownloadMultiplasNotas()
        }
      }
    },
    mounted () {
      this.$emit('bloquear', true)
    },

    methods: {
      async buscarUrlDownloadMultiplasNotas () {
        this.textoCarregando = 'Procurando notas'
        this.loading = true
        const files = await buscarMultiplasUrls(this.listaIds)
        const fileUrl = []
        files.forEach(async item => {
          if (item.falha === true) {
            this.erros.push(item)
          } else {
            fileUrl.push(item)
          }
        })
        const requestList = fileUrl.map(item => axios.get(process.env.VUE_APP_WS_ROOT + item.path, { responseType: 'arraybuffer' }))
        this.textoCarregando = 'Baixando notas'
        axios.all(requestList)
          .then(axios.spread((...responses) => responses.forEach((item, index) => {
            if (item.status === 200) {
              var name = String(item.config.url).replace(`${process.env.VUE_APP_WS_ROOT}/files/`, `${index + 1}_`)
              this.files.push({
                name,
                data: item.data
              })
            } else {
              console.info('status 400')
              this.erros.push(fileUrl[index])
            }
          }))).finally(() => {
            this.lote()
          })
      },
      async lote () {
        this.textoCarregando = 'Montando o lote'
        const zip = new JSZip()
        if (this.files.length === 0) {
          this.concluido = true
          return
        }
        this.files.map(file => zip.file(file.name, file.data, { binary: true }))
        const zipFile = await zip.generateAsync({ type: 'blob' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(zipFile)
        this.nome = await this.nomeArquivo()
        link.download = `lote_${this.nome}`
        link.click()
        this.concluido = true
      },
      async nomeArquivo () {
        let hoje = new Date()
        let dia = hoje.toJSON().slice(0, 10)
        let hora = hoje.getHours() < 10 ? '0' + hoje.getHours() : hoje.getHours() + 'h'
        let minutos = +hoje.getMinutes() < 10 ? '0' + hoje.getMinutes() : hoje.getMinutes() + 'm'
        let data = dia + '_' + hora + minutos
        return String(data)
      },
      async concluirLote () {
        await conferirMultiplasNotas(this.listaIds)
        this.fechar()
      },

      fechar () {
        this.textoCarregando = 'Procurando notas'
        this.progresso = 0
        this.erros = []
        this.files = []
        this.listOfIds = []
        this.loading = false
        this.concluido = false
        this.$emit('exibir', false)
        this.$emit('bloquear', false)
        this.$emit('atualizar')
      }

    }
  }

</script>
