<template>
  <v-container fluid>
    <h1 class="titulo">Selecionar Parceiro</h1>
    <v-row>
      <v-spacer />
      <v-col
          lg="2"
          md="3"
          cols="12"
      >
        <ButtonIcon
            small
            block
            icone="pc-filtro"
            color="primaria"
            texto="FILTRAR"
            @click="filtrar"
        />
      </v-col>
    </v-row>
    <DataTable
        silent
        class="mt-5"
        item-key="cnpj"
        :params="params"
        :filter="filters"
        :headers="headers"
        :url="urlDataTable"
        :loading="$store.state.carregandoSilenciosamente"
        :key="dataTableKey"
    >
      <template v-slot:[`item.revenda`]="item">
        <td>{{ item.revenda }}</td>
      </template>
      <template v-slot:[`item.cnpj`]="item">
        <td>{{ item.cnpj }}</td>
      </template>
      <template v-slot:[`item.cfo`]="item">
        <td>{{ parseInt(item.cfo) }}</td>
        <td class="text-right">
          <v-btn
              plain
              small
              color="primaria"
              @click="transferir(item.cfo)"
          >
            SELECIONAR PARCERIA
          </v-btn>
        </td>
      </template>
    </DataTable>
    <DialogFiltrarParceria
        :exibir="exibirDialogFiltro"
        @exibir="exibir => exibirDialogFiltro = exibir"
        @filtro="aplicarFiltro"
    />
  </v-container>
</template>

<script>

  import DialogFiltrarParceria from '@/components/dialog/DialogFiltrarParceria'
  import { transferirComissionado } from '@/service/comissionado'

  export default {
    components: { DialogFiltrarParceria },
    data: () => ({
      urlDataTable: '/listar-parcerias',
      dataTableKey: 1,
      exibirDialogFiltro: false,
      filtro: {
        nome: '',
        cpfCnpj: '',
        usuario: ''
      },
      headers: [
        { text: 'Parceria', value: 'revenda', sortable: false },
        { text: 'CPF/CNPJ', value: 'cnpj', sortable: false },
        { text: 'CFO', value: 'cfo', sortable: false },
        { text: '', value: '', sortable: false }
      ],
      filters: {},
      pagination: {
        sortBy: [], sortDesc: [ true ]
      }
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    mounted () {
      this.$store.commit('setRotaAtual', 'Parcerias')
    },
    methods: {
      filtrar () {
        this.exibirDialogFiltro = true
      },
      aplicarFiltro (filtro) {
        this.urlDataTable = `/listar-parcerias${filtro}`
        this.dataTableKey++
      },
      async transferir (cfo) {
        try {
          const { mensagem } = await transferirComissionado(this.$route.params.login, cfo)
          this.$toast.success(mensagem)
        } catch (erro) {
          this.$toast.error(erro)
        }

        this.$router.go(-1)
      }
    }
  }

</script>
