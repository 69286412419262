<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <v-icon color="primaria">
        pc-calendario
      </v-icon>
      <span class="ano">{{ informe.ano }}</span>
    </v-card-text>
    <v-card-text>{{ informe.nomeOriginal }}</v-card-text>
    <v-card-actions>
      <a
          target="_blank"
          :href="informe.url"
          class="visualizar pointer"
      >
        Visualizar
      </a>
      <v-spacer />
      <a
          download
          :href="informe.url"
      >
        <v-icon color="primaria">pc-download</v-icon>
      </a>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'CardRendimento',
    props: {
      informe: Object
    }
  }
</script>

<style scoped>
  .v-card {
    padding: 0.5rem;
  }

  .ano {
    font-weight: 600 !important;
    font-size: 18px;
    margin-left: 10px;
    color: var(--black);
  }

  .visualizar {
    text-decoration: underline;
    font-size: 16px;
    color: var(--black);
  }

  .pointer {
    cursor: pointer;
  }
</style>
