<template>
  <v-container fluid style="background-color: #f7f7f7; min-height: calc(100vh - 80px);">
    <v-row v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
      <v-col class="mb-0 pb-0" cols="12" md="2">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="22px"
            class="ml-0"
        />
        <span v-else class="titulo">Meus Clientes</span>
      </v-col>
      <v-col cols="12" md="2" class="pr-0 pl-1" id="colunaExtrair">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="18px"
            class="skeletonExtrairWeb"
        />
        <v-menu v-else
                v-model="extrairMenu"
                attach="#colunaExtrair"
                content-class="rounded-t-xl elevation-5"
                :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card
                v-bind="attrs" v-on="on"
                style="border: 1px solid #E7E7E8; border-radius: 100px; width: 100%; height: 32px; background-color: #FFFFFF;"
                class="d-flex btn-extrair align-center justify-space-between px-3"
                @click="extrairMenu = true"
                elevation="0"
            >
              <span class="item-selecionado-label-extracao">Extrair arquivos</span>
              <v-icon class="mt-1">mdi-chevron-down</v-icon>
            </v-card>
          </template>
          <v-card elevation="0" style="background: #CFD0D1;">
            <v-card
                style="border: 1px solid #E7E7E8; border-radius: 100px; width: 100%; height: 32px; background-color: #FFFFFF;"
                class="d-flex btn-extrair align-center justify-space-between px-3"
                @click="extrairMenu = false"
                elevation="0"
            >
              <span class="item-selecionado-label-extracao">Extrair arquivos</span>
              <v-icon class="mt-1">mdi-chevron-down</v-icon>
            </v-card>
            <v-card-text>
              <v-checkbox
                  v-model="opcao"
                  off-icon="mdi-square-rounded"
                  value="xslx"
                  hide-details
              >
                <template v-slot:label>
                  <span style="font-size: 12px!important;">arquivo</span><b style="font-size: 12px!important;">.XLSX</b>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="opcao"
                  off-icon="mdi-square-rounded"
                  value="pdf"
                  hide-details
              >
                <template v-slot:label>
                  <span style="font-size: 12px!important;">arquivo</span><b style="font-size: 12px!important;">.PDF</b>
                </template>
              </v-checkbox>
              <ButtonIcon
                  icone="mdi-tray-arrow-up"
                  texto="Extrair"
                  style="width: 95%!important"
                  class="btn-extrair"
                  @click="extrair"
                  outlined
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4"></v-col>
      <v-col :class="isMobile ? 'mt-0 pt-2 pt-0 pb-0 d-flex justify-start pr-3' : 'mt-0 pt-12 pb-2 d-flex justify-end pr-3'" cols="12" md="4">
        <table class="tabelaLegenda" style="width: 100%; margin-top: -30px;">
          <tbody>
            <tr>
              <td colspan="4">
                <!-- <v-skeleton-loader
                    v-if="loadingSkeleton"
                    type="text"
                    style="width: 50%;"
                    class="skeletonLegenda"
                /> -->
              </td>
            </tr>
            <v-row v-if="loadingSkeleton">
              <v-col cols="3" v-for="n in 4" :key="n" class="skeletonLegendaTipos">
                <v-skeleton-loader type="text" :transition="transition" />
              </v-col>
            </v-row>
            <tr v-else>
              <td class="texto-legenda texto-sucesso pr-2"><img class="imglegenda" src="@/assets/img/sucesso.png">Sucesso</td>
              <td class="texto-legenda texto-informativo pr-2"><img class="imglegenda" src="@/assets/img/informativo.png">Informativo</td>
              <td class="texto-legenda texto-pendencia pr-2"><img style="padding-right: 5px;padding-bottom: 2px;" src="@/assets/img/pendencia.png">Pendência</td>
              <td class="texto-legenda texto-atencao pr-1"><img class="imglegenda" src="@/assets/img/atencao.png">Atenção</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row v-if="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" style="margin-left: -5px;">
      <v-col class="mt-0 pt-7 pt-0 pb-0 d-flex justify-start pr-3" cols="12" md="4">
      </v-col>
      <v-col class="mb-0 pb-0 pb-2 pt-0" cols="6" md="6">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="18px"
        />
        <span v-else class="titulo">Meus Clientes</span>
      </v-col>
      <v-col cols="6" md="6" class="pr-4 pl-0 pt-0" id="colunaExtrair">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="18px"
            class="skeletonExtrair"
        />
        <v-menu v-else
                attach="#colunaExtrair"
                :close-on-content-click="false"
                content-class="rounded-t-xl elevation-5"
                v-model="extrairMenu"
                elevation="0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card
                v-bind="attrs" v-on="on"
                style="border: 1px solid #E7E7E8; height: 36px; border-radius: 100px; background-color: #FFFFFF;"
                class="d-flex btn-extrair align-center justify-space-between px-3"
                @click="extrairMenu = true"
                elevation="0"
            >
              <span class="item-selecionado-label-extracao">Extrair arquivos</span>
              <v-icon class="mt-1">mdi-chevron-down</v-icon>
            </v-card>
          </template>

          <v-card elevation="0" style="background: #CFD0D1;">
            <!-- Efeito visual -->
            <v-card
                style="border: 1px solid #E7E7E8; border-radius: 100px; height: 36px; background-color: #FFFFFF;"
                class="d-flex btn-extrair align-center justify-space-between px-3"
                @click="extrairMenu = false"
                elevation="0"
            >
              <span class="item-selecionado-label-extracao">Extrair arquivos</span>
              <v-icon class="mt-1">mdi-chevron-down</v-icon>
            </v-card>
            <v-card-text style="padding: 15px 2px !important;">
              <v-checkbox
                  v-model="opcao"
                  off-icon="mdi-square-rounded"
                  style="margin-left: 3px;"
                  value="xslx"
                  hide-details
              >
                <template v-slot:label>
                  <span style="font-size: 12px!important; font-family: Inter">arquivo</span><b>.XLSX</b>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="opcao"
                  off-icon="mdi-square-rounded"
                  style="margin-left: 3px;"
                  value="pdf"
                  hide-details
              >
                <template v-slot:label>
                  <span style="font-size: 12px!important; font-family: Inter">arquivo</span><b>.PDF</b>
                </template>
              </v-checkbox>
              <ButtonIcon
                  icone="mdi-tray-arrow-up"
                  texto="Extrair"
                  style="width:95%!important; height: 36px!important"
                  class="btn-extrair"
                  @click="extrair"
                  outlined
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
      <table class="tabelaLegenda">
        <tbody>
          <v-row v-if="loadingSkeleton" class="mr-1">
            <v-col cols="3" v-for="n in 4" :key="n">
              <v-skeleton-loader type="text" :transition="transition" />
            </v-col>
          </v-row>
          <tr v-else>
            <td class="texto-legenda texto-sucesso"><img class="imglegenda" src="@/assets/img/sucesso.png">Sucesso</td>
            <td class="texto-legenda texto-informativo"><img class="imglegenda" src="@/assets/img/informativo.png">Informativo</td>
            <td class="texto-legenda texto-pendencia"><img style="padding-right: 5px;padding-bottom: 2px;" src="@/assets/img/pendencia.png">Pendência</td>
            <td class="texto-legenda texto-atencao"><img class="imglegenda" src="@/assets/img/atencao.png">Atenção</td>
          </tr>
        </tbody>
      </table>
    </v-row>
    <v-row class="d-flex flex-column mt-10 mb-0 pb-0 pr-2" v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" style="background-color: #f7f7f7;">
      <v-row class="mx-2">
        <v-col cols="12" md="4" v-if="isSublogin" id="colunaParceiro">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              class="filtrosSkeletonWeb"
              height="50"
          />
          <v-row v-else class="mr-1" style="border: 1px solid #E7E7E8; border-radius: 8px; background-color: #FFFFFF;">
            <v-col cols="8">
              <v-menu
                  fixed
                  :absolute="false"
                  :position-x="10"
                  :position-y="10"
                  min-width="30%"
                  width="30%"
                  attach="#colunaParceiro"
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-bind="attrs" v-on="on" elevation="0" style="align-items: center;">
                    <div style="text-align: start">
                      <table style="width: 100%;">
                        <tr>
                          <td style="width: 90%;">
                            <span style="color: #5E6267; font-size: 16px; text-wrap: ellipsis; overflow-x: hidden;">Visualizar clientes de </span>
                          </td>
                          <td>
                            <v-icon>mdi-chevron-down</v-icon>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-card>
                </template>
                <div class="item-gestor" @click="visualizarGestor()" style="margin-top: 12px;">
                  <v-row>
                    <v-col style="padding-left: 25px;">
                      <v-icon color="white">mdi-storefront-outline</v-icon><span style="color: white"> {{ usuarioLogado.login }}</span>
                    </v-col>
                  </v-row>
                </div>
                <div style="max-height: 300px; overflow-y: auto;">
                  <v-list style="cursor: pointer">
                    <v-list-item
                        v-for="(item, index) in sublogins"
                        :key="index"
                        @click="filtroSubloginSelecionado(item)"
                        :class="verificaClassItem(item)"
                    >
                      <v-list-item-title :class="item.login === subloginSelecionado.login ? 'item-selecionado-parceiro-vendedor' : 'parceiro-selecionado'" @click="selecionarSubLogins(item)" v-if="item.tipo === 'vendedor'">{{ item.login }} ({{ item.tipo }})</v-list-item-title>
                      <v-list-item-title :class="item.login === subloginSelecionado.login ? 'item-selecionado-parceiro-revenda' : 'parceiro-selecionado-revenda'" @click="selecionarSubLogins(item)" v-else>{{ item.login }}  ({{ item.tipo }})</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </v-col>
            <v-divider
                vertical
                class="my-3"
            />
            <v-col cols="4" class="item-selecionado-label-tipo" style="display:flex; align-items:center">
              Parceiro
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" v-if="isSublogin" id="colunaTipo">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              class="w-100"
              height="50"
          />
          <v-row v-else class="mx-1" style="margin-bottom: 12px !important;border: 1px solid #E7E7E8; border-radius: 8px; background-color: #FFFFFF;" id="colunaData">
            <v-col cols="7" v-if="tipoSelecionado === 'Tipo'">
              <span style="color: #5E6267; font-size: 16px;">Filtrar por</span>
            </v-col>
            <v-col cols="7" class="pb-0" v-if="tipoSelecionado === 'CPF/CNPJ'">
              <v-text-field
                  v-model="textoFiltroDocumento"
                  dense
                  hide-details
                  :key="'CPF'"
                  v-mask="textoFiltroDocumento.length > 14 ? '##.###.###/####-##' : '###.###.###-##'"
                  style="font-size: 12px!important;"
                  placeholder="Digite o CPF ou CNPJ"
              />
            </v-col>
            <v-col cols="4" class="pb-0" v-if="tipoSelecionado === 'Grupo/Cota'">
              <v-text-field
                  v-model="textoFiltro"
                  dense
                  hide-details
                  :key="'Grupo'"
                  style="font-size: 12px!important;"
                  placeholder="Grupo"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="ma-0 pa-0" size="20">mdi-account-multiple</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3" class="pb-0" v-if="tipoSelecionado === 'Grupo/Cota'">
              <v-text-field
                  v-model="textoFiltroCota"
                  dense
                  hide-details
                  :key="'Cota'"
                  style="font-size: 12px!important;"
                  placeholder="Cota"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="ma-0 pa-0" size="20">mdi-puzzle</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="7" class="pb-0" v-if="tipoSelecionado === 'Período'">
              <v-menu fixed
                      :absolute="false"
                      :position-x="10"
                      :position-y="10"
                      attach="#colunaData"
                      v-model="menuDataInicial"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dateRangeText"
                      prepend-icon="mdi-calendar"
                      readonly
                      dense
                      :key="'Periodo'"
                      style="font-size: 12px!important;"
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    color="primaria"
                    range
                    :max="maxDates"
                >
                  <v-btn
                      text
                      color="primaria"
                      @click="menuDataInicial = false"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="7" class="pb-0" v-if="tipoSelecionado === 'Proposta'">
              <v-text-field
                  v-model="textoFiltroProposta"
                  dense
                  type="number"
                  hide-details
                  :key="'Proposta'"
                  style="font-size: 12px!important;"
                  placeholder="Digite a Proposta"
              />
            </v-col>
            <v-divider
                vertical
                class="my-3"
            />
            <v-col cols="5">
              <v-menu fixed
                      :absolute="false"
                      :position-x="10"
                      :position-y="10"
                      attach="#colunaTipo"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-bind="attrs" v-on="on" elevation="0" style="align-items: center;">
                    <div style="text-align: start">
                      <table style="width: 100%;">
                        <tr>
                          <td style="width: 90%;">
                            <span class="item-selecionado-label-tipo"> {{ tipoSelecionado }}</span>
                          </td>
                          <td>
                            <v-icon>mdi-chevron-down</v-icon>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-card>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in tipos"
                      :key="index"
                      class="tipo-hover"
                      @click="selecionaTipo(item)"
                  >
                    <v-list-item-title :class="item === tipoSelecionado ? 'item-selecionado' : 'item-sem-selecao'">{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" v-if="isSublogin" id="colunaSituacao">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              class="filtrosSkeletonWebSituacao"
              height="50"
          />
          <v-row v-else class="ml-1" style="border: 1px solid #E7E7E8; border-radius: 8px; background-color: #FFFFFF;">
            <div class="col-md-12">
              <v-menu
                  attach="#colunaSituacao"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  v-model="menuSituacao">
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-bind="attrs" v-on="on" elevation="0" style="align-items: center;">
                    <div style="text-align: start">
                      <table style="width: 100%;">
                        <tr>
                          <td style="width: 99%;">
                            <span style="color: rgb(94, 98, 103)">Situação da cota</span>
                          </td>
                          <td>
                            <v-icon>mdi-chevron-down</v-icon>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-card>
                </template>

                <v-card elevation="0" @hook:mounted="selecionaItemMenu" @hook:updated="selecionaItemMenu">
                  <v-card-text>
                    <table class="tabelaLegenda">
                      <tbody>
                        <tr>
                          <td @click="alterarTipoSituacao('Sucesso')" style="cursor: pointer; padding-right: 8px; padding-bottom: 7px;text-align: center;" :class="situacaoSelecionada === 'Sucesso' ? 'tabelaLegendaSituacaoSucessoSelecionada' : 'tabelaLegendaSituacaoSucesso' "><v-icon style="color:#3FD47B" v-if="situacaoSelecionada === 'Sucesso'">mdi-check</v-icon> SUCESSO</td>
                          <td @click="alterarTipoSituacao('Informativo')" style="cursor: pointer; padding-right: 8px; padding-bottom: 7px;text-align: center;" :class="situacaoSelecionada === 'Informativo' ? 'tabelaLegendaSituacaoInformativoSelecionada' : 'tabelaLegendaSituacaoInformativo'"><v-icon style="color:#90A5EC" v-if="situacaoSelecionada === 'Informativo'">mdi-information-variant</v-icon> INFORMATIVO</td>
                          <td @click="alterarTipoSituacao('Pendência')" style="cursor: pointer; padding-right: 8px; padding-bottom: 7px;text-align: center;" :class="situacaoSelecionada === 'Pendência' ? 'tabelaLegendaSituacaoPendenciaSelecionada' : 'tabelaLegendaSituacaoPendencia'"><v-icon style="color:#9349F4" v-if="situacaoSelecionada === 'Pendência'">mdi-minus</v-icon> PENDÊNCIA</td>
                          <td @click="alterarTipoSituacao('Atenção')" style="cursor: pointer; padding-right: 8px; padding-bottom: 7px;text-align: center;" :class="situacaoSelecionada === 'Atenção' ? 'tabelaLegendaSituacaoAtencaoSelecionada' : 'tabelaLegendaSituacaoAtencao'"><v-icon style="color:#EE4784" v-if="situacaoSelecionada === 'Atenção'">mdi-exclamation</v-icon> ATENÇÃO</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-text v-if="situacaoSelecionada === 'Sucesso'" id="situacaoSucesso">
                    <v-row>
                      <v-col cols="12" md="12">
                        <span style="font-size: 12px;">Situações da cota foram concluídas com êxito.</span>
                      </v-col>
                      <!-- <v-col cols="12" md="4" class="text-end">
                        <v-icon @click="alterarTipoSituacao('Atenção')">mdi-chevron-left</v-icon>
                        <v-icon @click="alterarTipoSituacao('Informativo')">mdi-chevron-right</v-icon>
                      </v-col> -->
                    </v-row>
                    <v-list-item class="sucesso-situacao-checkbox" :class='selecionarTodosSucesso ? " items-situacao-cota-sucesso-checked" : " items-situacao-cota-sucesso"'>
                      <v-list-item-content>
                        <v-checkbox
                            style="margin-left: 8px;"
                            label="Selecionar Todos"
                            v-model="selecionarTodosSucesso"
                            hide-details
                            :color="selecionarTodosSucesso ? 'white': '#3FD47B' "
                            @change="selecionarTodos('Sucesso', selecionarTodosSucesso)"
                        >
                          <template v-slot:label>
                            <span :class='selecionarTodosSucesso ? "checkboxLabelSucessoChecked" : "checkboxLabelSucesso"'>Selecionar Todos</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="sucesso-situacao-checkbox" :class='situacao.checked ? " items-situacao-cota-sucesso-checked" : " items-situacao-cota-sucesso"' v-for="(situacao) in opcoesStatusSucesso" :key="situacao.valor">
                      <v-list-item-content>
                        <v-checkbox
                            style="margin-left: 8px;"
                            :value="situacao.valor"
                            :label="situacao.nome"
                            v-model="situacao.checked"
                            v-bind:checked="situacao.checked"
                            :name="situacao.valor"
                            :id="situacao.valor"
                            :color="situacao.checked ? 'white': '#3FD47B'"
                            @change="atualizaSituacao(situacao)"
                            v-on:input="situacao.checked = $event.target.value"
                            hide-details
                        >
                          <template v-slot:label>
                            <span :class='situacao.checked ? "checkboxLabelSucessoChecked" : "checkboxLabelSucesso"'> {{ situacao.nome }}</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>

                  <v-card-text v-if="situacaoSelecionada === 'Informativo'" id="situacaoInformativo">
                    <v-row>
                      <v-col cols="12" md="12">
                        <span style="font-size: 12px;">Informações de acompanhamento da cota que NÃO dependem de ação.</span>
                      </v-col>
                      <!-- <v-col cols="12" md="4" class="text-end">
                        <v-icon @click="alterarTipoSituacao('Sucesso')">mdi-chevron-left</v-icon>
                        <v-icon @click="alterarTipoSituacao('Pendência')">mdi-chevron-right</v-icon>
                      </v-col> -->
                    </v-row>
                    <v-list-item class="informativo-situacao-checkbox " :class='selecionarTodosInformativo ? " items-situacao-cota-informativo-checked" : " items-situacao-cota-informativo"'>
                      <v-list-item-content>
                        <v-checkbox
                            label="Selecionar Todos"
                            style="margin-left: 8px;"
                            v-model="selecionarTodosInformativo"
                            hide-details
                            :color="selecionarTodosInformativo? 'white': '#90A5EC'"
                            @change="selecionarTodos('Informativo', selecionarTodosInformativo)"
                        >
                          <template v-slot:label>
                            <span :class='selecionarTodosInformativo ? "checkboxLabelInformativoChecked" : "checkboxLabelInformativo"'> Selecionar Todos</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="informativo-situacao-checkbox" :class='situacao.checked ? " items-situacao-cota-informativo-checked" : " items-situacao-cota-informativo"' v-for="(situacao) in opcoesStatusInformativo" :key="situacao.valor">
                      <v-list-item-content>
                        <v-checkbox
                            style="margin-left: 8px;"
                            :value="situacao.valor"
                            :label="situacao.nome"
                            v-model="situacao.checked"
                            :color="situacao.checked? 'white': '#90A5EC'"
                            @change="atualizaSituacao(situacao)"
                            :id="situacao.valor"
                            :name="situacao.valor"
                            hide-details
                        >
                          <template v-slot:label>
                            <span :class='situacao.checked ? "checkboxLabelInformativoChecked" : "checkboxLabelInformativo"'> {{ situacao.nome }}</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>

                  <v-card-text v-if="situacaoSelecionada === 'Pendência'" id="situacaoPendencia">
                    <v-row>
                      <v-col cols="12" md="12">
                        <span style="font-size: 12px;">Situações da cota que precisam de ação urgente do parceiro.</span>
                      </v-col>
                      <!-- <v-col cols="12" md="4" class="text-end">
                        <v-icon @click="alterarTipoSituacao('Informativo')">mdi-chevron-left</v-icon>
                        <v-icon @click="alterarTipoSituacao('Atenção')">mdi-chevron-right</v-icon>
                      </v-col> -->
                    </v-row>
                    <v-list-item class="pendencia-situacao-checkbox " :class='selecionarTodosPendencia ? " items-situacao-cota-pendencia-checked" : " items-situacao-cota-pendencia"'>
                      <v-list-item-content>
                        <v-checkbox
                            label="Selecionar Todos"
                            style="margin-left: 8px;"
                            v-model="selecionarTodosPendencia"
                            hide-details
                            :color="selecionarTodosPendencia? 'white': '#9349F4'"
                            @change="selecionarTodos('Pendência', selecionarTodosPendencia)"
                        >
                          <template v-slot:label>
                            <span :class='selecionarTodosPendencia ? "checkboxLabelPendenciaChecked" : "checkboxLabelPendencia"'> Selecionar Todos</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pendencia-situacao-checkbox" :class='situacao.checked ? " items-situacao-cota-pendencia-checked" : " items-situacao-cota-pendencia"' v-for="(situacao) in opcoesStatusPendencia" :key="situacao.valor">
                      <v-list-item-content>
                        <v-checkbox
                            style="margin-left: 8px;"
                            :value="situacao.valor"
                            :label="situacao.nome"
                            v-model="situacao.checked"
                            :id="situacao.valor"
                            :name="situacao.valor"
                            :color="situacao.checked? 'white': '#9349F4'"
                            @change="atualizaSituacao(situacao)"
                            hide-details
                        >
                          <template v-slot:label>
                            <span :class='situacao.checked ? "checkboxLabelPendenciaChecked" : "checkboxLabelPendencia"'> {{ situacao.nome }}</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                  <v-card-text v-if="situacaoSelecionada === 'Atenção'" id="situacaoAtencao">
                    <v-row>
                      <v-col cols="12" md="12">
                        <span style="font-size: 12px;">Situações da cota que precisam de atenção do cliente.</span>
                      </v-col>
                      <!-- <v-col cols="12" md="4" class="text-end">
                        <v-icon @click="alterarTipoSituacao('Pendência')">mdi-chevron-left</v-icon>
                        <v-icon @click="alterarTipoSituacao('Sucesso')">mdi-chevron-right</v-icon>
                      </v-col> -->
                    </v-row>
                    <v-list-item class="atencao-situacao-checkbox" :class='selecionarTodosAtencao ? " items-situacao-cota-atencao-checked" : " items-situacao-cota-atencao"'>
                      <v-list-item-content>
                        <v-checkbox
                            style="margin-left: 8px;"
                            label="Selecionar Todos"
                            v-model="selecionarTodosAtencao"
                            hide-details
                            :color="selecionarTodosAtencao? 'white': '#EE4784'"
                            @change="selecionarTodos('Atenção', selecionarTodosAtencao)"
                        >
                          <template v-slot:label>
                            <span :class='selecionarTodosAtencao ? "checkboxLabelAtencaoChecked" : "checkboxLabelAtencao"'> Selecionar Todos</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="atencao-situacao-checkbox " :class='situacao.checked ? " items-situacao-cota-atencao-checked" : " items-situacao-cota-atencao"' v-for="(situacao) in opcoesStatusAtencao" :key="situacao.valor">
                      <v-list-item-content>
                        <v-checkbox
                            style="margin-left: 8px;"
                            :value="situacao.valor"
                            :label="situacao.nome"
                            v-model="situacao.checked"
                            :id="situacao.valor"
                            :name="situacao.valor"
                            :color="situacao.checked? 'white': '#EE4784'"
                            @change="atualizaSituacao(situacao)"
                            hide-details
                        >
                          <template v-slot:label>
                            <span :class='situacao.checked ? "checkboxLabelAtencaoChecked" : "checkboxLabelAtencao"'> {{ situacao.nome }}</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" class="d-flex justify-center">
                      <ButtonIcon
                          texto="Aplicar Filtro"
                          class="aplicar-filtro-situacao"
                          @click="menuSituacao = false; efetuarfiltro()"
                      >
                        <template v-slot:img>
                          <img src="@/assets/icons/portal-corretora/svg/dsFiltro.svg" alt="" class="mr-2" />
                        </template>
                      </ButtonIcon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </div>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mx-2 mt-0 py-1">
        <v-col cols="8" :style="loadingSkeleton ? 'height: 50px;' : ''">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="text"
              height="0px"
              class="mt-1 mb-0 pb-0"
          />
          <v-chip v-else
                  v-for="tag in tags"
                  :key="tag.id"
                  :color="tag.color"
                  :text-color="tag.color"
                  style="font-size: 9px; height: 24px; margin: 6px"
                  outlined
                  close
                  @click:close="removerTags(tag)"
          >
            {{ tag.valor }}
          </v-chip>
        </v-col>
        <v-col cols="2" v-if="usuarioLogado.perfil !== 'SUBLOGIN'" id="coluna-limpar-filtros" :style="loadingSkeleton ? 'height: 50px;' : ''">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              height="50px"
              class="mt-1 mb-0 pb-0"
          />
          <ButtonIcon v-else
                      texto="Limpar Filtros"
                      @click="limparFiltro"
                      class="btn-limpar-filtros"
                      block
          >
            <template v-slot:img>
              <svg style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none" id="svg-limpar-filtros">
                <g clip-path="url(#clip0_764_404)">
                  <path d="M6.6875 7.14844C6.83668 7.14844 6.97976 7.20852 7.08525 7.31548C7.19074 7.42243 7.25 7.56749 7.25 7.71875V14.5625C7.25 14.7138 7.19074 14.8588 7.08525 14.9658C6.97976 15.0727 6.83668 15.1328 6.6875 15.1328C6.53832 15.1328 6.39524 15.0727 6.28975 14.9658C6.18426 14.8588 6.125 14.7138 6.125 14.5625V7.71875C6.125 7.56749 6.18426 7.42243 6.28975 7.31548C6.39524 7.20852 6.53832 7.14844 6.6875 7.14844ZM9.5 7.14844C9.64918 7.14844 9.79226 7.20852 9.89775 7.31548C10.0032 7.42243 10.0625 7.56749 10.0625 7.71875V14.5625C10.0625 14.7138 10.0032 14.8588 9.89775 14.9658C9.79226 15.0727 9.64918 15.1328 9.5 15.1328C9.35082 15.1328 9.20774 15.0727 9.10225 14.9658C8.99676 14.8588 8.9375 14.7138 8.9375 14.5625V7.71875C8.9375 7.56749 8.99676 7.42243 9.10225 7.31548C9.20774 7.20852 9.35082 7.14844 9.5 7.14844ZM12.875 7.71875C12.875 7.56749 12.8157 7.42243 12.7102 7.31548C12.6048 7.20852 12.4617 7.14844 12.3125 7.14844C12.1633 7.14844 12.0202 7.20852 11.9148 7.31548C11.8093 7.42243 11.75 7.56749 11.75 7.71875V14.5625C11.75 14.7138 11.8093 14.8588 11.9148 14.9658C12.0202 15.0727 12.1633 15.1328 12.3125 15.1328C12.4617 15.1328 12.6048 15.0727 12.7102 14.9658C12.8157 14.8588 12.875 14.7138 12.875 14.5625V7.71875Z" fill="#191E26" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8125 4.29688C16.8125 4.59939 16.694 4.88951 16.483 5.10342C16.272 5.31733 15.9859 5.4375 15.6875 5.4375H15.125V15.7031C15.125 16.3082 14.8879 16.8884 14.466 17.3162C14.044 17.744 13.4717 17.9844 12.875 17.9844H6.125C5.52826 17.9844 4.95597 17.744 4.53401 17.3162C4.11205 16.8884 3.875 16.3082 3.875 15.7031V5.4375H3.3125C3.01413 5.4375 2.72798 5.31733 2.517 5.10342C2.30603 4.88951 2.1875 4.59939 2.1875 4.29688V3.15625C2.1875 2.85374 2.30603 2.56361 2.517 2.34971C2.72798 2.1358 3.01413 2.01562 3.3125 2.01562H7.25C7.25 1.71311 7.36853 1.42299 7.5795 1.20908C7.79048 0.995173 8.07663 0.875 8.375 0.875L10.625 0.875C10.9234 0.875 11.2095 0.995173 11.4205 1.20908C11.6315 1.42299 11.75 1.71311 11.75 2.01562H15.6875C15.9859 2.01562 16.272 2.1358 16.483 2.34971C16.694 2.56361 16.8125 2.85374 16.8125 3.15625V4.29688ZM5.13275 5.4375L5 5.5048V15.7031C5 16.0056 5.11853 16.2958 5.3295 16.5097C5.54048 16.7236 5.82663 16.8438 6.125 16.8438H12.875C13.1734 16.8438 13.4595 16.7236 13.6705 16.5097C13.8815 16.2958 14 16.0056 14 15.7031V5.5048L13.8673 5.4375H5.13275ZM3.3125 4.29688V3.15625H15.6875V4.29688H3.3125Z" fill="#191E26" />
                </g>
                <defs>
                  <clipPath id="clip0_764_404">
                    <rect width="18" height="18.25" fill="white" transform="translate(0.5 0.875)" />
                  </clipPath>
                </defs>
              </svg>
            </template>
          </ButtonIcon>
        </v-col>
        <v-col cols="2" v-if="usuarioLogado.perfil !== 'SUBLOGIN'" :style="loadingSkeleton ? 'height: 50px;' : ''">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              height="50px"
              class="mt-1 filtrosSkeletonWebSituacao"
          />
          <ButtonIcon v-else
                      texto="Filtrar"
                      @click="efetuarfiltro"
                      class="btn-aplicar-filtro ml-3"
                      block
          >
            <template v-slot:img>
              <img src="@/assets/icons/portal-corretora/svg/dsFiltro.svg" alt="" class="mr-2" />
            </template>
          </ButtonIcon>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-divider class="mt-1" />
      </v-row>

      <v-row class="mx-2">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="8%"
            height="14"
            class="mb-2 mt-2"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="90"
            class="mb-2"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="8%"
            height="14"
            class="mb-2 mt-1"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="90"
            class="mb-2"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="8%"
            height="14"
            class="mb-2 mt-1"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="90"
            class="mb-2"
        />
        <DataTableJornadaDaCota v-else
                                hide-default-header
                                silent
                                class="tabela tabelaMeusClientes"
                                item-key="idCota"
                                :params="params"
                                :filter="filters"
                                :headers="headers"
                                :url="urlDataTable"
                                :loading="false"
                                :key="dataTableKey"
                                :texto-sem-dados="'Não há clientes com essas especificações'"
        >
          <template v-slot:[`item.email`]="item">
            <td style="max-width: 1px !important; display: none;">
              {{ item.email }}
            </td>
          </template>
          <template v-slot:[`item.nome`]="item">
            <td @mouseover="idCotaAtivo = item.idCota" @mouseout="idCotaAtivo=null" :class="idCotaAtivo === item.idCota ? defineStartHoverStyleStatus(item.status) : '' " :style="tdStyles(item.status)+'border-top-left-radius: 9px!important; border-bottom-left-radius: 9px!important;'">
              <div class="col d-flex" style="padding-left: 0px!important; align-items: center;">
                <div :style="retangleStatusStyles(item.status) + ';margin-right: 14px!important'">
                </div>
                <div>
                  <span class="nameStyle">{{ item.nome }}</span>
                  <div style="margin-top: 8px!important;">
                    <div v-if="item.cpfCnpj.length === 11">
                      <span class="cpfCnpjLabel">CPF </span><span class="cpfCnpjText">{{ getTextoTipoPessoa(item.cpfCnpj) }}</span>
                    </div>
                    <div v-else>
                      <span class="cpfCnpjLabel">CNPJ </span><span class="cpfCnpjText">{{ getTextoTipoPessoa(item.cpfCnpj) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </template>
          <template v-slot:[`item.cota`]="item">
            <td @mouseover="idCotaAtivo = item.idCota" @mouseout="idCotaAtivo=null" :class="idCotaAtivo === item.idCota ? defineMiddleHoverStyleStatus(item.status) : '' " :style="trStyles()+ 'padding-right:10px!important'">
              <div class="containerInfo">
                <span class="labelInfoStyle">Cota</span>
                <div style="margin-bottom:4px!important; display:flex!important; flex-direction:row!important;">
                  <v-icon size="20px" color="#7E8185" style="padding-left: 7px;">
                    pc-cotas-contempladas
                  </v-icon>
                  <span class="groupAndProposText ml-1">
                    {{ item.cota }}
                  </span>
                </div>
              </div>
            </td>
          </template>
          <template v-slot:[`item.grupo`]="item">
            <td @mouseover="idCotaAtivo = item.idCota" @mouseout="idCotaAtivo=null" :class="idCotaAtivo === item.idCota ? defineMiddleHoverStyleStatus(item.status) : '' " :style="trStyles()+'padding-right:10px!important'">
              <div class="containerInfo">
                <span class="labelInfoStyle">Grupo</span>
                <div style="margin-bottom:4px!important; display:flex!important;padding-left: 7px!important; flex-direction:row!important;">
                  <v-img style="margin-right:2px!important;" max-height="20px" max-width="20px" height="20px" width="20px" src="@/assets/img/IconGrupo.svg">
                  </v-img>
                  <span class="groupAndProposText ml-1">
                    {{ item.grupo }}
                  </span>
                </div>
              </div>
            </td>
          </template>
          <template v-slot:[`item.proposta`]="item">
            <td @mouseover="idCotaAtivo = item.idCota" @mouseout="idCotaAtivo=null" :class="idCotaAtivo === item.idCota ? defineMiddleHoverStyleStatus(item.status) : '' " :style="trStyles()+'padding-right:10px!important'">
              <div class="containerInfo">
                <span class="labelInfoStyle">Proposta</span>
                <div style="margin-bottom:4px!important; display:flex!important; flex-direction:row!important;">
                  <v-icon size="20px" color="#7E8185" style="padding-left: 7px;">
                    pc-documento-escrito
                  </v-icon>
                  <span class="groupAndProposText ml-1">
                    {{ item.idDocumento }}
                  </span>
                </div>
              </div>
            </td>
          </template>
          <template v-slot:[`item.status`]="item">
            <td @mouseover="idCotaAtivo = item.idCota" @mouseout="idCotaAtivo=null" :class="idCotaAtivo === item.idCota ? defineMiddleHoverStyleStatus(item.status) : '' " class="py-1" :style="trStyles()+'padding-right:5px!important'">
              <div :style="'background-color:'+getTdColorStyle(item.status)" class="btnSituacao mx-2" title="Situação da Cota">
                <span class="textBtnSituacao">
                  {{ item.nomeStatus }}
                </span>
              </div>
            </td>
          </template>
          <template v-slot:[`item.jornadaCota`]="item">
            <td @mouseover="idCotaAtivo = item.idCota" @mouseout="idCotaAtivo=null" :class="idCotaAtivo === item.idCota ? defineEndHoverStyleStatus(item.status) : '' " class="text-center" :style="trStyles()+'border-top-right-radius: 9px!important; border-bottom-right-radius: 9px!important;'">
              <div class="verJornadaContainer campoBotao" style="display: flex; align-items: center; padding-left: 8px; padding-right: 0px;" @click="visualizarCliente(item)">
                <div style="flex: 1; text-align: center;">
                  <span class="verJornadaText">
                    Ver Jornada
                  </span>
                </div>
                <div>
                  <v-btn fab elevation="0" width="30pt" height="30pt" color="primaria" style="margin:0px!important;">
                    <v-icon color="#FFFFFF" size="17">
                      pc-olho
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </td>
          </template>
        </DataTableJornadaDaCota>
      </v-row>
    </v-row>
    <div class="px-4 pt-7" v-else>
      <v-row class="pb-7">
        <v-col cols="12" md="4" style="padding-bottom: 0; margin-bottom:0" v-if="isSublogin" id="colunaParceiro">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              class="w-100 filtrosSkeleton"
              height="45"
          />
          <v-row v-else style="border: 1px solid #E7E7E8; border-radius: 8px; background-color: #FFFFFF;">
            <v-col cols="9" md="2" class="d-flex align-center py-3">
              <v-menu fixed
                      :absolute="false"
                      :position-x="10"
                      :position-y="10"
                      min-width="87%"
                      width="87%"
                      attach="#colunaParceiro"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-bind="attrs" v-on="on" elevation="0" style="align-items: center;">
                    <div style="text-align: start">
                      <table style="width: 100%;">
                        <tr>
                          <td>
                            <span class="filter-menu-placeholder">Visualizar clientes de</span>
                          </td>
                          <td class="d-flex justify-end">
                            <v-icon>mdi-chevron-down</v-icon>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-card>
                </template>
                <div class="item-gestor" @click="visualizarGestor()" style="margin-top: 12px;">
                  <v-row>
                    <v-col style="padding-left: 25px;">
                      <v-icon color="white">mdi-storefront-outline</v-icon>
                      <span style="color: white"> {{ usuarioLogado.login }}</span>
                    </v-col>
                  </v-row>
                </div>
                <div style="max-height: 300px; overflow-y: auto;">
                  <v-list style="cursor: pointer">
                    <v-list-item
                        v-for="(item, index) in sublogins"
                        :key="index"
                        @click="filtroSubloginSelecionado(item)"
                        :class="verificaClassItem(item)"
                    >
                      <v-list-item-title :class="item.login === subloginSelecionado.login ? 'item-selecionado-parceiro-vendedor' : 'parceiro-selecionado'" @click="selecionarSubLogins(item)" v-if="item.tipo === 'vendedor'">{{ item.login }} ({{ item.tipo }})</v-list-item-title>
                      <v-list-item-title :class="item.login === subloginSelecionado.login ? 'item-selecionado-parceiro-revenda' : 'parceiro-selecionado-revenda'" @click="selecionarSubLogins(item)" v-else>{{ item.login }} ({{ item.tipo }})</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </v-col>
            <v-divider vertical class="my-3" />
            <v-col cols="3" md="2" class="col-md-3 text-start d-flex align-center py-2">
              <span class="item-selecionado-label-tipo align-vertical-parceiro">Parceiro</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="pb-4">
        <v-col cols="12" md="4" id="colunaTipo">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              class="w-100 filtrosSkeleton"
              height="45"
          />
          <v-row v-else style="border: 1px solid #E7E7E8; border-radius: 8px; background-color: #FFFFFF;" id="colunaData">
            <v-col cols="7" md="2" v-if="tipoSelecionado === 'Tipo'" class="d-flex align-center">
              <span class="filter-menu-placeholder">Filtrar por</span>
            </v-col>
            <v-col cols="7" md="2" class="pb-1 pt-1" v-if="tipoSelecionado === 'CPF/CNPJ'">
              <v-text-field
                  v-model="textoFiltroDocumento"
                  dense
                  hide-details
                  :key="'CPF'"
                  v-mask="textoFiltroDocumento.length > 14 ? '##.###.###/####-##' : '###.###.###-##'"
                  style="font-size: 11px!important;"
                  placeholder="Digite o CPF ou CNPJ"
              />
            </v-col>
            <v-col cols="4" md="2" class="pb-1 pt-1" v-if="tipoSelecionado === 'Grupo/Cota'">
              <v-text-field
                  prepend-icon="mdi-account-multiple"
                  v-model="textoFiltro"
                  dense
                  hide-details
                  :key="'Grupo'"
                  type="number"
                  style="font-size: 11px!important;"
                  placeholder="Grupo"
              />
            </v-col>
            <v-col cols="3" md="2" class="pb-1 pt-1" v-if="tipoSelecionado === 'Grupo/Cota'">
              <v-text-field
                  prepend-icon="mdi-puzzle"
                  v-model="textoFiltroCota"
                  dense
                  hide-details
                  :key="'Cota'"
                  type="number"
                  style="font-size: 11px!important;"
                  placeholder="Cota"
              />
            </v-col>
            <v-col cols="7" md="2" class="pb-1 pt-1" v-if="tipoSelecionado === 'Período'">
              <v-menu fixed
                      :absolute="false"
                      :position-x="10"
                      :position-y="10"
                      attach="#colunaData"
                      v-model="menuDataInicial"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dateRangeText"
                      prepend-icon="mdi-calendar"
                      readonly
                      dense
                      :key="'Periodo'"
                      hide-details
                      style="font-size: 11px!important;"
                      v-bind="attrs"
                      v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    range
                    :max="maxDates"
                    color='primaria'
                >
                  <v-btn
                      text
                      color='primaria'
                      @click="menuDataInicial = false"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="7" md="2" class="pb-1 pt-1" v-if="tipoSelecionado === 'Proposta'">
              <v-text-field
                  prepend-icon=""
                  v-model="textoFiltroProposta"
                  dense
                  type="number"
                  hide-details
                  :key="'Proposta'"
                  style="font-size: 12px!important;"
                  placeholder="Digite a Proposta"
              />
            </v-col>
            <v-divider vertical class="my-3" />
            <v-col cols="5" md="6" class="text-end">
              <v-menu fixed
                      :absolute="false"
                      :position-x="10"
                      :position-y="10"
                      attach="#colunaTipo"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-bind="attrs" v-on="on" elevation="0" class="d-flex align-center justify-space-between">
                    <span class="item-selecionado-label-tipo align-vertical"> {{ tipoSelecionado }}</span>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-card>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in tipos"
                      :key="index"
                      @click="selecionaTipo(item)"
                  >
                    <v-list-item-title :class="item === tipoSelecionado ? 'item-selecionado' : 'item-sem-selecao'">{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" v-if="isSublogin" id="colunaSituacao">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              class="w-100 filtrosSkeleton"
              height="45"
          />
          <v-row v-else style="border: 1px solid #E7E7E8; border-radius: 8px; background-color: #FFFFFF;">
            <v-col cols="12" class="d-flex align-center py-3">
              <v-menu
                  attach="#colunaSituacao"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  v-model="menuSituacao"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-bind="attrs" v-on="on" elevation="0" width="100%" class="d-flex align-center justify-space-between">
                    <span class="filter-menu-placeholder">Situação da cota</span>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-card>
                </template>

                <v-card elevation="0">
                  <v-card-text class="pb-0 px-0 mb-0">
                    <table class="tabelaLegenda">
                      <tr class="px-0">
                        <td @click="alterarTipoSituacao('Sucesso')" style="cursor: pointer; padding-bottom: 7px;text-align: center;" :class="situacaoSelecionada === 'Sucesso' ? 'tabelaLegendaSituacaoSucessoSelecionada' : 'tabelaLegendaSituacaoSucesso' ">
                          <v-icon color="#3FD47B" size="18" v-if="situacaoSelecionada === 'Sucesso'">mdi-check</v-icon> SUCESSO
                        </td>
                        <td @click="alterarTipoSituacao('Informativo')" class="px-0" style="cursor: pointer; padding-bottom: 7px; text-align: center;" :class="situacaoSelecionada === 'Informativo' ? 'tabelaLegendaSituacaoInformativoSelecionada' : 'tabelaLegendaSituacaoInformativo'">
                          <v-icon color="#90A5EC" size="18" v-if="situacaoSelecionada === 'Informativo'">mdi-information-variant</v-icon> INFORMATIVO
                        </td>
                        <td @click="alterarTipoSituacao('Pendência')" class="px-0" style="cursor: pointer; padding-bottom: 7px;text-align: center;" :class="situacaoSelecionada === 'Pendência' ? 'tabelaLegendaSituacaoPendenciaSelecionada' : 'tabelaLegendaSituacaoPendencia'">
                          <v-icon color="#9349F4" size="18" v-if="situacaoSelecionada === 'Pendência'">mdi-minus</v-icon> PENDÊNCIA
                        </td>
                        <td @click="alterarTipoSituacao('Atenção')" style="cursor: pointer; padding-bottom: 7px;text-align: center;" :class="situacaoSelecionada === 'Atenção' ? 'tabelaLegendaSituacaoAtencaoSelecionada' : 'tabelaLegendaSituacaoAtencao'">
                          <v-icon color="#EE4784" size="18" v-if="situacaoSelecionada === 'Atenção'">mdi-exclamation</v-icon> ATENÇÃO
                        </td>
                      </tr>
                    </table>
                  </v-card-text>
                  <v-card-text class="my-0 py-1" v-if="situacaoSelecionada === 'Sucesso'" id="situacaoSucesso">
                    <v-row>
                      <v-col cols="12">
                        <h2 class="situacaoSelecionadaTitulo" style="color: #3FD47B">Sucesso</h2>
                        <span class="situacaoSelecionadaSubtitulo">Situações da cota foram concluídas com êxito.</span>
                      </v-col>
                      <!-- <v-col cols="4" md="4" class="text-end pt-3">
                        <v-icon @click="alterarTipoSituacao('Atenção')" size="22">mdi-chevron-left</v-icon>
                        <v-icon @click="alterarTipoSituacao('Informativo')" size="22">mdi-chevron-right</v-icon>
                      </v-col> -->
                    </v-row>
                    <v-list-item class="sucesso-situacao-checkbox" :class='selecionarTodosSucesso ? " items-situacao-cota-sucesso-checked" : " items-situacao-cota-sucesso"'>
                      <v-list-item-content>
                        <v-checkbox
                            v-model="selecionarTodosSucesso"
                            hide-details
                            :color="selecionarTodosSucesso ? 'white': '#3FD47B' "
                            @change="selecionarTodos('Sucesso', selecionarTodosSucesso)"
                        >
                          <template v-slot:label>
                            <span :class='selecionarTodosSucesso ? "checkboxLabelSucessoChecked" : "checkboxLabelSucesso"'>Selecionar todas</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="sucesso-situacao-checkbox" :class='situacao.checked ? " items-situacao-cota-sucesso-checked" : " items-situacao-cota-sucesso"' v-for="(situacao) in opcoesStatusSucesso" :key="situacao.valor">
                      <v-list-item-content>
                        <v-checkbox
                            :value="situacao.valor"
                            :label="situacao.nome"
                            v-model="situacao.checked"
                            :id="situacao.valor"
                            :name="situacao.valor"
                            :color="situacao.checked ? 'white': '#3FD47B'"
                            @change="atualizaSituacao(situacao)"
                            hide-details
                        >
                          <template v-slot:label>
                            <span :class='situacao.checked ? "checkboxLabelSucessoChecked" : "checkboxLabelSucesso"'> {{ situacao.nome }}</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>

                  <v-card-text class="my-0 py-1" v-if="situacaoSelecionada === 'Informativo'" id="situacaoInformativo">
                    <v-row>
                      <v-col cols="12">
                        <h2 class="situacaoSelecionadaTitulo" style="color: #90A5EC">Informativo</h2>
                        <span class="situacaoSelecionadaSubtitulo">Não depende do cliente, nem parceiro</span>
                      </v-col>
                      <!-- <v-col cols="4" class="text-end pt-3">
                        <v-icon @click="alterarTipoSituacao('Sucesso')" size="22">mdi-chevron-left</v-icon>
                        <v-icon @click="alterarTipoSituacao('Pendência')" size="22">mdi-chevron-right</v-icon>
                      </v-col> -->
                    </v-row>
                    <v-list-item class="informativo-situacao-checkbox " :class='selecionarTodosInformativo ? " items-situacao-cota-informativo-checked" : " items-situacao-cota-informativo"'>
                      <v-list-item-content>
                        <v-checkbox
                            v-model="selecionarTodosInformativo"
                            hide-details
                            :color="selecionarTodosInformativo? 'white': '#90A5EC'"
                            @change="selecionarTodos('Informativo', selecionarTodosInformativo)"
                        >
                          <template v-slot:label>
                            <span :class='selecionarTodosInformativo ? "checkboxLabelInformativoChecked" : "checkboxLabelInformativo"'> Selecionar todas</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="informativo-situacao-checkbox" :class='situacao.checked ? " items-situacao-cota-informativo-checked" : " items-situacao-cota-informativo"' v-for="(situacao) in opcoesStatusInformativo" :key="situacao.valor">
                      <v-list-item-content>
                        <v-checkbox
                            :value="situacao.valor"
                            :label="situacao.nome"
                            v-model="situacao.checked"
                            :color="situacao.checked? 'white': '#90A5EC'"
                            @change="atualizaSituacao(situacao)"
                            :id="situacao.valor"
                            :name="situacao.valor"
                            hide-details
                        >
                          <template v-slot:label>
                            <span :class='situacao.checked ? "checkboxLabelInformativoChecked" : "checkboxLabelInformativo"'> {{ situacao.nome }}</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>

                  <v-card-text class="my-0 py-1" v-if="situacaoSelecionada === 'Pendência'" id="situacaoPendencia">
                    <v-row>
                      <v-col cols="12">
                        <h2 class="situacaoSelecionadaTitulo" style="color: #9349F4">Pendência</h2>
                        <span class="situacaoSelecionadaSubtitulo">Não depende do cliente, nem parceiro</span>
                      </v-col>
                      <!-- <v-col cols="4" class="text-end pt-3">
                        <v-icon @click="alterarTipoSituacao('Informativo')" size="22">mdi-chevron-left</v-icon>
                        <v-icon @click="alterarTipoSituacao('Atenção')" size="22">mdi-chevron-right</v-icon>
                      </v-col> -->
                    </v-row>
                    <v-list-item class="pendencia-situacao-checkbox " :class='selecionarTodosPendencia ? " items-situacao-cota-pendencia-checked" : " items-situacao-cota-pendencia"'>
                      <v-list-item-content>
                        <v-checkbox
                            v-model="selecionarTodosPendencia"
                            hide-details
                            :color="selecionarTodosPendencia? 'white': '#9349F4'"
                            @change="selecionarTodos('Pendência', selecionarTodosPendencia)"
                        >
                          <template v-slot:label>
                            <span :class='selecionarTodosPendencia ? "checkboxLabelPendenciaChecked" : "checkboxLabelPendencia"'> Selecionar todas</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pendencia-situacao-checkbox" :class='situacao.checked ? " items-situacao-cota-pendencia-checked" : " items-situacao-cota-pendencia"' v-for="(situacao) in opcoesStatusPendencia" :key="situacao.valor">
                      <v-list-item-content>
                        <v-checkbox
                            :value="situacao.valor"
                            :label="situacao.nome"
                            v-model="situacao.checked"
                            :id="situacao.valor"
                            :name="situacao.valor"
                            :color="situacao.checked? 'white': '#9349F4'"
                            @change="atualizaSituacao(situacao)"
                            hide-details
                        >
                          <template v-slot:label>
                            <span :class='situacao.checked ? "checkboxLabelPendenciaChecked" : "checkboxLabelPendencia"'> {{ situacao.nome }}</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>

                  <v-card-text class="my-0 py-1" v-if="situacaoSelecionada === 'Atenção'" id="situacaoAtencao">
                    <v-row>
                      <v-col cols="12">
                        <h2 class="situacaoSelecionadaTitulo" style="color: #EE4784">Atenção</h2>
                        <span class="situacaoSelecionadaSubtitulo">Não depende do cliente, nem parceiro</span>
                      </v-col>
                      <!-- <v-col cols="4" class="text-end pt-3">
                        <v-icon @click="alterarTipoSituacao('Pendência')" size="22">mdi-chevron-left</v-icon>
                        <v-icon @click="alterarTipoSituacao('Sucesso')" size="22">mdi-chevron-right</v-icon>
                      </v-col> -->
                    </v-row>
                    <v-list-item class="atencao-situacao-checkbox" :class='selecionarTodosAtencao ? " items-situacao-cota-atencao-checked" : " items-situacao-cota-atencao"'>
                      <v-list-item-content>
                        <v-checkbox
                            v-model="selecionarTodosAtencao"
                            hide-details
                            :color="selecionarTodosAtencao? 'white': '#EE4784'"
                            @change="selecionarTodos('Atenção', selecionarTodosAtencao)"
                        >
                          <template v-slot:label>
                            <span :class='selecionarTodosAtencao ? "checkboxLabelAtencaoChecked" : "checkboxLabelAtencao"'> Selecionar todas</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="atencao-situacao-checkbox " :class='situacao.checked ? " items-situacao-cota-atencao-checked" : " items-situacao-cota-atencao"' v-for="(situacao) in opcoesStatusAtencao" :key="situacao.valor">
                      <v-list-item-content>
                        <v-checkbox
                            :value="situacao.valor"
                            :label="situacao.nome"
                            v-model="situacao.checked"
                            :id="situacao.valor"
                            :name="situacao.valor"
                            :color="situacao.checked? 'white': '#EE4784'"
                            @change="atualizaSituacao(situacao)"
                            hide-details
                        >
                          <template v-slot:label>
                            <span :class='situacao.checked ? "checkboxLabelAtencaoChecked" : "checkboxLabelAtencao"'> {{ situacao.nome }}</span>
                          </template>
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" class="d-flex justify-center">
                      <ButtonIcon
                          texto="Aplicar Filtro"
                          class="aplicar-filtro-situacao"
                          @click="menuSituacao = false; efetuarfiltro()"
                      >
                        <template v-slot:img>
                          <img src="@/assets/icons/portal-corretora/svg/dsFiltro.svg" alt="" class="mr-2" />
                        </template>
                      </ButtonIcon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col class="d-flex justify-center pl-0 pr-1" cols="6">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              height="48px"
              class="mt-1 mb-0 pb-0 w-100"
          />
          <ButtonIcon v-else
                      texto="Limpar Filtros"
                      @click="limparFiltro"
                      class="btn-limpar-filtros"
                      style="height: 48px !important"
                      block
          >
            <template v-slot:img>
              <img src="@/assets/icons/portal-corretora/svg/dsLixeira.svg" alt="" class="mr-2" />
            </template>
          </ButtonIcon>
        </v-col>
        <v-col class="d-flex justify-center pl-2 pr-0" cols="6">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              height="48px"
              class="mt-1 mb-0 pb-0 w-100"
          />
          <ButtonIcon v-else
                      texto="Filtrar"
                      @click="efetuarfiltro"
                      class="btn-aplicar-filtro"
                      style="height: 48px !important"
                      block
          >
            <template v-slot:img>
              <img src="@/assets/icons/portal-corretora/svg/dsFiltro.svg" alt="" class="mr-2" />
            </template>
          </ButtonIcon>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0" cols="12" md="4" v-if="isSublogin">
          <v-chip
              v-for="tag in tags"
              :key="tag.id"
              :color="tag.color"
              :text-color="tag.color"
              class="mb-3 mr-3"
              outlined
              style="font-size: 10px; font-family: Inter; font-weight: 400"
              close
              @click:close="removerTags(tag)"
          >
            {{ tag.valor }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-divider class="mt-2" />
      </v-row>

      <v-row>
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="20%"
            height="14"
            class="mb-2 mt-2"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="200"
            class="mb-2"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="20%"
            height="14"
            class="mb-2 mt-1"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="200"
            class="mb-2"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="20%"
            height="14"
            class="mb-2 mt-1"
        />
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            width="100%"
            height="200"
            class="mb-2"
        />
        <DataTableJornadaDaCota v-else
                                silent
                                class="tabela tabelaMeusClientes mt-0"
                                :params="params"
                                :filter="filters"
                                :url="urlDataTable"
                                item-key="idCota"
                                :loading="false"
                                :texto-sem-dados="'Não há clientes com essas especificações'"
                                hide-default-header
        >
          <template v-slot:items-mobile="item">
            <v-card class="d-flex flex-row align-center justify-center py-3">
              <v-sheet :color="getTdColorStyle(item.status)" class="rounded-l-0 rounded-r-xl" height="196" width="8" />
              <v-card-text class="py-0 my-0">
                <v-row class="flex-column justify-center my-0 px-2 py-0" style="margin-bottom: 13px!important">
                  <label class="nameStyle">{{ item.nome }}</label>
                  <span class="cpfCnpjLabel">{{ item.cpfCnpj.length === 11 ? 'CPF' : 'CNPJ' }}<span class="cpfCnpjText ml-2">{{ getTextoTipoPessoa(item.cpfCnpj) }}</span></span>
                </v-row>
                <v-row class="align-center my-0 py-0" style="margin-bottom: 13px!important">
                  <v-col cols="3" class="py-0 my-0">
                    <label class="labelInfoStyle">Cota</label>
                    <div class="d-flex align-center">
                      <v-icon size="20" color="#5E6267" style="padding-left: 7px;">pc-cotas-contempladas</v-icon>
                      <span class="groupAndProposText ml-1">{{ item.cota }}</span>
                    </div>
                  </v-col>
                  <v-col cols="4" class="py-0 my-0">
                    <label class="labelInfoStyle">Grupo</label>
                    <div class="d-flex align-center" style="padding-left: 7px!important;">
                      <v-img style="margin-right:2px!important" max-height="20px" max-width="20px" height="20px" width="20px" src="@/assets/img/IconGrupo.svg" />
                      <span class="groupAndProposText ml-1">{{ item.grupo }}</span>
                    </div>
                  </v-col>
                  <v-col cols="5" class="py-0 my-0">
                    <label class="labelInfoStyle">Proposta</label>
                    <div class="d-flex align-center">
                      <v-icon size="20" color="#5E6267" class="mb-1" style="padding-left: 7px;">pc-documento-escrito</v-icon>
                      <span class="groupAndProposText ml-1">{{ item.idDocumento }}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="justify-center my-0 px-2 py-0" style="margin-bottom: 12px!important">
                  <v-sheet width="100%" height="36" :color="getTdColorStyle(item.status)" style="border-radius: 8px;" class="textBtnSituacao d-flex align-center justify-center">
                    {{ item.nomeStatus }}
                  </v-sheet>
                </v-row>
                <v-row class="justify-end my-0 px-2 py-0">
                  <div class="verJornadaContainer" @click="visualizarCliente(item)">
                    <div style="display:flex; align-items:center; padding-left:10px; padding-right:5px">
                      <span class="verJornadaText">
                        Ver Jornada
                      </span>
                    </div>
                    <v-btn
                        fab
                        elevation="0"
                        width="40px"
                        height="41px"
                        color="primaria"
                        style="margin:0px!important;"
                    >
                      <v-icon color="#FFFFFF" size="17">
                        pc-olho
                      </v-icon>
                    </v-btn>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </DataTableJornadaDaCota>
      </v-row>
    </div>
    <DialogExtrairMeusClientes
        :exibir="exibirDialogExtrair"
        @exibir="exibir => exibirDialogExtrair = exibir"
        @extrair="extrair"
    />
    <DialogFiltrarMeusClientes
        :exibir="exibirDialogFiltrar"
        @exibir="exibir => exibirDialogFiltrar = exibir"
        @filtro="filtrar"
    />
  </v-container>
</template>

<script>

  import moment from 'moment/moment'
  import DialogExtrairMeusClientes from '@/components/dialog/DialogExtrairMeusClientes'
  import DialogFiltrarMeusClientes from '@/components/dialog/DialogFiltrarMeusClientes'
  import DataTableJornadaDaCota from '@/components/JornadaDaCota/DataTableJornadaDaCota.vue'
  import { Mensagens } from '@/plugins/constants'
  import { listarSubloginsJornada } from '@/service/sublogin'
  import { mapActions, mapGetters } from 'vuex'
  import session from '../../plugins/session'
  import { isEmpty } from 'lodash'
  import { meusClientespdf, meusClientesexcel, unidadesLogin } from '@/service/jornada-cota'
  import { setTimeout } from 'core-js'

  export default {
    name: 'MeusClientes',
    components: {
      DialogFiltrarMeusClientes,
      DialogExtrairMeusClientes,
      DataTableJornadaDaCota
    },
    data: () => ({
      loadingSkeleton: false,
      transition: 'scale-transition',
      urlDataTable: '',
      dataTableKey: 1,
      menuDataInicial: false,
      menuDataFinal: false,
      extrairMenu: false,
      dates: [],
      status: [],
      tags: [],
      opcao: [],
      tipoSelecionado: 'Tipo',
      selecionarTodosSucesso: undefined,
      selecionarTodosInformativo: undefined,
      selecionarTodosPendencia: undefined,
      selecionarTodosAtencao: undefined,
      situacaoSelecionada: 'Sucesso',
      menuSituacao: false,
      maxDates: undefined,
      headers: [
        { text: 'Cliente', width: 210, value: 'nome', sortable: false, visible: false },
        { text: 'Cota', width: 100, value: 'cota', sortable: false, visible: false },
        { text: 'Grupo', width: 100, value: 'grupo', sortable: false, visible: false },
        { text: 'Proposta', width: 100, value: 'proposta', sortable: false, visible: false },
        { text: 'Situação da Cota', width: 266, value: 'status', sortable: false, visible: false },
        { text: 'Ação', width: 50, value: 'jornadaCota', align: 'center', sortable: false, visible: false }
      ],
      opcoesStatus: [
        {
          nome: 'Aguardando confirmação da contemplação',
          valor: 'cota_contemplada_lance',
          tipo: 'Informativo',
          checked: undefined
        },
        {
          nome: 'Pagamento do bem realizado',
          valor: 'pagamento_bem_total',
          tipo: 'Sucesso',
          checked: undefined
        },
        {
          nome: 'Análise automática aprovada',
          valor: 'analise_credito_automatica_aprovada',
          tipo: 'Sucesso',
          checked: undefined
        },
        {
          nome: 'Análise automática reprovada',
          valor: 'analise_credito_automatica_reprovada',
          tipo: 'Pendência',
          checked: undefined
        },
        {
          nome: 'Análise manual aprovada',
          valor: 'analise_credito_manual_aprovada',
          tipo: 'Sucesso',
          checked: undefined
        },
        {
          nome: 'Análise manual reprovada',
          valor: 'analise_credito_manual_reprovada',
          tipo: 'Atenção',
          checked: undefined
        },
        {
          nome: 'Análise manual em andamento',
          valor: 'analise_credito_manual_iniciada',
          tipo: 'Informativo',
          checked: undefined
        },
        {
          nome: 'Reanálise em andamento',
          valor: 'analise_credito_manual_reanalise_iniciada',
          tipo: 'Informativo',
          checked: undefined
        },
        {
          nome: 'Pendência na análise manual',
          valor: 'analise_credito_manual_aguardando_cliente',
          tipo: 'Pendência',
          checked: undefined
        },
        {
          nome: 'Lance aguardando pagamento',
          valor: 'pagamento_lance_aguardando_cliente',
          tipo: 'Atenção',
          checked: undefined
        },
        {
          nome: 'Cota contemplada',
          valor: 'aviso_contemplacao_enviado',
          tipo: 'Sucesso',
          checked: undefined
        },
        {
          nome: 'Cota alocada',
          valor: 'cota_alocada',
          tipo: 'Sucesso',
          checked: undefined
        },
        {
          nome: 'Cota descontemplada',
          valor: 'cota_desclassificada',
          tipo: 'Atenção',
          checked: undefined
        },
        {
          nome: 'Lance Pago',
          valor: 'pagamento_lance_realizado',
          tipo: 'Sucesso',
          checked: undefined
        }
      ],
      opcoesStatusSucesso: [],
      opcoesStatusInformativo: [],
      opcoesStatusPendencia: [],
      opcoesStatusAtencao: [],
      filters: {},
      dataInicialAberta: false,
      pagination: {
        sortBy: [], sortDesc: [true]
      },
      exibirDialogFiltrar: false,
      exibirDialogExtrair: false,
      sublogins: [],
      tipos: ['Tipo', 'CPF/CNPJ', 'Grupo/Cota', 'Período', 'Proposta'],
      subloginSelecionado: {},
      subloginSelecionadoFiltro: '',
      usuarioLogado: {},
      filtrosUrl: '',
      textoFiltro: '',
      textoFiltroCota: '',
      textoFiltroDocumento: '',
      textoFiltroProposta: '',
      mascaraDocumento: '###.###.###-##',
      dt_inicio: '27/10/2023',
      dt_fim: '27/11/2023',
      dataInicial: undefined,
      dataFinal: undefined,
      isFiltred: false,
      idCotaAtivo: null,
      listarStatusSucesso: false,
      listarStatusInformativo: false,
      listarStatusPendencia: false,
      listarStatusAtencao: false
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      },
      ...mapGetters({
        filtroMeusClientes: 'filtroMeusClientes'
      }),
      isSublogin () {
        return this.usuarioLogado.perfil !== 'SUBLOGIN'
      },
      isMobile () {
        return window.innerWidth < 960
      },
      dataInicialFormatada () {
        return this.dataInicial ? moment(this.dataInicial).format('DD/MM/yyyy') : ''
      },
      dataFinalFormatada () {
        return this.dataFinal ? moment(this.dataFinal).format('DD/MM/yyyy') : ''
      },
      dateRangeText () {
        let dataInicial = moment(this.dates[0])
        let dataFinal = moment(this.dates[1])

        return dataInicial.format('DD/MM/YYYY') + ' - ' + dataFinal.format('DD/MM/YYYY')
      }
    },
    watch: {
      dates (value) {
        this.maxDates = moment(value[0]).add(365, 'days').format('YYYY-MM-DD')
      }
    },
    async mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
      }, 6000)
      this.urlDataTable = this.$route.params.urlTable
      this.unidade = session.get('unidade')
      await this.dadosUsuario()
      await this.listarSubloginsJornada()
      this.calcularRangeInicialDoFiltro()
      this.opcoesStatusSucesso = this.opcoesStatus.filter(e => e.tipo === 'Sucesso').sort((a, b) => a.nome.localeCompare(b.nome))
      this.opcoesStatusInformativo = this.opcoesStatus.filter(e => e.tipo === 'Informativo').sort((a, b) => a.nome.localeCompare(b.nome))
      this.opcoesStatusPendencia = this.opcoesStatus.filter(e => e.tipo === 'Pendência').sort((a, b) => a.nome.localeCompare(b.nome))
      this.opcoesStatusAtencao = this.opcoesStatus.filter(e => e.tipo === 'Atenção').sort((a, b) => a.nome.localeCompare(b.nome))

      let filtroClientes = this.getFiltroMeusClientes()

      if (filtroClientes.filtrado) {
        this.tipoSelecionado = filtroClientes.tipoSelecionado
        this.textoFiltroDocumento = filtroClientes.textoFiltroDocumento
        this.textoFiltroProposta = filtroClientes.textoFiltroProposta
        this.textoFiltro = filtroClientes.grupo
        this.textoFiltroCota = filtroClientes.cota
        this.subloginSelecionado = filtroClientes.subloginSelecionado
        this.subloginSelecionadoFiltro = filtroClientes.subloginSelecionadoFiltro

        this.calcularRangeInicialDoFiltroPorDataDefinida(filtroClientes.dataInicial, filtroClientes.dataFinal)
        await this.buscarEmpresa()
        filtroClientes.listaStatus.forEach(x => {
          this.selecionaItem(this.opcoesStatusSucesso, x)
          this.selecionaItem(this.opcoesStatusInformativo, x)
          this.selecionaItem(this.opcoesStatusPendencia, x)
          this.selecionaItem(this.opcoesStatusAtencao, x)
        })
        this.efetuarfiltro()
      } else {
        await this.clearMeusClientes()
        await this.buscarEmpresa()
      }
    },
    methods: {
      ...mapActions(['inicializaClientes', 'clearMeusClientes', 'setFiltroMeusClientes', 'clearFiltro', 'setUrlFiltro']),
      ...mapGetters({
        getFiltroMeusClientes: 'getFiltroMeusClientes'
      }),
      selecionaItem (lista, item) {
        let itemSelecionado = lista.find(x => x.valor === item)

        if (itemSelecionado) {
          itemSelecionado.checked = true
          this.atualizaSituacao(itemSelecionado)
        }
      },
      selecionaItemMenu () {
        if (this.situacaoSelecionada === 'Sucesso' && !this.listarStatusSucesso) {
          this.addCheck()
          this.listarStatusSucesso = true
        } else if (this.situacaoSelecionada === 'Informativo' && !this.listarStatusInformativo) {
          this.addCheck()
          this.listarStatusInformativo = true
        } else if (this.situacaoSelecionada === 'Pendência' && !this.listarStatusPendencia) {
          this.addCheck()
          this.listarStatusPendencia = true
        } else if (this.situacaoSelecionada === 'Atenção' && !this.listarStatusAtencao) {
          this.addCheck()
          this.listarStatusAtencao = true
        }
      },
      addCheck () {
        let filtroClientes = this.getFiltroMeusClientes()

        filtroClientes.listaStatus.forEach(x => {
          let item = document.getElementById(x)
          if (item) {
            item.click()
          }
        })
      },
      selecionarSubLogins (item) {
        this.subloginSelecionado = item
      },
      async filtroSubloginSelecionado (valor) {
        this.subloginSelecionado = valor

        if (valor && valor.tipo === 'revenda') {
          const revenda = await this.buscarUnidadesLogin(valor.login)

          let filtroClientes = this.getFiltroMeusClientes()
          this.setFiltroMeusClientes({
            idEmpresa: revenda.unidades[0].idEmpresa,
            idComissionadoPrincipal: revenda.usuario.idComissionado,
            filtrado: filtroClientes.filtrado,
            login: valor.login,
            tipo: 'revenda',
            idUnidade: revenda.unidades[0].idUnidade,
            dataInicial: filtroClientes.dataInicial,
            dataFinal: filtroClientes.dataFinal,
            dates: filtroClientes.dates,
            tipoSelecionado: filtroClientes.tipoSelecionado,
            textoFiltroDocumento: filtroClientes.textoFiltroDocumento,
            textoFiltroProposta: filtroClientes.textoFiltroProposta,
            grupo: filtroClientes.grupo,
            cota: filtroClientes.cota,
            listaStatus: filtroClientes.listaStatus,
            subloginSelecionado: filtroClientes.subloginSelecionado,
            subloginSelecionadoFiltro: filtroClientes.subloginSelecionadoFiltro
          })
          this.efetuarfiltro()
        }

        if (valor && (valor.tipo === 'vendedor')) {
          await this.setFiltroUsuario(valor, false, 'vendedor')
          this.efetuarfiltro()
        }
      },
      verificaClassItem (item) {
        if (item.login === this.subloginSelecionado.login) {
          if (item.tipo === 'revenda') {
            return 'item-selecionado-parceiro-revenda-item'
          } else {
            return 'item-selecionado-parceiro-vendedor-item'
          }
        } else if (!this.isMobile) {
          if (item.tipo === 'revenda') {
            return 'item-selecionado-parceiro-revenda-item-hover'
          } else {
            return 'item-selecionado-parceiro-vendedor-item-hover'
          }
        }
      },
      async setFiltroUsuario (valor, clear, tipo) {
        const unidade = session.get('unidade')
        const usuario = session.get('usuario')
        const principal = await this.buscarUnidadesLogin(usuario.login)

        if (clear) {
          this.setStoreFiltroMeusClientes({
            login: valor.login,
            tipo: tipo,
            idUnidade: unidade.idUnidade,
            idEmpresa: principal.unidades[0].idEmpresa,
            idComissionadoPrincipal: principal.usuario.idComissionado
          }, true)
        } else {
          this.setStoreFiltroMeusClientes({
            login: valor.login,
            tipo: tipo,
            idUnidade: unidade.idUnidade,
            idEmpresa: principal.unidades[0].idEmpresa,
            idComissionadoPrincipal: principal.usuario.idComissionado
          }, false)
        }
      },
      setStoreFiltroMeusClientes (valor, clear) {
        let dateRangeStr = this.dateRangeText.split(' - ')
        let dataInicialStr = moment(dateRangeStr[0], 'DD/MM/YYYY')
        let dataFinalStr = moment(dateRangeStr[1], 'DD/MM/YYYY')

        if (clear) {
          this.setFiltroMeusClientes({
            login: valor.login,
            tipo: valor.tipo,
            idUnidade: valor.idUnidade,
            idEmpresa: valor.idEmpresa,
            idComissionadoPrincipal: valor.idComissionadoPrincipal,
            filtrado: false,
            dataInicial: dataInicialStr.format('YYYY-MM-DD'),
            dataFinal: dataFinalStr.format('YYYY-MM-DD'),
            tipoSelecionado: 'Tipo',
            textoFiltroDocumento: '',
            textoFiltroProposta: '',
            grupo: '',
            cota: '',
            listaStatus: [],
            subloginSelecionado: {},
            subloginSelecionadoFiltro: ''
          })
        } else {
          this.setFiltroMeusClientes({
            login: valor.login,
            tipo: valor.tipo,
            idUnidade: valor.idUnidade,
            idEmpresa: valor.idEmpresa,
            idComissionadoPrincipal: valor.idComissionadoPrincipal,
            filtrado: true,
            dataInicial: dataInicialStr.format('YYYY-MM-DD'),
            dataFinal: dataFinalStr.format('YYYY-MM-DD'),
            tipoSelecionado: this.tipoSelecionado,
            textoFiltroDocumento: this.textoFiltroDocumento,
            textoFiltroProposta: this.textoFiltroProposta,
            grupo: this.textoFiltro,
            cota: this.textoFiltroCota,
            listaStatus: this.status,
            subloginSelecionado: this.subloginSelecionado,
            subloginSelecionadoFiltro: this.subloginSelecionadoFiltro
          })
        }
      },
      selecionaTipo (valor) {
        this.tipoSelecionado = valor
        this.textoFiltro = ''
        this.textoFiltroCota = ''
        this.textoFiltroDocumento = ''
        this.textoFiltroProposta = ''
      },
      calcularRangeInicialDoFiltroPorDataDefinida (dataInicial, dataFinal) {
        this.dates = [moment(dataInicial).format('YYYY-MM-DD'), moment(dataFinal).format('YYYY-MM-DD')]
        this.maxDates = moment(dataInicial).add(365, 'days').format('YYYY-MM-DD')
      },
      calcularRangeInicialDoFiltro () {
        let dataInicial = moment(Date.now()).subtract(1, 'months')
        let dataFinal = moment(dataInicial).add(1, 'months')
        this.dates = [dataInicial.format('YYYY-MM-DD'), dataFinal.format('YYYY-MM-DD')]
        this.maxDates = moment(dataInicial).add(365, 'days').format('YYYY-MM-DD')
      },
      selecionarTodos (tipo, checked) {
        if (tipo === 'Sucesso') {
          this.verificaItemnsSelecionados(this.opcoesStatusSucesso, checked)
        } else if (tipo === 'Informativo') {
          this.verificaItemnsSelecionados(this.opcoesStatusInformativo, checked)
        } else if (tipo === 'Atenção') {
          this.verificaItemnsSelecionados(this.opcoesStatusAtencao, checked)
        } else if (tipo === 'Pendência') {
          this.verificaItemnsSelecionados(this.opcoesStatusPendencia, checked)
        }
      },
      async visualizarGestor () {
        this.subloginSelecionado = { }
        this.setFiltroUsuario(this.usuarioLogado, false)
        await this.buscarEmpresa()
        this.efetuarfiltro()
      },
      verificaItemnsSelecionados (lista, checked) {
        lista.forEach(e => {
          if (checked) {
            e.checked = e.valor
          } else {
            e.checked = undefined
          }
          this.atualizaSituacao(e)
        })
      },
      atualizaSituacao (event, tipo) {
        this.addTags(event.valor, event.checked)
      },
      addTags (value, checked) {
        let item = this.opcoesStatus.find(x => x.valor === value)

        if (checked) {
          let existsTags = this.tags.find(x => x.id === value)

          if (item && !existsTags) {
            this.tags.push({
              id: item.valor,
              valor: item.nome,
              tipo: item.tipo,
              color: this.defineCorPorTipo(item.tipo)
            })
            this.status.push(item.valor)
          }
        } else {
          this.tags = this.tags.filter(x => x.id !== value)
          this.status = this.status.filter(x => x !== item.valor)
        }
      },
      alterarTipoSituacao (tipo) {
        this.situacaoSelecionada = tipo
      },
      removerTags (tag) {
        this.tags = this.tags.filter(x => x.id !== tag.id)
        this.status = this.status.filter(x => x !== tag.id)

        if (tag.tipo === 'Sucesso') {
          this.opcoesStatusSucesso.find(x => x.valor === tag.id).checked = undefined
          this.selecionarTodosSucesso = undefined
        } else if (tag.tipo === 'Atenção') {
          this.opcoesStatusAtencao.find(x => x.valor === tag.id).checked = undefined
          this.selecionarTodosAtencao = undefined
        } else if (tag.tipo === 'Pendência') {
          this.opcoesStatusPendencia.find(x => x.valor === tag.id).checked = undefined
          this.selecionarTodosPendencia = undefined
        } else if (tag.tipo === 'Informativo') {
          this.opcoesStatusInformativo.find(x => x.valor === tag.id).checked = undefined
          this.selecionarTodosInformativo = undefined
        }
      },
      defineCorPorTipo (tipo) {
        switch (tipo) {
          case 'Informativo':
            return '#90A5EC'
          case 'Atenção':
            return '#EE4784'
          case 'Pendência':
            return '#9349F4'
          default:
            return '#3FD47B'
        }
      },
      async exibirFiltro () {
        this.exibirDialogFiltrar = true
      },
      exibirExtrair () {
        this.exibirDialogExtrair = true
      },
      getTextoTipoPessoa (documento) {
        if (documento.length === 11) {
          return this.formataCPF(documento)
        } else {
          return this.formataCNPJ(documento)
        }
      },
      formataCPF (cpf) {
        cpf = cpf.replace(/[^\d]/g, '')
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      },
      formataCNPJ (cnpj) {
        cnpj = cnpj.replace(/[^\d]/g, '')
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      },
      filtrar (filtro) {
        this.urlDataTable = `/jornada-cota/cotas` + filtro
        this.filtrosUrl = filtro
        this.filters = { ...this.filters }
        // this.subloginSelecionado = ''
      },
      async efetuarfiltro () {
        this.loadingSkeleton = true
        setTimeout(() => {
          this.loadingSkeleton = false
        }, 6000)
        this.isFiltred = true
        this.menuSituacao = false
        let filtroClientes = this.getFiltroMeusClientes()
        const dataInicial = moment(this.dates[0]).format('DD-MM-yyyy')
        const dataFinal = moment(this.dates[1]).format('DD-MM-yyyy')

        const documento = this.textoFiltroDocumento.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')

        if (filtroClientes.idComissionadoPrincipal === '') {
          await this.buscarEmpresa()
        }
        let filtro = `?idUnidade=${this.unidade.idUnidade}`
        if (filtroClientes.tipo && filtroClientes.tipo === 'revenda') {
          filtro = `?idUnidade=${filtroClientes.idUnidade}`
        }
        if (this.tipoSelecionado === 'Período') {
          filtro += `&dataIni=${dataInicial}`
          filtro += `&dataFim=${dataFinal}`
        }

        const dtInicial = new Date()
        const dtFinal = new Date()
        dtFinal.setFullYear(dtFinal.getFullYear() - 1)

        if (this.validarAnoBissexto(dtInicial) || this.validarAnoBissexto(dtFinal)) {
          dtFinal.setDate(dtFinal.getDate() + 1)
        }

        const formatDate = (date) => {
          const day = String(date.getDate()).padStart(2, '0')
          const month = String(date.getMonth() + 1).padStart(2, '0')
          const year = date.getFullYear()
          return `${day}-${month}-${year}`
        }

        const dtInicialFormatada = formatDate(dtInicial)
        const dtFinalFormatada = formatDate(dtFinal)

        if (this.tipoSelecionado === 'Tipo') {
          filtro += `&dataIni=${dtFinalFormatada}`
          filtro += `&dataFim=${dtInicialFormatada}`
        }

        filtro += '&limit=100'
        filtro += '&offset=1'
        filtro += `&idEmpresa=${filtroClientes.idEmpresa}`
        filtro += `&idComissionado=${filtroClientes.idComissionadoPrincipal}`
        if (filtroClientes.tipo && filtroClientes.tipo === 'vendedor') {
          filtro += `&subLogin=${filtroClientes.login}`
        }
        filtro += this.status.length > 0 ? `&status=${this.status.join(',')}` : `&status=`
        switch (this.tipoSelecionado) {
          case 'CPF/CNPJ': filtro += `&cpfCnpj=${documento}`.trim()
                           break
          case 'Proposta': filtro += `&proposta=${this.textoFiltroProposta}`.trim()
                           break
          case 'Grupo/Cota': filtro += `&grupo=${this.textoFiltro}&cota=${this.textoFiltroCota}`.trim()
                             break
          default:
            break
        }
        this.setStoreFiltroMeusClientes({
          login: filtroClientes.login,
          tipo: filtroClientes.tipo,
          idUnidade: filtroClientes.idUnidade,
          idComissionadoPrincipal: filtroClientes.idComissionadoPrincipal,
          idEmpresa: filtroClientes.idEmpresa
        }, false)
        this.filtrar(filtro)
      },
      async efetuarfiltroLimpo () {
        this.isFiltred = true
        this.menuSituacao = false
        let filtroClientes = this.getFiltroMeusClientes()

        const di = moment(Date.now()).format('DD-MM-yyyy')
        const df = moment(Date.now()).format('DD-MM-yyyy')

        const documento = this.textoFiltroDocumento.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')

        if (filtroClientes.idComissionadoPrincipal === '') {
          await this.buscarEmpresa()
        }
        let filtro = `?idUnidade=${this.unidade.idUnidade}`
        if (filtroClientes.tipo && filtroClientes.tipo === 'revenda') {
          filtro = `?idUnidade=${filtroClientes.idUnidade}`
        }
        if (this.tipoSelecionado === 'Tipo') {
          filtro += `&dataIni=${di}`
          filtro += `&dataFim=${df}`
        }
        filtro += '&limit=100'
        filtro += '&offset=1'
        filtro += `&idEmpresa=${filtroClientes.idEmpresa}`
        filtro += `&idComissionado=${filtroClientes.idComissionadoPrincipal}`
        if (filtroClientes.tipo && filtroClientes.tipo === 'vendedor') {
          filtro += `&subLogin=${filtroClientes.login}`
        }
        filtro += this.status.length > 0 ? `&status=${this.status.join(',')}` : `&status=`
        switch (this.tipoSelecionado) {
          case 'CPF/CNPJ': filtro += `&cpfCnpj=${documento}`.trim()
                           break
          case 'Proposta': filtro += `&proposta=${this.textoFiltroProposta}`.trim()
                           break
          case 'Grupo/Cota': filtro += `&grupo=${this.textoFiltro}&cota=${this.textoFiltroCota}`.trim()
                             break
          default:
            break
        }
        this.setStoreFiltroMeusClientes({
          login: filtroClientes.login,
          tipo: filtroClientes.tipo,
          idUnidade: filtroClientes.idUnidade,
          idComissionadoPrincipal: filtroClientes.idComissionadoPrincipal,
          idEmpresa: filtroClientes.idEmpresa
        }, false)
        this.filtrar(filtro)
      },
      async limparFiltro () {
        this.tipoSelecionado = 'Tipo'
        this.textoFiltro = ''
        this.textoFiltroCota = ''
        this.textoFiltroDocumento = ''
        this.textoFiltroProposta = ''
        this.calcularRangeInicialDoFiltro()
        this.status = []
        this.selecionarTodos('Sucesso', false)
        this.selecionarTodos('Informativo', false)
        this.selecionarTodos('Pendência', false)
        this.selecionarTodos('Atenção', false)
        this.selecionarTodosSucesso = undefined
        this.selecionarTodosInformativo = undefined
        this.selecionarTodosPendencia = undefined
        this.selecionarTodosAtencao = undefined
        this.subloginSelecionado = {}
        this.setFiltroUsuario(this.usuarioLogado, true, '')
        await this.buscarEmpresa()
        this.efetuarfiltroLimpo()
      },
      async extrair () {
        const opcao = this.opcao
        if (opcao.includes('pdf')) {
          try {
            const req = await meusClientespdf(this.filtrosUrl)
            let blob = new Blob([req], {
              type: 'application/pdf;charset=UTF-8'
            })
            const url = URL.createObjectURL(blob)
            let reader = new FileReader()
            reader.readAsDataURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Meus Clientes')
            document.body.appendChild(link)
            link.click()
          } catch (erro) {
            this.$toast.error(erro)
          }
        }
        if (opcao.includes('xslx')) {
          try {
            const req = await meusClientesexcel(this.filtrosUrl)
            let blob = new Blob([req], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            })
            const url = URL.createObjectURL(blob)
            let reader = new FileReader()
            reader.readAsDataURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Meus Clientes')
            document.body.appendChild(link)
            link.click()
          } catch (erro) {
            this.$toast.error(erro)
          }
        }
      },
      async visualizarCliente (item) {
        await this.inicializaClientes({
          idCota: item.idCota,
          cpfCnpj: item.cpfCnpj,
          idComissionado: item.idComissionado,
          cota: item.cota,
          dataAtualizacao: item.dataAtualizacao,
          email: item.email,
          grupo: item.grupo,
          telefone: item.telefone,
          status: item.status,
          nomeStatus: item.nomeStatus,
          nome: item.nome,
          idDocumento: item.idDocumento,
          idTipoDocumento: item.idTipoDocumento,
          idEmpresa: item.idEmpresa,
          idUnidade: session.get('unidade').idUnidade,
          documentosCliente: []
        })
        await this.setUrlFiltro(this.urlDataTable)
        await this.$router.push({
          name: 'JornadaDaCota'
        })
      },

      // nescessario, para resovler rapido o item hierarquia jornada da cota stara.
      async listarSubloginsJornada () {
        try {
          if (this.usuarioLogado.idUsuario) {
            this.sublogins = await listarSubloginsJornada(this.usuarioLogado.idUsuario)
            // console.info(this.sublogins)
          } else if (this.usuarioLogado.id) {
            this.sublogins = await listarSubloginsJornada(this.usuarioLogado.id)
            // console.info(this.sublogins)
          }
        } catch (erro) {
          this.$toast.error(Mensagens.erro)
        }
      },
      async dadosUsuario () {
        let visaoAdm = session.get('visaoAdministrativa').login
        if (!isEmpty(visaoAdm)) {
          this.usuarioLogado = session.get('visaoAdministrativa')
        } else {
          this.usuarioLogado = session.get('usuario')
        }
      // TODO: Visão administrativa
      },
      getColorPorStatus (status, sucesso, informativo, pendencia, atencao) {
        const cotaAprovada = ['pagamento_bem_total', 'cota_alocada', 'analise_credito_automatica_aprovada', 'analise_credito_manual_aprovada', 'aviso_contemplacao_enviado', 'pagamento_lance_realizado', 'cota_quitada']
        const cotaAndamento = ['pagamento_bem_parcial', 'analise_credito_manual_iniciada', 'analise_credito_manual_reanalise_iniciada', 'cota_contemplada_lance']
        const cotaPendencia = ['analise_credito_automatica_reprovada', 'analise_credito_manual_aguardando_cliente', 'cota_inadimplente', 'cota_contemplada_inadimplente']
        const cotaReprovado = ['pagamento_lance_aguardando_cliente', 'analise_credito_manual_reprovada', 'cota_desclassificada', 'cota_cancelada_atraso', 'cota_cancelada_pedido']

        if (cotaAprovada.includes(status)) {
          return sucesso
        }
        if (cotaAndamento.includes(status)) {
          return informativo
        }
        if (cotaPendencia.includes(status)) {
          return pendencia
        }
        if (cotaReprovado.includes(status)) {
          return atencao
        }

        return sucesso
      },
      getTdColorStyle (status) {
        let sucesso = '#3fd47b'
        let informativo = '#90a5ec'
        let pendencia = '#9349f4'
        let atencao = '#ee4784'

        return this.getColorPorStatus(status, sucesso, informativo, pendencia, atencao)
      },
      getTrColorStyle (status) {
        let sucesso = '#e5f4eb'
        let informativo = '#edeff7'
        let pendencia = '#eee6f7'
        let atencao = '#f7e6ec'

        return this.getColorPorStatus(status, sucesso, informativo, pendencia, atencao)
      },
      tdStyles (status) {
        if (this.isMobile) {
          return 'border-left: 8px solid ' + this.getTdColorStyle(status) + ';' + 'background-color: ' + this.getTrColorStyle(status)
        } else {
          return 'background-color: #ffffff; padding: 0px !important;'
        }
      },
      retangleStatusStyles (status) {
        if (this.isMobile) {
          return ''
        } else {
          return ' width: 8px; height: 48px; flex-shrink: 0; border-radius: 0px 25px 25px 0px; margin-right: 11px;' + ' background-color: ' + this.getTdColorStyle(status)
        }
      },
      trStyles () {
        return 'background-color: #ffffff ;'
      },
      defineStyleStatus (fase) {
        const cotaAprovada = ['pagamento_bem_total', 'cota_alocada', 'analise_credito_automatica_aprovada', 'analise_credito_manual_aprovada', 'aviso_contemplacao_enviado', 'pagamento_lance_realizado', 'cota_quitada']
        const cotaAndamento = ['pagamento_bem_parcial', 'analise_credito_manual_iniciada', 'analise_credito_manual_reanalise_iniciada', 'cota_contemplada_lance']
        const cotaPendencia = ['analise_credito_automatica_reprovada', 'analise_credito_manual_aguardando_cliente', 'cota_inadimplente', 'cota_contemplada_inadimplente']
        const cotaReprovado = ['pagamento_lance_aguardando_cliente', 'analise_credito_manual_reprovada', 'cota_desclassificada', 'cota_cancelada_atraso', 'cota_cancelada_pedido']

        if (cotaAprovada.includes(fase)) {
          return { 'CotaAprovada': true }
        }
        if (cotaAndamento.includes(fase)) {
          return { 'CotaAndamento': true }
        }
        if (cotaReprovado.includes(fase)) {
          return { 'CotaReprovada': true }
        }
        if (cotaPendencia.includes(fase)) {
          return { 'CotaPendencia': true }
        }
      },
      defineStartHoverStyleStatus (fase) {
        const cotaAprovada = ['pagamento_bem_total', 'cota_alocada', 'analise_credito_automatica_aprovada', 'analise_credito_manual_aprovada', 'aviso_contemplacao_enviado', 'pagamento_lance_realizado', 'cota_quitada']
        const cotaAndamento = ['pagamento_bem_parcial', 'analise_credito_manual_iniciada', 'analise_credito_manual_reanalise_iniciada', 'cota_contemplada_lance']
        const cotaPendencia = ['analise_credito_automatica_reprovada', 'analise_credito_manual_aguardando_cliente', 'cota_inadimplente', 'cota_contemplada_inadimplente']
        const cotaReprovado = ['pagamento_lance_aguardando_cliente', 'analise_credito_manual_reprovada', 'cota_desclassificada', 'cota_cancelada_atraso', 'cota_cancelada_pedido']

        if (cotaAprovada.includes(fase)) {
          return { 'startCotaAprovada': true }
        }
        if (cotaAndamento.includes(fase)) {
          return { 'startCotaAndamento': true }
        }
        if (cotaReprovado.includes(fase)) {
          return { 'startCotaReprovada': true }
        }
        if (cotaPendencia.includes(fase)) {
          return { 'startCotaPendencia': true }
        }
      },
      defineMiddleHoverStyleStatus (fase) {
        const cotaAprovada = ['pagamento_bem_total', 'cota_alocada', 'analise_credito_automatica_aprovada', 'analise_credito_manual_aprovada', 'aviso_contemplacao_enviado', 'pagamento_lance_realizado', 'cota_quitada']
        const cotaAndamento = ['pagamento_bem_parcial', 'analise_credito_manual_iniciada', 'analise_credito_manual_reanalise_iniciada', 'cota_contemplada_lance']
        const cotaPendencia = ['analise_credito_automatica_reprovada', 'analise_credito_manual_aguardando_cliente', 'cota_inadimplente', 'cota_contemplada_inadimplente']
        const cotaReprovado = ['pagamento_lance_aguardando_cliente', 'analise_credito_manual_reprovada', 'cota_desclassificada', 'cota_cancelada_atraso', 'cota_cancelada_pedido']

        if (cotaAprovada.includes(fase)) {
          return { 'middleCotaAprovada': true }
        }
        if (cotaAndamento.includes(fase)) {
          return { 'middleCotaAndamento': true }
        }
        if (cotaReprovado.includes(fase)) {
          return { 'middleCotaReprovada': true }
        }
        if (cotaPendencia.includes(fase)) {
          return { 'middleCotaPendencia': true }
        }
      },
      defineEndHoverStyleStatus (fase) {
        const cotaAprovada = ['pagamento_bem_total', 'cota_alocada', 'analise_credito_automatica_aprovada', 'analise_credito_manual_aprovada', 'aviso_contemplacao_enviado', 'pagamento_lance_realizado', 'cota_quitada']
        const cotaAndamento = ['pagamento_bem_parcial', 'analise_credito_manual_iniciada', 'analise_credito_manual_reanalise_iniciada', 'cota_contemplada_lance']
        const cotaPendencia = ['analise_credito_automatica_reprovada', 'analise_credito_manual_aguardando_cliente', 'cota_inadimplente', 'cota_contemplada_inadimplente']
        const cotaReprovado = ['pagamento_lance_aguardando_cliente', 'analise_credito_manual_reprovada', 'cota_desclassificada', 'cota_cancelada_atraso', 'cota_cancelada_pedido']

        if (cotaAprovada.includes(fase)) {
          return { 'endCotaAprovada': true }
        }
        if (cotaAndamento.includes(fase)) {
          return { 'endCotaAndamento': true }
        }
        if (cotaReprovado.includes(fase)) {
          return { 'endCotaReprovada': true }
        }
        if (cotaPendencia.includes(fase)) {
          return { 'endCotaPendencia': true }
        }
      },
      async buscarEmpresa () {
        try {
          if (this.usuarioLogado.perfil !== 'SUBLOGIN') {
            const req = await unidadesLogin(this.usuarioLogado.login)
            const idEmpresa = req.unidades.find(e => e.idUnidade === this.unidade.idUnidade).idEmpresa
            let filtroClientes = this.getFiltroMeusClientes()
            this.setFiltroMeusClientes({
              idEmpresa: idEmpresa,
              idComissionadoPrincipal: req.usuario.idComissionado,
              filtrado: filtroClientes.filtrado,
              login: filtroClientes.login,
              tipo: filtroClientes.tipo,
              idUnidade: filtroClientes.idUnidade,
              dataInicial: filtroClientes.dataInicial,
              dataFinal: filtroClientes.dataFinal,
              dates: filtroClientes.dates,
              tipoSelecionado: filtroClientes.tipoSelecionado,
              textoFiltroDocumento: filtroClientes.textoFiltroDocumento,
              textoFiltroProposta: filtroClientes.textoFiltroProposta,
              grupo: filtroClientes.grupo,
              cota: filtroClientes.cota,
              listaStatus: filtroClientes.listaStatus,
              subloginSelecionado: filtroClientes.subloginSelecionado,
              subloginSelecionadoFiltro: filtroClientes.subloginSelecionadoFiltro
            })
          }
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async buscarUnidadesLogin (login) {
        try {
          return await unidadesLogin(login)
        } catch (erro) {
          this.$toast.error(Mensagens.erro)
        }
      },
      validarAnoBissexto (data) {
        return new Date(data.getFullYear(), 1, 29).getMonth() === 1
      }
    }
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap');
.tabelaLegenda {
  border-collapse:collapse;
  width: 333px!important;
}

.containerInfo{
  display:flex;
  flex-direction: column;
  justify-content:center;
  padding-left: 0px !important;
  min-width: 100px !important;
  height:48px !important;
  border-radius: 8px !important;
  border: 1px solid var(--tons-de-cinza-20, #E7E7E8)!important;
}

.labelInfoStyle {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #191E26;
  padding-left: 7px;
}

.groupAndProposText {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #7E8185;
}

.nameStyle{
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.visualizarText{
  color: #5E6267;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.listaClienteLabel{
  color: #191E26;
  font-family: 'Inter',sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.cpfCnpjLabel{
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #191E26;
}

.cpfCnpjText{
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #5E6267;
}

.btnSituacao {
  /* min-width: 264px!important; */
  height: 48px!important;
  flex-shrink: 0!important;
  border-radius: 8px!important;
  padding: 0px 0px!important;
  margin-right: 10px!important;
  margin-left: 8px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.speedDial{
  width:100px!important;
  height: 43px!important;
  padding: 0px 0px !important;
  background-color: #cfd0d1;
  border-radius: 8px!important;
  overflow-y: hidden!important;
}
.textBtnSituacao {
  text-align: center!important;
  font-family: 'Inter', sans-serif!important;
  font-style: normal!important;
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: normal!important;
  color: #FFFFFF!important;
  text-transform: none!important;
  margin-right: 18px;
  margin-left: 18px;
}

.verJornadaContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width:129px!important;
  height: 41px!important;
  background-color: #cfd0d1;
  border-radius: 25px!important;
  cursor: pointer;
  margin-right: 16px !important;
}

.verJornadaText{
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #191E26;
  line-height: 21px;
}

.startCotaPendencia
{
  border: 1px solid rgb(147, 73, 244)!important;
  border-right: 0px!important;
}
.startCotaAprovada
{
  border: 1px solid rgb(63, 212, 123)!important;
  border-right: 0px!important;
}
.startCotaAndamento
{
  border: 1px solid rgb(144, 165, 236)!important;
  border-right: 0px!important;
}
.startCotaReprovada
{
  border: 1px solid rgb(238, 71, 132)!important;
  border-right: 0px!important;
}

.middleCotaPendencia
{
  border: 1px solid rgb(147, 73, 244)!important;
  border-right: 0px!important;
  border-left: 0px!important;
}
.middleCotaAprovada
{
  border: 1px solid rgb(63, 212, 123)!important;
  border-right: 0px!important;
  border-left: 0px!important;
}
.middleCotaAndamento
{
  border: 1px solid rgb(144, 165, 236)!important;
  border-right: 0px!important;
  border-left: 0px!important;
}
.middleCotaReprovada
{
  border: 1px solid rgb(238, 71, 132)!important;
  border-right: 0px!important;
  border-left: 0px!important;
}
.endCotaPendencia
{
  border: 1px solid rgb(147, 73, 244)!important;
  border-left: 0px!important;
}
.endCotaAprovada
{
  border: 1px solid rgb(63, 212, 123)!important;
  border-left: 0px!important;
}
.endCotaAndamento
{
  border: 1px solid rgb(144, 165, 236)!important;
  border-left: 0px!important;
}
.endCotaReprovada
{
  border: 1px solid rgb(238, 71, 132)!important;
  border-left: 0px!important;
}

.verJornadaText:hover{
  color: var(--primaria);
}

.tabelaLegendaSucesso {
  border-radius: 12px 0px 0px 12px;
  background-color: #3fd47b;
  color: white;
  font-size: 12px;
  height: 48px;
  text-align: center;
  width: 90px;
}
.tabelaLegendaInformativo {
  background: #90A5EC;
  color: white;
  font-size: 12px;
  height: 48px;
  text-align: center;
  width: 90px;
}

.tabelaLegendaPendencia {
  background: #9349F4;
  color: white;
  font-size: 12px;
  height: 48px;
  text-align: center;
  width: 90px;
}

.client_container {
  display: flex;
  align-items: center;
  width: 350px;
}

.tabelaLegendaAtencao {
  background: #EE4784;
  color: white;
  font-size: 12px;
  border-radius: 0px 12px 12px 0px;
  height: 48px;
  text-align: center;
  width: 90px;
}

.tabelaTipos {
  border: 1px solid #CFD0D1;
  border-radius: 4px;
}

.titulo {
  font-family: Inter;
  font-size: 22px;
  font-weight: 800;
  line-height: 150%;
  color: #000;
}

.fasesDaCotaLabel{
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #191E26;
}

.CotaAndamento
{
  color: #6800F1 !important;
  font-weight: bold;
  border: 2px solid #6800F1;
  text-align: center;
}
.CotaReprovada
{
  color: #B80043 !important;
  font-weight: bold;
  border: 2px solid #B80043;
  text-align: center;
}

.CotaPendencia
{
  color: #9349f4 !important;
  font-weight: bold;
  border: 2px solid #9349f4;
  text-align: center;
}
.CotaAprovada
{
  color: #008836 !important;
  font-weight: bold;
  border: 2px solid #008836;
  text-align: center;
}
.links
{
  font-size: 14px;
  color: var(--primaria);
  text-decoration-line: underline;
}
a:visited{
  color: var(--primaria);
}
.align-td{
  height: 100%;
  display: flex;
  align-items: center;
}
.expandir-status{
  background-color: white;
  width: 100%;
  min-width: 100%;
}

.item-selecionado {
  color: var(--primaria);
  font-size: 16px;
  font-weight: bold;
}

.item-selecionado-parceiro-vendedor {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: bold;
}

.item-selecionado-parceiro-vendedor-item {
  background: rgba(94, 98, 103, 1);
}

.item-selecionado-parceiro-revenda {
  color: var(--primaria);
  font-size: 16px;
  font-weight: bold;
}

.item-selecionado-parceiro-revenda-item{
  background-color: rgba(231, 231, 232, 1);

}

.item-selecionado-vendedor {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(94, 98, 103, 1);
  font-size: 16px;
  font-weight: bold;
}

.filter-menu-placeholder {
  color: #5E6267;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.item-selecionado-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--primaria);
}

.item-selecionado-label-tipo {
  font-family: Inter;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--primaria);
}

.item-selecionado-label-extracao {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: rgb(94, 98, 103);
}

.item-sem-selecao {
  color: #5E6267;
  font-weight: normal;
  font-size: 16px;
}

.texto-sucesso {
  color: rgba(63, 212, 123, 1);
}

.situacaoSelecionadaTitulo {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.situacaoSelecionadaSubtitulo {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #5E6267;
}

.items-situacao-cota-sucesso{
  height: 20px!important;
  color: #3FD47B;
}

.items-situacao-cota-sucesso-checked {
  height: 48px!important;
  background-color: #3FD47B;
  border-radius: 8px;
  margin: 2px 0;
}

.texto-informativo {
  color: #90A5EC;
}

.items-situacao-cota-informativo {
  height: 20px!important;
  color: #90A5EC;
}

.items-situacao-cota-informativo-checked {
  height: 48px!important;
  background-color: #90A5EC;
  border-radius: 8px;
  margin: 2px 0;
}

.texto-pendencia {
  color: #9349F4;
}

.items-situacao-cota-pendencia {
  height: 20px!important;
  color: #9349F4;
}
.items-situacao-cota-pendencia-checked {
  background-color: #9349F4;
  height: 48px!important;
  border-radius: 8px;
  margin: 2px 0;
}

.texto-atencao {
  color: #EE4784;
}

.items-situacao-cota-atencao {
  height: 20px!important;
  color: #EE4784;
}

.items-situacao-cota-atencao-checked {
  height: 48px!important;
  background-color: #EE4784;
  border-radius: 8px;
  margin: 2px 0;
}

.tabelaLegendaSituacaoSucesso {
  color: #5E6267;
  font-size: 11px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.tabelaLegendaSituacaoInformativo {
  color: #5E6267;
  font-size: 11px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.tabelaLegendaSituacaoPendencia {
  color: #5E6267;
  font-size: 11px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.tabelaLegendaSituacaoAtencao {
  color: #5E6267;
  font-size: 11px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.tabelaLegendaSituacaoSucessoSelecionada {
  color: rgb(63, 212, 123, 1);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 2px solid rgb(63, 212, 123, 1);
}

.tabelaLegendaSituacaoInformativoSelecionada {
  color: rgb(144, 165, 236, 1);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-underline-offset:9px;
  border-bottom: 2px solid rgb(144, 165, 236, 1);;
}

.tabelaLegendaSituacaoPendenciaSelecionada {
  color: rgb(147, 73, 244, 1);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 2px solid rgb(147, 73, 244, 1);
}

.tabelaLegendaSituacaoAtencaoSelecionada {
  color: rgb(238, 71, 132, 1);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  border-bottom: 2px solid rgb(238, 71, 132, 1);
}

.checkboxLabelSucesso {
  color: rgb(63, 212, 123, 1);
  font-size: 14px;
  font-weight: 400;
}

.checkboxLabelSucessoChecked {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.checkboxLabelInformativo {
  color: rgb(144, 165, 236, 1);
  font-size: 14px;
  font-weight: 400;
}

.checkboxLabelInformativoChecked {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.checkboxLabelPendencia {
  color: rgb(147, 73, 244, 1);
  font-size: 14px;
  font-weight: 400;
}

.checkboxLabelPendenciaChecked {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.checkboxLabelAtencao {
  color: rgb(238, 71, 132, 1);
  font-size: 14px;
  font-weight: 400;
}

.checkboxLabelAtencaoChecked {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.parceiro-selecionado {
  color: #191E26;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.parceiro-selecionado-revenda {
  color: var(--primaria);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: bold;
}

.item-gestor {
  background-color: var(--primaria);
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
}

.hint-tabela-legenda {
  color: #9EA1A4;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}
.texto-legenda {
  font-family: Inter;
  border-bottom: none;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

button.btn-limpar-filtros
{
  font-family: Inter;

  box-shadow: none!important;
  font-weight: 600!important;
  font-size: 16px!important;
  letter-spacing: 0!important;
  height: 48px!important;
  border: 1px solid #CFD0D1 !important;
  color: #191E26 !important;
  background-color: white !important;
}

button.btn-aplicar-filtro
{
  font-family: Inter;
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
  height: 48px !important;
}

button.aplicar-filtro-situacao
{
  height: 48px !important;
  width: 90%!important;
  font-family: Inter;
  box-shadow: none!important;
}

button.btn-extrair
{
  font-size:14px!important;
  font-weight: 600!important;
  box-shadow: none!important;
  letter-spacing: 0!important;
  background-color: #FFFFFF!important;
  margin-top: 15px!important;
  color: var(--primaria)!important;
  height: 48px!important;
}

button.btn-limpar-filtros:active
{
  border: 1px solid var(--primariaDarken) !important;
  color: var(--primariaDarken) !important;
}
button.btn-extrair:active
{
  border: 1px solid var(--primariaDarken) !important;
  color: var(--primariaDarken) !important;
  background-color: #FFFFFF!important;
}

button.btn-aplicar-filtro:active
{
  background-color: var(--primariaDarken) !important;
}

button.aplicar-filtro-situacao:active
{
  background-color: var(--primariaDarken) !important;
}

.v-card--link:before {
  background: transparent !important;
}

.v-input--checkbox::v-deep {
  .v-icon {
    color: white;
  }
  margin-top: 0;
  margin-bottom: 0;
}

.campoBotao{
  margin-right: -3px;
}
.skeletonListaClientes{
  margin-top: -80px !important;
  padding-top: -80px !important;
  margin-bottom: 6px;
}
.skeletonExtrair {
  margin-bottom: -80px !important;
}
.skeletonExtrairWeb {
  margin-bottom: -80px !important;
  margin-right: -14px !important;
}
.skeletonLegenda {
  margin-left: -8px !important;
}
.skeletonLegendaTipos {
  margin-left: -8px !important;
}
.filtrosSkeletonWeb {
  margin-left: -12px !important;
}
.filtrosSkeletonWebSituacao {
  margin-right: -14px !important;
}

.imglegenda {
  height: 11px;
  padding-right: 3px;
}

@media screen and (min-width: 1700px){
  .campoBotao{
    margin-right: -36px;
  }
}

@media screen and (min-width: 960px){

    * {
      font-family: Inter;
    }

    .item-selecionado-parceiro-vendedor-item-hover:hover {
      background: rgba(94, 98, 103, 1);
      div {
        color:white
      }
    }
    .parceiro-selecionado:hover {
      color: white;
    }

    button.btn-limpar-filtros:hover
    {
      border: 1px solid var(--primariaDarken, red) !important;
      color: var(--primariaDarken, red) !important;
    }

    button.btn-limpar-filtros::before
    {
      background-color: transparent !important;
    }
    #coluna-limpar-filtros:hover {
      button {
        border: 1px solid var(--primariaDarken)!important;
        color: var(--primariaDarken)!important;
      }
      svg path {
        fill: var(--primariaDarken)!important;
      }
    }

    button.btn-extrair:hover
    {
      border: 1px solid var(--primariaDarken)!important;
      color: var(--primariaDarken)!important;
      background-color: #FFFFFF!important;
    }

    button.btn-aplicar-filtro:hover
    {
      background-color: var(--primariaDarken)!important;
    }

    button.aplicar-filtro-situacao:hover
    {
      background-color: var(--primariaDarken)!important;
    }

    #svg-limpar-filtros:hover path {
      fill: var(--primariaDarken)!important;
    }
    .tipo-hover:hover {
      height: 20px!important;
      background-color: var(--primariaTransparent)!important;
      color: white!important;
    }
    .items-situacao-cota-atencao:hover {
      height: 20px!important;
      color: #EE4784;
      background-color: rgb(238, 71, 132, 0.06);
      border-radius: 8px;
    }
    .items-situacao-cota-pendencia:hover {
      height: 20px!important;
      color: #9349F4;
      background-color: rgb(147, 73, 244, 0.06);
      border-radius: 8px;
    }

    .items-situacao-cota-informativo:hover {
      height: 20px!important;
      color: #90A5EC;
      background-color: rgb(144, 165, 236, 0.06);
      border-radius: 8px;
    }
    .items-situacao-cota-sucesso:hover {
      height: 20px!important;
      color: #3FD47B;
      background-color: rgb(63, 212, 123, 0.06);
      border-radius: 8px;
    }

    .items-situacao-cota-atencao:active {
      height: 20px!important;
      color: #EE4784;
      background-color: rgb(238, 71, 132, 0.2);
      border-radius: 8px;
    }
    .items-situacao-cota-pendencia:active {
      height: 20px!important;
      color: #9349F4;
      background-color: rgb(147, 73, 244, 0.2);
      border-radius: 8px;
    }

    .items-situacao-cota-informativo:active {
      height: 20px!important;
      color: #90A5EC;
      background-color: rgb(144, 165, 236, 0.2);
      border-radius: 8px;
    }
    .items-situacao-cota-sucesso:active {
      height: 20px!important;
      color: #3FD47B;
      background-color: rgb(63, 212, 123, 0.2);
      border-radius: 8px;
    }
}

@media screen and (max-width: 960px){
    .container {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      padding-left: 7px !important;
      padding-right: 7px !important;
    }
    .tabelaLegendaSituacaoSucesso,
    .tabelaLegendaSituacaoInformativo,
    .tabelaLegendaSituacaoPendencia,
    .tabelaLegendaSituacaoAtencao,
    .tabelaLegendaSituacaoSucessoSelecionada,
    .tabelaLegendaSituacaoInformativoSelecionada,
    .tabelaLegendaSituacaoPendenciaSelecionada,
    .tabelaLegendaSituacaoAtencaoSelecionada {
      text-align: center;
      font-family: Inter;
      text-transform: capitalize;
      font-size: 11px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      height: 36px;
    }

    .items-situacao-cota-atencao:focus {
      height: 20px!important;
      color: #EE4784;
      background-color: #ee47840f;
      border-radius: 8px;
    }

    .items-situacao-cota-pendencia:focus {
      height: 20px!important;
      color: #9349F4;
      background-color: #9349f40f;
      border-radius: 8px;
    }

    .items-situacao-cota-informativo:focus {
      height: 20px!important;
      color: #90A5EC;
      background-color: #90a5ec0f;
      border-radius: 8px;
    }

    .items-situacao-cota-sucesso:focus {
      height: 20px!important;
      color: #3FD47B;
      background-color: #3fd47b33;
      border-radius: 8px;
    }

    .items-situacao-cota-atencao:active {
      height: 20px!important;
      color: #EE4784;
      background-color: #ee47840f;
      border-radius: 8px;
    }

    .items-situacao-cota-pendencia:active {
      height: 20px!important;
      color: #9349F4;
      background-color: #9349f40f;
      border-radius: 8px;
    }

    .items-situacao-cota-informativo:active {
      height: 20px!important;
      color: #90A5EC;
      background-color: #9349f40f;
      border-radius: 8px;
    }
    .items-situacao-cota-sucesso:active {
      height: 20px!important;
      color: #3FD47B;
      background-color: rgb(63, 212, 123, 0.6);
      border-radius: 8px;
    }

    .texto-legenda{
      border-bottom: none;
      font-family: Inter;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;
      text-transform: capitalize;
    }
    #colunaExtrair {
      padding-bottom: 8px;
    }
    .listaClienteLabel {
      margin-bottom: 2px;
    }
    .item-selecionado-label-tipo {
      margin-right: -30px;
      text-align: center;
      display: flex;
      align-items:c enter;
    }
    .align-vertical {
      display: inline;
      align-items: right;
      min-width: 100px;
    }
    .align-vertical-parceiro {
      display: inline;
      align-items: right;
      min-width: 73px;
    }
    .btnSituacao {
      width: 264px!important;
      height: 48px!important;
      flex-shrink: 0!important;
      border-radius: 8px!important;
      padding: 0px 0px!important;
      margin-right: 0px!important;
      margin-left: 0px!important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .textBtnSituacao {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .verJornadaContainer{
      margin-right: 0px !important;
    }
    .filtrosSkeleton {
      margin-left: -12px;
      margin-bottom: -12px;
      margin-top: -12px;
      min-width: 107% !important;
    }
    .tabelaLegenda {
      margin-top: 16px;
      min-width: 50%;
      margin-bottom: 24px;
      margin-left: 16px;
      align-items: center;
    }
}

@media screen and (min-width: 700px) and (max-width: 960px){
  .tabelaLegenda {
      margin-top: 16px;
      min-width: 50%;
      margin-bottom: 24px;
      margin-left: 16px;
    }
}

@media screen and (max-width: 414px){
  .btn-limpar-filtros {
    margin-left: 3px;
  }
  .filtrosSkeleton {
      margin-left: -12px;
      margin-bottom: -12px;
      margin-top: -12px;
      min-width: 108% !important;
    }
}

</style>

<style lang="scss">

.sucesso-situacao-checkbox {
  height: 48px !important;
  margin: 2px 0;

  .v-input--selection-controls {
    margin-top: 2px!important;
    padding-top: 2px!important;
  }
  .v-icon {
    color: #3FD47B !important;
  }
}

.informativo-situacao-checkbox {
  height: 48px !important;
  margin: 2px 0;

  .v-input--selection-controls {
    margin-top: 2px!important;
    padding-top: 2px!important;
  }
  .v-icon {
    color: #90A5EC !important;
  }
}

.pendencia-situacao-checkbox {
  height: 48px !important;
  margin: 2px 0;

  .v-input--selection-controls {
    margin-top: 2px!important;
    padding-top: 2px!important;
  }
  .v-icon {
    color: #9349F4 !important;
  }
}

.atencao-situacao-checkbox {
  height: 48px !important;
  margin: 2px 0;

  .v-input--selection-controls {
    margin-top: 2px!important;
    padding-top: 2px!important;
  }
  .v-icon {
    color: #EE4784 !important;
  }
}
</style>
