import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import store from '@/store'
import { get } from 'lodash'
import session from './session'
import axiosCancel from 'axios-cancel'
import router from './router'

const http = axios.create({
  baseURL: `${process.env.VUE_APP_WS_ROOT}/api`,
  timeout: 60 * 3000
})

export default http

axiosCancel(http)

Vue.use(VueAxios, http)

// region Interceptor de request
function onRequestFulfilled (config) {
  if (session.exists()) {
    const perfil = session.get('usuario').perfil
    console.info(perfil === 'MASTER')
    config.headers['Authorization'] = session.get('usuario').token
  }

  const options = config['options'] = {
    silent: false,
    ...config['options']
  }

  if (!options.silent) {
    store.commit('loadingStart')
  } else {
    store.commit('setCarregandoSilenciosamente', true)
  }

  return config
}

function onRequestRejected (error) {
  hideLoading(error)
  return Promise.reject(error)
}

http.interceptors.request.use(onRequestFulfilled, onRequestRejected)
// endregion

// region Interceptor de response

function onResponseFulfilled (response) {
  hideLoading(response)

  return response
}

function onResponseRejected (error) {
  hideLoading(error)

  const status_code = get(error, 'response.status', 0)
  const url = get(error, 'response.config.url', '')

  if ([401, 403].includes(status_code) && url !== '/login') {
    const perfil = session.get('usuario').perfil
    console.info(perfil)
    session.destroy()
    if (perfil === 'MASTER' || perfil === 'GESTOR') {
      router.push({
        name: 'Login',
        replace: true
      })

      error.response.data.mensagem = 'Sessão expirada por inatividade. Acesse novamente para continuar utilizando a plataforma'
    } else {
      window.location.href = `https://login.microsoftonline.com/logout.srf`
    }
    return Promise.reject(error)
  } else {
    if (url.indexOf('listar-notificacao-login-datachegada') > -1) {
      console.info(error)
      return
    };
    return Promise.reject(error)
  }
}

http.interceptors.response.use(onResponseFulfilled, onResponseRejected)
// endregion

function hideLoading (response) {
  const silent = get(response, 'config.options.silent', false)
  if (!silent) {
    store.commit('loadingDone')
  } else {
    store.commit('setCarregandoSilenciosamente', false)
  }
}
