<template>
  <v-dialog
      persistent
      width="500"
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>Falha ao autenticar na Área Restrita</v-card-title>
      <v-card-text>
        Falha ao autenticar na Área Restrita, em caso de dúvidas entre em contato com o Administrador, por motivos de segurança nós iremos realizar logout na sua conta Unicred
      </v-card-text>
      <v-card-actions>
        <Button
            small
            block
            @click="fechar"
        >
          Ok
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import { mapActions } from 'vuex'

  export default {
    name: 'DialogErroLoginUnicred',
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    mounted () {
      if (this.exibir === true) {
        this.delayCloseAlert()
      }
    },
    methods: {
      ...mapActions([
        'setErroUnicred'
      ]),
      fechar () {
        this.setErroUnicred(false)
        window.location.href = 'https://adfs.e-unicred.com.br/adfs/ls/idpinitiatedsignon/?wa=wsignout1.0'
      },
      delayCloseAlert () {
        setTimeout(() => {
          this.setErroUnicred(false)
          window.location.href = 'https://adfs.e-unicred.com.br/adfs/ls/idpinitiatedsignon/?wa=wsignout1.0'
        }, 10 * 1000)
      }
    }
  }
</script>

<style scoped>

</style>
