<template>
  <v-dialog
      persistent
      width="200"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="exibir"
  >
    <v-card class="pb-3">
      <v-card-title>
        <span>Aceitar nota fiscal</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-actions>
        <v-form>
          <v-row>
            <v-form
                lazy-validation
                ref="formulario"
            >
              <v-col class="mt-4 d-flex flex-column">
                <h4>Número da nota fiscal *</h4>
                <TextField
                    maxlength="20"
                    v-mask="'####################'"
                    v-model="numeroNota"
                    :rules="[$rules.numeroObrigatorio, maiorQueZero]"
                />
                <v-checkbox
                    v-model="baixarNotaFiscal"
                    color="primaria"
                    label="Deseja fazer download?" />
              </v-col>
            </v-form>
          </v-row>
        </v-form>
      </v-card-actions>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <Button
                outlined
                block
                @click="fechar"
            >
              Cancelar
            </Button>
          </v-col>
          <v-col cols="6">
            <Button
                block
                @click="aceitarNota"
            >
              Confirmar
            </Button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Button from '../Button.vue'
  import { buscarUrlArquivo } from '@/service/arquivo'
  import { aceitarNotaFiscal, enviarNotaConferida } from '@/service/notasFiscais'

  export default {
    name: 'DialogAceitarNota',
    components: { Button },
    props: {
      exibir: {
        type: Boolean,
        default: false
      },
      idNota: Number,
      idArquivo: Number
    },
    data: () => ({
      numeroNota: '',
      maiorQueZero: v => Number(v) > 0 || 'O número precisa ser maior que zero',
      baixarNotaFiscal: false
    }),
    methods: {
      async aceitarNota () {
        if (this.$refs.formulario.validate()) {
          await aceitarNotaFiscal(this.idNota, this.numeroNota)
          // Enviar emails
          if (this.baixarNotaFiscal) {
            window.open(await buscarUrlArquivo(this.idArquivo))
            await enviarNotaConferida(this.idNota)
            this.$toast.success('Nota fiscal enviada!')
          } else {
            this.$toast.success('Nota fiscal conferida!')
          }
          this.fechar()
          this.$emit('proxima')
        }
      },
      fechar () {
        this.$refs.formulario.reset()
        this.$emit('exibir', false)
      }
    }
  }

</script>
