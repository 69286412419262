<template>
  <v-container fluid style="background-color: #F7F7F7; padding: 15px 20px; min-height: 92vh;">
    <v-row>
      <v-alert
          v-model="erro"
          @dismiss="erro = false"
          type="error"
          dismissible
          dense
      >
        <span class="text-h8">
          {{ mensagemErro }}
        </span>
      </v-alert>
    </v-row>
    <v-row class="pt-3 breadcrumb">
      <v-skeleton-loader
          v-if="loadingSkeleton"
          type="image"
          :width="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '20%' : '30%'"
          height="18px"
          class="breadcrumbSkeleton"
      />
      <BreadCrumb v-else :items="breadcrumb" show-back back-to="MeusClientes" />
    </v-row>
    <v-row>
      <v-col :class="isMobile ? 'mb-0 pb-0 pl-1' : 'mb-0 pb-0 pl-2'" cols="12" md="6">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '50%' : '60%'"
            height="25px"
        />
        <label v-else class="label-jornada-cota">Jornada da Cota</label>
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type=""
        />
        <label v-else class="exibir-ocultar" @click="exibirOcultar" v-show="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          {{ exibirDetalhes ? 'ver menos' : 'ver mais' }}
          <v-icon color='var(--primary)'>
            {{ exibirDetalhes ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </label>
      </v-col>
      <v-col :class="isMobile ? 'mt-0 pt-2 pt-4 pl-0 pb-2 d-flex justify-start pr-3' : 'mt-0 pt-2 pt-0 pb-0 d-flex justify-end pr-3'" :cols="!isMobile ? 6 : 12">
        <table class="tabelaLegenda">
          <tbody>
            <tr>
              <td colspan="4">
                <!-- <v-skeleton-loader
                    v-if="loadingSkeleton"
                    type="text"
                    style="width: 50%;"
                    class="skeletonLegenda"
                />
                <span v-else class="hint-tabela-legenda">Legenda de estado da cota:</span> -->
              </td>
            </tr>
            <tr v-if="loadingSkeleton">
              <td v-for="n in 4" :key="n">
                <v-skeleton-loader type="text" :transition="transition" />
              </td>
            </tr>
            <tr v-else>
              <td class="texto-legenda texto-sucesso"><img class="imglegenda" src="@/assets/img/sucesso.png">Sucesso</td>
              <td class="texto-legenda texto-informativo"><img class="imglegenda" src="@/assets/img/informativo.png">Informativo</td>
              <td class="texto-legenda texto-pendencia"><img style="padding-right: 5px;padding-bottom: 2px;" src="@/assets/img/pendencia.png">Pendência</td>
              <td class="texto-legenda texto-atencao"><img class="imglegenda" src="@/assets/img/atencao.png">Atenção</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <div class="mt-6 exibir-ocultar-mobile d-flex justify-end">
      <label v-if="!loadingSkeleton" @click="exibirOcultar" v-show="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
        {{ exibirDetalhes ? 'ver menos' : 'ver mais' }}
        <v-icon color='var(--primary)'>
          {{ exibirDetalhes ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </label>
    </div>
    <v-row v-show="exibirDetalhes" v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
      <v-col class="CardInformacoesCliente">
        <CardTopoInformacoes :dados="infocliente" />
      </v-col>
      <v-col class="CardAtualizacoesCliente">
        <CardTopoAtualizacoes :dados="infocliente" />
      </v-col>
    </v-row>
    <v-row v-else class="cardClientes" v-show="exibirDetalhes">
      <v-col>
        <CardExtratoClienteMobile :dados="infocliente" />
      </v-col>
    </v-row>
    <v-row class="pt-3" style="margin-top: 0px!important;">
      <JornadaDaCotaTimeline :items="fases" />
    </v-row>
  </v-container>
</template>

<script>
  import CardTopoInformacoes from '@/components/JornadaDaCota/CardTopoInformacoes.vue'
  import CardTopoAtualizacoes from '@/components/JornadaDaCota/CardTopoAtualizacoes.vue'
  import CardExtratoClienteMobile from '@/components/JornadaDaCota/CardExtratoClienteMobile.vue'
  import JornadaDaCotaTimeline from '@/components/JornadaDaCota/JornadaDaCotaTimeline.vue'
  import BreadCrumb from '@/components/JornadaDaCota/BreadCrumb.vue'
  import { Mensagens } from '@/plugins/constants'
  import '@mdi/font/css/materialdesignicons.css'
  import { mapGetters, mapActions } from 'vuex'
  import session from '@/plugins/session'
  import http from '@/plugins/http'

  export default {
    name: 'JornadaDaCota',
    components: {
      CardTopoInformacoes,
      CardTopoAtualizacoes,
      CardExtratoClienteMobile,
      JornadaDaCotaTimeline,
      BreadCrumb
    },
    data: () => ({
      exibirDetalhes: true,
      loadingSkeleton: false,
      erro: false,
      acompanhar: true,
      mensagemErro: '',
      headers: [
        { text: '', value: 'timeline', sortable: false }
      ],
      fases: [],
      showModal: false,
      isExpandido: false
    }),
    computed: {
      ...mapGetters({
        infocliente: 'getMeusClientes',
        tableFilterUrl: 'getUrlFiltro'
      }),
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      breadcrumb () {
        return [
          { name: 'Meus Clientes', to: 'MeusClientes' },
          { name: 'Jornada da Cota', to: '' }
        ]
      }
    },
    mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
      }, 6000)
      this.buscarTimeline()
    },
    methods: {
      ...mapActions(['setFaseAnalise', 'inicializaClientes']),
      async buscarTimeline () {
        let parametros = this.$route.params
        if (parametros.idComissionado) {
          this.$store.commit('setRotaAtual', 'MeusClientes')
          await http.get(`jornada-cota/cotas/timeline?idDocumento=${parametros.idDocumento}&idTipoDocumento=${parametros.idTipoDocumento}&idEmpresa=${parametros.idEmpresa}&idUnidade=${parametros.idUnidade}&idComissionado=${parametros.idComissionado}`, { options: { silent: true } })
            .then((response) => {
              if (response) {
                this.fases = response.data.timeline
                this.infocliente = response.data.cota

                if (!this.infocliente.nome) {
                  this.inicializarCliente(response.data.cota)
                  this.infocliente.dataAtualizacao = response.data.cota.dataAtualizacao
                  this.infocliente.status = response.data.cota.status
                  this.infocliente.nomeStatus = response.data.cota.nomeStatus
                  this.infocliente.nome = response.data.cota.nome
                  this.infocliente.extratoCota = response.data.cota && response.data.cota.extratoCota ? response.data.cota.extratoCota : {}
                }
              } else {
                this.$toast.error(Mensagens.ERRO)
              }
            })
            .catch(erro => {
              if (!/(cancelRequest)/.test(erro.message)) {
                this.$toast.error(Mensagens.ERRO)
              }
            })
        } else {
          await http.get(`jornada-cota/cotas/timeline?idDocumento=${this.infocliente.idDocumento}&idTipoDocumento=${this.infocliente.idTipoDocumento}&idEmpresa=${this.infocliente.idEmpresa}&idUnidade=${this.infocliente.idUnidade}&idComissionado=${this.infocliente.idComissionado}`, { options: { silent: true } })
            .then((response) => {
              if (response) {
                this.fases = response.data.timeline

                this.infocliente.dataAtualizacao = response.data.cota.dataAtualizacao
                this.infocliente.status = response.data.cota.status
                this.infocliente.nomeStatus = response.data.cota.nomeStatus
                this.infocliente.nome = response.data.cota.nome
                this.infocliente.extratoCota = response.data.cota && response.data.cota.extratoCota ? response.data.cota.extratoCota : {}
              } else {
                this.$toast.error(Mensagens.ERRO)
              }
            })
            .catch(erro => {
              if (!/(cancelRequest)/.test(erro.message)) {
                this.$toast.error(Mensagens.ERRO)
              }
            })
        }
        // await this.extratoCota()
      },
      extratoCota () {
        http.get(`extrato-cota/${this.infocliente.idDocumento}`, { options: { silent: true } })
          .then((response) => {
            if (response) {
              this.infocliente.extratoCota = response.data.cota.extratoCota
            } else {
              this.$toast.error(Mensagens.ERRO)
            }
          })
          .catch(erro => {
            if (!/(cancelRequest)/.test(erro.message)) {
              this.$toast.error(Mensagens.ERRO)
            }
          })
      },
      async inicializarCliente (item) {
        await this.inicializaClientes({
          idCota: item.idCota,
          cpfCnpj: item.cpfCnpj,
          idComissionado: this.$route.params.idComissionado,
          cota: item.cota,
          dataAtualizacao: item.dataAtualizacao,
          email: item.email,
          grupo: item.grupo,
          telefone: item.telefone,
          status: item.status,
          nomeStatus: item.nomeStatus,
          nome: item.nome,
          idDocumento: item.idDocumento,
          idTipoDocumento: item.idTipoDocumento,
          idEmpresa: item.idEmpresa,
          idUnidade: session.get('unidade').idUnidade,
          documentosCliente: []
        })
      },
      maskPhone (v) {
        var r = v.replace(/\D/g, '')
        r = r.replace(/^0/, '')
        if (r.length > 10) {
          r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
        } else if (r.length > 5) {
          r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
        } else if (r.length > 2) {
          r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
        } else {
          r = r.replace(/^(\d*)/, '($1')
        }
        return r
      },
      exibirOcultar () {
        this.exibirDetalhes = !this.exibirDetalhes
      }
    }
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap');
.tabelaLegenda {
  border-collapse:collapse;
  width: 333px;
  /* margin-top: -35px; */
}

.imglegenda {
  height: 11px;
  padding-right: 5px;
}

.hint-tabela-legenda {
  color: #9EA1A4;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}
.texto-sucesso {
  color: rgba(63, 212, 123, 1);
}
.texto-informativo {
  color: #90A5EC;
}
.texto-pendencia {
  color: #9349F4;
}
.texto-atencao {
  color: #EE4784;
}
.texto-legenda {
  font-family: Inter;
  border-bottom: none;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.CotaAndamento
{
  color: #6800F1 !important;
  font-weight: bold;
  border: 2px solid #6800F1;
  text-align: center;
}
.CotaReprovada
{
  color: #B80043 !important;
  font-weight: bold;
  border: 2px solid #B80043;
  text-align: center;
}
.CotaAprovada
{
  color: #008836 !important;
  font-weight: bold;
  border: 2px solid #008836;
  text-align: center;
}
.breadcrumb {
  margin-left: -5px;
}
.CardInformacoesCliente {
  margin-left: -7px;
}
.CardAtualizacoesCliente {
  margin-left: -7px;
}
.label-jornada-cota {
  margin-top: 12px;
  margin-bottom: -10px;
  margin-left: 0px;
  font-family: Inter;
  font-size: 26px;
  font-weight: 800;
  line-height: 33.8px;
  text-align: left;
  color: #191E26;
}
.exibir-ocultar {
  margin-top: 20px;
  margin-left: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary);
}

@media screen and (max-width: 960px){
  .tabelaLegenda {
    margin-top: 0;
    margin-left: 5px;
    padding-left: 5px;
    min-width: 103%;
  }
  .breadcrumb {
    margin-left: -23px;
  }
  .CardInformacoesCliente {
    margin-left: -12px;
  }
  .cardClientes {
    margin-left: -20px;
    margin-right: -20px;
  }
  .breadcrumbSkeleton {
    margin-left: 15px;
  }
  .exibir-ocultar-mobile {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: right;
    color: var(--primary);
    margin-right: -10px;
    margin-bottom: 5px;
  }
  .label-jornada-cota {
    margin-left: 0px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    text-align: left;
    color: #191E26;
  }
}

@media screen and (min-width: 700px) and (max-width: 960px){
    .tabelaLegenda {
      margin-top: -5px;
      width: 50%;
    }
    .linhaLegenda{
      width: 50%;
    }
}

</style>
