import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import store from './store'
import mobileMixin from './mixins/mobileMixin' // Atualize o caminho conforme necessário
import { VueMaskDirective } from 'v-mask'

import '@/mixins'
import '@/plugins/toast'
import '@/plugins/http'

import '@/assets/application.scss'
import '@/assets/icons/portal-corretora/style.css'

import '@/directives/input-filter'

Vue.config.productionTip = false
Vue.mixin(mobileMixin)

Vue.directive('mask', VueMaskDirective)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
