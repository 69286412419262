<template>
  <v-container>
    <h1 class="titulo">Pesquisar</h1>
    <v-form
        v-model="valido"
        @submit.prevent="buscar"
    >
      <v-row>
        <v-col
            cols="12"
            md="4"
            lg="3"
        >
          <TextField
              v-model="filtro"
              :rules=[$rules.required,$rules.maiorQueCinco]
          />
        </v-col>
        <v-col
            cols="12"
            md="2"
            lg="2"
        >
          <Button
              small
              block
              type="submit"
              :disabled="!valido || $store.state.carregandoSilenciosamente"
          >
            Buscar
          </Button>
        </v-col>
      </v-row>
    </v-form>
    <DataTable
        :key="chaveTabela"
        class="mt-5"
        item-key="id"
        ref="datatable"
        :params="params"
        :filter="filters"
        :headers="headers"
        :show-pagination="false"
        :itens-externos="usuarios"
        :loading="$store.state.carregandoSilenciosamente"
    >
      <template v-slot:[`item.nome`]="item">
        <td>{{ item.nome }}</td>
      </template>
      <template v-slot:[`item.login`]="item">
        <td>{{ formatarCpf(item.login) }}</td>
      </template>
      <template v-slot:[`item.cpf`]="item">
        <td>{{ item.cpf }}</td>
      </template>
      <template v-slot:[`item.tipo`]="item">
        <td>{{ item.tipo }}</td>
        <td>
          <a
              small
              class="primaria--text"
              v-if="item.tipo == 'parceiro'"
              @click="acessarParceria(item.login)"
          >
            <v-icon color="primaria">pc-edificio</v-icon>
            Parceria
          </a>
          <span v-if="item.tipo !== 'parceiro' && item.tipo !== 'sublogin'">Não permitido para o tipo de usuário</span>
        </td>
        <td>
          <a
              class="primaria--text"
              @click="acessarUsuario(item.login, item.tipo)"
              v-if="item.tipo == 'parceiro' || item.tipo == 'sublogin'"
          >
            <v-icon color="primaria">pc-perfil</v-icon>
            Usuário
          </a>
        </td>
      </template>
    </DataTable>
  </v-container>
</template>

<script>

  import { pesquisar } from '@/service/usuario'
  import { consultarLoginParceria } from '@/service/parceria'
  import { cpf } from '@/plugins/formatters'
  import { consultarIdSublogin } from '@/service/sublogin'

  export default {
    name: 'Pesquisar',
    data: () => ({
      chaveTabela: 0,
      valido: false,
      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Login', value: 'login' },
        { text: 'CPF/CNPJ', value: 'cpf' },
        { text: 'Tipo', value: 'tipo' },
        { sortable: false },
        { sortable: false }
      ],
      pagination: {
        sortBy: [ 'id' ], sortDesc: [ true ]
      },
      filters: {},
      filtro: '',
      usuarios: [],
      formatarCpf: cpf
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)
    },
    methods: {
      async buscar () {
        try {
          this.usuarios = await pesquisar(this.filtro, false)
          this.chaveTabela++
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async acessarParceria (login) {
        try {
          const { codigoCCFO: cnpj } = await consultarLoginParceria(login)

          this.$router.push({
            name: 'CadastroParceria',
            params: {
              cnpj: cnpj.replace(/(\\|-|,|\.|\/)/g, '')
            }
          })
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async acessarUsuario (login, tipo) {
        try {
          if (tipo === 'parceiro') {
            const { codigoCCFO: cnpj } = await consultarLoginParceria(login)

            this.$router.push({
              name: 'Comissionado',
              params: {
                cnpj: cnpj.replace(/(\\|-|,|\.|\/)/g, ''),
                login
              }
            })
          } else {
            const { idSublogin, idComissionado } = await consultarIdSublogin(login)

            this.$router.push({
              name: 'Sublogin',
              params: {
                matricula: idComissionado
              },
              query: {
                id: idSublogin
              }
            })
          }
        } catch (erro) {
          this.$toast.error(erro)
        }
      }
    }
  }

</script>

<style scoped>

  .filtro-container {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }

  .capitalize {
    text-transform: capitalize;
  }

</style>
