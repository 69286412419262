<template>
  <v-container>
    <h1
        class="mr-5 titulo"
        v-if="$route.params.login"
    >
      Usuário Terceiro: {{ comissionado.nome }}
    </h1>
    <h1
        v-else
        class="mr-5 titulo"
    >
      Cadastro de Terceiro Usuário
    </h1>
    <span class="subtitulo">
      Fornecedor:
      <b>
        CFO {{ comissionado.cfo }}
      </b>
    </span>
    <v-form
        ref="form"
        :key="chaveFormulario"
    >
      <v-card class="my-5 pb-1">
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
            >
              <v-flex
                  row
                  align-end
              >
                <v-spacer />
                <Checkbox
                    class="mx-md-3"
                    label="Enviar para Newcon"
                    v-model="formulario.enviarNewcon"
                    disabled
                />
                <Checkbox
                    class="mx-md-3"
                    label="Ativo"
                    v-model="comissionado.ativo"
                />
              </v-flex>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  maxlength="20"
                  label="Login*"
                  @blur="validarExistenciaLogin"
                  @keyup="validaCaractere($event)"
                  v-model="comissionado.login"
                  :rules="validacaoLogin"
                  :loading="carregandoLogin && !$route.params.login"
                  :disabled="this.$route.params.login ? true : false || carregandoLogin"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  :disabled="this.$route.params.login ? true : false"
                  label="CPF/CNPJ*"
                  v-model="comissionado.cpfCnpj"
                  :rules="[$rules.required,$rules.documento]"
                  v-mask="['###.###.###-##','##.###.###/####-##']"
              />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <TextField
                  label="Nome*"
                  v-model="comissionado.nome"
                  :rules=[$rules.required]
                  maxlength="50"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  label="Telefone*"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  :rules=[$rules.required]
                  v-model="comissionado.telefone"
              />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <TextField
                  maxlength="70"
                  label="E-mail alternativo*"
                  :rules=[$rules.required,$rules.email]
                  v-model="comissionado.email"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  v-mask="'##/##/####'"
                  :rules=[$rules.required]
                  label="Data de nascimento*"
                  v-model="comissionado.dataNascimento"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <SelectField
                  label="Empresa*"
                  :items="$session.get('configuracoes').coligadas"
                  :rules=[$rules.required]
                  v-model="comissionado.coligada"
                  item-value="id"
                  item-text="nome"
                  disabled
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
            </v-col>
            <v-col
                md="3"
                cols="12"
            >
            </v-col>
            <v-col
                cols="6"
                class="pt-0"
                v-if="!$vuetify.breakpoint.mobile"
            />
            <v-col
                md="3"
                cols="12"
                class="pt-0"
            >
            </v-col>
            <v-col
                md="3"
                cols="12"
                class="pt-0"
            >
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>Responsável pelo terceiro</v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <TextField
                      label="Nome*"
                      maxlength="50"
                      :rules=[$rules.required]
                      v-model="comissionado.nomeResponsavelRevenda"
                      disabled
                  />
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <TextField
                      maxlength="70"
                      label="E-mail*"
                      :rules=[$rules.required,$rules.email]
                      v-model="comissionado.emailResponsavelRevenda"
                      v-on:change="consultarDadosVend(comissionado.emailResponsavelRevenda)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-row class="justify-sm-space-between">
            <v-col
                class="d-none d-sm-block pl-5 mr-auto"
                cols="12"
                md="2"
            >
              <Button
                  small
                  block
                  icon
                  @click="navegarTerceiro"
                  id="botaoVoltar"
              >
                <v-icon
                    left
                    color="iconcolor"
                >
                  pc-seta-esquerda
                </v-icon>
                <span class="iconcolor--text">VOLTAR</span>
              </Button>
            </v-col>
            <v-col
                cols="12"
                md="3"
                class="text-right"
            >
            </v-col>
            <v-col
                cols="12"
                md="2"
            >
              <Button
                  v-if="$route.params.login"
                  icone="pc-seta-esquerda"
                  color="secundaria"
                  small
                  block
                  outlined
                  @click="resetar"
                  :disabled="comissionadoFixo.ssoExterno"
              >
                RESETAR SENHA
              </Button>
            </v-col>
            <v-col
                cols="12"
                md="2"
            >
              <Button
                  small
                  block
                  @click="alterar"
                  color="secundaria"
                  icone="pc-seta-esquerda"
              >
                SALVAR
              </Button>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog
        persistent
        v-model="exibirDialogReset"
    >
      <v-card class="pb-1">
        <v-card-title>
          <v-btn
              icon
              class="ml-auto"
              @click="exibirDialogReset = false"
          >
            <v-icon
                color="primaria"
                size="10"
            >
              pc-fechar
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>Senha redefinida com sucesso. Nova senha: {{ novaSenha }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <Button
              small
              @click="exibirDialogReset = false"
          >
            OK
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import { validarLogin } from '@/service/usuario'
  import { consultarTerceiroUsuario, cadastrarTerceiroUsuario, alterarTerceiroUsuario, consultarDadosVendedor } from '@/service/terceirousuario'
  import { resetarSenha } from '@/service/senha'

  export default {
    data: () => ({
      carregandoLogin: false,
      exibirDialogReset: false,
      novaSenha: undefined,
      tabela: {
        cabecalho: [
          { text: 'ID', value: 'id' },
          { text: 'Nome', value: 'nome' },
          { text: 'Login', value: 'login' },
          { text: 'Status', value: 'status' },
          { value: 'acao', width: 10, sortable: false }
        ]
      },
      loginInvalido: false,
      terceiro: {
        emailResponsavelAdministrativo: undefined,
        emailGenrenteComercial: undefined
      },
      formulario: {
        enviarNewcon: false
      },
      comissionado: {
        usuarioSistema: false,
        idSso: undefined,
        ssoExterno: false,
        login: undefined,
        telefone: undefined,
        nomeResponsavelRevenda: undefined,
        id: undefined,
        emailResponsavelRevenda: undefined,
        cpfCnpj: undefined,
        cfo: undefined,
        nome: undefined,
        ativo: false,
        comissionadoPrincipal: false,
        permiteSublogin: false,
        quantidadeSublogins: 0,
        codigoComissionado: undefined,
        emailAlternativo: undefined,
        dataNascimento: undefined,
        tipoEmpresa: undefined,
        emailResponsavelAdministrativo: undefined
      },
      comissionadoFixo: {
        ssoExterno: false
      },
      emailComissionado: undefined,
      sublogins: [],
      subloginsAtivos: undefined,
      chaveFormulario: 0,
      maiorQueZero: v => Number(v) > 0 || 'O número precisa ser maior que zero'
    }),
    computed: {
      validacaoLogin () {
        return [
          v => !!v || 'Campo obrigatório!',
          v => /^\w+\.\w+$/.test(v) || 'Formato inválido!',
          v => !this.loginInvalido || 'Login já utilizado. Por favor, informe outro.'
        ]
      }
    },
    mounted () {
      if (this.$route.params.login) {
        this.consultarTerceiroUsuario()
      } else {
        this.comissionado.ativo = true
        this.formulario.enviarNewcon = true
        this.comissionado.cfo = this.$route.query.cfo
        this.terceiro.emailResponsavelAdministrativo = this.$route.query.emailResponsavelAdministrativo
        this.terceiro.emailGenrenteComercial = this.$route.query.emailGenrenteComercial
        this.comissionado.coligada = this.$route.query.coligada
        this.comissionado.empresa = this.$route.query.coligada
        this.comissionado.emailResponsavelRevenda = this.$route.query.emailResponsavelAdministrativo
        this.consultarDadosVend(this.$route.query.emailResponsavelAdministrativo)
      }
    },
    methods: {
      validaCaractere (value) {
        let login = this.comissionado.login
        if (value.key === '.') {
          if (login.indexOf('.') === 0) {
            login = login.substring(1)
          }
          if (/\w+\.\w+/.test(login) && login.charAt(login.length - 1) === '.') {
            login = login.substring(0, login.length - 1)
          }
          if (/\.{2}/.test(login)) {
            login = login.substring(0, login.length - 1)
          }
        }
        if (/^\d/.test(login)) {
          login = login.replace(/\d/g, '')
        }
        login = login.toLowerCase().replace(/[^a-z0-9.]/g, '')
        this.comissionado.login = login
      },
      navegarTerceiro () {
        this.$router.go(-1)
      },
      async consultarTerceiroUsuario () {
        try {
          this.comissionado = await consultarTerceiroUsuario(this.$route.params.login)
          this.emailComissionado = this.comissionado.email
          this.comissionadoFixo.ssoExterno = this.comissionado.ssoExterno
        } catch (erro) {
          this.$toast.error(erro)
        }

        this.chaveFormulario++
      },
      async alterar () {
        if (this.$refs.form.validate()) {
          try {
            if (this.$route.params.login) {
              const { mensagem } = await alterarTerceiroUsuario(this.$route.params.login, {
                codigoComissionado: this.comissionado.codigo,
                emailAlternativo: this.comissionado.email,
                emailResponsavelRevenda: this.comissionado.emailResponsavelRevenda,
                comissionadoPrincipal: this.comissionado.comissionadoPrincipal,
                nomeResponsavelRevenda: this.comissionado.nomeResponsavelRevenda,
                nomeUsuario: this.comissionado.nome,
                permiteSublogin: this.comissionado.permiteSublogin,
                quantidadeSublogins: this.comissionado.quantidadeSublogins,
                telefone: this.comissionado.telefone.replace(/(\(|\)|-|\s)/g, ''),
                coligada: this.comissionado.coligada,
                dataNascimento: this.comissionado.dataNascimento,
                enviarNewcon: this.formulario.enviarNewcon,
                codigoInscricaoNacional: this.comissionado.cpfCnpj,
                login: this.comissionado.login,
                usuarioSistema: this.comissionado.usuarioSistema,
                idSso: this.comissionado.idSso,
                ssoExterno: this.comissionado.ssoExterno
              })

              this.$toast.success(mensagem)
              this.consultarTerceiroUsuario()
            } else {
              const { mensagem } = await cadastrarTerceiroUsuario({
                cfo: this.comissionado.cfo,
                emailResponsavelAdministrativo: this.terceiro.emailResponsavelAdministrativo,
                emailGerenteComercial: this.terceiro.emailGenrenteComercial,
                codigoComissionado: this.comissionado.codigo,
                cpfCnpj: this.comissionado.cpfCnpj,
                dataNascimento: this.comissionado.dataNascimento,
                emailAlternativo: this.comissionado.email,
                nomeResponsavelRevenda: this.comissionado.nomeResponsavelRevenda,
                emailResponsavelRevenda: this.comissionado.emailResponsavelRevenda,
                comissionadoPrincipal: this.comissionado.comissionadoPrincipal,
                login: this.comissionado.login,
                permiteSublogin: this.comissionado.permiteSublogin,
                quantidadeSublogins: this.comissionado.quantidadeSublogins,
                telefone: this.comissionado.telefone,
                enviarNewcon: this.formulario.enviarNewcon,
                nomeUsuario: this.comissionado.nome,
                codigoInscricaoNacional: this.comissionado.cpfCnpj,
                usuarioSistema: this.comissionado.usuarioSistema,
                idSso: this.comissionado.idSso,
                ssoExterno: this.comissionado.ssoExterno,
                coligada: this.comissionado.coligada
              })

              this.$router.push({
                name: 'CadastroTerceiro',
                params: {
                  cnpj: this.$route.params.cnpj
                }
              })

              this.$toast.success(mensagem)
            }
          } catch (erro) {
            this.$toast.error(erro)
          }
        }
      },
      async consultarDadosVend (email) {
        this.comissionado.nomeResponsavelRevenda = ''
        let loginVendedor = String(email)
        let usuarioSplited = loginVendedor.split('@', 1)[0]

        let data = await consultarDadosVendedor(usuarioSplited)
        this.comissionado.nomeResponsavelRevenda = data.nome_revenda
      },
      corStatus (status) {
        switch (status) {
          case 'INATIVO':
            return '#B9BDC4'

          case 'ATIVO':
            return '#008836'

          case 'BLOQUEADO':
            return '#CE181E'

          case 'AGUARDANDO_LIBERACAO':
            return '#000000'

          case 'SUBSTITUIDO':
            return '#222222'

          case 'FERIAS':
            return '#38b8f3'

          case 'AFASTADO':
            return '#ce5b18'

          case 'DESLIGADO':
            return '#B9BDC4'
        }
      },
      async validarExistenciaLogin () {
        if (this.comissionado.login.length > 0) {
          this.carregandoLogin = true

          try {
            const { existe } = await validarLogin(this.comissionado.login)
            this.loginInvalido = existe
          } catch (erro) {
            this.$toast.erro(erro)
          }
          this.carregandoLogin = false
        }
      },
      async resetar () {
        try {
          const { senhaNova } = await resetarSenha(this.comissionado.login, this.emailComissionado)
          this.novaSenha = senhaNova
          this.$toast.success('Senha redefinida com sucesso!')
          this.exibirDialogReset = true
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async carregarUsuarioVendedor () {
        try {
          const { data } = await consultarDadosVendedor(this.comissionado.login)
          return data
        } catch (erro) {
          this.$toast.error(erro)
        }
      }
    }
  }

</script>
