import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import storage from './storage'
import { pick } from 'lodash'
import { persistentes, nao_persistentes } from './data'
import login from '@/store/modules/login'
import meusClientes from '@/store/modules/meus-clientes'
import jornadaNotificacoes from '@/store/modules/jornada-notificacoes'

Vue.use(Vuex)

const key = (process.env.VUE_APP_SECURED === 'true') ? '0fad0a812088c8ba8d877cbefc90d8ff' : 'area-restrita'

const vuexLocal = new VuexPersistence({
  key,
  storage,
  reducer: (state) => ({
    ...pick(state, Object.keys(persistentes)),
    meusClientes: state.meusClientes,
    login: state.login,
    jornadaNotificacoes: state.jornadaNotificacoes
  })
})

function defaultState () {
  return {
    ...persistentes,
    ...nao_persistentes
  }
}

const state = defaultState()

let loading = 0
let alertStack = []

const mutations = {
  destroy () {
    store.replaceState({ ...persistentes, ...nao_persistentes, login: { ...login.state } })
  },
  setState (state, data) {
    Object.assign(state, data)
  },
  loadingStart (state) {
    state.loading = !!++loading
  },
  loadingDone (state) {
    if (loading > 0) {
      state.loading = !!--loading
    }
  },
  alertShow (state, options) {
    if (state.alert.visible) {
      alertStack.push(options)
    } else {
      state.alert = { options, visible: true }
    }
  },
  setDrawer (state, visible) {
    state.drawer = visible
  },
  setCarregandoSilenciosamente (state, valor) {
    state.carregandoSilenciosamente = valor
  },
  setRotaAtual (state, rota) {
    state.rotaAtual = rota
  },
  toggleExibirDialogVisao (state) {
    state.exibirDialogVisao = !state.exibirDialogVisao
  }
}

const actions = {}

const plugins = [ vuexLocal.plugin ]

const getters = {
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins,
  modules: {
    meusClientes,
    login,
    jornadaNotificacoes
  }
})

export default store
