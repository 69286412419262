<template>
  <v-dialog
      persistent
      v-model="exibir"
  >
    <v-card class="pb-2">
      <v-card-title>
        <span>Extrair como</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item-group
              multiple
          >
            <v-list-item>
              <template>
                <v-list-item-content>
                  <v-list-item-title>Arquivo .xslx</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                      v-model="opcao"
                      value="xslx"
                  ></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-list-item>
              <template>
                <v-list-item-content>
                  <v-list-item-title>Arquivo .pdf</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                      v-model="opcao"
                      value="pdf"
                  ></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            @click="extrair"
        >
          Confirmar
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      opcao: []
    }),
    watch: {
      exibir () {
        this.nome = undefined
        this.cpfCnpj = undefined
        this.login = undefined
      }
    },
    methods: {
      fechar () {
        this.$emit('exibir', false)
      },
      extrair () {
        // TODO: Aplicar extração
        this.$emit('extrair', this.opcao)
        this.opcao = []
        this.fechar()
      }
    }
  }
</script>
