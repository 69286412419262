<template>
  <v-dialog
      persistent
      v-model="exibir"
      max-width="343"
  >
    <v-card :height="280" class="d-flex flex-column justify-center align-center pb-0">
      <v-card-text class="d-flex flex-column align-center">
        <div class="d-flex justify-center">
          <v-icon class="icon mb-2 mt-5" :style="getIconColorStyles" color="white">{{ getPhaseIcon }}</v-icon>
        </div>
        <div :style="getColorAssert" class="tituloModal">Atenção!</div>
      </v-card-text>
      <v-card-text class="texto">Verifique todos os itens antes de enviar, ainda existem documentos duplicados.</v-card-text>
      <v-card-text>
        <v-btn
            class="btn-fechar"
            @click="fechar"
            width="300px"
            block>
          Cancelar
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { getThemeByStep } from '@/plugins/color'
  import { mapGetters } from 'vuex'
  export default {
    components: {},
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      loading: false,
      enviado: false
    }),
    computed: {
      ...mapGetters({
        fase: 'getFaseAnaliseMeusClientes'
      }),
      getPhaseColor () {
        return getThemeByStep(this.fase.evento).primaria
      },
      getPhaseIcon () {
        return getThemeByStep(this.fase.evento).icone
      },
      getIconColorStyles () {
        return `
          background-color: ${getThemeByStep(this.fase.evento).terciaria};
          border-color: ${getThemeByStep(this.fase.evento).secundaria}
        `
      },
      getColor () {
        return `
          color: ${getThemeByStep(this.fase.evento).primaria};
        `
      }
    },
    methods: {
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }
</script>

<style scoped>
  .tituloModal {
    /* Título/H8 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .icon {
    border: 8px solid;
    border-radius: 100%;
    height: 50px;
    width: 50px;
  }
  .texto {
    color: #191E26 !important;

    /* Text sm / Regular */
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important; /* 142.857% */
    text-align: center !important;
  }
  button.btn-fechar
  {
    font-family: Inter;
    letter-spacing: 0!important;
    text-transform: none;
    text-align: center;
    font-style: normal;
    border-radius: 8px;
    line-height: 21px;
    font-weight: 600;
    font-size: 14px;
    box-shadow: none !important;
    height: 34px!important;
    border: 1px solid #CFD0D1 !important;
    color: #191E26 !important;
    background-color: white !important;
    width: 100px;
  }

  button.btn-fechar:active
  {
    border: 1px solid var(--primariaDarken) !important;
    color: var(--primariaDarken) !important;
    background-color: #FFFFFF!important;
  }

  button.btn-fechar::before
  {
    background-color: transparent !important;
  }

  @media screen and (min-width: 960px){
    button.btn-fechar:hover
    {
      border: 1px solid var(--primariaDarken)!important;
      color: var(--primariaDarken)!important;
      background-color: #FFFFFF!important;
    }
  }

  @media screen and (min-width: 960px){
    .icon {
      border: 8px solid;
      border-radius: 100%;
      height: 45px;
      width: 45px;
    }
  }
</style>
