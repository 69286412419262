<template>
  <svg
      class="spinner"
      :viewBox="`0 0 47 47`"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        fill="#ABB5BE"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.5 7.42105C21.3885 7.42105 19.2977 7.83694 17.3469 8.64499C15.3961 9.45303 13.6235 10.6374 12.1305 12.1305C10.6374 13.6235 9.45304 15.3961 8.64499 17.3468C7.83695 19.2976 7.42105 21.3885 7.42105 23.5C7.42105 25.6115 7.83695 27.7024 8.64499 29.6531C9.45303 31.6039 10.6374 33.3765 12.1305 34.8695C13.6235 36.3626 15.3961 37.547 17.3468 38.355C19.2976 39.1631 21.3885 39.5789 23.5 39.5789C25.6115 39.5789 27.7024 39.1631 29.6531 38.355C31.6039 37.547 33.3765 36.3626 34.8695 34.8695C36.3626 33.3765 37.547 31.6039 38.355 29.6532C39.1631 27.7024 39.5789 25.6115 39.5789 23.5C39.5789 21.3885 39.1631 19.2976 38.355 17.3469C37.547 15.3961 36.3626 13.6235 34.8695 12.1305C33.3765 10.6374 31.6039 9.45303 29.6531 8.64499C27.7024 7.83695 25.6115 7.42105 23.5 7.42105ZM14.507 1.78883C17.3581 0.607841 20.414 -2.0642e-06 23.5 0C26.5861 0 29.6419 0.607846 32.4931 1.78883C35.3442 2.96982 37.9348 4.70082 40.117 6.88299C42.2992 9.06517 44.0302 11.6558 45.2112 14.5069C46.3922 17.3581 47 20.4139 47 23.5C47 26.5861 46.3922 29.6419 45.2112 32.4931C44.0302 35.3442 42.2992 37.9348 40.117 40.117C37.9348 42.2992 35.3442 44.0302 32.4931 45.2112C29.6419 46.3922 26.5861 47 23.5 47C20.4139 47 17.3581 46.3922 14.5069 45.2112C11.6558 44.0302 9.06516 42.2992 6.88298 40.117C4.70081 37.9348 2.96981 35.3442 1.78883 32.4931C0.607843 29.6419 -1.47443e-06 26.5861 0 23.5C1.47443e-06 20.4139 0.607849 17.3581 1.78884 14.5069C2.96982 11.6558 4.70082 9.06516 6.883 6.88298C9.06518 4.70081 11.6558 2.96981 14.507 1.78883Z"
    />
    <path
        :fill="cor || $vuetify.theme.themes.light.primaria"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4441 3.96046C14.3087 1.37825 18.8522 0 23.5 0C25.5493 0 27.2105 1.66126 27.2105 3.71053C27.2105 5.75979 25.5493 7.42105 23.5 7.42105C20.3199 7.42105 17.2112 8.36407 14.567 10.1308C11.9229 11.8976 9.86198 14.4088 8.645 17.3469C7.42802 20.2849 7.10961 23.5178 7.73002 26.6368C8.35043 29.7559 9.8818 32.6208 12.1305 34.8695C13.5795 36.3186 13.5795 38.668 12.1305 40.117C10.6814 41.5661 8.33205 41.5661 6.883 40.117C3.59647 36.8305 1.35831 32.6432 0.451557 28.0846C-0.455196 23.5261 0.0101824 18.801 1.78884 14.5069C3.5675 10.2129 6.57956 6.54268 10.4441 3.96046Z"
    />
  </svg>
</template>

<script>

  export default {
    props: {
      tamanho: {
        type: Number,
        default: 47
      },
      cor: {
        type: String
      }
    }
  }

</script>
