<template>
  <v-dialog
      :scrollable="true"
      width="667"
      content-class="app-content"
      v-model="exibir"
      persistent
  >
    <v-card>
      <v-card-title>
        <span class="titulo-dialog">Cadastro de documentos</span>
        <v-btn
            id="ButtonDialogFecharNovoDocumento"
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon color="primaria" size="10">pc-fechar</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-form ref="formNovoDocumento" :key="dialogKey">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <TextField
                  id="TextFieldIdDocumento"
                  label="ID do documento"
                  v-model="documento.idDocumento"
                  v-uppercase
                  disabled
              />
            </v-col>
            <v-col cols="12" md="9">
              <TextField
                  id="TextFieldTituloDocumento"
                  label="Título do documento*"
                  v-model="documento.tituloDocumento"
                  v-uppercase
                  maxlength="100"
                  :rules="[$rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <TextField
                  id="TextFieldVersaoArquivo"
                  label="Versão do arquivo"
                  v-model="documento.versaoArquivo"
                  v-uppercase
                  validate-on-blur
                  disabled
                  :rules="[this.$rules.required]"
              />
            </v-col>
            <v-col cols="12" md="9">
              <FileInput
                  id="FileInputDocumento"
                  label="Arquivo*"
                  v-model="documento.rotulo"
                  :accept="['application/pdf']"
                  :limpar="exibir"
                  :msg-arquivo-grande="msg.arquivoGrande"
                  :msg-extensao-invalida="msg.extensaoInvalida"
                  @arquivo="anexarArquivo"
              />
            </v-col>
            <v-col class="select-container" cols="12" md="9">
              <select class="select" v-model="selected" @change="change()">
                <option :value='0'>SELECIONE UMA UNIDADE</option>
                <option v-for="option in unidades" :key="option.idUnidade" :value="option.idUnidade">
                  {{ option.nomeUnidade }}
                </option>
              </select>
              <img v-if="selected !== 0" :src="urlLogo" width="120" height="45">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <Button
              id="ButtonCancelarNovoDocumento"
              small
              outlined
              class="ml-auto"
              @click="fechar"
          >
            CANCELAR
          </Button>
          <Button
              id="ButtonConfirmarNovoDocumento"
              small
              @click="cadastrarNovoDocumento()"
          >
            CONFIRMAR
          </Button>
        </v-card-actions>
      </v-form>
    </v-card>
    <PopUpConfirmacao
        :exibir="exibirConfirmacao"
        titulo="Atenção"
        :mensagem="msg.confirmarCadastro"
        @confirmou="cadastrar"
        @cancelou="exibirConfirmacao = false" />
  </v-dialog>
</template>

  <script>
  import { salvarDocumentoPolitica } from '@/service/politica'
  import PopUpConfirmacao from '@/components/PopUpConfirmacao'

  export default {
    components: {
      PopUpConfirmacao
    },
    props: {
      exibir: {
        type: Boolean,
        default: false
      },
      documentos: Number,
      unidades: Array
    },
    data: () => ({
      dialogKey: 0,
      documento: {
        idDocumento: '-',
        tituloDocumento: '',
        versaoArquivo: '1',
        rotulo: []
      },
      selected: 0,
      urlLogo: '',
      exibirConfirmacao: false,
      maximoCem: [
        v => !v || v.length < 100 || 'Campo obrigatório de, no máximo, 100 caracteres.'
      ],
      msg: {
        arquivoGrande: 'Arquivo inválido. Deve ser enviado um arquivo PDF de até 10MB',
        extensaoInvalida: 'Arquivo inválido. Deve ser enviado um arquivo PDF de até 10MB',
        confirmarCadastro: 'Após esta ação haverá mais de um documento em vigor e durante o login será solicitado um aceite para cada um dos documentos ativos. Está certo que deseja continuar?'
      }
    }),
    watch: {
      exibir () {
        this.exibirConfirmacao = false
        this.documento = {
          idDocumento: '-',
          tituloDocumento: '',
          versaoArquivo: '1',
          unidade: 0,
          rotulo: []
        }
        this.dialogKey++
      }
    },

    methods: {
      anexarArquivo (file) {
        this.documento.rotulo = file
      },
      async cadastrarNovoDocumento () {
        if (this.$refs.formNovoDocumento.validate() && this.documento.rotulo) {
          if (this.documentos >= 1) {
            this.exibirConfirmacao = true
          } else {
            await this.cadastrar()
          }
        } else {
          this.$toast.error('Preencha os campos obrigatórios!')
        }
      },
      async cadastrar () {
        const formData = new FormData()
        formData.append('tituloDocumento', this.documento.tituloDocumento)
        formData.append('arquivo', this.documento.rotulo)
        formData.append('idUnidade', this.documento.unidade)
        await salvarDocumentoPolitica(formData, false)
        this.fechar()
      },
      resetarCampos () {
        this.documento = {
          idDocumento: '-',
          tituloDocumento: '',
          versaoArquivo: '1',
          unidade: 0,
          rotulo: []
        }
        this.selected = 0
        this.urlLogo = ''
      },
      change () {
        let prop = this.unidades.find(i => i.idUnidade === this.selected)
        this.documento.unidade = prop.idUnidade
        this.urlLogo = prop.urlLogotipo
      },
      fechar () {
        // this.$refs.formNovoDocumento.reset() // resetar a validação
        this.resetarCampos()
        this.$emit('close')
      }
    }
  }
  </script>

  <style scoped>
    .subtitulo-dialog {
      font-size: 14px;
      color: var(--black);
      margin: 20px 0;
    }
    .select {
    text-align: center;
    height: 45px;
    border-radius: 10px;
    background-color: #CE181E;
    color: #FFFFFF;
  }
  .select-container{
      font-size: medium;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  </style>
