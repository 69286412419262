<template>
  <v-container fluid>
    <h1 class="titulo">Informe de rendimentos</h1>
    <v-row v-if="informes.length > 0">
      <v-col
          cols="6" md="3"
          v-for="informe in informes"
          :key="informe.url"
      >
        <CardRendimento :informe=informe />
      </v-col>
    </v-row>
    <v-row v-else>
      <span class="ml-5">Não há dados disponíveis</span>
    </v-row>
  </v-container>
</template>

<script>
  import { buscarInformesRendimento } from '@/service/informe-rendimento'

  export default {
    name: 'InformeRendimento',
    data: () => ({
      informes: []
    }),
    async mounted () {
      try {
        this.$store.commit('setRotaAtual', this.$route.name)
        let visaoAdministrativa = this.$session.get('visaoAdministrativa')
        this.informes = visaoAdministrativa ? await buscarInformesRendimento(visaoAdministrativa.login) : await buscarInformesRendimento()
        this.informes = this.informes.sort((a, b) => b.ano - a.ano)
      } catch (erro) {
        this.$toast.error(erro)
      }
    }
  }

</script>
