import http from '@/plugins/http'
import session from '@/plugins/session'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function buscarNovasNotasBaas (login = '', unidade = '') {
  try {
    let url = `/buscar-notas-baas`
    if (login) {
      url += `/unidade/${unidade}/${login}`
    }

    const { data } = await http.get(url)
    return data
  } catch (erro) {
    throw erro.response.data.mensagem
  }
}

export async function buscarNotasPorStatus (status, size = 10, silent = false, login = '') {
  try {
    // O status pode ser "Pendente", "Recebida", "Enviada", "Conferida", "Recusada" ou "Enviada"
    let url = `/listar-notas-status/${status}`

    if (login) {
      url += `/${login}`
    }

    const { data } = await http.get(url, {
      options: { silent },
      headers: {
        size
      }
    })
    return data.content
  } catch (erro) {
    throw erro.response.data.mensagem
  }
}

export async function enviarNotaParaOAzure (arquivoRequest) {
  try {
    const { data } = await http.post('/envio-nota-fiscal', arquivoRequest)
    return data
  } catch (error) {
    throw error.response.data.mensagem
  }
}

export async function aceitarNotaFiscal (idNota, numeroNota) {
  try {
    let form = new FormData()
    form.append('numeroNota', numeroNota)
    const { data } = await http.post(`/aceitar-nota-fiscal/${idNota}`, form)
    return data
  } catch (error) {
    throw error.response.data.mensagem
  }
}

export async function recusarNotaFiscal (idNota, motivoRecusa) {
  try {
    let form = new FormData()
    form.append('motivoRecusa', motivoRecusa)
    const unidade = session.get('unidade')
    const { data } = await http.post(`/recusar-nota-fiscal/${idNota}?idUnidade=${unidade.idUnidade}&urlLogin=${process.env.VUE_APP_AREA_RESTRITA_URL}/${unidade.idUnidade}/login`, form)
    return data
  } catch (error) {
    throw error.response.data.mensagem
  }
}

export async function enviarNotaConferida (idNota) {
  try {
    const { data } = await http.post(`/enviar-nota-conferida/${idNota}`)
    return data
  } catch (error) {
    throw error.response.data.mensagem
  }
}

export async function conferirMultiplasNotas (listaId) {
  try {
    const { data } = await http.post('/conferir-multiplas-notas/', listaId, {
      options: { silent: true }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function baixarMultiplasNotas (listaId) {
  try {
    const { data } = await http.post('/nota-fiscal/download', listaId, {
      options: { silent: true }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function buscarUrlDownload (idNota) {
  try {
    const { data } = await http.get(`/nota-fiscal/download/${idNota}`, {
      options: { silent: true }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function obterRelatorio (relatorio = {}) {
  try {
    const { data } = await http.post('/nota-fiscal/relatorio/processamento', relatorio)
    return data
  } catch (erro) {
    throw Error(erro)
  }
}

export async function buscarMultiplasUrls (listaId = []) {
  try {
    const { data } = await http.post('/nota-fiscal/download/multiplas', listaId, {
      options: { silent: true }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}
