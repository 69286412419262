import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import session from '@/plugins/session'
import { get } from 'lodash'

export async function solicitarMapa (idUnidade, idDataBase) {
  try {
    const unidade = session.get('unidade')
    const { data } = await http.get(`/mapa-comissao/solicitar?idUnidade=${idUnidade}&idDataBase=${idDataBase}&url=${process.env.VUE_APP_AREA_RESTRITA_URL}/${unidade.idUnidade}/login`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function listarDatasBase (idUnidade, ano, login) {
  try {
    if (ano) {
      const { data } = await http.get(`/listar-data-base/${idUnidade}?ano=${ano}&login=${login}`)
      return data
    } else {
      const { data } = await http.get(`/listar-data-base/${idUnidade}?login=${login}`)
      return data
    }
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function consulta (idDataBase) {
  const { data } = await http.get(`/consulta-data-base/${idDataBase}`)
  return data
}

export async function consultarQuantidadeRegitros (dataReferencia) {
  const { data } = await http.get(`/consulta-quantidade-referencias?data=${dataReferencia}`)
  return data
}

export async function cadastrar (datasBase) {
  const { data } = await http.post('/cadastrar-data-base', datasBase)
  return data
}

export async function editar (idDataBase, datasBase) {
  const { data } = await http.put(`/atualizar-data-base/${idDataBase}`, datasBase)
  return data
}

export async function excluir (idDatasBase) {
  try {
    await http.delete(`/excluir-data-base/${idDatasBase}`)
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function notificacoesMapa (idUnidade) {
  try {
    const { data } = await http.get(`/mapa-comissao/notificacao/${idUnidade}`)
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function alterarStatusNotificacao (id, status) {
  try {
    const { data } = await http.patch(`/mapa-comissao/status/${id}`, { status })
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}
