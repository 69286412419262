<template>
  <v-dialog
      :scrollable="true"
      width="466"
      content-class="app-content"
      v-model="dialog"
  >
    <v-card>
      <v-card-title>
        <span class="titulo-dialog">Motivo de recusa</span>
        <v-btn
            id="ButtonDialogFecharNovoDocumento"
            icon
            class="ml-auto"
            @click="close"
        >
          <v-icon size="10">pc-fechar</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <p class="texto-principal">{{ notaFiscal.comentario }}</p>
        <p class="texto-secundario">Registrado em {{ data }} &#9642; {{ hora }}</p>
        <p class="texto-secundario">Movimento ID: {{ notaFiscal.idMovimento }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn
            id="ButtonVoltar"
            class="ml-auto mb-3 mr-5"
            @click="close"
        >
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      notaFiscal: Object
    },
    data: () => ({
      dialog: false,
      data: '',
      hora: ''
    }),
    watch: {
      dialog (value) {
        if (value) {
          this.data = this.getDataHora()[0]
          this.hora = this.getDataHora()[1]
        }
      }
    },
    methods: {
      show () {
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.$emit('exibir', false)
      },
      getDataHora () {
        var dataHora = this.notaFiscal.dataRecusa.split('T')
        dataHora[1] = dataHora[1].substring(0, 8)
        return dataHora
      }
    }
  }
</script>

<style scoped>
  .texto-principal {
    font-size: 14px;
    color: var(--black);
    margin: 20px 0;
  }
  .texto-secundario {
    font-size: 14px;
    margin: 0;
  }
  #ButtonVoltar {
    background-color: var(--primaria);
    color: var(--white);
    padding: 10px;
  }
</style>
