<template>
  <v-container>
    <h1
        class="mr-5 titulo"
    >
      Cadastro de sub login
    </h1>
    <v-card class="pb-1 pt-5">
      <v-card-actions>
        <v-spacer />
        <ButtonIcon
            icone="pc-filtro"
            texto="Filtrar"
            @click="exibirDialogFiltrar = true"
        />
        <ButtonIcon
            outlined
            icone="pc-mais"
            texto="NOVO SUB LOGIN"
            @click="novoSublogin"
        />
      </v-card-actions>
      <v-card-text>
        <DataTable
            item-key="id"
            elevation="0"
            :headers="tabela.cabecalho"
            :show-pagination="false"
            :itens-externos="sublogins"
            :loading="$store.state.carregandoSilenciosamente"
        >
          <template v-slot:[`item.id`]="item">
            <td>{{ item.id }}</td>
          </template>
          <template v-slot:[`item.nome`]="item">
            <td>{{ item.nome }}</td>
          </template>
          <template v-slot:[`item.login`]="item">
            <td>{{ item.login }}</td>
          </template>
          <template v-slot:[`item.status`]="item">
            <td>
              <v-chip
                  label
                  class="text--white"
                  v-if="item.status"
                  :color="corStatus(item.status)"
              >
                {{ item.status ? item.status.replace('_', ' ') : '' }}
              </v-chip>
            </td>
          </template>
          <template v-slot:[`item.acao`]="item">
            <td>
              <v-btn
                  icon
                  @click="editarSublogin(item)"
              >
                <v-icon color="primaria">pc-editar</v-icon>
              </v-btn>
            </td>
          </template>
        </DataTable>
      </v-card-text>
    </v-card>
    <DialogMapaComissaoGerado />
    <DialogFiltrarSublogin :exibir="exibirDialogFiltrar" @exibir="v => exibirDialogFiltrar = v" @filtro="listarSublogins" />
  </v-container>
</template>

<script>

  import DialogMapaComissaoGerado from '@/components/dialog/DialogMapaComissaoGerado'
  import DialogFiltrarSublogin from '@/components/dialog/DialogFiltrarSublogin'
  import { listarSublogins } from '@/service/sublogin'

  export default {
    components: { DialogMapaComissaoGerado, DialogFiltrarSublogin },
    data: () => ({
      seletorEmpresa: ['EMBRACON', 'RCI', 'VOLKSWAGEN', 'HIBRIDA'],
      tabela: {
        cabecalho: [
          { text: 'ID', value: 'id' },
          { text: 'Nome', value: 'nome' },
          { text: 'Login', value: 'login' },
          { text: 'Status', value: 'status' },
          { value: 'acao', width: 10, sortable: false }
        ]
      },
      idUsuario: undefined,
      sublogins: [],
      subloginsAtivos: undefined,
      notificacoes: {},
      exibirDialogFiltrar: false,
      exibirDialogNotificacao: false
    }),
    mounted () {
      const paginaRecarregada = localStorage.getItem('paginaRecarregada')
      if (!paginaRecarregada) {
        localStorage.setItem('paginaRecarregada', true)
        window.location.reload()
      }
      this.$store.commit('setRotaAtual', this.$route.name)
      this.idUsuario = this.$session.get('visaoAdministrativa').idUsuario | this.$session.get('usuario').id
      this.listarSublogins()
    },
    methods: {
      async listarSublogins (filtro) {
        try {
          this.sublogins = await listarSublogins(this.idUsuario, filtro)
          this.subloginsAtivos = 0

          this.sublogins.forEach(sublogin => {
            if (sublogin.status === 'ATIVO') {
              this.subloginsAtivos++
            }
          })
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      editarSublogin (sublogin) {
        this.$router.push({
          name: 'Sublogin',
          params: {
            matricula: this.idUsuario
          },
          query: {
            id: sublogin.id
          }
        })
      },
      corStatus (status) {
        switch (status) {
          case 'INATIVO':
            return '#B9BDC4'

          case 'ATIVO':
            return '#008836'

          case 'BLOQUEADO':
            return '#CE181E'

          case 'AGUARDANDO_LIBERACAO':
            return '#000000'

          case 'SUBSTITUIDO':
            return '#222222'

          case 'FERIAS':
            return '#38b8f3'

          case 'AFASTADO':
            return '#ce5b18'

          case 'DESLIGADO':
            return '#B9BDC4'
        }
      },
      novoSublogin () {
        this.$router.push({
          name: 'Sublogin'
        })
      }
    }
  }

</script>
