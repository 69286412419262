<template>
  <v-dialog
      persistent
      width="500"
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>
        <span>Dados cadastrais</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              color="primaria"
              size="10"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
            lazy-validation
            ref="formulario"
            v-model="valido"
            @submit.prevent="salvar"
        >
          <TextField
              label="E-mail*"
              maxlength="70"
              autocomplete="off"
              v-model="dados.email"
              :rules=[$rules.required,$rules.email]
          />
          <TextField
              label="Telefone*"
              autocomplete="off"
              v-model="dados.telefone"
              :rules="[$rules.required]"
              v-mask="['(##) ####-####', '(##) #####-####']"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            outlined
            @click="fechar"
        >
          Cancelar
        </Button>
        <Button
            small
            @click="salvar"
        >
          Salvar
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import session from '@/plugins/session'
  import { alterarDadosCadastraisComissionado } from '@/service/comissionado'
  import { alterarDadosCadastraisSublogin } from '@/service/sublogin'

  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      valido: false,
      dados: {
        email: undefined,
        telefone: undefined
      }
    }),
    watch: {
      exibir (valor) {
        if (valor) {
          if (this.$session.get('usuario').perfil !== 'SUBLOGIN' && this.$session.get('usuario').perfil !== 'PARCEIRO') {
            this.fechar()
          }
        }
      }
    },
    mounted () {
      this.dados = {
        email: this.$session.get('usuario').email,
        telefone: this.$session.get('usuario').telefone
      }
    },
    methods: {
      async salvar () {
        try {
          if (this.valido) {
            // eslint-disable-next-line no-unused-vars
            let mensagem = ''

            if (this.$session.get('usuario').perfil === 'PARCEIRO') {
              const { mensagem: resposta } = await alterarDadosCadastraisComissionado({
                email: this.dados.email,
                telefone: this.dados.telefone.replace(/(\(|\)|-|\s)/g, '')
              })

              mensagem = resposta
            } else {
              const { mensagem: resposta } = await alterarDadosCadastraisSublogin({
                email: this.dados.email,
                telefone: this.dados.telefone.replace(/(\(|\)|-|\s)/g, '')
              })

              mensagem = resposta
            }

            this.$toast.success(mensagem)
            this.fechar()

            session.set({
              usuario: {
                ...this.$session.get('usuario'),
                email: this.dados.email,
                telefone: this.dados.telefone
              }
            })
          }
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>
