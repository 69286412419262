<template>
  <v-dialog
      persistent
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>
        <span>Mapa Gerado</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              color="primaria"
              size="10"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>O seu mapa de comissões {{ nome }} está disponível!</v-card-text>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            outlined
            @click="alterarStatus('VER_DEPOIS')"
        >
          VER DEPOIS
        </Button>
        <Button
            small
            @click="visualizar"
        >
          VER AGORA
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import { alterarStatusNotificacao } from '@/service/mapa-comissao'

  export default {
    data: () => ({
      exibir: false,
      notificacoes: [],
      id: undefined,
      nome: undefined,
      ano: undefined
    }),
    watch: {
      exibir (valor) {
        if (!valor) {
          this.buscarSolicitacoes()
        }
      }
    },
    mounted () {
      this.buscarSolicitacoes()
    },
    methods: {
      buscarSolicitacoes () {
        this.notificacoes = this.$session.get('solicitacaoMapaComissao')

        if (this.notificacoes.length > 0) {
          this.id = this.notificacoes[0].id
          this.nome = this.notificacoes[0].nomeDataBase
          this.ano = this.notificacoes[0].anoReferencia

          this.exibir = true
        }
      },
      async visualizar () {
        await this.alterarStatus('LIDO')

        this.$session.set({
          solicitacaoMapaComissao: []
        })

        this.$router.push({
          name: 'MapaComissao',
          query: {
            ano: this.ano
          }
        })
      },
      async alterarStatus (status) {
        try {
          await alterarStatusNotificacao(this.notificacoes[0].id, status)
          this.removerPrimeiraNotificacao()
          this.exibir = false
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async fechar () {
        await this.alterarStatus('LIDO')
        this.exibir = false
      },
      removerPrimeiraNotificacao () {
        this.notificacoes.shift()

        this.$session.set({
          solicitacaoMapaComissao: this.notificacoes
        })
      }
    }
  }

</script>
