<template>
  <v-container fluid>
    <h1 class="titulo-nota">Notas fiscais</h1>
    <span class="subtitulo">
      Centro de custo:
      <span class="font-weight-bold">{{ centroCusto ? parseInt(centroCusto, 10) : '' }}</span>
    </span>
    <v-card class="mt-5" flat>
      <v-tabs
          id="TabNotaFiscal"
          v-model="tab"
          hide-slider
          background-color="transparent"
      >
        <v-tab id="TabPendente">
          Pendentes
        </v-tab>
        <v-tab id="TabEnviada">
          Enviadas
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- PENDENTE -->
        <v-tab-item>
          <DataTable
              :headers="headersPendente"
              :params="params"
              :reload="reload"
              :url="urlPendentes"
              :key="dataTableKeyPendente"
          >
            <template v-slot:[`item.vencimento`]="item">
              <td>{{ getDateBR(item.vencimento) }}</td>
            </template>
            <template v-slot:[`item.valorBruto`]="item">
              <td>R$ {{ convertToMoney(item.valorBruto) }}</td>
            </template>
            <template v-slot:[`item.valorImposto`]="item">
              <td>R$ {{ convertToMoney(item.valorImposto) }}</td>
            </template>
            <template v-slot:[`item.valorLiquido`]="item">
              <td>R$ {{ convertToMoney(item.valorLiquido) }}</td>
            </template>
            <template v-slot:[`item.status`]="item">
              <td>
                <span class="capitalize" v-if="item.codigoMovimento === '1.1.22'">
                  <Chip
                      color="#31ce18"
                      texto="Bônus"
                      class="my-1"
                  />
                </span>
                <span class="capitalize">
                  <Chip :id="`ChipStatus_${item.idNotaFiscalParceiro}`"
                        :color="setStatusColor(item.status)"
                        :texto="item.status"
                        class="my-1"
                  />
                </span>
              </td>
            </template>
            <template v-slot:[`item.anexar`]="item">
              <td>
                <div v-if="$session.get('visaoAdministrativa').idUsuario">
                  <v-file-input
                      prepend-icon=""
                      placeholder="Anexar NF"
                      show-size
                      truncate-length="10"
                      @click.prevent="anexarNota(item)"
                      v-if="item.status === 'Pendente' || item.status === 'Recusada'"
                  />
                </div>
                <div v-else>
                  <v-file-input
                      accept="application/pdf"
                      prepend-icon=""
                      :rules="arquivoRules"
                      placeholder="Anexar NF"
                      show-size
                      truncate-length="10"
                      @click="anexarNota(item)"
                      v-if="item.status === 'Pendente' || item.status === 'Recusada'"
                      v-model='arquivo' />
                </div>
              </td>
            </template>
            <template v-slot:[`item.balao`]="item">
              <td>
                <v-btn v-if="item.status === 'Recusada' && item.comentario !== '' "
                       icon
                       @click="exibirMotivoRecusa(item)">
                  <v-icon color="primaria">
                    pc-mensagem
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </DataTable>
        </v-tab-item>

        <!-- Enviada -->
        <v-tab-item>
          <template>
            <v-container>
              <v-row no-gutters>
                <v-col cols="7" class="informativo">
                  <v-icon size="16">
                    pc-ajuda
                  </v-icon>
                  <span>
                    Aqui constam as notas que foram incluídas no sistema de pagamento.
                    Mas fique atento! Antes da efetivação do pagamento a nota precisa ser validada e você pode receber uma mensagem do setor responsável.
                  </span>
                </v-col>
                <!-- eslint-disable-next-line -->
                <v-col cols="5" align="end">
                  <Button @click="exibirFiltro = true">
                    <v-icon size="20">
                      pc-filtro
                    </v-icon>
                    Filtrar
                  </Button>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <DataTable
              :headers="headersEnviada"
              :params="params"
              :reload="reload"
              :url="urlEnviadas"
              :key="dataTableKeyEnviada"
          >
            <template v-slot:[`item.vencimento`]="item">
              <td>{{ getDateBR(item.vencimento) }}</td>
            </template>
            <template v-slot:[`item.valorBruto`]="item">
              <td>R$ {{ convertToMoney(item.valorBruto) }}</td>
            </template>
            <template v-slot:[`item.valorImposto`]="item">
              <td>R$ {{ convertToMoney(item.valorImposto) }}</td>
            </template>
            <template v-slot:[`item.valorLiquido`]="item">
              <td>R$ {{ convertToMoney(item.valorLiquido) }}</td>
            </template>
            <template v-slot:[`item.anexadoEm`]="item">
              <td>{{ getDateTimeBR(item.dataEnvio) }}</td>
            </template>
            <template v-slot:[`item.anexadoPor`]="item">
              <td>{{ item.nome }}</td>
            </template>
            <template v-slot:[`item.enviadoEm`]="item">
              <td>{{ getDateBR(item.dataEnvio) }}</td>
            </template>
          </DataTable>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <Loading :visible="uploading" :background-opacity="0.3" />
    <DialogMotivoRecusa ref="dialogMotivoRecusa" :nota-fiscal="notaSelecionada" />
    <DialogFiltrarNotasFiscais :exibir="exibirFiltro"
                               filtro-vencimento
                               :todas-as-notas="notasEnviadas"
                               @filtro="aplicarFiltro"
                               @exibir="exibir => exibirFiltro = exibir" />
  </v-container>
</template>

<script>
  import DialogFiltrarNotasFiscais from '@/components/dialog/DialogFiltrarNotasFiscais'
  import { buscarNovasNotasBaas, enviarNotaParaOAzure } from '@/service/notasFiscais'
  import { money, toDateTimeBR, toDateBR } from '@/plugins/formatters'
  import session from '../../plugins/session'

  export default {
    name: 'ParceiroNotaFiscal',
    components: {
      DialogFiltrarNotasFiscais
    },
    data: () => ({
      tab: null,
      exibirFiltro: false,
      reload: false,
      centroCusto: '',
      arquivo: undefined,
      arquivoRules: [
        arquivo => !arquivo || arquivo.type === 'application/pdf' || arquivo.size < 10000000 || 'Arquivo inválido. Deve ser enviado um arquivo PDF de até 10mb'
      ],
      uploading: false,
      notaSelecionada: {},
      notasPendentes: [],
      notasEnviadas: [],
      notasFiltradas: [],
      dataTableKeyPendente: 1,
      dataTableKeyEnviada: 1,
      headersPendente: [
        { text: 'Vencimento', value: 'vencimento' },
        { text: 'Valor bruto', value: 'valorBruto' },
        { text: 'Imposto renda', value: 'valorImposto' },
        { text: 'Valor líquido', value: 'valorLiquido' },
        { text: 'Status', value: 'status' },
        { text: 'Nota fiscal', value: 'anexar' },
        { text: '', value: 'balao', align: 'center', width: 50 }
      ],
      headersEnviada: [
        { text: 'Vencimento', value: 'vencimento', width: 130 },
        { text: 'Valor bruto', value: 'valorBruto' },
        { text: 'Imposto renda', value: 'valorImposto' },
        { text: 'Valor líquido', value: 'valorLiquido' },
        { text: 'Arquivo anexado em', value: 'anexadoEm' },
        { text: 'Arquivo anexado por', value: 'anexadoPor' },
        { text: 'Arquivo enviado em', value: 'enviadoEm' }
      ],
      pagination: {
        sortBy: [ 'vencimento' ], sortDesc: [ false ]
      },
      urlPendentes: '',
      urlEnviadas: ''
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    watch: {
      async arquivo (arquivoSelecionado) {
        if (arquivoSelecionado && arquivoSelecionado.size < 10000000 && arquivoSelecionado.type.includes('pdf')) {
          this.uploading = true
          try {
            const dados = new FormData()

            dados.append('arquivo', arquivoSelecionado)
            dados.append('idNotaFiscal', this.notaSelecionada.idNotaFiscalParceiro)
            dados.append('idUnidade', session.get('unidade').idUnidade)

            await enviarNotaParaOAzure(dados)

            window.location.reload()
            this.$toast.success('Arquivo enviado com sucesso!')
          } catch {
            this.$toast.error('Falha ao enviar o arquivo. Tente novamente em instantes.')
          }
          this.uploading = false
          this.arquivo = null
        }
      }
    },
    async mounted () {
      let loginAdm = this.$session.get('visaoAdministrativa').login
      let idUnidadeVisaoAdministrativa = this.$session.get('unidade').idUnidade
      this.$store.commit('setRotaAtual', this.$route.name)

      const { centroCusto } = await buscarNovasNotasBaas(loginAdm, idUnidadeVisaoAdministrativa)
      this.centroCusto = centroCusto

      this.urlPendentes += 'listar-notas-pendentes' + (loginAdm ? '/' + loginAdm : '')
      this.urlEnviadas += 'listar-notas-status/Enviada' + (loginAdm ? '/' + loginAdm : '')
      this.reload = !this.reload
    },
    methods: {
      anexarNota (nota) {
        if (this.$session.get('visaoAdministrativa').idUsuario) {
          this.$store.commit('toggleExibirDialogVisao')
        } else {}

        this.notaSelecionada = nota
      },
      aplicarFiltro (filtro) {
        let loginAdm = this.$session.get('visaoAdministrativa').login
        this.urlPendentes = 'listar-notas-pendentes' + (loginAdm ? '/' + loginAdm : '')
        this.urlEnviadas = 'listar-notas-status/Enviada' + (loginAdm ? '/' + loginAdm : '')
        switch (this.tab) {
          case 0:
            this.urlPendentes += filtro
            this.dataTableKeyPendente++
            break
          case 1:
            this.urlEnviadas += filtro
            this.dataTableKeyEnviada++
            break
        }
      },
      exibirMotivoRecusa (nota) {
        this.notaSelecionada = nota
        this.$refs.dialogMotivoRecusa.show()
      },
      convertToMoney (value) {
        return money(value)
      },
      getDateBR (timestamp) {
        return toDateBR(timestamp?.split('T')[0])
      },
      getDateTimeBR (timestamp) {
        return toDateTimeBR(timestamp)
      },
      setStatusColor (status) {
        switch (status.toUpperCase()) {
          case 'PENDENTE': return '#697077'
          case 'RECEBIDA': return '#03142E'
          case 'CONFERIDA': return '#008836'
          case 'RECUSADA': return '#CE181E'
          default: return ''
        }
      }
    }
  }
</script>

<style scoped>
  .sublinhar {
    text-decoration: underline;
  }

  .informativo {
    width: 68%;
    font-size: 12px;
    margin: 15px 0;
  }
  .border {
    border: 1px solid black;
  }

</style>
