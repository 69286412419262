<template>
  <v-form>
    <v-dialog
        persistent
        v-model="exibir"
    >
      <v-card class="pb-1">
        <v-card-title>
          <span>Ativar SSO</span>
          <v-btn
              icon
              class="ml-auto"
              @click="fechar"
          >
            <v-icon
                size="10"
                color="primaria"
            >
              pc-fechar
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <TextField
              v-model="link"
              label="URL do SSO"
              :rules="[$rules.required]"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <Button
              small
              @click="salvar"
          >
            Salvar
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

  <script>

  import { ativarDesativarSSO } from '@/service/unidade'

  export default {
    props: {
      idUnidadeSso: Number,
      urlSso: String,
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      link: ''
    }),
    watch: {
      exibir () {
        this.link = this.urlSso
      }
    },
    methods: {
      async salvar () {
        try {
          var { mensagem } = await ativarDesativarSSO(this.idUnidadeSso, {
            sso: true,
            link: this.link
          })

          this.fechar()
          this.$toast.success(mensagem)
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

  </script>

  <style scoped>

    .v-btn {
      padding: 0 2rem !important;
    }

  </style>
