import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function buscarDashboard (silent = true) {
  try {
    const { data } = await http.get('/dashboard', {
      options: { silent }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}
