import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'

export async function consultarUnidade (id) {
  try {
    const { data } = await http.get(`/api/consultar-unidade/${id}`, { baseURL: process.env.VUE_APP_WS_ROOT, options: { silent: true } })
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function atualizarUnidade (id, unidade) {
  try {
    const { data } = await http.put(`/atualizar-unidade/${id}`, unidade)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function ativarDesativarSSO (idUnidade, sso) {
  try {
    const { data } = await http.patch(`/unidade/atualizar-sso/${idUnidade}`, sso)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}
