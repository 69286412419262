export let state
state = {
  notificacoes: [],
  numeroNotificacao: 0
}
export const getters = {
  getNotificacoes: state => {
    return state.notificacoes
  },
  getNumeroNotificacao: state => {
    return state.numeroNotificacao
  }
}

export const mutations = {
  setNotificacoes (state, notificacoes) {
    state.notificacoes = notificacoes
  },
  addInfoNotificacao (state, notificacao) {
    state.notificacoes.unshift(notificacao)
  },
  removeInfoNotificacao (state) {
    state.notificacoes.pop()
  },
  setNumeroNotificacao (state, quantidade) {
    state.numeroNotificacao = quantidade
  },
  addNotificacao (state) {
    state.numeroNotificacao++
  }
}

export const actions = {
  async setNumeroNotificacao ({ commit }, payload) {
    commit('setNumeroNotificacao', payload)
  },
  async addNumeroNotificacao ({ commit }) {
    commit('addNotificacao')
  },
  async setNotificacoes ({ commit }, payload) {
    commit('setNotificacoes', payload)
  },
  async addInfoNotificacao ({ commit }, payload) {
    commit('addInfoNotificacao', payload)
  },
  async removeInfoNotificacao ({ commit }) {
    commit('removeInfoNotificacao')
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
