<template>
  <v-skeleton-loader
      v-if="loadingSkeleton"
      type="image"
      width="100%"
      :height="isMobile ? 130 : 200"
  />
  <v-card v-else :color="getPhaseColor" :height="isMobile ? 130 : 200" class="card d-flex align-center w-100" dark elevation="0">
    <v-card-text class="ml-0">
      <v-row>
        <v-col cols="2" class="d-flex align-center pr-0 mr-0">
          <img v-if="fase.evento == 'analise_credito_automatica_reprovada'" src="@/assets/icons/portal-corretora/svg/dsExclamacao.svg" class="icon mb-3 mr-1" :style="getIconColorStyles" />
          <v-icon v-else class="icon mb-3 mr-1" :style="getIconColorStyles" color="white">{{ getPhaseIcon }}</v-icon>
        </v-col>
        <v-col cols="7" class="d-flex flex-column justify-center" :class="isMobile ? 'pl-18 pr-0 pr-0 mr-0' : 'pl-0'" :style="!isMobile ? 'margin-left: -10px;' : ''">
          <label class="name mb-3">{{ fase.nome }}</label>
          <span class="tooltip" v-if="!fase.flagAtualizarCaso">{{ fase.tooltip }}</span>
          <span class="tooltip" v-if="fase.flagAtualizarCaso">{{ fase.pendencia }}</span>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end">
          <span class="elapsedTimeText">{{ getElapsedTime }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import moment from 'moment'
  import { getThemeByStep } from '@/plugins/color'
  // import { Icon } from '@iconify/vue2'

  export default {
    name: 'CardSituacaoDaCota',
    props: {
      fase: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      loadingSkeleton: false
    }),
    computed: {
      getPhaseColor () {
        console.info('COR', this.fase.evento)
        return getThemeByStep(this.fase.evento).primaria
      },
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      getPhaseIcon () {
        // console.info('ICONE', this.fase.evento)
        return getThemeByStep(this.fase.evento).icone
      },
      getElapsedTime () {
        let days = moment().diff(moment(this.fase.dataEvento, 'DD-MM-YYYY'), 'days')
        if (days > 30) {
          return moment(this.fase.dataEvento, 'DD-MM-YYYY').format('DD/MM/YYYY')
        }

        return days === 1 ? 'Há 1 dia' : `Há ${days} dias`
      },
      getIconColorStyles () {
        return `
          background-color: ${getThemeByStep(this.fase.evento).terciaria};
          border-color: ${getThemeByStep(this.fase.evento).secundaria};
        `
      }
    },
    mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
      }, 6000)
      console.info(this.fase)
    }
  }
</script>

<style scoped>

  .card {
    border-radius: 9px;
  }

  .icon {
    border: 8px solid;
    border-radius: 100%;
    height: 50px;
    width: 50px;
  }

  .name {
    font-family: Inter;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tooltip {
    font-family: Inter;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .elapsedTimeText {
    font-family: Inter;
    line-height: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  @media screen and (max-width: 960px) {
    .name {
      color: #FFF;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .tooltip {
      color: #FFF;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .elapsedTimeText {
      color: #FFF;
      text-align: right;
      font-family: Inter;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .icon {
      border: 8px solid;
      border-radius: 100%;
      height: 40px;
      width:40px;
    }
  }
</style>
