<template>
  <v-container fluid>
    <h1 class="titulo">Transferir Sublogin - Selecionar novo comissionado</h1>
    <span class="subtitulo">
      Sublogin
      <b>
        ID - {{ idSublogin }}
      </b>
    </span>
    <v-row>
      <v-spacer />
      <!-- <v-col
          lg="2"
          md="3"
          cols="12"
      >
        <ButtonIcon
            small
            block
            icone="pc-filtro"
            color="secundaria"
            texto="FILTRAR"
            @click="filtrar"
        />
      </v-col> -->
    </v-row>
    <DataTable
        silent
        class="mt-5"
        item-key="cnpj"
        :params="params"
        :filter="filters"
        :headers="headers"
        :url="urlDataTable"
        :loading="$store.state.carregandoSilenciosamente"
        :key="dataTableKey"
    >
      <template v-slot:[`item.login`]="item">
        <td>{{ item.login }}</td>
      </template>
      <template v-slot:[`item.cpfCnpj`]="item">
        <td>{{ item.cpfCnpj }}</td>
      </template>
      <template v-slot:[`item.acao`]="item">
        <td class="text-right">
          <v-btn
              plain
              small
              color="secundaria"
              @click="transferir(idSublogin, item.login, idUnidade)"
          >
            SELECIONAR
          </v-btn>
        </td>
      </template>
    </DataTable>
    <!-- <DialogFiltrarParceria
        :exibir="exibirDialogFiltro"
        @exibir="exibir => exibirDialogFiltro = exibir"
        @filtro="aplicarFiltro"
    /> -->
  </v-container>
</template>

<script>

  // import DialogFiltrarParceria from '@/components/dialog/DialogFiltrarParceria'
  import { transferir } from '@/service/sublogin'
  import { transferirGestaoUnificada } from '@/service/subloginGestaoUnificada'
  import { gestaoUnificada } from '@/directives/gestaoUnificada'

  export default {
    // components: { DialogFiltrarParceria },
    data: () => ({
      cfo: null,
      idSublogin: 0,
      idUnidade: 0,
      gestaoUnificada: false,
      urlDataTable: `/comissionado/listar/`,
      dataTableKey: 1,
      exibirDialogFiltro: false,
      filtro: {
        nome: '',
        cpfCnpj: '',
        usuario: ''
      },
      headers: [
        { text: 'Login', value: 'login', sortable: false },
        { text: 'CNPJ', value: 'cpfCnpj', sortable: false },
        { text: '', value: 'acao', sortable: false }
      ],
      filters: {},
      pagination: {
        sortBy: [], sortDesc: [ true ]
      }
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    mounted () {
      this.$store.commit('setRotaAtual', 'Parcerias')
      this.cfo = this.$route.params.cfo
      this.idSublogin = this.$route.params.id
      this.cnpj = this.$route.params.cnpj
      this.loginComissionado = this.$route.params.login
      this.urlDataTable = this.urlDataTable + this.cfo
      this.gestaoUnificada = gestaoUnificada()
      this.idUnidade = this.$session.get('unidade').idUnidade
    },
    methods: {
      // filtrar () {
      //   this.exibirDialogFiltro = true
      // },
      // aplicarFiltro (filtro) {
      //   this.urlDataTable = `/listar-parcerias${filtro}`
      //   this.dataTableKey++
      // },
      async transferir (idSublogin, userNovoComissionado, idUnidade) {
        try {
          if (this.gestaoUnificada) {
            const { mensagem } = await transferirGestaoUnificada(idSublogin, userNovoComissionado, idUnidade)
            this.$toast.success(mensagem)
          } else {
            const { mensagem } = await transferir(idSublogin, userNovoComissionado)
            this.$toast.success(mensagem)
          }
        } catch (erro) {
          this.$toast.error(erro)
        } finally {
          this.$router.go(-2)
        }
      }
    }
  }

</script>
