<template>
  <v-container fluid style="background-color: #F7F7F7; padding: 15px 20px; min-height: 92vh;">
    <v-row class="pt-3 breadcrumb">
      <v-skeleton-loader
          v-if="loadingSkeleton"
          type="image"
          :width="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '30%' : '50%'"
          height="18px"
          class="breadcrumbSkeleton"
      />
      <BreadCrumb v-else :items="breadcrumb" show-back back-to="JornadaDaCota" />
    </v-row>
    <v-row class="linhaLegenda">
      <v-col :class="!isMobile ? 'mb-0 pb-0 ml-0 pl-0' : 'mb-0 pb-0'" cols="12" md="8">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '25%' : '30%'"
            height="25px"
        />
        <label v-else class="label-detalhe">Detalhe</label>
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type=""
        />
        <label v-else class="exibir-ocultar" @click="exibirOcultar" v-show="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          {{ exibirDetalhes ? 'ver menos' : 'ver mais' }}
          <v-icon color='var(--primary)'>
            {{ exibirDetalhes ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </label>
      </v-col>
    </v-row>
    <v-row class="linhaLegenda">
      <v-col :class="isMobile ? 'mt-0 pt-2 pt-0 pb-0 d-flex justify-start pr-3' : 'mt-0 pt-2 pt-0 pb-0 d-flex justify-end pr-3'" cols="12">
        <table class="tabelaLegenda" style="width: 100%;">
          <tbody>
            <tr>
              <td colspan="4">
                <!-- <v-skeleton-loader
                    v-if="loadingSkeleton"
                    type="text"
                    style="width: 50%;"
                    class="skeletonLegenda"
                />
                <span v-else class="hint-tabela-legenda">Legenda de estado da cota:</span> -->
              </td>
            </tr>
            <v-row v-if="loadingSkeleton">
              <v-col cols="3" v-for="n in 4" :key="n" class="skeletonLegendaTipos">
                <v-skeleton-loader type="text" :transition="transition" />
              </v-col>
            </v-row>
            <tr v-else>
              <td class="texto-legenda texto-sucesso"><img class="imglegenda" src="@/assets/img/sucesso.png">Sucesso</td>
              <td class="texto-legenda texto-informativo"><img class="imglegenda" src="@/assets/img/informativo.png">Informativo</td>
              <td class="texto-legenda texto-pendencia"><img style="padding-right: 5px;padding-bottom: 2px;" src="@/assets/img/pendencia.png">Pendência</td>
              <td class="texto-legenda texto-atencao"><img class="imglegenda" src="@/assets/img/atencao.png">Atenção</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <div class="mt-6 exibir-ocultar-mobile d-flex justify-end">
      <v-skeleton-loader
          v-if="loadingSkeleton"
          type="image"
          width="30%"
          height="15px"
          class="mb-2"
      />
      <label v-else @click="exibirOcultar" v-show="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
        {{ exibirDetalhes ? 'ver menos' : 'ver mais' }}
        <v-icon color='var(--primary)'>
          {{ exibirDetalhes ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </label>
    </div>
    <v-row v-show="exibirDetalhes" v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
      <v-col class="CardInformacoesCliente">
        <CardInformacoesCliente :dados="cliente" />
      </v-col>
      <v-col class="CardAtualizacoesCliente">
        <CardAtualizacoesCliente :dados="cliente" />
      </v-col>
    </v-row>
    <v-row v-else class="cardClientes" v-show="exibirDetalhes">
      <v-col>
        <CardExtratoClienteMobile :dados="cliente" />
      </v-col>
    </v-row>
    <v-row class="pt-5 CardSituacaoDaCota" style="margin-top: 0px!important;">
      <v-col :cols="isMobile ? 12 : 6">
        <v-row>
          <CardSituacaoDaCota :fase="fase" />
        </v-row>
        <v-row class="padding-top-documentos">
          <CardDocumentosNecessarios :fase="fase" :cliente="cliente" />
        </v-row>
      </v-col>
      <v-col :cols="isMobile ? 12 : 6" :class="isMobile ? '' : 'padding-duas-colunas'">
        <v-row style="padding-top: 2px;" :class="isMobile ? '' : 'pl-2'">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              width="130"
              type="image"
              height="18px"
              class="mt-6"
          />
          <v-skeleton-loader
              v-if="loadingSkeleton"
              :width="!isMobile ? '30%' : '40%'"
              type="avatar"
              class="ml-2 mb-2 mt-2"
          />
          <v-skeleton-loader
              v-if="loadingSkeleton"
              :width="197"
              type="image"
              height="18px"
              class="mt-6"
          />
          <v-skeleton-loader
              v-if="loadingSkeleton"
              :width="!isMobile ? '50px' : '40%'"
              type="avatar"
              class="ml-2 mb-2 mt-2"
          />
          <CardBaixarDocumentos v-else :cliente="cliente" :fase="fase" />
        </v-row>
        <v-row v-if="!fase.flagAtualizarCaso">
          <div class="Card pa-3">
            <v-row class="mb-0">
              <v-col cols="10" class="font-weight-bold d-flex align-end justify-start textoInformativoTituloText">
                Documentação em análise
              </v-col>
              <v-col cols="2">
                <v-icon class="d-flex align-center justify-end mr-1">mdi-clock-outline</v-icon>
              </v-col>
            </v-row>
            <span class="textoInformativoText">
              O setor responsável já está analisando os documentos enviados. Você e o seu cliente receberão
              uma resposta em até 7 dias úteis.
            </span>
          </div>
        </v-row>

        <!-- Adicionar um for nessa v-row : v-for="documento in documentosNescessários"-->
        <v-row class="divDocumentos" v-if="fase.flagAtualizarCaso">
          <!-- Componente UploadFiles.vue. Passar pra ele as props: nome do documento, descrição etc -->
          <v-col>
            <div class="CardTitulo mb-3 mt-3">
              <v-skeleton-loader
                  v-if="loadingSkeleton"
                  type="image"
                  :width="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '40%' : '50%'"
                  height="18px"
                  style="margin-top: 10px!important; margin-bottom: 10px!important;"
              />
              <div v-else class="ml-1 mb-2">Upload dos arquivos e anexos</div>
              <div v-show="exibirUpload == ''">
                <CardFileUpload
                    :documento="outrasDocs"
                    ref="Reenvio de documentação"
                    @value="retornaTela"
                    @modal="uploadFichaCadastral()"
                    @campoPreenchido="validaCampos(validaArrayFixo,$event)"
                ></CardFileUpload>
              </div>
            </div>
            <div v-show="!(exibirUpload == '')">
              <v-row v-if="!isDeviceMobile">
                <v-col cols="11">
                  <div class="CardTitulo">{{ documentoSelecionado.titulo }}</div>
                </v-col>
                <v-col cols="1">
                  <v-icon size="12" @click="exibirUpload = ''">pc-fechar</v-icon>
                </v-col>
              </v-row>
              <v-divider v-if="!isDeviceMobile" class="my-2"></v-divider>
              <v-row>
                <v-col>
                  <div class="d-flex align-center justify-center" style="background-color: transparent">
                    <v-img contain :max-width="360" :height="isDeviceMobile ? documentoSelecionado.height/1.2 : documentoSelecionado.height" :src="documentoSelecionado.src"></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-0 d-flex text-center" :class="[isDeviceMobile ? 'justify-center' : 'justify-start']">
                <v-col offset-md="1" cols="12" md="10">
                  <div class="tituloDocumento d-flex align-center justify-center">
                    {{
                      documentoSelecionado.texto
                    }}
                  </div>
                </v-col>
              </v-row>
              <!-- AVISOS QUE FICAM ABAIXO DO DOCUMENTO -->
              <v-row dense v-show="documentoSelecionado.nome === 'fichaCadastralPF'">
                <v-col offset-md="1">
                  <div class="d-flex align-center">
                    <v-icon size="18">mdi-download-box-outline</v-icon>
                    <span class="ml-1">Faça download da <a class="links" :href="documentoFichaCadastralPF" target="_blank">ficha cadastral</a></span>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-show="documentoSelecionado.nome === 'fichaCadastralPJ'">
                <v-col offset-md="1" cols="12">
                  <div class="d-flex align-center">
                    <v-icon size="18">mdi-download-box-outline</v-icon>
                    <span class="ml-1">Faça download da <a class="links" :href="documentoFichaCadastralPJ" target="_blank">ficha cadastral</a></span>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-show="documentoSelecionado.iluminacao">
                <v-col offset-md="1" cols="12">
                  <div class="d-flex align-center">
                    <v-icon size="18">mdi-weather-sunny</v-icon>
                    <span class="ml-1">Escolha um ambiente com boa iluminação.</span>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-show="documentoSelecionado.plastico">
                <v-col offset-md="1" cols="12">
                  <div class="d-flex align-center">
                    <v-icon size="18">mdi-cancel</v-icon>
                    <span class="ml-1">Retire seu documento de pastas ou plásticos, eles atrapalham a fotografia.</span>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-show="documentoSelecionado.titularOuEndereco">
                <v-col offset-md="1" cols="12">
                  <div class="d-flex align-center">
                    <v-icon size="18">mdi-alert-circle-outline</v-icon>
                    <span class="ml-1">Certifique-se que tenha o nome do titular e endereço.</span>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-show="documentoSelecionado.downloadDocumento">
                <v-col offset-md="1" cols="12">
                  <div class="d-flex align-center">
                    <v-icon size="18">mdi-download-box-outline</v-icon>
                    <span class="ml-1">Você pode fazer o download do documento clicando <a class="links" :href="documentoFichaAnaliseCredito" target="_blank">aqui.</a></span>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-show="documentoSelecionado.assinaturaConsorciado">
                <v-col offset-md="1" cols="12">
                  <div class="d-flex align-center">
                    <v-icon size="18">mdi-alert-circle-outline</v-icon>
                    <span class="ml-1">O formulário deve conter a assinatura do consorciado.</span>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-show="documentoSelecionado.certificaAssinatura">
                <v-col offset-md="1" cols="12">
                  <div class="d-flex align-center">
                    <v-icon size="18">mdi-alert-circle-outline</v-icon>
                    <span class="ml-1">Certifica-se que o formulário esteja preenchido adequadamente antes de anexá-lo.</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center mt-4">
                <v-col cols="12" md="8">
                  <div class="d-flex align-center justify-center w-100">
                    <Button class="button-attach-documents" block @click="enviar(documentoSelecionado.nome)">Anexar</Button>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="divObservacao" v-if="fase.flagAtualizarCaso">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              width="100%"
              height="110px"
              class="ml-0 mr-1"
          />
          <v-textarea v-else
                      placeholder="Insira aqui suas observações"
                      rows="3"
                      max-rows="4"
                      solo
                      flat="true"
                      no-resize
                      class="observacoes"
                      hide-details
                      v-model="observacao"
          >
          </v-textarea>
        </v-row>
        <v-row class="divBotoes" v-if="fase.flagAtualizarCaso">
          <v-col cols="6" class="divBotaoCancelar" id="coluna-limpar-filtros">
            <v-skeleton-loader
                v-if="loadingSkeleton"
                type="image"
                width="100%"
                height="45px"
                class="ml-1 mr-1 mt-1 mb-1"
            />
            <v-btn v-else
                   @click="goBack()"
                   class="btn-fechar"
                   block>
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                <path d="M3.29275 4.12676C3.37126 4.04696 3.46453 3.98364 3.56722 3.94044C3.6699 3.89724 3.77999 3.875 3.89116 3.875C4.00234 3.875 4.11242 3.89724 4.2151 3.94044C4.31779 3.98364 4.41106 4.04696 4.48957 4.12676L8.96246 8.66349L13.4353 4.12676C13.5139 4.04709 13.6072 3.98388 13.7099 3.94076C13.8126 3.89764 13.9226 3.87545 14.0338 3.87545C14.1449 3.87545 14.2549 3.89764 14.3576 3.94076C14.4603 3.98388 14.5536 4.04709 14.6322 4.12676C14.7108 4.20644 14.7731 4.30103 14.8156 4.40513C14.8582 4.50923 14.88 4.62081 14.88 4.73349C14.88 4.84617 14.8582 4.95774 14.8156 5.06185C14.7731 5.16595 14.7108 5.26054 14.6322 5.34021L10.1576 9.87522L14.6322 14.4102C14.7108 14.4899 14.7731 14.5845 14.8156 14.6886C14.8582 14.7927 14.88 14.9043 14.88 15.017C14.88 15.1296 14.8582 15.2412 14.8156 15.3453C14.7731 15.4494 14.7108 15.544 14.6322 15.6237C14.5536 15.7034 14.4603 15.7666 14.3576 15.8097C14.2549 15.8528 14.1449 15.875 14.0338 15.875C13.9226 15.875 13.8126 15.8528 13.7099 15.8097C13.6072 15.7666 13.5139 15.7034 13.4353 15.6237L8.96246 11.087L4.48957 15.6237C4.41099 15.7034 4.3177 15.7666 4.21502 15.8097C4.11234 15.8528 4.0023 15.875 3.89116 15.875C3.78003 15.875 3.66998 15.8528 3.5673 15.8097C3.46463 15.7666 3.37133 15.7034 3.29275 15.6237C3.21416 15.544 3.15183 15.4494 3.1093 15.3453C3.06677 15.2412 3.04488 15.1296 3.04488 15.017C3.04488 14.9043 3.06677 14.7927 3.1093 14.6886C3.15183 14.5845 3.21416 14.4899 3.29275 14.4102L7.76733 9.87522L3.29275 5.34021C3.21404 5.26061 3.15159 5.16604 3.10898 5.06193C3.06637 4.95782 3.04443 4.84621 3.04443 4.73349C3.04443 4.62077 3.06637 4.50916 3.10898 4.40504C3.15159 4.30093 3.21404 4.20637 3.29275 4.12676Z" fill="#191E26" />
              </svg>
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
                v-if="loadingSkeleton"
                type="image"
                width="100%"
                height="45px"
                class="ml-1 mr-1 mt-1 mb-1"
            />
            <v-btn v-else
                   class="actionButton"
                   color="primaria"
                   @click="exibirEnvioDocumentos"
                   block>
              <img style="padding-right: 10px;" src="@/assets/icons/portal-corretora/svg/dsBotaoPorta.svg">
              Enviar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <DialogConfirmaEnvioDocumentos
        :exibir="exibirDialogEnvioDocumentos"
        :observacao="observacao"
        @exibir="exibir => exibirDialogEnvioDocumentos = exibir"
    />
    <DialogAlertaDocumentos
        :exibir="exibirDialogAlertaDocumentos"
        @exibir="exibir => exibirDialogAlertaDocumentos = exibir"
    />
    <DialogAlertaDocumentosDuplicados
        :exibir="exibirDialogAlertaDocumentosDuplicados"
        @exibir="exibir => exibirDialogAlertaDocumentosDuplicados = exibir"
    />
  </v-container>
</template>

<script>
  import CardInformacoesCliente from '@/components/JornadaDaCota/CardInformacoesCliente.vue'
  import CardAtualizacoesCliente from '@/components/JornadaDaCota/CardAtualizacoesCliente.vue'
  import CardExtratoClienteMobile from '@/components/JornadaDaCota/CardExtratoClienteMobile.vue'
  import CardSituacaoDaCota from '@/components/JornadaDaCota/CardSituacaoDaCota.vue'
  import CardDocumentosNecessarios from '@/components/JornadaDaCota/CardDocumentosNecessarios.vue'
  import DialogAlertaDocumentos from '@/components/dialog/DialogAlertaDocumentos.vue'
  import DialogAlertaDocumentosDuplicados from '@/components/dialog/DialogAlertaDocumentosDuplicados.vue'
  import BreadCrumb from '@/components/JornadaDaCota/BreadCrumb.vue'
  import { mapGetters } from 'vuex'
  import { Mensagens } from '@/plugins/constants'
  import http from '@/plugins/http'
  import session from '../../../plugins/session'
  import CardFileUpload from '@/components/CardFileUpload.vue'

  export default {
    name: 'Acompanhar',
    components: {
      CardFileUpload,
      CardInformacoesCliente,
      CardAtualizacoesCliente,
      CardExtratoClienteMobile,
      CardSituacaoDaCota,
      CardDocumentosNecessarios,
      DialogAlertaDocumentos,
      DialogAlertaDocumentosDuplicados,
      BreadCrumb
    },
    data: () => ({
      loadingSkeleton: false,
      categoriaSelecionada: {
        nome: '',
        valor: ''
      },
      documentoDuplicado: false,
      exibirDialogEnvioDocumentos: false,
      exibirDialogAlertaDocumentosDuplicados: false,
      exibirDialogAlertaDocumentos: false,
      empresaRamoTransporte: false,
      requisicao: [],
      docsEmTela: [],
      arrayTransporte: [],
      observacao: '',
      validaArrayFixo: [],
      validaArrayDinamico: [],
      validaArrayTransporte: [],
      outrasDocs: {
        documento: 'Outras documentações',
        nome: 'outrasDocs',
        extensoes: 'png,jpg,pdf',
        obrigatorio: false,
        tamanhoKb: '8000',
        tooltip: '<pre>Espaço reservado para outras documentações que o setor responsável pela análise pode vir a exigir. Entenda que é importante que a decisão seja tomada com o máximo de informações.</pre>'
      },
      exibirUpload: '',
      categoriaCliente: '',
      documentoSelecionado: {},
      documentoFichaCadastralPF: '/FichaCadastralPF.pdf',
      documentoFichaCadastralPJ: '/FichaCadastralPJ.pdf',
      documentoFichaAnaliseCredito: '/FichaCadastralPJ.pdf',
      exibirDetalhes: true
    }),
    computed: {
      ...mapGetters({
        cliente: 'getMeusClientes',
        fase: 'getFaseAnaliseMeusClientes',
        arquivosVuex: 'getDocumentosMeusClientes'
      }),
      breadcrumb () {
        return [
          { name: 'Meus Clientes', to: 'MeusClientes' },
          { name: 'Jornada da Cota', to: 'JornadaDaCota' }
        ]
      },
      exibeEmpresaTrasporte () {
        return this.categoriaSelecionada.valor !== '' && !this.isPf()
      },
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      camposValidados () {
        let validacao = true
        if (this.validaArrayFixo.length > 0) {
          validacao = validacao && this.validaArrayFixo.every(doc => {
            return doc.validado
          })
        }
        if (this.validaArrayDinamico.length > 0) {
          validacao = validacao && this.validaArrayDinamico.every(doc => doc.validado)
        }
        if (this.validaArrayTransporte.length > 0) {
          validacao = validacao && this.validaArrayTransporte.every(doc => doc.validado)
        }
        if (this.categoriaSelecionada.valor === '') {
          validacao = false
        }
        return validacao
      }
    },
    watch: {
      categoriaSelecionada (value, oldValue) {
        if (value.valor !== oldValue.valor) {
          this.empresaRamoTransporte = false
          this.getListaDocumentos()
        }
      },
      async empresaRamoTransporte (newValue, oldValue) {
        if (newValue !== oldValue && this.empresaRamoTransporte) {
          this.getListaDocumentos()
        } else {
          let filesTransporte = this.arquivosVuex.filter(doc => {
            return doc.campo === 'frotaCrlv' || doc.campo === 'atpv'
          })
          filesTransporte.forEach(doc => this.removeDocumentos(doc.id))
        }
      }
    },
    mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
      }, 6000)
    },
    methods: {
      getCategoriaSelecionada (value) {
        this.categoriaSelecionada = {
          nome: value.nome,
          valor: value.valor
        }
      },
      validaCampos (arrayValidacao, { nomeCampo, preenchido }) {
        let campo = arrayValidacao.find(doc => {
          return doc.nome === nomeCampo
        })
        campo.validado = preenchido
        this.setArrayValidacao()
      },
      setArrayValidacao () {
        this.validaArrayFixo = []
        this.validaArrayTransporte = []
        this.validaArrayDinamico = []
        this.validaArrayFixo.push(...this.validacaoDocsFixos.filter(v => v.obrigatorio && !this.isPf() === v.isPj))
        if (this.empresaRamoTransporte) {
          this.validaArrayTransporte.push(...this.arrayTransporte.filter(v => v.obrigatorio))
        }
        this.validaArrayDinamico.push(...this.docsEmTela.filter(v => v.obrigatorio))
        this.validarArray(this.validaArrayFixo)
        this.validarArray(this.validaArrayTransporte)
        this.validarArray(this.validaArrayDinamico)
      },
      validarArray (arrayValidado) {
        arrayValidado = arrayValidado.map((doc) => {
          return {
            ...doc,
            validado: 'validado' in doc ? doc.validado : false
          }
        })
      },
      goBack () {
        this.$router.push({
          name: 'JornadaDaCota'
        })
      },
      retornaTela () {
        this.exibirUpload = false
      },
      uploadDocumento (tipoDoc) {
        this.exibirUpload = tipoDoc
        this.documentoSelecionado = this.tiposDocumentos.find(doc => doc.nome === this.exibirUpload)
      },
      uploadOutrasDocs () {
        this.exibirUpload = true
        this.documentoSelecionado = this.tiposDocumentos.find(doc => doc.nome === this.exibirUpload)
      },
      uploadFichaCadastral () {
        this.exibirUpload = 'fichaCadastralPJ'
        this.documentoSelecionado = this.tiposDocumentos.find(doc => doc.nome === this.exibirUpload)
      },
      uploadSimples () {
        this.exibirUpload = 'simplesBalancete'
        this.documentoSelecionado = this.tiposDocumentos.find(doc => doc.nome === this.exibirUpload)
      },
      enviar (nomeArquivo) {
        if (Array.isArray(this.$refs[nomeArquivo])) {
          this.$refs[nomeArquivo][0].uploadArquivo()
          this.$refs[nomeArquivo][0].nomeCampo = nomeArquivo
        } else {
          this.$refs[nomeArquivo].uploadArquivo()
        }
      },
      verificaEnviar () {
        return this.arquivosVuex.length > 0 && (this.observacao.length > 0 && this.observacao.length <= 500)
      },
      async getListaDocumentos () {
        const idEmpresa = this.cliente.idEmpresa
        const idUnidade = session.get('unidade').idUnidade

        let url = `/jornada-cota/documentos?categoria=${this.categoriaSelecionada.valor}&pessoaFisica=${this.isPf()}&transporte=false&idEmpresa=${idEmpresa}&idUnidade=${idUnidade}`

        if (this.empresaRamoTransporte) {
          url = `/jornada-cota/documentos?categoria=${this.categoriaSelecionada.valor}&pessoaFisica=${this.isPf()}&transporte=${this.empresaRamoTransporte}&idEmpresa=${idEmpresa}&idUnidade=${idUnidade}`
        }

        await http.get(url).then((response) => {
          if (!this.empresaRamoTransporte) {
            this.docsEmTela = []
            this.setDocumentos = []
            this.requisicao = response.data
            this.organizaDocumentos()
            this.setArrayValidacao()
          } else {
            this.arrayTransporte = response.data
            this.organizaDocTransporte()
            this.setArrayValidacao()
          }
        }).catch(erro => {
          if (!/(cancelRequest)/.test(erro.message)) {
            this.$toast.error(Mensagens.ERRO)
          }
        })
      },
      isPf () {
        return this.cliente?.cpfCnpj?.replaceAll('.', '').replace('-', '').length === 11
      },
      organizaDocTransporte () {
        this.arrayTransporte = this.arrayTransporte.filter(
          object1 => !this.requisicao.some(
            object2 => object1.documento === object2.documento
          ))
        this.arrayTransporte.forEach((doc, index) => {
          if (doc.documento === 'ATPV do caminhão a ser adquirido, se usado') {
            doc.nome = 'atpv'
          }
          if (doc.documento === 'Relação de Frota ou Certificado de Veículos CRLV') {
            doc.nome = 'frotaCrlv'
          }
        })
      },
      organizaDocumentos () {
        this.requisicao.forEach((doc, index) => {
          if (doc.documento.toLowerCase() === 'documento de identificação') {
            doc.nome = 'identificacao'
            this.docsEmTela.push(doc)
          }
          if (doc.documento.toLowerCase() === 'selfie') {
            doc.nome = 'selfie'
            this.docsEmTela.push(doc)
          }
          if (doc.documento.toLowerCase() === 'comprovante de residência') {
            doc.nome = 'comprovanteResidencia'
            this.docsEmTela.push(doc)
          }
          if (doc.documento.toLowerCase() === 'documento de renda') {
            doc.nome = 'comprovanteRenda'
            doc.nomeExibicao = 'Comprovante de renda'
            this.docsEmTela.push(doc)
          }
          if (doc.documento === 'Ficha cadastral pessoa fisica') {
            doc.nome = 'fichaCadastralPF'
            this.docsEmTela.push(doc)
          }
          if (doc.documento === 'Análise de Crédito') {
            doc.nome = 'analiseCredito'
            this.docsEmTela.push(doc)
          }
        })
      },
      verificarDuplicados () {
        const documentos = {}
        let duplicadosEncontrados = false
        this.arquivosVuex.forEach(arquivo => {
          const chave = `${arquivo.documento}-${arquivo.nome}`
          if (documentos[chave]) {
            this.documentoDuplicado = true
            duplicadosEncontrados = true
          } else {
            documentos[chave] = true
          }
        })

        if (!duplicadosEncontrados) {
          this.documentoDuplicado = false
        }
      },
      exibirEnvioDocumentos () {
        this.verificarDuplicados()
        if (this.documentoDuplicado) {
          this.exibirDialogAlertaDocumentosDuplicados = true
          return
        }
        if (this.verificaEnviar()) {
          this.exibirDialogEnvioDocumentos = true
        } else {
          this.exibirDialogAlertaDocumentos = true
        }
      },
      exibirOcultar () {
        this.exibirDetalhes = !this.exibirDetalhes
      }
    }
  }

</script>

<style scoped>
.imglegenda {
  height: 11px;
  padding-right: 5px;
}

.tabelaLegenda {
  border-collapse:collapse;
  width: 333px;
  /* margin-top: -35px; */
}
.linhaLegenda{
  width: 100%;
}

button.btn-fechar
{
  font-family: Inter;
  text-transform: none;
  text-align: center;
  font-style: normal;
  border-radius: 8px;
  line-height: 21px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0!important;
  box-shadow: none !important;
  height: 48px!important;
  border: 1px solid #CFD0D1 !important;
  color: #191E26 !important;
  background-color: white !important;
}

button.btn-fechar:active
{
  border: 1px solid var(--primariaDarken) !important;
  color: var(--primariaDarken) !important;
  background-color: #FFFFFF!important;
}

button.btn-fechar::before
{
  background-color: transparent !important;
}

#coluna-limpar-filtros:hover {
  button {
    border: 1px solid var(--primariaDarken)!important;
    color: var(--primariaDarken)!important;
  }
  svg path {
    fill: var(--primariaDarken)!important;
  }
}

.divObservacao {
  width: 100%;
  padding-right: 0px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: -40px;
}

.observacoes {
  border-radius: 8px;
  border: 2px solid #E7E7E8 !important;
  background: #FFF;
  width: 100%;
}
.padding-duas-colunas {
  padding-left: 19px;
}
.padding-top-documentos {
  padding-top: 21px;
}
.CardTitulo{
  font-weight: bold;
  color: var(--black);
}

.actionButton {
  font-family: Inter;
  height: 48px !important;
  text-transform: none;
  text-align: center;
  font-style: normal;
  border-radius: 8px;
  line-height: 21px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0!important;
  box-shadow: none !important;
  width: 100%;
  color: var(--white) !important;
}

.divCategoria {
  padding-left: 14px;
  padding-right: -22px;
  margin-right: -40px;
}
.divDocumentos {
  padding-left: 8px;
  /* padding-right: -30px; */
  margin-right: -22px;
  margin-top: -25px;
}

.actionButton:hover, .actionButton:active, .actionButton:focus {
  background-color: var(--primariaDarken) !important;
}

.divBotoes {
  margin-top: 5px;
  margin-left: -5px;
  margin-right: -10px;
}

.Card{
  border: 1px solid #737B7D;
  border-radius: 6px;
  margin-left: 18px;
}

.textoInformativoText {
    font-family: Inter;
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    color: #5E6267;
    font-weight: 400;
    font-size: 16px;
}

.textoInformativoTituloText {
    font-family: Inter;
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    color: #191E26;
    font-weight: 600;
    font-weight: 700;
    font-size: 12px;
}

.CardInformacoesCliente {
  margin-left: -8px;
  margin-right: -15px;
  padding-right: 20px;
}
.CardAtualizacoesCliente {
  margin-left: 0px;
  padding-left: 20px;
  margin-right: -5px;
  max-width: 50% !important;
}
.CardSituacaoDaCota {
  margin-left: -8px;
  margin-right: -8px;
}
.breadcrumb {
  margin-left: -5px;
}
.label-detalhe {
  margin-top: 12px;
  margin-bottom: -10px;
  margin-left: 8px;
  font-family: Inter;
  font-size: 26px;
  font-weight: 800;
  line-height: 33.8px;
  text-align: left;
  color: #191E26;
}
.exibir-ocultar {
  margin-top: 20px;
  margin-left: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--primary);
}
.texto-sucesso {
  color: rgba(63, 212, 123, 1);
}
.texto-informativo {
  color: #90A5EC;
}
.texto-pendencia {
  color: #9349F4;
}
.texto-atencao {
  color: #EE4784;
}
.texto-legenda {
  font-family: Inter;
  border-bottom: none;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

@media screen and (min-width: 960px){
  button.btn-fechar:hover
  {
    border: 1px solid var(--primariaDarken)!important;
    color: var(--primariaDarken)!important;
    background-color: #FFFFFF!important;
  }
}

@media screen and (max-width: 960px){
  .divCategoria {
    padding-left: 0px;
    margin-left: -17px;
    margin-right: -40px;
  }
  .divDocumentos {
    margin-left: -26px;
    margin-right: -25px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .container {
    margin: 0;
    padding: 0;
  }
  .divObservacao {
    width: auto;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 22px;
    margin-left: -12px;
    margin-right: -35px;
  }

  .observacoes {

    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
   /* .divBotaoCancelar {
    margin-left: -14px;
  } */
  .divBotoes {
    width: auto;
    padding-top: -10px;
    margin-left: -30px;
    margin-right: -18px;
  }
  .breadcrumb {
    margin-left: -23px;
  }
  .breadcrumbSkeleton {
    margin-left: 15px;
  }
  .CardInformacoesCliente {
    margin-left: -12px;
  }
  .Card{
    border: 1px solid #737B7D;
    border-radius: 6px;
    margin-left: 2px;
  }

  .CardTitulo{
    font-weight: bold;
    color: var(--black);
    padding-top: 15px;
  }
  .cardClientes {
    margin-left: -20px;
    margin-right: -20px;
  }
  .exibir-ocultar-mobile {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: right;
    color: var(--primary);
    margin-right: -10px;
    margin-bottom: 5px;
  }
  .label-detalhe {
    margin-left: -6px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    text-align: left;
    color: #191E26;
  }

}
@media screen and (min-width: 700px) and (max-width: 960px){
  .tabelaLegenda {
      margin-top: -5px;
      min-width: 103%;
    }
    .linhaLegenda{
      width: 50%;
    }
}

@media screen and (min-width: 700px) and (max-width: 960px){
    .tabelaLegenda {
      margin-top: -5px;
      width: 50%;
    }
    .linhaLegenda{
      width: 50%;
    }
}

</style>
