<template>
  <v-skeleton-loader
      v-if="loadingSkeleton"
      type="image"
      width="100%"
      :height="isMobile ? 50 : 50"
      style="margin-bottom: 10px!important;"
      class="ml-0 mt-1"
  />
  <div v-else :class="{'inputbox': makeUpload,
                       'inputboxCorreto': correctUpload,
                       'inputboxErrada': errorUpload,
                       'gridTemplateOfComponent': true}">
    <v-row class="mt-0 ml-0 w-100 bordas">
      <v-col cols="10" class="gridTemplateUploadFile">
        <!-- Titulo -->
        <div class="CardTitulo text-center documentTitleGridItem">
          <span class="texto-titulo">{{ documento.nomeExibicao ? documento.nomeExibicao : documento.documento }}</span> <span style="color: red" v-if="documento.obrigatorio">*</span>
          <v-tooltip top :max-width="isDeviceMobile ? '94vw' : '400px'" color="#9EA1A4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense color="#9EA1A4" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
              <!-- Tamanho dos icones tooltip - https://v2.vuetifyjs.com/en/components/icons/#usage -->
            </template>
            <span class="disable_pre">
              <span v-html="formatTooltip(documento.tooltip)"></span>
            </span>
          </v-tooltip>
        </div>
        <div class="documentDescriptionGridItem" v-if="makeUpload">
          <span class="texto-descritivo">{{ documento.extensoes.toUpperCase() }}  | Arquivos de até {{ documento.tamanhoKb/1000 }}mb </span>
        </div>
      </v-col>
      <v-col cols="2" :class="isDeviceMobile ? 'd-flex justify-end pr-1 pt-1' : 'd-flex justify-end pr-3 pt-4'">
        <svg class="cor-estilo-desenho" width="50" height="50" :viewBox="isDeviceMobile ? '9 -10 31 42' : '0 0 41 41'" fill="none" xmlns="http://www.w3.org/2000/svg" @click="uploadArquivo()">
          <g clip-path="url(#clip0_4297_5255)">
            <path d="M11.9174 22.6011C12.0622 22.6011 12.201 22.6594 12.3033 22.7632C12.4057 22.8669 12.4632 23.0077 12.4632 23.1545V25.9214C12.4632 26.215 12.5782 26.4965 12.7829 26.704C12.9877 26.9116 13.2653 27.0282 13.5548 27.0282H26.6543C26.9438 27.0282 27.2215 26.9116 27.4262 26.704C27.6309 26.4965 27.7459 26.215 27.7459 25.9214V23.1545C27.7459 23.0077 27.8034 22.8669 27.9058 22.7632C28.0082 22.6594 28.147 22.6011 28.2917 22.6011C28.4365 22.6011 28.5753 22.6594 28.6777 22.7632C28.78 22.8669 28.8376 23.0077 28.8376 23.1545V25.9214C28.8376 26.5085 28.6075 27.0715 28.1981 27.4867C27.7887 27.9018 27.2333 28.135 26.6543 28.135H13.5548C12.9758 28.135 12.4205 27.9018 12.011 27.4867C11.6016 27.0715 11.3716 26.5085 11.3716 25.9214V23.1545C11.3716 23.0077 11.4291 22.8669 11.5314 22.7632C11.6338 22.6594 11.7726 22.6011 11.9174 22.6011Z" />
            <path d="M19.7181 12.7363C19.7688 12.6847 19.8291 12.6438 19.8954 12.6159C19.9617 12.588 20.0328 12.5737 20.1046 12.5737C20.1764 12.5737 20.2475 12.588 20.3138 12.6159C20.3801 12.6438 20.4403 12.6847 20.491 12.7363L23.7659 16.0566C23.8684 16.1605 23.9259 16.3015 23.9259 16.4484C23.9259 16.5954 23.8684 16.7363 23.7659 16.8402C23.6634 16.9441 23.5244 17.0025 23.3794 17.0025C23.2345 17.0025 23.0955 16.9441 22.993 16.8402L20.6504 14.4639V24.1959C20.6504 24.3427 20.5929 24.4834 20.4905 24.5872C20.3882 24.691 20.2493 24.7493 20.1046 24.7493C19.9598 24.7493 19.821 24.691 19.7186 24.5872C19.6163 24.4834 19.5588 24.3427 19.5588 24.1959V14.4639L17.2161 16.8402C17.1136 16.9441 16.9746 17.0025 16.8297 17.0025C16.6848 17.0025 16.5458 16.9441 16.4433 16.8402C16.3408 16.7363 16.2832 16.5954 16.2832 16.4484C16.2832 16.3015 16.3408 16.1605 16.4433 16.0566L19.7181 12.7363Z" />
          </g>
          <defs>
            <clipPath id="clip0_4297_5255">
              <rect width="17.466" height="17.7086" fill="red" transform="translate(11.3716 11.6439)" />
            </clipPath>
          </defs>
        </svg>
      </v-col>
    </v-row>
    <!-- INFORMAÇÕES DE URQUIVO APOS UPLOAD -->
    <v-row class="mt-0 ml-0 w-100 bordas-subitem" style="background-color: #E7E7E8" v-show="arquivos.length">
      <v-col cols="12" class="gridTemplateUploadFile">
        <!-- Icone -->
        <div class="iconGridItem" @click.stop>
        </div>
        <!-- Titulo -->
        <div v-show="arquivos.length">
          <div v-for="(f, index) in arquivos" :key="index" class="file-info">
            <div class="file-details">
              <div class="file-name">{{ f.nome }}</div>
              <div class="file-actions">
                <v-icon size="31" class="icons"
                        v-show="correctUpload && !f.duplicado && !f.tipoDiferente && !f.tamanhoSuperior"
                        @click.stop="remove(index)">
                  mdi-trash-can-outline
                </v-icon>

                <v-icon size="31" class="icons" color="#CE181E" v-if="f.duplicado || f.tipoDiferente || f.tamanhoSuperior" @click.stop="atualiza(index)">mdi-autorenew</v-icon>
                <v-icon size="31" class="icons trash-error" color="#CE181E" v-if="f.duplicado || f.tipoDiferente || f.tamanhoSuperior" @click.stop="remove(index)">mdi-trash-can-outline</v-icon>
              </div>
            </div>
            <div class="file-size">{{ formatFileSize(f.tamanho) }}</div>
            <div class="progress-info">
              <div class="progress-wrapper">
                <v-progress-linear v-model="progress" height="10" :color="!f.duplicado && !f.tipoDiferente && !f.tamanhoSuperior ? '#3FD47B' : '#CE181E'"></v-progress-linear>
              </div>
              <div :class="!f.duplicado && !f.tipoDiferente && !f.tamanhoSuperior ? 'progress-value porcentagem' : 'progress-value-error porcentagem'">{{ !f.duplicado && !f.tipoDiferente && !f.tamanhoSuperior ? progress + '%' : 'erro' }}</div>
            </div>
            <div class="progress-info" v-if="f.duplicado">
              <span class="progress-value-error">{{ msg }}</span>
            </div>
            <div class="progress-info" v-if="f.tipoDiferente">
              <span class="progress-value-error">{{ msg }}</span>
            </div>
            <div class="progress-info" v-if="f.tamanhoSuperior">
              <span class="progress-value-error">{{ msg }}</span>
            </div>
            <div class="progress-info pt-5" v-show="corrigirUpload && (f.duplicado || f.tipoDiferente || f.tamanhoSuperior)">
              <span class="progress-value-error">{{ msgCorrecao }}</span>
            </div>
          </div>
        </div>
        <div class="documentDescriptionGridItem" v-if="makeUpload">
          <v-file-input v-model="file"
                        small-chips
                        show-size
                        prepend-icon=""
                        @click="checkForDuplicateFile"
                        @change="inputChanged"
                        ref="field"
                        style="display: none"
          >
          </v-file-input>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { isArray } from 'lodash'
  import { mapGetters, mapActions } from 'vuex'
  import http from '../plugins/http'
  import { Mensagens } from '../plugins/constants'
  import { events } from '@/plugins/events'

  const TAMANHO_MAXIMO_ARQUIVO = 8
  const TAMANHO_MAXIMO_ARQUIVO_EM_MB = TAMANHO_MAXIMO_ARQUIVO * 1024 * 1024
  const TIPO_ARQUIVO_PERMITIDO = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']
  export default {
    name: 'CardFileUpload',
    props: {
      documento: {
      },
      msgArquivoGrande: {
        type: String,
        default: `Arquivo muito grande. O arquivo deve conter no máximo ${TAMANHO_MAXIMO_ARQUIVO}MB`
      },
      msgExtensaoInvalida: {
        type: String,
        default: `Arquivo com extensão não permitida. São permitidos somente arquivos com as extensões: ${TIPO_ARQUIVO_PERMITIDO.join(', ')}`
      },
      msgArquivoRepetito: {
        type: String,
        default: `Erro ao enviar o arquivo. O Documento é duplicado. Envie novamente!`
      },
      msgCorrigir: {
        type: String,
        default: `Atenção! Troque ou exclua este documento antes de subir novo arquivo.`
      }
    },
    data () {
      return {
        loadingSkeleton: false,
        file: {},
        arquivos: [],
        makeUpload: true,
        correctUpload: false,
        errorUpload: false,
        progress: 0,
        msg: '',
        msgCorrecao: '',
        corrigirUpload: false
      }
    },
    computed: {
      ...mapGetters({
        infocliente: 'getMeusClientes',
        arquivosVuex: 'getDocumentosMeusClientes'
      })
    },
    watch: {
      file (value, oldValue) {
        this.$emit('value', value.length)
      },
      arquivosVuex (valor) {
        this.arquivos = this.arquivosVuex.filter(arquivo => arquivo.campo === this.documento.nome)
      },
      arquivos (valor) {
        const hasFiles = valor.length > 0
        if (hasFiles) {
          // this.makeUpload = false
          this.correctUpload = true
          if (valor[0].obrigatorio) {
            this.$emit('campoPreenchido', { nomeCampo: this.documento.nome, preenchido: hasFiles })
          }
        } else {
          this.makeUpload = true
          this.correctUpload = false
        }
      }
    },
    mounted () {
      this.arquivos = this.arquivosVuex.filter(arquivo => arquivo.campo === this.documento.nome)
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
      }, 6000)
    },
    methods: {
      ...mapActions(['removeDocumentos', 'addDocumentos']),
      remove (index) {
        this.removeDocumentos(this.arquivos[index].id)
        this.$emit('campoPreenchido', { nomeCampo: this.documento.nome, preenchido: false })
        if (this.arquivos.length >= 1) {
          this.errorUpload = false
        }
      },
      checkForDuplicateFile () {
        if (this.file && this.arquivos.some(objeto => objeto.nome === this.file.name)) {
          this.file = null
        }
      },
      async atualiza (index) {
        await this.uploadArquivo()
        await this.remove(index)
        this.errorUpload = false
        this.corrigirUpload = false
      },
      async inputChanged () {
        const novoArquivo = {
          id: this.file.id,
          url: this.file.url,
          datacriacao: this.file.datacriacao,
          documento: this.file.documento,
          nome: this.file.name,
          campo: this.file.campo,
          obrigatorio: true,
          duplicado: false,
          tipoDiferente: false,
          tamanhoSuperior: false
        }

        if (!this.isTamanhoArquivoValido(this.file)) {
          this.limparArquivo()
          this.msg = this.msgArquivoGrande
          this.errorUpload = true
          novoArquivo.tamanhoSuperior = true
          this.arquivos.push(novoArquivo)
        } else if (!this.isExtensaoArquivoValido(this.file)) {
          this.limparArquivo()
          this.msg = this.msgExtensaoInvalida
          this.errorUpload = true
          novoArquivo.tipoDiferente = true
          this.arquivos.push(novoArquivo)
        }

        // const arquivoDuplicado = this.arquivos.find(objeto => objeto.nome === this.file.name)
        const arquivoDuplicado = this.arquivosVuex.find(objeto => objeto.nome === this.file.name)

        if (arquivoDuplicado) {
          this.limparArquivo()
          novoArquivo.duplicado = true
          this.errorUpload = true
          this.msg = this.msgArquivoRepetito
          this.arquivos.push(novoArquivo)
        }

        // this.arquivos.push(novoArquivo)
        if (this.isTamanhoArquivoValido(this.file) && this.isExtensaoArquivoValido(this.file)) {
          const dados = new FormData()

          dados.append('file', this.file)
          dados.append('idCota', this.infocliente.idCota)
          dados.append('tipo', this.documento.nome)

          events.$emit('changeTituloLoading', 'Anexando...')

          await http.post(`jornada-cota/arquivo`, dados)
            .then((response) => {
              let req = response.data
              req.documento = this.documento.documento
              req.nome = this.file.name
              req.campo = this.documento.nome
              req.obrigatorio = this.documento.obrigatorio
              req.tamanho = this.file.size
              this.addDocumentos(req)
              events.$emit('changeTituloLoading', 'Carregando...')
              this.$emit('campoPreenchido', { nomeCampo: this.documento.nome, preenchido: this.file != null })
            })
            .catch(erro => {
              events.$emit('changeTituloLoading', 'Carregando...')
              if (!/(cancelRequest)/.test(erro.message)) {
                this.$toast.error(Mensagens.ERRO)
              }
            })
        }
      },

      isTamanhoArquivoValido (file) {
        return file && file.size <= TAMANHO_MAXIMO_ARQUIVO_EM_MB
      },
      isExtensaoArquivoValido (file) {
        let arquivo_permitido = (this.accept) && isArray(this.accept) ? this.accept : TIPO_ARQUIVO_PERMITIDO
        return file && arquivo_permitido.includes(file.type)
      },
      limparArquivo () {
        this.file = null
        this.key++
      },
      async uploadArquivo () {
        this.corrigirUpload = false
        return new Promise((resolve) => {
          this.$refs.field.$refs.input.click()
          this.fakeLoad()
          setTimeout(() => {
            resolve()
          }, 2000)
        })
      },
      corrigirArquivo () {
        this.msgCorrecao = this.msgCorrigir
        this.corrigirUpload = true
      },
      formatFileSize (sizeInBytes) {
        if (sizeInBytes === undefined || sizeInBytes === null) {
          return '0 KB'
        }
        const kbSize = sizeInBytes / 1024
        if (kbSize < 1024) {
          return kbSize.toFixed(0) + ' KB'
        } else {
          const mbSize = kbSize / 1024
          return mbSize.toFixed(2) + ' MB'
        }
      },

      fakeLoad () {
        let valorInicial = 0
        let valorFinal = 100
        let numEtapas = 100
        let incremento = (valorFinal - valorInicial) / numEtapas

        const aumentarProgresso = () => {
          this.progress += incremento
          if (this.progress < valorFinal) {
            setTimeout(aumentarProgresso, 200) // Atraso de 200 milissegundos
          } else {
            this.progress = valorFinal
          }
        }
        aumentarProgresso()
      },
      formatTooltip (html) {
        return html.replace(/<pre>/g, '<span>').replace(/<\/pre>/g, '</span>')
      }
    }
  }
</script>

<style scoped>
/* estilo personalizado para os chips */
.v-file-input__selection__chip {
  margin: 10px;
}
.cor-estilo-desenho {
  fill: #5E6267 !important;
}
.cor-estilo-desenho:hover {
  fill: var(--primariaDarken) !important;
}
.Chip{
  background-color: white !important;
  color: red;
  border: 1px solid red;
  transition: background-color 0.3s, color 0.3s;
}
.Chip:hover {
  background-color: red !important;
  color: white;
  font-weight: bold;
}
.CardTitulo{
  font-weight: bold;
  color: var(--black);
}
.inputbox{
  border: #eceaea solid 1px;
  box-shadow: #eceaea;
  border-radius: 8px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  background-color: #ffffff;
}
.inputboxCorreto{
  /* border: green solid 1px; */
  border-radius: 8px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}
.inputboxErrada{
  /* border: red solid 1px; */
  border-radius: 8px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}
.file-info {
  margin-bottom: 10px; /* Espaçamento entre os arquivos */
}

.file-details {
  display: flex;
  align-items: center;
}

.file-actions {
  margin-left: auto;
}
.file-size {
  margin-top: -5px;
  text-align: left;
  color: #5E6267;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.progress-info {
  display: flex;
  align-items: center;
}

.progress-wrapper {
  flex-grow: 1;
}
.v-progress-linear {
  border-radius: 15px;
}

.progress-value {
  margin-left: 10px;
  color: #3FD47B;
}
.progress-value-error {
  margin-left: 10px;
  color: #CE181E;
}

.texto-descritivo {
  color: #5E6267;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.texto-titulo {
  color:  #191E26;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

.gridTemplateUploadFile{
  display: grid;
  grid-template-areas:
  "icon document_title"
  "icon document_description";
  grid-template-columns: 1.1rem 1fr;
  grid-template-rows: auto 1fr;
  gap:5px;
  margin-left: -19px;
  margin-right: 19px;
}
.bordas {
  /* box-shadow: 0.5px 0.5px 5px 0px rgb(192, 192, 192); */
  border-radius: 8px;
  border: 1px solid #E7E7E8 !important;
}
.bordas-subitem {
  /* box-shadow: 0.5px 0.5px 5px 0px rgb(192, 192, 192); */
  border-radius: 0px 0px 8px 8px;
  margin-top: 9px !important;
}
.iconGridItem{
  grid-area:icon;
}
.documentTitleGridItem{
  grid-area: document_title;
  text-align: start !important;
  line-break: normal;
  word-wrap: normal;
  word-break: keep-all;
}
.documentDescriptionGridItem{
  grid-area: document_description;
  text-align: start !important;
}

.file-name {
  color: #5E6267;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.disable_pre{
  white-space: normal;
}
pre {
  white-space: normal;
}

.icons {
  margin-right: -10px;
}
.trash-error {
  padding-left: 38px;
}
.porcentagem {
  margin-right: -12px;
  Font:  Inter;
  Weight: 400;
  Size: 12px;
}
.svg {
  width: 80px;
}
.v-tooltip__content {
  opacity: 1 !important;
}
@media screen and (max-width: 960px){
.documentTitleGridItem{
  font-size: 15px;
  /* min-width: 250px; */
  white-space: nowrap;
  padding-right: 0px;
  margin-right: 0px;
}
.documentDescriptionGridItem{
  font-size: 15px;
}
.file-name {
  color: #5E6267;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.texto-titulo {
  color:  #191E26;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}
.icons {
  margin-right: -0.9rem;
}
.porcentagem {
  margin-right: -1.2rem;
  Font:  Inter;
  Weight: 400;
  Size: 12px;
}
}
</style>
