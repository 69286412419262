<template>
  <v-layout
      v-resize="onResize"
      column
      class="datatable w-100"
      :class="{'hide-separator': !separator}"
  >
    <div :class="[isDeviceMobile ? 'px-0' : 'px-0 py-0']" style="padding-left: 0px !important">
      <v-data-table
          :hide-default-footer="pagination.totalItems > 0 ? false : true"
          :items-per-page.sync="itemsPerPage"
          :footer-props="footerProps"
          :style="cssProps"
          :mobile-breakpoint="0"
          :page.sync="pagination.page"
          :headers="headersExibicao"
          :header-props="{ sortIcon: 'pc-ordenar' }"
          :items="items"
          :item-key="itemKey"
          :options.sync="options"
          :server-items-length.sync="pagination.totalItems"
          v-bind="$attrs"
          :show-select="showSelect"
          :single-select="singleSelect"
          v-model="selected"
          :multi-sort="false"
          :must-sort="true"
          calculate-widths
          :sort-by="pagination.sortBy"
          :no-data-text="textoSemDados"
      >
        <template v-slot:item="{ item, isExpanded, expand }">
          <span class="dataAtualizacaoLabel">{{ getDaysText(item.dataAtualizacao) }}</span>
          <tr v-if="!isMobile"
              v-show="!esconderLinha || esconderLinha(item)"
              @click="isDeviceMobile ? expand(!isExpanded) : null"
          >
            <td v-if="showSelect">
              <Checkbox
                  :color="corCheckbox"
                  :value="selected.includes(item)"
                  @change="toggleCheckbox(item, $event)"
                  hide-details
              />
            </td>
            <template style="max-width: 0px !important;">
              <slot :name="`item.email`" v-bind="item" />
            </template>
            <template v-for="header in headersExibicao">
              <slot v-if="header.visible" :name="`item.${header.value}`" v-bind="item" />
            </template>
            <td v-if="podeExpandirLinha">
              <v-icon
                  :class="(isExpanded) ? 'mdi-rotate-180' : 'mdi-rotate-90'"
                  x-small
                  color="#03142E"
              >
                pc-seta
              </v-icon>
            </td>
          </tr>
          <tr
              v-else
              class="expand-color"
              v-show="true"
          >
            <slot name="items-mobile" v-bind="item"></slot>
          </tr>
        </template>

        <template v-for="(header, index) in headersExibicao" v-slot:[`header.${header.value}`]>
          <span v-show="header.visible" v-bind:key="index">{{ header.text }}</span>
        </template>
        <template v-slot:expanded-item="{ item }">
          <tr
              class="expand-color" v-if="!isMobile"
              v-show="!esconderLinha || esconderLinha(item)"
          >
            <td class="expand-color" :colspan="headersExibicao.length">
              <v-row class="my-1">
                <template v-for="(header, index) in headersReais">
                  <v-col v-if="!header.visible" cols="12" md="4" lg="4" xl="3" v-bind:key="index">
                    <v-row no-gutters>
                      <span class="expand-header mr-5" v-bind:key="index">{{ header.text }}</span>
                      <slot :name="`item.${header.value}`" v-bind="item" />
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </td>
          </tr>
          <tr
              v-else
              class="expand-color"
              v-show="!esconderLinha || esconderLinha(item)"
          >
            <td class="expand-color" :colspan="headersExibicao.length">
              <v-row class="my-1">
                <template v-for="(header, index) in headersReais">
                  <v-col v-if="!header.visible && header.value !=='status'" cols="6" v-bind:key="index">
                    <div class="expand-header" v-bind:key="index">{{ header.value }}</div>
                    <slot :name="`item.${header.value}`" v-bind="item" />
                  </v-col>
                  <v-col v-else-if="header.value ==='status'" cols="12" v-bind:key="index" class="py-0">
                    <div class="expand-header" v-bind:key="index">{{ header.text }}</div>
                    <div><slot :name="`item.${header.value}`" v-bind="item" /></div>
                  </v-col>
                </template>
              </v-row>
            </td>
          </tr>
        </template>
        <template v-slot:top>
          <div
              v-if="titulo"
              class="cabecalho py-2"
          >
            <span class="ml-4">{{ titulo }}</span>
            <v-chip
                v-if="corCheckbox"
                class="ml-2"
                small
                label
                :color="corCheckbox"
            />
            <v-icon class="arrow-header"
                    :class="{ 'down': isExpandido, 'up': !isExpandido }"
                    small
                    v-if="isMobile"
                    @click="toggleHeader">
              pc-seta
            </v-icon>
          </div>
          <!-- <div v-show="isExpandido" class="container-ordenar">
            <v-icon small color="inputcolor">pc-ordenar</v-icon>
            <label
                class="btn-ordenar"
                @click="abrirDialogOrdenacao"
            >ordenar</label>
          </div> -->
          <div v-show="isExpandido" class="text-center">
            <v-bottom-sheet
                v-if="isMobile"
                width="2000"
                v-model="dialogOrdenacaoMobile"
            >
              <v-list>
                <v-subheader class="subtitulo-dialog">Ordenar por:</v-subheader>
                <v-divider />
                <v-list-item-group
                    v-model="sortBy"
                    class="teste"
                    color="primaria"
                >
                  <v-list-item
                      v-for="(header, i) in getHeadersDisponiveisOrdenacao()"
                      v-bind:key="i">
                    <v-list-item-content>
                      <v-list-item-title class="center" v-text="header.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-divider />
                <v-col cols="12">
                  <Button
                      block
                      class="primaria"
                      @click="aplicarOrdenacaoMobile">
                    Aplicar
                  </Button>
                </v-col>
                <v-col cols="12">
                  <Button
                      block
                      color="primaria"
                      outlined
                      @click="dialogOrdenacaoMobile = false">
                    Cancelar
                  </Button>
                </v-col>
              </v-list>
            </v-bottom-sheet>
            <v-dialog :scrollable="true" content-class="app-content" v-model="dialogOrdenacaoDesktop" persistent>
              <v-card>
                <v-card-title>
                  <slot name="title">Ordenar por:</slot>
                  <v-btn icon class="ml-auto" @click="dialogOrdenacaoDesktop = false">
                    <v-icon dark size="12">pc-fechar</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text id="content">
                  <SelectField
                      label="Coluna"
                      :items="getHeadersDisponiveisOrdenacao()"
                      item-text="text"
                      item-value="value"
                      v-model="sortBy" />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-col cols="6">
                    <Button
                        small
                        block
                        color="primaria"
                        outlined
                        @click="dialogOrdenacaoDesktop = false">
                      Cancelar
                    </Button>
                  </v-col>
                  <v-col cols="6">
                    <Button
                        small
                        block
                        class="primaria"
                        @click="aplicarOrdenacaoDesktop">
                      Aplicar
                    </Button>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
        <template v-if="$scopedSlots.totalizador && !isMobile" v-slot:[`body.append`]="{ headers }">
          <tr class="totalizador">
            <td v-for="(header, i) in headers" :key="i">
              <div class="bold valor-total" v-if="i === (showSelect ? 1 : 0)">
                {{ (selected.length > 0) ? 'Totais da seleção' : 'Totais' }}
              </div>
              <div
                  @click="clickRodape && header.clicavel && clickRodape()"
                  :class="(header.clicavel ? 'click' : '')"
                  v-if="header.visible && header.totalizar && items.length > 0">
                {{ somaColuna(header.value) }} {{ header.complemento }}
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:footer="{ footer }">
          {{ recalcularColunas() }}
          <slot v-if="isExpandido" name="footer" v-bind="footer" />
        </template>
        <!-- <template v-slot:footer>
          <v-data-footer :options="options">
            <v-select
                v-model="itemsPerPage"
                :items="perpageOptions"
                label="Items per page"
                @input="updateItemsPerPage"
            ></v-select>
          </v-data-footer>
        </template> -->
      </v-data-table>
    </div>
  </v-layout>
</template>

<script>
  import Vue from 'vue'
  import { get } from 'lodash'
  import { Mensagens } from '@/plugins/constants'
  import { money } from '@/plugins/formatters'
  import { isObject } from '@/plugins/helpers'

  const COLUNA_EXPANDIR_LINHA = 'expandir'

  export default {
    props: {
      url: String,
      params: Object,
      headers: Array,
      reload: Boolean,
      itemKey: String,
      titulo: String,
      filter: Object,
      showSelect: Boolean,
      silent: {
        type: Boolean,
        default: false
      },
      singleSelect: {
        type: Boolean,
        default: false
      },
      itensExternos: Array,
      esconderLinha: Function,
      elevation: {
        type: String,
        default: '0'
      },
      clickRodape: Function,
      corCheckbox: {
        type: String,
        default: 'secundaria'
      },
      separator: {
        type: Boolean,
        default: true
      },
      showPagination: {
        type: Boolean,
        default: true
      },
      paginationSubtitle: {
        type: String,
        default: undefined
      },
      textoSemDados: {
        type: String,
        default: 'Não há dados disponíveis'
      },
      qtdColunasMobile: {
        type: Number,
        default: 3
      }
    },
    // default 10 registros por tela (valor -1 em perpageOptions, habilita a opção "Todos")
    data: () => ({
      itemsPerPage: 10,
      perpageOptions: [5, 10, 15, 20, 25, -1],
      visibilityDataTable: 'visible',
      isExpandido: true,
      isMobile: false,
      pagination: {
        page: 1,
        totalItems: 0,
        size: 10,
        pageCount: 0,
        sortby: [],
        sortDesc: []
      },
      options: {},
      items: [],
      itemsTotal: [],
      expanded: [],
      selected: [],
      headersReais: [],
      headersExibicao: [],
      podeExpandirLinha: false,
      dialogOrdenacaoMobile: false,
      dialogOrdenacaoDesktop: false,
      sortBy: '',
      sortDesc: false
    }),
    computed: {
      footerProps () {
        return {
          'items-per-page-options': this.perpageOptions,
          'disable-items-per-page': false,
          'items-per-page-text': this.isMobile ? '' : 'Linhas por página:'
        }
      },
      isMobile () {
        return window.innerWidth <= 960
      },
      cssProps () {
        return {
          '--show-data-table': this.visibilityDataTable,
          '--cor-checkbox': this.corCheckbox
        }
      }
    },
    watch: {
      options: {
        handler () {
          this.reloadTable()
        },
        deep: true
      },
      reload () {
        this.reloadTable()
        this.selected = []
      },
      url () {
        this.pagination.page = 1
      },
      filter () {
        this.reloadTable()
      },
      selected () {
        this.$emit('input', this.selected)
      },
      itensExternos (value) {
        if (value.length !== this.items.length) {
          this.reloadTable()
        }
      },
      sortby (value) {
        if (!value) {
          this.sortDesc = false
        }
      },
      isMobile (value) {
        const COLUNA_EXPANDIR = 0
        if (value) {
          this.podeExpandirLinha = true
          const COLUNAS_MOBILE = this.qtdColunasMobile

          this.headersReais.forEach((header, key) => {
            header.visible = (key < COLUNAS_MOBILE || key === COLUNA_EXPANDIR)
          })
        } else {
          this.podeExpandirLinha = false
          this.headersReais.forEach((header) => {
            header.visible = true
          })
        }
        this.atualizaHeaders()
      },
      '$store.state.drawer': function () {
        this.atualizaHeaders()
      },
      podeExpandirLinha (valor) {
        if (valor) {
          this.headersReais.push({
            text: '',
            value: COLUNA_EXPANDIR_LINHA,
            sortable: false,
            visible: true,
            width: 44
          })
        }
      }
    },
    created () {
      this.headersReais = this.headers.map(item => {
        item.visible = true
        return item
      }, [])
    },
    mounted () {
      const paginaExterna = get(this.params, 'pagination', {})
      this.pagination = { ...this.pagination, ...paginaExterna }
    },
    methods: {
      // updateItemsPerPage (newItemsPerPage) {
      //   this.itemsPerPage = newItemsPerPage
      // },
      toggleCheckbox (item, valor) {
        if (this.singleSelect) {
          this.selected = [item]

          if (valor) {
            this.$emit('singleSelected', item)
          } else {
            this.$emit('singleSelected', null)
          }
        } else {
          const id = this.selected.indexOf(item)

          if (id === -1) {
            this.selected.push(item)
          } else {
            this.selected.splice(id, 1)
          }
        }
      },
      reloadTable () {
        const { sortBy, sortDesc } = this.options
        if (!this.isRequisicaoServidor()) {
          Vue.nextTick(() => {
            const items = this.itensExternos ?? []
            this.pagination.totalItems = items.length
            this.$emit('onLoad', items)
            this.items = items
            if (sortBy.length === 1 && sortDesc.length === 1) {
              this.items = items.sort((a, b) => {
                const sortA = this.getObjectValue(a, sortBy[0])
                const sortB = this.getObjectValue(b, sortBy[0])

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1
                  if (sortA > sortB) return -1
                  return 0
                } else {
                  if (sortA < sortB) return -1
                  if (sortA > sortB) return 1
                  return 0
                }
              })
            }
          })
        } else {
          if (sortBy.length > 0 && sortDesc.length > 0) {
            let ordenacao = ''
            for (var i = 0; i < sortBy.length; i++) {
              const coluna = sortBy[i].split('-')
              for (var k = 0; k < coluna.length; k++) {
                let column = coluna[k]
                let sort = sortDesc[i] ? 'desc' : 'asc'
                ordenacao += `${column} ${sort},`
              }
            }
            this.pagination = { ...this.pagination, ordenacao: ordenacao }
          }

          Vue.nextTick(() => {
            this.$http.cancel(this._uid)
            return this.$http.get(this.url, {
              requestId: this._uid,
              params: { ...this.filter, administrador: this.params.administrador },
              headers: { ...this.pagination, page: this.pagination.page },
              options: { silent: this.silent }
            }).then((res) => {
              this.pagination.pageCount = Math.ceil(res.data.length)
              this.pagination.totalItems = res.data.length // aqui a quantidade de registros

              this.items = this.getResults(res)

              this.$emit('onLoad', this.items)

              this.onResize()
            }).catch(erro => {
              if (!/(cancelRequest)/.test(erro.message)) {
                this.$toast.error(Mensagens.ERRO)
              }
            })
          })
        }
      },
      getResults (res) {
        if (this.itemsPerPage === -1) {
          this.itemsPerPage = this.pagination.totalItems
        }
        let pageAtual = this.pagination.page
        let result = []
        let totalPage = this.pagination.pageCount
        let count = (pageAtual * this.itemsPerPage) - this.itemsPerPage
        let delimiter = count + this.itemsPerPage
        this.itemsTotal = res.data

        if (pageAtual <= totalPage) {
          for (let i = count; i < delimiter; i++) {
            if (this.itemsTotal[count]) {
              result.push(this.itemsTotal[count])
              count++
            }
          }
        }
        return result
      },
      getObjectValue (obj, key) {
        if (key.includes('.')) {
          const keys = key.split('.')
          return isObject(obj[keys[0]]) ? obj[keys[0]][keys[1]] : obj[key]
        }
        return obj[key]
      },
      toggleHeader () {
        this.visibilityDataTable = this.visibilityDataTable !== 'visible' ? 'visible' : 'collapse'
        this.isExpandido = !this.isExpandido
      },
      isRequisicaoServidor () {
        return (this.url)
      },
      somaColuna (coluna) {
        const totalColuna = this.items.reduce((total, item) => {
          if (this.selected.length > 0) {
            if (this.selected.some(e => JSON.stringify(e) === JSON.stringify(item))) {
              return total + (item[coluna] || 0)
            }
            return total
          } else {
            return total + (item[coluna] || 0)
          }
        }, 0)

        return money(totalColuna)
      },
      recalcularColunas () {
        if (window.innerWidth < 960) {
          return
        }
        /*
          Basicamente setamos um tamanho fixo para cada coluna, e calculamos se essa coluna + o tamanho do meu
          é maior que o tamanho da tela, se for, deixamos ela invisível, se não foi deixamos visível
        */
        this.atribuirTamanhoColuna()

        const tamanhoMenu = this.$store.state.drawer ? 256 : 0
        const tamanhoPadding = 40

        for (let i = this.headersReais.length - 1; i >= 0; i--) {
          if (this.headersReais[i].visible && this.headersReais[i].value !== COLUNA_EXPANDIR_LINHA) {
            const tamanhoComponentes = this.headersReais[i].tamanho + tamanhoMenu + tamanhoPadding
            if (tamanhoComponentes > window.innerWidth) {
              this.headersReais[i].visible = false
              continue
            } else {
              break
            }
          }
        }

        for (let i = 0; i <= this.headersReais.length - 1; i++) {
          if (!this.headersReais[i].visible && this.headersReais[i].value !== COLUNA_EXPANDIR_LINHA) {
            const tamanhoComponentes = this.headersReais[i].tamanho + tamanhoMenu + tamanhoPadding
            if (tamanhoComponentes < window.innerWidth + 10) {
              this.headersReais[i].visible = true
              continue
            } else {
              break
            }
          }
        }

        const expandir = this.headersReais.some(header => !header.visible)
        this.podeExpandirLinha = expandir

        if (!this.tamanho) {
          this.atualizaHeaders()
          this.tamanho = -1
        }
      },
      atribuirTamanhoColuna () {
        let totalColunas = this.showSelect ? 100 : 44 // Se existir checkbox, inicia com valor 100,se não 44 que é o tamanho do icone de expansão
        if (this.headersReais.length > 0) {
          for (let i = 0; i <= this.headersReais.length - 1; i++) {
            const width = (this.headersReais[i].width) ?? 150
            this.headersReais[i].width = width
            totalColunas += width
            this.headersReais[i].tamanho = totalColunas
          }
        }
      },
      onResize () {
        for (let i = 0; i < this.headersReais.length; i++) {
          if (this.headersReais[i].hasOwnProperty('value')) {
            if (this.headersReais[i].value === COLUNA_EXPANDIR_LINHA && !this.podeExpandirLinha) {
              this.headersReais.splice(i, 1)
            }
          }
        }

        this.isMobile = window.innerWidth < 960
        this.recalcularColunas()
        this.atualizaHeaders()
      },
      podeOrdenar (header) {
        return !header.hasOwnProperty('sortable') || header.sortable
      },
      aplicarOrdenacaoMobile () {
        this.dialogOrdenacaoMobile = false

        const header = this.headersReais[this.sortBy]
        if (header) {
          this.options.sortBy = [header.value]
          this.options.sortDesc = [this.sortDesc]
          this.toggleOrder()
          this.reloadTable()
        }
      },
      aplicarOrdenacaoDesktop () {
        this.dialogOrdenacaoDesktop = false

        if (this.sortBy) {
          this.options.sortBy = [this.sortBy]
          this.options.sortDesc = [this.sortDesc]
          this.toggleOrder()
          this.reloadTable()
        }
      },
      toggleOrder () {
        this.sortDesc = !this.sortDesc
      },
      // abrirDialogOrdenacao () {
      //   this.dialogOrdenacaoMobile = this.isMobile
      //   this.dialogOrdenacaoDesktop = !this.isMobile
      // },
      getHeadersDisponiveisOrdenacao () {
        return this.headersReais.filter(item => {
          if (this.podeOrdenar(item)) {
            return item
          }
        })
      },
      atualizaHeaders () {
        this.headersExibicao = this.headersReais.filter(item => item.visible)
      },
      getColorPorStatus (status, sucesso, informativo, pendencia, atencao) {
        const cotaAprovada = ['pagamento_bem_total', 'cota_alocada', 'analise_credito_automatica_aprovada', 'analise_credito_manual_aprovada', 'aviso_contemplacao_enviado', 'pagamento_lance_realizado']
        const cotaAndamento = ['pagamento_bem_parcial', 'analise_credito_manual_iniciada', 'analise_credito_manual_reanalise_iniciada', 'cota_contemplada_lance']
        const cotaPendencia = ['analise_credito_automatica_reprovada', 'analise_credito_manual_aguardando_cliente']
        const cotaReprovado = ['pagamento_lance_aguardando_cliente', 'analise_credito_manual_reprovada', 'cota_desclassificada']

        if (cotaAprovada.includes(status)) {
          return sucesso
        }
        if (cotaAndamento.includes(status)) {
          return informativo
        }
        if (cotaPendencia.includes(status)) {
          return pendencia
        }
        if (cotaReprovado.includes(status)) {
          return atencao
        }

        return sucesso
      },
      getTdColorStyle (status) {
        let sucesso = '#3fd47b'
        let informativo = '#90a5ec'
        let pendencia = '#9349f4'
        let atencao = '#ee4784'

        return this.getColorPorStatus(status, sucesso, informativo, pendencia, atencao)
      },
      tdStyles (status) {
        return 'border-left: 4pt solid ' + this.getTdColorStyle(status)
      },
      getDaysText (dataAtualizacao) {
        const today = new Date()
        const date_split = dataAtualizacao.split('/')
        const dia = parseInt(date_split[0], 10)
        const mes = parseInt(date_split[1] - 1, 10)
        const ano = parseInt(date_split[2], 10)
        const data_atualizacao = new Date(ano, mes, dia)
        const umDiaEmMilissegundos = 24 * 60 * 60 * 1000
        const dateUpdated = Math.floor((today - data_atualizacao) / umDiaEmMilissegundos)

        if (dateUpdated === 0) {
          return 'Hoje'
        }

        if (dateUpdated === 1) {
          return `Há 1 dia`
        }

        if (dateUpdated > 1 && dateUpdated <= 30) {
          return `Há ${dateUpdated} dias`
        }

        return data_atualizacao.toLocaleDateString()
      }
    }
  }
</script>

<style>
    .borderTdMobile{
      border: 1px solid #E7E7E8!important;
      border-radius: 8px;
      font-size: 10px!important;
    }

    .tabelaMeusClientes thead th {
      background-color: white;
      &:first-child { border-radius: 8px 0 0 0; border-color: #737B7D; }
      &:last-child { border-radius: 0 8px 0 0; border-color: #737B7D }
    }

    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table {
      background-color: #f7f7f7;
      border-radius: 8px;
    }

    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table > thead > tr {
        border-radius: 8px!important;
        border: thin solid rgba(0, 0, 0, 0.12)!important;

    }
    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table {
        border-spacing: 0 0.5rem;
    }
    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table > tbody > tr  {
      height: 80px!important;
    }
    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-size: 14px !important;
        color: var(--lightblack) !important;
        font-weight: 600 !important;
    }

    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 12px !important;
      color: #737B7D !important;
      padding-left: 0px!important;
      padding-right: 0px!important;
    }

    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table > thead > tr {
        background: white!important;
    }
    #pagination > ul > li > button.v-pagination__navigation--disabled > span{
      color:#CFD0D1!important;
    }
    .theme--light.v-data-table .v-data-footer {
      border-top: none;
      background-color: #f7f7f7;
    }
    .v-data-footer {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 0.75rem;
      padding: 0 8px;
      border: none;
    }
    .v-application--is-ltr .v-data-footer__select {
      margin-left: auto;
      margin-right: 14px;
      color: #191E26;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: right;
    }
    .v-application--is-ltr .v-data-footer__pagination {
      color: #191E26;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
    }
    .v-data-footer__select .v-select__selections .v-select__selection--comma {
      color: #191E26;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
    }

</style>

<style scoped>
    .datatable {
        position: relative;
        z-index: 1;
    }

    .carregando {
        position: absolute;
        bottom: 15px;
        z-index: 3;
    }

    .pagination {
      float: right;
    }

    .pagination-subtitle {
      float: right;
      line-height: 45px;
    }

    .bold {
      font-weight: bold;
    }

    .totalizador {
      background-color: var(--lightgray);
    }

    .cabecalho {
      background-color: var(--lightgray);
      color: var(--primaria);
      font-size: 16px;
      font-weight: bold;
    }

    .arrow-header {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 15px;
      color: #373F41;
    }

    .down {
      transform: rotate(0deg);
    }
    .up {
      transform: rotate(180deg);
    }

    .btn-ordenar {
      cursor: pointer;
      text-decoration: underline;
      color: var(--inputcolor);
    }

    .container-ordenar {
      position: relative;
      margin-right: 10px;
      text-align: right;
    }

    .subtitulo-dialog {
      color: var(--labelcolor);
      font-size: 16px;
      font-weight: bold;
      justify-content: center;
    }

    .center {
      text-align: center;
    }

    .valor-total {
      color: var(--black);
    }

    .click {
      text-decoration: underline;
      cursor: pointer;
    }

    .dataAtualizacaoLabel{
      color:  #5E6267;
      font-family: 'Inter',sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .expand-header {
      font-size: 16px !important;
      color: #373F41 !important;
      font-weight: bold;
    }

    .expand-color {
      background-color: #F4F5F4;
    }
</style>

<style lang="scss">
    .v-simple-checkbox {
        .mdi-minus-box, .mdi-checkbox-marked {
            color: var(--cor-checkbox);
        }
    }

    .datatable {
        .v-data-table-header {
          visibility: var(--show-data-table);
        }

        .v-data-table__wrapper {
          overflow-x: hidden;
        }

        tbody {
          visibility: var(--show-data-table);
        }

        .v-input--checkbox {
            &.v-input--selection-controls,
            .v-input--selection-controls__input {
                margin-top: 0;
                margin-right: 0;
                padding-top: 0.5px;
            }
        }

        &.hide-separator tbody tr {
            &:hover {
                background: none !important;
            }

            &:not(:last-child) {
                border-bottom: none;
                border-top: none;
            }
        }

        // remove o efeito de hover
        .v-input--selection-controls__input {
            .v-input--selection-controls__ripple:before {
                display: none;
            }
        }

        .v-ripple__animation {
            background: var(--accent);
        }
    }

    .v-pagination__navigation {
      width: auto;

      .v-icon {
        font-size: 16px;
        font-style: normal;
        padding: 10px;
        color: var(--labelcolor);
      }
    }
    .tabelaMeusClientes .v-data-table > .v-data-table__wrapper > table > thead > tr > th > i {
      font-size: 12px !important;
    }
</style>
