<template>
  <div
      v-if="visible"
      :style="{background: `rgba(255, 255, 255, ${backgroundOpacity})`}"
      class="d-flex flex-column align-center justify-center outer-container"
  >
    <Spinner />
    <p class="mt-6 text--primaria text-h5"> {{ texto }} </p>
  </div>
</template>

<script>

  import Spinner from './Spinner'
  import { events } from '@/plugins/events'

  export default {
    name: 'Loading',

    components: {
      Spinner
    },
    props: {
      visible: Boolean,
      backgroundOpacity: {
        type: Number,
        default: 0.5
      }
    },
    data () {
      return {
        texto: 'Carregando...'
      }
    },
    created () {
      events.$on('changeTituloLoading', (options) => this.alteraTexto(options))
    },
    methods: {
      alteraTexto (texto) {
        this.texto = texto
      }
    }
  }
</script>

<style scoped>
  .outer-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
</style>
