var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Exceção de cadastro")]),_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"mb-5 mb-md-0",attrs:{"no-gutters":""}},[_c('div',{staticClass:"horizontal"},[_c('div',[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##','##.###.###/####-##']),expression:"['###.###.###-##','##.###.###/####-##']"}],attrs:{"disabled":this.$route.params.login ? true : false,"label":"CPF/CNPJ*","rules":[this.$rules.required, this.$rules.documento]},model:{value:(_vm.cpfCnpj),callback:function ($$v) {_vm.cpfCnpj=$$v},expression:"cpfCnpj"}})],1),_c('div',[_c('Button',{staticClass:"primaria",attrs:{"id":"ButtonSalvar","small":"","disabled":_vm.disableSalvar},on:{"click":_vm.confimarInclusao}},[_vm._v(" SALVAR ")])],1)])])],1)],1),_c('v-card',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.registro,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Registro:")]),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[(item.comissionados)?_c('div',{staticClass:"info-cont"},[_c('p',[_vm._v("Comissionados Afetados:")]),_vm._l((item.comissionados),function(i){return _c('div',{key:i,staticClass:"chip-vermelho"},[_c('div',[_vm._v(_vm._s(i))])])})],2):_vm._e(),(item.sublogins)?_c('div',{staticClass:"info-cont"},[_c('p',[_vm._v("Sublogins Afetados:")]),_vm._l((item.sublogins),function(i){return _c('div',{key:i,staticClass:"chip-vermelho"},[_c('div',[_vm._v(_vm._s(i))])])})],2):_vm._e()])])]}},{key:"item.documento",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.mascaraDocumento(String(item.documento)))+" ")])]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(String(item.nome).toLocaleUpperCase())+" ")])]}},{key:"item.ex_colaborador",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'chip-vermelho': item.ex_colaborador, 'chip-verde': !item.ex_colaborador}},[_vm._v(" "+_vm._s(item.ex_colaborador ? 'Sim' : 'Não')+" ")])]}},{key:"item.executado",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'chip-verde': item.executado, 'chip-vermelho': !item.executado}},[_vm._v(" "+_vm._s(item.executado ? 'Sim' : 'Não')+" ")])]}},{key:"item.login_executor",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.login_executor ? item.login_executor : '--')+" ")])]}},{key:"item.sublogins",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.sublogins !== null ? item.sublogins.length : '0')+" ")])]}},{key:"item.comissionados",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.comissionados !== null ? item.comissionados.length : '0')+" ")])]}},{key:"item.data_execucao",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.data_execucao !== "" ? _vm.ajustarData(item.data_execucao) : "--")+" ")])]}}])})]],2),_c('v-card-actions',[_c('div',{staticClass:"font-weight-bold text-button",staticStyle:{"cursor":"pointer"}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"black"}},[_vm._v(" pc-seta-esquerda ")]),_c('span',[_vm._v("VOLTAR")])],1),_c('v-spacer')],1)],1),_c('DialogIncluirExcecao',{attrs:{"documento":_vm.cpfCnpj,"exibir":_vm.exibirDialogConfirmacao},on:{"exibir":function (exibir) { return _vm.exibirDialogConfirmacao = exibir; },"reload":_vm.reload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }