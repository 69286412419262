<template>
  <v-dialog persistent width="400" v-bind="$attrs" v-on="$listeners" v-model="exibir">
    <v-card class="pb-3">
      <v-card-title>
        <span>Relatório de processamento</span>
        <v-btn icon class="ml-auto" @click="fechar">
          <v-icon size="10" color="primaria">pc-fechar</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-actions>
        <v-form>
          <v-row>
            <v-col class="mt-4 flex-column">
              <div class="container">
                <div class="container-data-hora">
                  <p>Período inicial:</p>
                  <input class="date-input" type="datetime-local" v-model="relatorio.data_inicial">
                </div>
                <div class="container-data-hora">
                  <p>Período final:</p>
                  <input class="date-input" type="datetime-local" v-model="relatorio.data_final">
                </div>
              </div>
              <h4>Incluir no relatório:</h4>
              <label v-for="(status_, index) in status_processamento" :key="index">
                <div class="checkbox">
                  <input type="checkbox" v-model="relatorio.status_incluidos" :value="status_">
                  {{ status_ }}
                </div>
              </label>
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <Button outlined block @click="fechar">Cancelar</Button>
          </v-col>
          <v-col cols="6">
            <Button block @click="gerar">Gerar</Button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container-data-hora{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    margin: 5px;
    background-color: #f8f8f8d2;
    border-radius: 5px;
    padding: 10px;
}
.date-input{
    width: 250px;
    height: 35px;
    background-color: #e7e7e7d2;
    color: red;
    size: 30px;
    border-radius: 5px;
    border-color: red;
}
.checkbox {
  display: inline-block;
  margin: 5px;
  box-sizing: border-box;
  text-align: center;
  background-color: rgb(223, 223, 223);
  padding: 5px;
  border-radius: 10px;
}

</style>

<script>
  import Button from '../Button.vue'
  import { obterRelatorio } from '@/service/notasFiscais'
  import * as Xlsx from 'xlsx'

  export default {
    name: 'DialogRelatorioNotas',
    components: { Button },
    props: {
      exibir: {
        type: Boolean,
        default: false
      },
      idNota: Number
    },
    data () {
      return {
        status_processamento: ['Enviada', 'Recusada', 'Recebida', 'Conferida', 'Pendente'],
        relatorio: {
          data_inicial: '',
          data_final: '',
          status_incluidos: []
        },
        cabecalhos: ['ID nota', 'ID Movimento', 'Nome Parceiro', 'CPF / CNPJ', 'Valor', 'Valor Bruto', 'Imposto', 'Data Ultima alteração', 'Data Envio', 'Data Vencimento', 'STATUS', 'Mensagem Recusa', 'Unidade', 'Coligada', 'Centro de Custo', 'Download'],
        dados: []
      }
    },
    watch: {
      exibir () {
        this.relatorio = {
          data_inicial: '',
          data_final: '',
          status_incluidos: []
        }
        this.dados = []
      }
    },
    methods: {
      fechar () {
        this.$emit('exibir', false)
      },
      async gerar () {
        this.dados = []
        try {
          var relatorio = await obterRelatorio(this.relatorio)
          this.$toast.success('Relatório gerado com sucesso!')
        } catch (erro) {
          const filterError = /(400)/.test(erro.message)
          const empyResult = /(404)/.test(erro.message)
          if (filterError) return this.$toast.error('Parâmetros de busca inválidos, por favor revise os dados e tente novamente.')
          if (empyResult) return this.$toast.warning('Nenhuma nota foi encontrada para os parâmetros de pesquisa informados.')
        }
        if (relatorio.length === 0) return
        this.dados.push(this.cabecalhos)
        const dataRows = relatorio.map(item => [
          item.id_nota_fiscal_parceiro,
          item.id_movimento_nota_fiscal_parceiro,
          item.nota_fiscal_parceiro_nome,
          item.nota_fiscal_parceiro_cpfCnpj,
          item.nota_fiscal_parceiro_valor_liquido,
          item.nota_fiscal_parceiro_valor_bruto,
          item.nota_fiscal_parceiro_imposto,
          item.nota_fiscal_parceiro_ultima_alteracao,
          item.nota_fiscal_parceiro_envio,
          item.nota_fiscal_parceiro_data_vencimento,
          item.nota_fiscal_parceiro_status,
          item.nota_fiscal_parceiro_comentario_recusa,
          item.nota_fiscal_parceiro_unidade,
          item.nota_fiscal_parceiro_coligada,
          item.nota_fiscal_parceiro_ccusto,
          item.nota_fiscal_parceiro_link_nota
        ].map(value => (value === null || value === '' || value === undefined) ? '-' : value))
        this.dados.push(...dataRows)
        this.criarXLS()
      },
      async criarXLS () {
        var plan = Xlsx.utils.book_new()
        var pasta = Xlsx.utils.aoa_to_sheet(this.dados)
        Xlsx.utils.book_append_sheet(plan, pasta, 'Resultado da consulta', true)
        Xlsx.writeFile(plan, 'relatorio.xlsx')
      }
    }
  }

</script>
