<template>
  <v-dialog
      v-if="exibir"
      v-model="exibir"
      scrollable
      width="80vw"
      persistent
  >
    <v-card>
      <v-card-title>
        <span class="titulo-conferencia">Conferência da nota fiscal</span>
        <v-btn
            icon
            class="ml-auto"
            @click="close"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="mt-4">
        <v-row>
          <v-col class="d-flex flex-column" cols="12" md="2">
            <label class="texto-header">CPF/CNPJ:</label>
            <label class="valor-header">{{ notaFiscal.cpfCnpj }}</label>
          </v-col>
          <v-col class="d-flex flex-column" cols="12" md="2">
            <label class="texto-header">Nome parceiro:</label>
            <label class="valor-header">{{ notaFiscal.nome }}</label>
          </v-col>
          <v-col class="d-flex flex-column" cols="12" md="2">
            <label class="texto-header">Vencimento:</label>
            <label class="valor-header">{{ getVencimento() }}</label>
          </v-col>
          <v-col class="d-flex flex-column" cols="12" md="2">
            <label class="texto-header">Valor bruto:</label>
            <label class="valor-header">R$ {{ convertToMoney(notaFiscal.valorBruto) }}</label>
          </v-col>
          <v-col class="d-flex flex-column" cols="12" md="2">
            <label class="texto-header">Imposto de renda:</label>
            <label class="valor-header">R$ {{ convertToMoney(notaFiscal.valorImposto) }}</label>
          </v-col>
          <v-col class="d-flex flex-column" cols="12" md="2">
            <label class="texto-header">Valor líquido:</label>
            <label class="valor-header">R$ {{ convertToMoney(notaFiscal.valorLiquido) }}</label>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text align="center" class="mt-5 mb-5">
        <div v-if="carregando">
          <Spinner />
          <h3>O download começará em instantes...</h3>
        </div>
        <iframe
            v-else-if="isValid"
            id="pdf"
            :src="url"
        />
        <h3 v-else>O arquivo não esta disponivel no momento, por favor aguarde e tente novamente.</h3>
      </v-card-text>
      <v-card-actions class="py-5">
        <v-row>
          <v-col>
            <label class="texto-header">Arquivo anexado em:</label>
            <label class="valor-header">{{ dataEnvio }}</label>
          </v-col>
          <v-col>
            <label class="texto-header">Arquivo anexado por:</label>
            <label class="valor-header">{{ notaFiscal.anexadoPor }}</label>
          </v-col>
          <v-col>
            <Button
                id="ButtonDialogRecusarNF"
                outlined
                block
                @click="exibirDialogRecusar = true"
            >
              Recusar
            </Button>
          </v-col>
          <v-col>
            <Button
                id="ButtonDialogConfirmar"
                block
                :disabled="notaFiscal.status !== 'Recebida'"
                @click="exibirDialogAceitar = true"
            >
              Confirmar
            </Button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <DialogAceitarNota :exibir="exibirDialogAceitar"
                       :id-nota="notaFiscal.idNotaFiscalParceiro"
                       :id-arquivo="notaFiscal.idArquivo"
                       @exibir="exibir => exibirDialogAceitar = exibir"
                       @proxima="proxima" />

    <DialogRecusarNota :exibir="exibirDialogRecusar"
                       :id-nota="notaFiscal.idNotaFiscalParceiro"
                       :id-arquivo="notaFiscal.idArquivo"
                       @exibir="exibir => exibirDialogRecusar = exibir"
                       @proxima="proxima" />
  </v-dialog>
</template>

<script>
  import { money, toDateBR } from '@/plugins/formatters'
  import { buscarNomeArquivoPublico } from '@/service/arquivo'
  import DialogAceitarNota from '@/components/dialog/DialogAceitarNota'
  import DialogRecusarNota from '@/components/dialog/DialogRecusarNota'
  import Spinner from '@/components/Spinner'

  export default {
    name: 'DialogConferenciaNotaFiscal',
    components: {
      DialogAceitarNota,
      DialogRecusarNota,
      Spinner
    },
    props: {
      exibir: Boolean,
      notaFiscal: Object
    },
    data: () => ({
      dialog: false,
      exibirDialogAceitar: false,
      exibirDialogRecusar: false,
      dataEnvio: '',
      url: undefined,
      carregando: true
    }),
    watch: {
      async notaFiscal (nota) {
        if (this.notaFiscal) {
          this.url = undefined
          this.dataEnvio = this.getDataEnvio()
          if (this.exibir) {
            let nome = await buscarNomeArquivoPublico(nota.idArquivo)
            let uri = process.env.VUE_APP_WS_ROOT + nome
            this.verifyPDF(uri)
            if (this.isValid === true) {
              this.url = uri + '#toolbar=0'
            }
          }
        } else {
          this.exibirDialogAceitar = false
          this.exibirDialogRecusar = false
          this.close()
        }
      }
    },
    methods: {
      convertToMoney (value) {
        return money(value)
      },
      getVencimento () {
        return toDateBR(this.notaFiscal.vencimento?.split('T')[0])
      },
      getDataEnvio () {
        return toDateBR(this.notaFiscal.dataEnvio?.split(' ')[0])
      },
      close () {
        this.$emit('exibir', false)
      },
      proxima () {
        this.close()
        this.$emit('proxima', this.notaFiscal.idNotaFiscalParceiro)
      },
      verifyPDF (url) {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            this.loading = false
            if (xhr.status === 200) {
              this.isValid = true
              this.carregando = false
            }
          }
        }
        xhr.open('HEAD', url)
        xhr.send()
      }
    }
  }
</script>

<style scoped>
  #pdf {
    width: 100%;
    height: 30rem;
  }

  .titulo-conferencia {
    font-size: 16px;
    font-weight: 600;
  }

  .valor-header {
    margin-left: 2px;
    font-size: 12px;
    font-weight: bold;
    color: var(--black);
  }

  .texto-header {
    font-size: 10px;
    color: var(--black);
  }
</style>
