<template>
  <v-container fluid>
    <PopUpConfirmacao
        :exibir="exibirConfirmacaoExclusao"
        titulo="Atenção"
        mensagem="Está certo que deseja excluir este registro? Esta ação não poderá ser desfeita."
        @confirmou="confirmouExclusao"
        @cancelou="cancelou" />
    <h1 class="titulo">Data base para mapa de comissões</h1>
    <v-row class="mb-2" v-if="idDataBase">
      <v-col cols="12" sm="12" md="6">
        <span class="subtitulo">Criado em <b>{{ comissao.dataHoraCriacao }}</b> por <b>{{ comissao.loginUsuario }}</b></span>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <span class="subtitulo">Editado em <b>{{ comissao.dataHoraEdicao }}</b> por <b>{{ comissao.loginUsuarioEditor }}</b></span>
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        <v-form
            v-model="formularioValido"
            ref="form"
        >
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <TextField
                  id="TextFieldReferência"
                  label="Dia/Mês/Ano de referência"
                  v-model="comissao.dataReferencia"
                  v-uppercase
                  v-mask="['##/##/####']"
                  :rules="[$rules.required, $rules.data_valida, $rules.anoMaiorQue2015]"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <TextField
                  id="TextFieldDescricao"
                  label="Descrição"
                  v-model="comissao.descricao"
                  :rules="[$rules.required]"
                  maxlength="50"
                  v-uppercase
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <TextField
                  id="TextFieldEncerramento"
                  label="Encerramento"
                  v-model="comissao.dataEncerramento"
                  v-mask="['##/##/####']"
                  :rules="[$rules.required, $rules.data_valida]"
                  maxlength="10"
                  v-uppercase
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <TextField
                  id="TextFieldDataVendaInicial"
                  label="Data da venda inicial"
                  v-model="comissao.dataVendaInicial"
                  v-mask="['##/##/####']"
                  :rules="[$rules.required, $rules.data_valida]"
                  maxlength="10"
                  v-uppercase
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <TextField
                  id="TextFieldDataVendaFinal"
                  label="Data da venda final"
                  v-model="comissao.dataVendaFinal"
                  v-mask="['##/##/####']"
                  :rules="[$rules.required, $rules.data_valida]"
                  maxlength="10"
                  v-uppercase
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <TextField
                  id="TextFieldDataAlocacaoInicial"
                  label="Data de alocação inicial"
                  v-model="comissao.dataAlocacaoInicial"
                  v-mask="['##/##/####']"
                  :rules="[$rules.required, $rules.data_valida]"
                  maxlength="10"
                  v-uppercase
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <TextField
                  id="TextFieldDataAlocacaoFinal"
                  label="Data de alocação final"
                  v-model="comissao.dataAlocacaoFinal"
                  v-mask="['##/##/####']"
                  :rules="[$rules.required, $rules.data_valida]"
                  maxlength="10"
                  v-uppercase
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div
            class="font-weight-bold text-button"
            style="cursor: pointer"
            @click="cancelar"
        >
          <v-icon
              color="black"
              class="pr-2"
          >
            pc-seta-esquerda
          </v-icon>
          <span>VOLTAR</span>
        </div>
        <v-spacer></v-spacer>
        <Button
            v-if="idDataBase"
            id="ButtonCancelar"
            small
            outlined
            @click="exibirConfirmacaoExclusao = true"
        >
          Excluir
        </Button>
        <Button
            id="ButtonSalvar"
            small
            class="primaria"
            @click="salvar()"
        >
          SALVAR
        </Button>
      </v-card-actions>
    </v-card>
    <v-dialog
        v-model="exibirDialogConfirmacao"
        persistent
    >
      <v-card>
        <v-card-title>
          <v-btn
              icon
              @click="exibirDialogConfirmacao = false"
              class="ml-auto">
            <v-icon
                dark
                size="20"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>Já existe {{ totalRegistrosReferencia }} registro(s) para a referência {{ comissao.dataReferencia }}. Está certo que deseja continuar?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
              color="primaria"
              outlined
              small
              @click="exibirDialogConfirmacao = false"
          >
            cancelar
          </Button>
          <Button
              color="primaria"
              small
              @click="() => idDataBase ? editar() : criar()"
          >
            confirmar
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import PopUpConfirmacao from '@/components/PopUpConfirmacao'
  import { cadastrar, editar, consulta, excluir, consultarQuantidadeRegitros } from '@/service/mapa-comissao'
  import { pushRoute, formatarDataMesAnoDiaISO } from '@/plugins/helpers'
  import { Mensagens } from '@/plugins/constants'
  import moment from 'moment'

  export default {
    name: 'GestorCadastroMapaComissao',
    components: {
      PopUpConfirmacao
    },
    data: () => ({
      idDataBase: undefined,
      comissao: {
        dataReferencia: '',
        descricao: '',
        dataEncerramento: '',
        dataVendaInicial: '',
        dataVendaFinal: '',
        dataAlocacaoInicial: '',
        dataAlocacaoFinal: '',
        dataHoraCriacao: '',
        dataHoraEdicao: '',
        loginUsuario: '',
        loginUsuarioEditor: ''
      },
      exibirConfirmacaoExclusao: false,
      formularioValido: false,
      exibirDialogConfirmacao: false,
      totalRegistrosReferencia: 0
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    created () {
      this.idDataBase = this.$route.params.id

      if (this.idDataBase) {
        this.carregarDataBase(this.idDataBase)
      } else {
        const dataAtual = new Date()
        this.comissao.dataReferencia = moment(dataAtual).format('DD/MM/YYYY')
      }
    },
    methods: {
      async confirmouExclusao () {
        try {
          await excluir(this.idDataBase)

          pushRoute('DatasBase', {
            params: {
              mensagem: 'Datas base excluidas com sucesso!'
            }
          })
        } catch (erro) {
          this.exibirConfirmacaoExclusao = false
          this.$toast.error(erro)
        }
      },
      cancelou () {
        this.exibirConfirmacaoExclusao = false
      },
      async carregarDataBase (idDataBase) {
        try {
          const dataBases = await consulta(idDataBase)
          this.comissao.descricao = dataBases.descricao
          this.comissao.dataReferencia = dataBases.dataReferencia
          this.comissao.dataEncerramento = dataBases.dataEncerramento
          this.comissao.dataVendaInicial = dataBases.dataVendaInicial
          this.comissao.dataVendaFinal = dataBases.dataVendaFinal
          this.comissao.dataAlocacaoInicial = dataBases.dataAlocacaoInicial
          this.comissao.dataAlocacaoFinal = dataBases.dataAlocacaoFinal
          this.comissao.dataHoraCriacao = dataBases.dataHoraCriacao
          this.comissao.dataHoraEdicao = dataBases.dataHoraEdicao
          this.comissao.loginUsuario = dataBases.loginUsuario
          this.comissao.loginUsuarioEditor = dataBases.loginUsuarioEditor
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      async salvar () {
        this.$refs.form.validate()

        try {
          if (this.formularioValido) {
            const dataReferencia = formatarDataMesAnoDiaISO(this.comissao.dataReferencia)
            this.totalRegistrosReferencia = await consultarQuantidadeRegitros(dataReferencia)

            if (this.totalRegistrosReferencia > 0) {
              this.exibirDialogConfirmacao = true
              return
            }

            if (this.idDataBase) {
              this.editar()
              return
            }

            this.criar()
            return
          }

          this.$toast.error('Campos obrigatórios não preenchidos. Revise os dados informados e tente novamente.')
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      async criar () {
        try {
          this.exibirDialogConfirmacao = false
          const dataBase = this.gerarDadosEnvio()
          await cadastrar(dataBase)

          pushRoute('DatasBase', {
            params: {
              mensagem: 'Datas base salvas com sucesso!'
            }
          })
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      async editar () {
        try {
          this.exibirDialogConfirmacao = false
          const dataBase = this.gerarDadosEnvio()
          await editar(this.idDataBase, dataBase)

          pushRoute('DatasBase', {
            params: {
              mensagem: 'Datas base salvas com sucesso!'
            }
          })
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      gerarDadosEnvio () {
        return {
          descricao: this.comissao.descricao,
          dataReferencia: formatarDataMesAnoDiaISO(this.comissao.dataReferencia),
          dataEncerramento: formatarDataMesAnoDiaISO(this.comissao.dataEncerramento),
          dataVendaInicial: formatarDataMesAnoDiaISO(this.comissao.dataVendaInicial),
          dataVendaFinal: formatarDataMesAnoDiaISO(this.comissao.dataVendaFinal),
          dataAlocacaoInicial: formatarDataMesAnoDiaISO(this.comissao.dataAlocacaoInicial),
          dataAlocacaoFinal: formatarDataMesAnoDiaISO(this.comissao.dataAlocacaoFinal)
        }
      },
      cancelar () {
        this.irParaMapaComissao()
      },
      irParaMapaComissao () {
        pushRoute('DatasBase')
      }
    }
  }

</script>

<style>

  .subtitulo {
    font-size: 14px;
  }

</style>
