import { validaCPF, validaCNPJ, validaCelular, validaTelefone, validaEmail } from '@/plugins/helpers'
import moment from 'moment'

export default {
  required: v => !!(v && String(v).trim()) || 'Campo obrigatório',
  numeroObrigatorio: v => !!(v && String(v).trim()) || 'Campo obrigatório',
  maior_que_zero: v => !v || v !== '0,00' || 'Campo obrigatório',
  documento: v => !v || (v.length <= 14 ? (validaCPF(v) || 'Documento inválido') : (validaCNPJ(v) || 'Documento inválido')),
  email: v => !v || validaEmail(v) || 'E-mail inválido',
  emailPontoVirgula: v => !v || validarEmailPontoVirgula(v) || 'E-mail inválido',
  celular_telefone: v => !v || (v.length <= 14 ? validaTelefone(v) || 'Telefone inválido' : validaCelular(v) || 'Telefone inválido'),
  data_valida: v => !v || (moment(v, 'DD/MM/YYYY').isValid()) || 'Data inválida',
  data_valida_mes: v => !v || (moment(v, 'MM/YYYY').isValid()) || 'Data inválida',
  maiorQueCinco: v => !v || v.length >= 5 || 'O campo deve possuir no mínimo 5 caracteres.',
  anoMaiorQue2015: v => !v || validarAnoMaiorQue2015(v) || 'Ano a partir de 2015',
  menorQue250: v => !v || v.length <= 250 || 'O campo deve possuir no máximo 250 caracteres',
  maiorQue500: v => (v || '').length <= 500 || 'Descrição deve ter menos que 500 caracteres'
}

function validarEmailPontoVirgula (valor) {
  let valido = true

  valor.split(';').forEach(email => {
    if (!validaEmail(email)) {
      valido = false
    }
  })

  return valido
}

function validarAnoMaiorQue2015 (data) {
  return moment(data, 'DD-MM-YYYY')
    .isSameOrAfter('01-01-2015')
}
