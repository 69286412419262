import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import session from '@/plugins/session'

export async function buscarTerceiroUsuario (cfo, silent = true) {
  try {
    const { data } = await http.get(`/terceirousuario/listar/${cfo}`, { options: { silent } })
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function consultarTerceiroUsuario (login) {
  try {
    const { data } = await http.get(`/terceirousuario/consultar/${login}`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function cadastrarTerceiroUsuario (terceirousuario) {
  try {
    const unidade = session.get('unidade')
    const { data } = await http.post(`/terceirousuario/cadastrar?idUnidade=${unidade.idUnidade}&urlLogin=${process.env.VUE_APP_AREA_RESTRITA_URL}/${unidade.idUnidade}/login`, terceirousuario)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function alterarTerceiroUsuario (login, terceirousuario) {
  try {
    const { data } = await http.put(`/terceirousuario/alterar/${login}`, terceirousuario)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function consultarDadosVendedor (login) {
  try {
    const { data } = await http.get(`/terceirousuario/vendedores/${login}`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}
