<template>
  <Button
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon v-if="icone" left>
      {{ icone }}
    </v-icon>
    <slot name="img"></slot>
    {{ texto }}
  </Button>
</template>

<script>
  export default {
    name: 'ButtonIcon',
    props: {
      texto: String,
      icone: String,
      svgPath: String
    },
    data: () => ({
    })
  }
</script>
