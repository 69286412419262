<template>
  <v-dialog
      persistent
      width="500"
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>
        <span>Atualização de senha</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              color="primaria"
              size="10"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          A nova senha deve respeitar os seguintes requisitos:
          <br />
          <br />
          <ul>
            <li>
              Não conter o nome e/ou sobrenome que compõe o seu login.
            </li>
            <li>Ter pelo menos 9 e no máximo 32 caracteres.</li>
            <li>Não conter a palavra "embracon".</li>
            <li>Atender a 3 das 4 seguintes condições: Conter pelo menos uma letra maiuscula; Conter pelo menos uma letra minúscula; Conter pelo menos um número; Conter pelo menos um caracter especial.</li>
          </ul>
        </p>
        <v-form
            ref="formulario"
            v-model="valido"
        >
          <TextField
              disabled
              label="Login"
              v-model="dados.login"
              autocomplete="off"
          />
          <PasswordField
              label="Senha atual*"
              v-model="dados.senhaAtual"
              maxlength="30"
              :rules="validacao"
              autocomplete="off"
          />
          <PasswordField
              label="Nova senha*"
              v-model="dados.novaSenha"
              maxlength="30"
              :rules="validacao"
              autocomplete="off"
          />
          <PasswordField
              label="Confirmação da nova senha*"
              v-model="dados.confirmacaoSenha"
              maxlength="30"
              :rules="validacao"
              autocomplete="off"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            outlined
            @click="fechar"
        >
          Cancelar
        </Button>
        <Button
            small
            @click="salvar"
        >
          Salvar
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import { alterarSenha } from '@/service/senha'

  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      valido: false,
      dados: {
        login: '',
        senhaAtual: '',
        novaSenha: '',
        confirmacaoSenha: ''
      },
      validacao: [
        v => !!v || 'Campo obrigatório',
        v => !v || !(v.length < 9) || 'O campo deve possuir no mínimo 9 caracteres',
        v => !v || !(v.length > 32) || 'O campo deve possuir no máximo 32 caracteres',
        v => !v || /([a-z])/.test(v) || 'A senha deve possuir letras minúsculas',
        v => !v || /([A-Z])/.test(v) || 'A senha deve possuir letras maiúsculas',
        v => !v || /([0-9])/.test(v) || 'A senha deve possuir números',
        v => !v || /(\W|_)/.test(v) || 'A senha deve possuir caracteres especiais',
        v => !v || v.replace(/([a-zA-Z0-9\W_])/g, '').length === 0 || 'Senha fora do padrão'
      ]
    }),
    computed: {
      getLogin () {
        return this.dados.login
      }
    },
    watch: {
      exibir (valor) {
        if (valor) {
          this.dados.login = this.$session.get('usuario').login
        } else {
          this.dados = {
            login: '',
            senhaAtual: '',
            novaSenha: '',
            confirmacaoSenha: ''
          }
        }
      },
      login (valor) {
        this.dados.login = valor
      }
    },
    methods: {
      async salvar () {
        if (this.$refs.formulario.validate()) {
          if (this.dados.novaSenha === this.dados.confirmacaoSenha) {
            try {
              const { mensagem } = await alterarSenha(this.dados)
              this.$toast.success(mensagem)

              this.fechar()
            } catch (erro) {
              this.$toast.error(erro)
            }

            return
          }

          return this.$toast.error('As senhas não conferem. Revise os dados informados e tente novamente.')
        }

        if (!this.dados.senhaAtual || !this.dados.novaSenha || !this.dados.confirmacaoSenha) {
          return this.$toast.error('Campos obrigatórios não preenchidos. Revise os dados informados e tente novamente.')
        }

        return this.$toast.error('A nova senha não atende aos padrões de segurança. Revise os dados informados e tente novamente.')
      },

      fechar () {
        this.$emit('exibir', false)
        this.$refs.formulario.reset()
      }
    }
  }

</script>
