import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import session from '@/plugins/session'
import { get } from 'lodash'

export async function desativarSublogin (login) {
  try {
    const { data } = await http.patch(`/sublogin/desativar/${login}`)
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function consultarSublogin (matricula, id, silent = false) {
  try {
    const { data } = await http.get(`/sublogin/consultar/${matricula}?id=${id}`, { options: { silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function cadastrarSublogin (sublogin, silent = false) {
  try {
    const unidade = session.get('unidade')
    const { data } = await http.post(`/sublogin/cadastrar?idUnidade=${unidade.idUnidade}&urlLogin=${process.env.VUE_APP_AREA_RESTRITA_URL}/${unidade.idUnidade}/login`, sublogin, { options: { silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function alterarSublogin (sublogin, silent = false) {
  try {
    const { data } = await http.put(`/sublogin/alterar`, sublogin, { options: { silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function alterarDadosCadastraisSublogin (dadosCadastrais, silent = false) {
  try {
    const { data } = await http.patch(`/sublogin/alterar`, dadosCadastrais, { options: { silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function listarSublogins (matricula, filtro) {
  try {
    const { data } = await http.get(filtro ? `/sublogin/listar/${matricula}${getRotaFiltro(filtro)}&jornada=false` : `/sublogin/listar/${matricula}?jornada=false`)
    return data
  } catch {
    return []
  }
}

export async function listarSubloginsJornada (matricula, filtro) {
  try {
    const { data } = await http.get(filtro ? `/sublogin/listar/${matricula}${getRotaFiltro(filtro)}&jornada=true` : `/sublogin/listar/${matricula}?jornada=true`, { options: { silent: true } })
    return data
  } catch {
    return []
  }
}

function getRotaFiltro (filtro) {
  let filtragem = '?'
  if (filtro.nome) {
    filtragem = filtragem.concat('nome=', filtro.nome, '&')
  }
  if (filtro.cpf_cnpj) {
    filtragem = filtragem.concat('cpf_cnpj=', filtro.cpf_cnpj, '&')
  }
  if (filtro.login) {
    filtragem = filtragem.concat('login=', filtro.login)
  } else {
    filtragem = filtragem.substring(0, filtragem.length - 1)
  }
  return filtragem
}

export async function consultarIdSublogin (login) {
  try {
    const { data } = await http.get(`/sublogin/buscar/${login}`)
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function alterarStatus (user = {}) {
  try {
    const data = await http.patch(`/sublogin/alterar-status`, user)
    console.warn(data)
    return data.data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function transferir (idSublogin, userNovoComissionado) {
  try {
    let dataObject = {
      idSublogin: idSublogin,
      userNovoComissionado: userNovoComissionado
    }
    const data = await http.post(`/sublogin/transferir`, dataObject)
    console.warn(data)
    return data.data
  } catch (error) {
    throw get(error, 'response.data.messagem', Mensagens.ERRO)
  }
}
