<template>
  <v-app :style="cssProps">
    <router-view class="router-view"></router-view>
    <Loading :visible="loading" />
    <Alert ref="alert" />
  </v-app>
</template>

<script>
  import Vue from 'vue'
  import { datadogRum } from '@datadog/browser-rum'
  import { mapState } from 'vuex'
  import { reduce } from 'lodash'
  import { events } from '@/plugins/events'
  import session from '@/plugins/session'
  import { getDarkenColor, getLightenColor, applyTransparency } from '@/plugins/color'
  import { PublicClientApplication } from '@azure/msal-browser'

  export default {
    name: 'App',
    computed: {
      ...mapState(['loading']),
      cssProps () {
        const theme = this.$vuetify.theme.currentTheme

        this.injectTextColorClasses(theme)

        return reduce(theme, (acc, v, k) => {
          acc[`--${k}`] = v
          return acc
        }, {})
      }
    },
    created () {
      Vue.prototype.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig)

      events.$on('alertShow', (options) => this.alertShow(options))
      events.$on('alertDismiss', () => this.alertDismiss())

      const html = document.querySelector('html')
      html.setAttribute('lang', 'pt-BR')
      html.setAttribute('translate', 'no')
    },
    mounted () {
      const unidade = session.get('unidade')

      this.$vuetify.theme.themes.light.primaria = unidade.corPrimaria || '#FFFFFF'
      this.$vuetify.theme.themes.light.secundaria = unidade.corSecundaria || '#000000'
      this.$vuetify.theme.themes.light.destaque = unidade.corDestaque || '#F0F0F0'
      this.$vuetify.theme.themes.light.textoprimario = unidade.corTextoPrimario || '#000000'
      this.$vuetify.theme.themes.light.textosecundario = unidade.corTextoSecundario || '#F0F0F0'
      this.$vuetify.theme.themes.light.textodestaque = unidade.corTextoDestaque || '#0F0F0F'

      this.$vuetify.theme.themes.light.primary = unidade.corPrimaria
      this.$vuetify.theme.themes.light.secondary = unidade.corSecundaria

      this.$vuetify.theme.themes.light.primariaDarken = getDarkenColor(this.$vuetify.theme.themes.light.primaria)
      this.$vuetify.theme.themes.light.primariaLighten = getLightenColor(this.$vuetify.theme.themes.light.primaria)
      this.$vuetify.theme.themes.light.primariaTransparent = applyTransparency(this.$vuetify.theme.themes.light.primaria)
      this.$vuetify.theme.themes.light.secundariaDarken = getDarkenColor(this.$vuetify.theme.themes.light.secundaria)
      this.$vuetify.theme.themes.light.secundariaLighten = getLightenColor(this.$vuetify.theme.themes.light.secundaria)
      this.$vuetify.theme.themes.light.secundariaTransparent = applyTransparency(this.$vuetify.theme.themes.light.secundaria)

      // data dog
      datadogRum.init({
        applicationId: process.env.VUE_APP_DATADOG_APP_ID,
        clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'area-restrita',
        env: process.env.VUE_APP_DATADOG_ENV,
        version: String(process.env.VUE_APP_FRONTEND_VERSION),
        sessionSampleRate: 25,
        sessionReplaySampleRate: 0,
        startSessionReplayRecordingManually: true,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackFrustrations: true,
        allowedTracingUrls: [/https:\/\/.*\.embraconnet\.com\.br/]
      })
    },
    methods: {
      injectTextColorClasses (theme) {
        let styles = ''
        for (const [key, value] of Object.entries(theme)) {
          styles += `.theme--light.v-application .text--${key} { color: ${value} !important; }\n`
        }
        let tag = document.getElementById('injected-theme')
        if (tag) {
          tag.remove()
        }
        tag = document.createElement('style')
        tag.id = 'injected-theme'
        tag.setAttribute('type', 'text/css')
        tag.appendChild(document.createTextNode(styles))
        document.head.appendChild(tag)
      },
      alertShow (options) {
        this.$refs.alert.show(options)
      },
      alertDismiss () {
        this.$refs.alert.dismiss()
      }
    }
  }

  Vue.prototype.$alert = {
    show: (options) => events.$emit('alertShow', options),
    dismiss: () => events.$emit('alertDismiss')
  }
</script>
