<template>
  <v-dialog
      persistent
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>
        <span>Sair</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>Tem certeza que deseja sair do sistema?</v-card-text>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            outlined
            @click="fechar"
        >
          Voltar
        </Button>
        <Button
            small
            @click="sair"
        >
          Confirmar
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    props: {
      login: String,
      exibir: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      fechar () {
        this.$emit('exibir', false)
      },
      async sair () {
        if (this.$session.get('unidadeAnterior').idUnidade !== '') {
          this.$session.set({
            unidade: {
              urlLogotipo: this.$session.get('unidadeAnterior').urlLogotipo,
              idUnidade: this.$session.get('unidadeAnterior').idUnidade,
              nomeUnidade: this.$session.get('unidadeAnterior').nomeUnidade,
              logotipo: this.$session.get('unidadeAnterior').logotipo,
              corPrimaria: this.$session.get('unidadeAnterior').corPrimaria,
              corSecundaria: this.$session.get('unidadeAnterior').corSecundaria,
              corDestaque: this.$session.get('unidadeAnterior').corDestaque,
              corTextoPrimario: this.$session.get('unidadeAnterior').corTextoPrimario,
              corTextoSecundario: this.$session.get('unidadeAnterior').corTextoSecundario,
              corTextoDestaque: this.$session.get('unidadeAnterior').corTextoDestaque,
              autenticacaoMultifator: this.$session.get('unidadeAnterior').autenticacaoMultifator
            }
          })
          this.$vuetify.theme.themes.light.primaria = this.$session.get('unidadeAnterior').corPrimaria || '#FFFFFF'
          this.$vuetify.theme.themes.light.secundaria = this.$session.get('unidadeAnterior').corSecundaria || '#000000'
          this.$vuetify.theme.themes.light.destaque = this.$session.get('unidadeAnterior').corDestaque || '#F0F0F0'
          this.$vuetify.theme.themes.light.textoprimario = this.$session.get('unidadeAnterior').corTextoPrimario || '#000000'
          this.$vuetify.theme.themes.light.textosecundario = this.$session.get('unidadeAnterior').corTextoSecundario || '#F0F0F0'
          this.$vuetify.theme.themes.light.textodestaque = this.$session.get('unidadeAnterior').corTextoDestaque || '#0F0F0F'
          this.$vuetify.theme.themes.light.primary = this.$session.get('unidadeAnterior').corPrimaria
          this.$vuetify.theme.themes.light.secundaria = this.$session.get('unidadeAnterior').corSecundaria
          this.$session.set({
            unidadeAnterior: {
              idUnidade: '',
              nomeUnidade: '',
              logotipo: '',
              corPrimaria: '',
              corSecundaria: '',
              corDestaque: '',
              corTextoPrimario: '',
              corTextoSecundario: '',
              corTextoDestaque: '',
              autenticacaoMultifator: true
            }
          })
        }

        if (this.$store.state.unidade.autenticacaoMultifator) {
          this.$router.push({
            name: 'Logout',
            replace: true,
            params: {
              logout: true
            }
          })
        } else {
          this.$router.push({ name: 'Login', replace: true })
          window.location.reload()
        }
      }
    }
  }

</script>

<style scoped>

  .v-btn {
    padding: 0 2rem !important;
  }

</style>
