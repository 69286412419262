<template>
  <!--
      TODO:
      - Mobile está quebrando cada /item em uma linha, manter o máximo possível
      na mesma linha e quebrar só quando não tiver espaço
  -->
  <v-row :class="!isMobile ? 'align-center' : 'align-center ml-1'">
    <v-col md="12" sm="10" class="d-flex">
      <span
          v-for="(item, key) in items"
          class="item"
          :key="item.name"
      >
        <span class="mr-1 delimiter" v-if="key > 0">
          {{ delimiter }}
        </span>
        <span class="mr-2" :class="{ firstItem: key === 0 }" @click="$router.push({ name: item.to })">
          {{ item.name }}
        </span>
      </span>
      <img
          src="@/assets/icons/portal-corretora/svg/dsCircleBack.svg"
          @click="$router.push({ name: backTo })"
          height="17"
          width="17"
          style="cursor: pointer; "
          v-if="showBack && !isMobile"
          class="ml-1"
      />
      <p class="items-breadcrump" v-if="isMobile">
        <img
            src="@/assets/icons/portal-corretora/svg/dsCircleBack.svg"
            @click="$router.push({ name: backTo })"
            height="17"
            width="17"
            style="cursor: pointer; float: right;"
            v-if="showBack"
            class="ml-1"
        />
      </p>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BreadCrumb',
    props: {
      items: {
        type: Array,
        default: () => ([
          { name: 'item 1', to: '' },
          { name: 'item 2', to: '' },
          { name: 'item 3', to: '' }
        ])
      },
      showBack: Boolean,
      backTo: String
    },
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      delimiter () {
        return this.isMobile ? '/' : '|'
      }
    }
  }
</script>

<style scoped>

  .items-breadcrump {
    width: 46%;
    margin-bottom: 0px;
  }

  .item, .firstItem {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #5E6267;
    cursor: pointer;
  }

  .item:hover {
    color: #3f4041;
  }

  @media screen and (max-width: 960px) {
    .firstItem {
      font-style: normal;
      line-height: 150%;
      color: #191E26;
      font-weight: 800;
    }

    .item {
      line-height: normal;
      font-style: normal;
      font-weight: 400;
    }
    .items-breadcrump {
      position: absolute;
      width: 20px;
      top: 12px;
      right: 12px;
    }
  }
</style>
