import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import session from '@/plugins/session'

export async function buscarComissionado (cfo, silent = true) {
  try {
    const { data } = await http.get(`/comissionado/listar/${cfo}`, { options: { silent } })
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function consultarComissionado (login) {
  try {
    const { data } = await http.get(`/comissionado/consultar/${login}`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function cadastrarComissionado (comissionado) {
  try {
    const unidade = session.get('unidade')
    const { data } = await http.post(`/comissionado/cadastrar?idUnidade=${unidade.idUnidade}&urlLogin=${process.env.VUE_APP_AREA_RESTRITA_URL}/${unidade.idUnidade}/login`, comissionado)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function alterarComissionado (login, comissionado) {
  try {
    const { data } = await http.put(`/comissionado/alterar/${login}`, comissionado)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function alterarDadosCadastraisComissionado (dadosCadastrais) {
  try {
    const { data } = await http.patch(`/comissionado/alterar`, dadosCadastrais)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function transferirComissionado (login, cfo) {
  try {
    const { data } = await http.put(`/comissionado/transferir/${login}`, { cfo })
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function buscarUsuarioSistema (cfo) {
  try {
    if (!cfo) {
      const { data } = await http.get('/comissionado/usuario-sistema')
      return data
    } else {
      const { data } = await http.get(`/comissionado/usuario-sistema?cfo=${cfo}`)
      return data
    }
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function buscarExcecaoUsuarios () {
  try {
    const data = await http.get(`/comissionado/excecoes/obter`)
    return data.data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }
  }
}

export async function incluirExcecaoUsuario (doc = '') {
  try {
    const data = await http.post(`/comissionado/excecoes/incluir/${doc}`)
    return { data }
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }
  }
}
