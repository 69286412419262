<template>
  <v-container>
    <h1
        class="titulo"
        v-if="!cadastro"
    >
      Sub login: {{ sublogin.nome }}
    </h1>
    <h1
        v-else
        class="titulo"
    >
      Cadastro de Sub Login
    </h1>
    <p
        v-if="gestor"
        class="text-body-2 "
    >
      Parceria: <b>{{ this.$route.params.cnpj }}</b> / Comissionado <b>{{ this.$route.params.login }}</b>
    </p>
    <v-card>
      <v-card-title v-if="!cadastro">
        <p
            class="text-body-2 my-auto"
        >
          Cadastrado em <b>{{ dataHoraCriacao }}</b> por <b>{{ sublogin.criadoPor }}</b>
        </p>
        <v-spacer />
        <Chip
            :texto="sublogin.status ? sublogin.status.replace('_', ' ') : ''"
            :color="corStatus(sublogin.status)"
        />
      </v-card-title>
      <v-card-actions>
        <v-form ref="form" :key="chaveFormulario">
          <v-row>
            <v-col
                md="3"
                cols="12"
                v-if="!gestor || visaoAdministrativa"
            >
              <SelectField
                  :items="usuariosSistema"
                  v-model="sublogin.usuarioSistema"
                  :disabled="!sublogin.sso || visaoAdministrativa || bloquearAcoes"
                  label="Usuário de sistema"
                  :rules="sublogin.sso ? [$rules.required] : []"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
            >
              <TextField
                  label="ID SSO Externo"
                  v-model="sublogin.idSso"
                  :disabled="!sublogin.sso || visaoAdministrativa || bloquearAcoes"
                  :rules="sublogin.sso ? [$rules.required, $rules.menorQue250] : []"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
                class="d-flex align-center"
            >
              <Checkbox
                  label="SSO Externo"
                  v-model="sublogin.sso"
                  :disabled="visaoAdministrativa || bloquearAcoes"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
                class="d-flex align-center"
            >
              <div class="select-container" v-if="sublogin.status !== 'SUBSTITUIDO' && sublogin.status !== 'INATIVO' && sublogin.status !== 'BLOQUEADO' && !cadastro && (!gestor || visaoAdministrativa)">
                <P>Alterar Status:</P>
                <select class="select" :disabled="bloquearAcoes || visaoAdministrativa" v-model="selecionado" @change="confirmarStatus()">
                  <option v-for=" i in siglaAfastamento" :key="i.value" :value="i.valor">
                    {{ i.desc }}
                  </option>
                </select>
              </div>
              <div class="select-container" v-if="gestor && !visaoAdministrativa && sublogin.status !== 'SUBSTITUIDO'">
                <select class="select" :disabled="bloquearAcoes" v-model="selecionado" @change="confirmarStatus()">
                  <option :value="1" :key="1">
                    ATIVO
                  </option>
                  <option :value="2" :key="2">
                    OCULTAR
                  </option>
                  <option :value="0" :key="0">
                    BLOQUEADO
                  </option>
                  <option :value="3" :key="3">
                    AGUARDANDO
                  </option>
                </select>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                md="3"
                cols="12"
            >
              <TextField
                  disabled
                  label="ID*"
                  v-model="sublogin.id"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
            >
              <TextField
                  maxlength="20"
                  label="Login*"
                  @blur="validarExistenciaLogin"
                  @keyup="validaCaractere($event)"
                  v-model="sublogin.login"
                  :rules="validacaoLogin"
                  :loading="$store.state.carregandoSilenciosamente && !this.$route.params.matricula"
                  :disabled="this.$route.params.matricula ? true : false || $store.state.carregandoSilenciosamente || visaoAdministrativa"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
            >
              <TextField
                  label="CPF/CNPJ*"
                  :disabled="!cadastro || visaoAdministrativa"
                  v-model="sublogin.cpfCnpj"
                  :rules=[$rules.required,$rules.documento]
                  v-mask="['###.###.###-##','##.###.###/####-##']"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
            >
              <TextField
                  label="Telefone*"
                  maxlength="20"
                  :rules=[$rules.required]
                  v-model="sublogin.telefone"
                  v-mask="'(##) # ####-####'"
                  :disabled="visaoAdministrativa || bloquearAcoes"
              />
            </v-col>
            <v-col
                md="6"
                cols="12"
            >
              <TextField
                  label="Nome*"
                  maxlength="50"
                  v-model="sublogin.nome"
                  :rules=[$rules.required]
                  :disabled="visaoAdministrativa || bloquearAcoes"
              />
            </v-col>
            <v-col
                md="6"
                cols="12"
            >
              <TextField
                  label="E-mail*"
                  maxlength="100"
                  v-model="sublogin.email"
                  :rules=[$rules.required,$rules.email]
                  :disabled="visaoAdministrativa || bloquearAcoes"
              />
            </v-col>
            <v-col
                md="6"
                cols="12"
            >
              <TextArea
                  no-resize
                  maxlength="255"
                  label="Observação"
                  v-model="sublogin.observacao"
                  :disabled="visaoAdministrativa"
              />
            </v-col>
            <v-col
                md="6"
                cols="12"
            >
              <TextArea
                  no-resize
                  label="Tag"
                  maxlength="255"
                  v-model="sublogin.tag"
                  :disabled="visaoAdministrativa"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
      <v-card-actions>
        <Button
            small
            icon
            class="pl-13"
            @click="$router.go(-1)"
        >
          <v-icon
              left
              color="iconcolor"
          >
            pc-seta-esquerda
          </v-icon>
          <span class="iconcolor--text">VOLTAR</span>
        </Button>
        <v-spacer />
        <Button v-if="!cadastro && cfo != undefined"
                small
                outlined
                @click="transferirSublogin(sublogin.id, String(cfo))"
        >
          TRANSFERIR
        </Button>
        <Button
            small
            plain
            class="pl-13"
            v-if="!gestor"
            @click="desativarSublogin"
            :disabled="bloquearAcoes"
        >
          <span class="iconcolor--text">DESATIVAR</span>
        </Button>
        <Button
            small
            outlined
            v-if="!cadastro && !gestaoUnificada"
            @click="resetarS"
            color="primaria"
            icone="pc-seta-esquerda"
            :disabled="subloginFixo.sso || bloquearAcoes"
        >
          RESETAR SENHA
        </Button>
        <Button
            :disabled="bloquearAcoes"
            @click="salvar">
          SALVAR
        </Button>
      </v-card-actions>
    </v-card>
    <v-dialog
        persistent
        v-model="exibirDialogReset"
    >
      <v-card class="pb-1">
        <v-card-title>
          <v-btn
              icon
              class="ml-auto"
              @click="exibirDialogReset = false"
          >
            <v-icon
                color="primaria"
                size="10"
            >
              pc-fechar
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>Senha redefinida com sucesso. Nova senha: {{ novaSenha }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <Button
              small
              @click="exibirDialogReset = false"
          >
            OK
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
        v-model="exibirDialogAfastamento"
    >
      <v-card class="pb-1">
        <v-card-title>
          <h3>Atenção!</h3>
        </v-card-title>
        <v-card-text v-if="selecionado == 1">Atenção, você esta re ativando o usuario {{ sublogin.login }}, deseja continuar? <br> <b>Usuários desligados a mais de 30 dias, não podem ser reativados.</b> <br> Uma nova senha sera gerada! </v-card-text>
        <v-card-text v-if="selecionado == 2">Atenção, você inativando e ocultando o usuario {{ sublogin.login }}, deseja continuar? <br> <b>Usuários inativos a mais de 30 dias, não podem ser reativados.</b></v-card-text>
        <v-card-text v-if="selecionado !== 1 && selecionado !== 2">Atenção, o usuario {{ sublogin.login }} será bloqueado, deseja continuar?<br> <b>Este usuário estara bloqueado até que seja re ativado manualmente. </b></v-card-text>
        <v-card-actions>
          <Button
              small
              @click="cancelarAlteracao"
          >
            Cancelar
          </Button>
          <v-spacer />
          <Button
              small
              @click="alterarStatusUsuario"
          >
            Confirmar
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogResponsabilidade
        @cadastrar="cadastrar"
        :exibir="exibirDialogResponsabilidade"
        @exibir="exibir => exibirDialogResponsabilidade = exibir"
    />
    <DialogDesativarSublogin
        :login="sublogin.login"
        :exibir="exibirDialogDesativar"
        @exibir="exibir => exibirDialogDesativar = exibir"
    />
  </v-container>
</template>

<script>

  import { validarLogin } from '@/service/usuario'
  import { buscarUsuarioSistema } from '@/service/comissionado'
  import { consultarSublogin, cadastrarSublogin, alterarSublogin, alterarStatus } from '@/service/sublogin'
  import { resetarSenha } from '@/service/senha'
  import DialogDesativarSublogin from '@/components/dialog/DialogDesativarSublogin'
  import moment from 'moment'
  import DialogResponsabilidade from '@/components/dialog/DialogResponsabilidade.vue'
  import { gestaoUnificada } from '@/directives/gestaoUnificada'

  export default {
    components: {
      DialogDesativarSublogin,
      DialogResponsabilidade
    },
    data: () => ({
      gestaoUnificada: false,
      exibirDialogDesativar: false,
      chaveFormulario: 0,
      exibirDialogResponsabilidade: false,
      loginInvalido: false,
      exibirDialogAfastamento: false,
      exibirDialogReset: false,
      novaSenha: undefined,
      cadastro: true,
      gestor: false,
      bloquearAcoes: false,
      selecionado: undefined,
      statusAnterior: undefined,
      siglaAfastamento: [{ desc: 'ATIVO', valor: 1 }, { desc: 'DESLIGADO', valor: 7 }, { desc: 'AFASTADO', valor: 6 }, { desc: 'FÉRIAS', valor: 5 }],
      usuariosSistema: [],
      sublogin: {
        id: undefined,
        nome: undefined,
        email: undefined,
        cpfCnpj: undefined,
        telefone: undefined,
        login: undefined,
        tag: undefined,
        observacao: undefined,
        status: undefined,
        criadoPor: undefined,
        dataCriacao: undefined,
        usuarioSistema: undefined,
        idSso: undefined,
        sso: undefined
      },
      subloginFixo: {
        sso: false
      },
      emailSublogin: undefined,
      visaoAdministrativa: false,
      cfo: null
    }),
    computed: {
      validacaoLogin () {
        return [
          v => !!v || 'Campo obrigatório!',
          v => /^\w+\.\w+$/.test(v) || 'Formato inválido!',
          v => !this.loginInvalido || 'Login já utilizado. Por favor, informe outro.'
        ]
      },
      dataHoraCriacao () {
        if (this.sublogin.dataCriacao) {
          const data = new Date(this.sublogin.dataCriacao)
          return moment(data).format('DD/MM/YYYY')
        }

        return '-'
      }
    },
    watch: {
      'sublogin.sso': function (valor) {
        if (!valor) {
          this.sublogin.idSso = null
        }
      }
    },
    mounted () {
      this.$store.commit('setRotaAtual', 'Sublogins')
      this.visaoAdministrativa = !!this.$session.get('visaoAdministrativa').idUsuario

      if (this.$route.params.matricula && this.$route.query.id) {
        this.cadastro = false
        this.carregarSublogin()
      }

      if ((this.$route.params.cnpj && this.$route.params.login) || this.$session.get('usuario').perfil === 'GESTOR' || this.$session.get('usuario').perfil === 'MASTER') {
        this.gestor = true
      }

      if (!this.visaoAdministrativa && !this.gestor) {
        this.buscarUsuarioSistema()
      }
      console.info(this.status)

      this.cfo = this.$route.query.cfo

      this.gestaoUnificada = gestaoUnificada()
    },
    methods: {
      async buscarUsuarioSistema () {
        try {
          const { usuarios } = await buscarUsuarioSistema(this.$route.query.cfo)
          this.usuariosSistema = usuarios

          if (this.sublogin.sso) {
            if (!this.gestor) {
              this.sublogin.usuarioSistema = this.$session.get('usuario').login
            }
          }
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      validaCaractere (value) {
        let login = this.sublogin.login
        if (value.key === '.') {
          if (login.indexOf('.') === 0) {
            login = login.substring(1)
          }
          if (/\w+\.\w+/.test(login)) {
            login = login.substring(0, login.length - 1)
          }
          if (/\.{2}/.test(login)) {
            login = login.substring(0, login.length - 1)
          }
        }
        if (/^\d/.test(login)) {
          login = login.replace(/\d/g, '')
        }
        login = login.toLowerCase().replace(/[^a-z0-9.]/g, '')
        this.sublogin.login = login
      },
      desativarSublogin () {
        if (!this.$session.get('visaoAdministrativa').idUsuario) {
          this.exibirDialogDesativar = true
        } else {
          this.$store.commit('toggleExibirDialogVisao')
        }
      },
      async carregarSublogin () {
        try {
          this.sublogin = await consultarSublogin(this.$route.params.matricula, this.$route.query.id)
          this.emailSublogin = this.sublogin.email
          this.subloginFixo.sso = this.sublogin.sso
          this.obterStatus(this.sublogin.status)
          if (this.visaoAdministrativa) {
            if (this.sublogin.sso) {
              const usuarioSistema = this.$session.get('visaoAdministrativa').login
              this.usuariosSistema = [usuarioSistema]
              this.sublogin.usuarioSistema = usuarioSistema
            }
          }
        } catch (erro) {
          this.$toast.error(erro)
        }

        this.chaveFormulario++
      },
      async salvar () {
        if (this.$session.get('visaoAdministrativa').idUsuario) {
          this.$store.commit('toggleExibirDialogVisao')
        } else {
          if (this.$refs.form.validate()) {
            try {
              if (!this.cadastro) {
                const { mensagem } = await alterarSublogin(this.sublogin)
                await this.carregarSublogin()
                this.$toast.success(mensagem)
              } else {
                this.exibirDialogResponsabilidade = true
              }
            } catch (erro) {
              this.$toast.error(erro)
            }
          }
        }
      },
      async cadastrar () {
        try {
          this.exibirDialogResponsabilidade = false

          const { mensagem } = await cadastrarSublogin({
            login: this.sublogin.login,
            cpfCnpj: this.sublogin.cpfCnpj,
            email: this.sublogin.email,
            nome: this.sublogin.nome,
            observacao: this.sublogin.observacao,
            tag: this.sublogin.tag,
            telefone: this.sublogin.telefone,
            loginInclusao: this.$route.params.login,
            usuarioSistema: this.sublogin.usuarioSistema,
            idSso: this.sublogin.idSso,
            sso: this.sublogin.sso
          })

          this.$router.go(-1)
          this.$toast.success(mensagem)
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      corStatus (status) {
        switch (status) {
          case 'INATIVO':
            return '#B9BDC4'

          case 'ATIVO':
            return '#008836'

          case 'BLOQUEADO':
            return '#CE181E'

          case 'AGUARDANDO_LIBERACAO':
            return '#000000'

          case 'SUBSTITUIDO':
            return '#222222'

          case 'FERIAS':
            return '#38b8f3'

          case 'AFASTADO':
            return '#ce5b18'

          case 'DESLIGADO':
            return '#B9BDC4'
        }
      },
      async validarExistenciaLogin () {
        try {
          const { existe } = await validarLogin(this.sublogin.login)
          this.loginInvalido = existe
        } catch (erro) {
          this.$toast.erro(erro)
        }
      },
      async resetarS () {
        if (!this.visaoAdministrativa) {
          try {
            const { senhaNova } = await resetarSenha(this.sublogin.login, this.emailSublogin)
            this.novaSenha = senhaNova
            this.$toast.success('Senha redefinida com sucesso!')
            this.exibirDialogReset = true
          } catch (erro) {
            this.$toast.error(erro)
          }
        } else {
          this.$store.commit('toggleExibirDialogVisao')
        }
      },
      confirmarStatus () {
        this.exibirDialogAfastamento = true
      },
      obterStatus (status) {
        switch (status) {
          case 'FERIAS':
            this.selecionado = 5
            this.statusAnterior = 5
            break
          case 'AFASTADO':
            this.selecionado = 6
            this.statusAnterior = 6
            break
          case 'DESLIGADO':
            this.selecionado = 7
            this.statusAnterior = 7
            break
          case 'SUBSTITUIDO':
            this.bloquearAcoes = true
            return 1
          case 'BLOQUEADO':
            if (this.gestor) {
              this.selecionado = 2
              this.statusAnterior = 2
            }
            this.bloquearAcoes = true
            this.selecionado = 2
            return 2
          case 'INATIVO':
            this.selecionado = 0
            this.statusAnterior = 0
            return 0
          case 'AGUARDANDO_LIBERACAO':
            if (this.gestor) {
              this.selecionado = 3
              return 3
            }
            return 1
          default:
            this.selecionado = 1
            this.statusAnterior = 1
            break
        }
      },
      async alterarStatusUsuario () {
        let user = {
          login: this.sublogin.login,
          status: this.selecionado
        }
        try {
          await alterarStatus(user)
          if (user.status === 1) {
            this.$toast.success('Usuário ativado com sucesso, será nescessario gerar uma nova senha de acesso!')
          } else {
            this.$toast.success('Usuário atualizado com sucesso!')
          }
          this.exibirDialogAfastamento = false
          this.$router.go(0)
        } catch (error) {
          console.error(error)
          this.$toast.error('Ocorreu um erro ao atualizar o status do usuário.')
        }
      },
      cancelarAlteracao () {
        this.exibirDialogAfastamento = false
        this.selecionado = this.statusAnterior
      },
      transferirSublogin (idSublogin, cfoComissionado) {
        this.$router.push({
          name: 'SubloginsAlterar',
          params: {
            id: idSublogin,
            cfo: cfoComissionado
          }
        })
      }
    }
  }

</script>
<style scoped>
 .select {
    text-align: center;
    height: 45px;
    width: 150px;
    border-radius: 10px;
    background-color: #CE181E;
    color: #FFFFFF;
  }
  .select-container{
      font-size: medium;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
  }
</style>
