import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt'
  },
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      options: {
        customProperties: true,
        variations: false
      },
      light: {
        primaria: '#FFFFFF',
        secundaria: '#000000',
        destaque: '#0F0F0F',
        textoprimario: '#000000',
        textosecundario: '#FFFFFF',
        textodestaque: '#F0F0F0',
        erro: '#CE181E',
        sucesso: '#008836',
        alerta: '#FFB801',
        roxo: '#6800F1',
        primary: '#03142E',
        secondary: '#CE181E',
        yellow: '#FACC00',
        cyan: '#048ABF',
        orange: '#F24F1D',
        dark: '#191E26',
        blue: '#173161',
        white: '#FFFFFF',
        black: '#000000',
        lightblack: '#373F41',
        // Text
        labelcolor: '#152536',
        inputcolor: '#5E6267',
        placeholdercolor: '#ABB5BE',
        // Icon
        iconcolor: '#343A3F',
        // Grays
        // Ir preenchendo conforme o desenvolvimento
        gray: '#B9BDC4',
        darkgray: '#5E6267',
        mediumgray: '#A7A7A7',
        lightgray: '#EEEEEE'
      }
    }
  }
})
