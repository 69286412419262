<template>
  <v-dialog
      persistent
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>
        <span>Atenção!</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>Está certo que deseja abandonar este cadastro? As informações não serão salvas.</v-card-text>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            outlined
            @click="fechar"
        >
          Cancelar
        </Button>
        <Button
            small
            @click="$router.go(-1)"
        >
          Confirmar
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>
