<template>
  <v-dialog
      persistent
      width="600px"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="exibir"
  >
    <v-card class="pb-3">
      <v-card-title>
        <span>Filtrar</span>
        <Button icon class="ml-auto" @click="fechar">
          <v-icon size="10" color="primaria">
            pc-fechar
          </v-icon>
        </Button>
      </v-card-title>
      <v-card-actions>
        <v-form>
          <v-row>
            <v-col v-if="filtroCpfCnpj" class="d-flex flex-column">
              <h4>CPF/CNPJ Parceiro</h4>
              <TextField v-model="cpfCnpj"
                         hide-details="auto"
                         v-mask="['###.###.###-##','##.###.###/####-##']"
                         :rules="[this.$rules.documento]" />
              <span class="mt-1">Apenas números</span>
            </v-col>

            <v-col v-if="filtroNumeroNota" class="d-flex flex-column">
              <h4>Nº Nota</h4>
              <TextField v-model="nmrNota" type="number" hide-details />
              <span class="mt-1">Apenas números</span>
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="filtroVencimento">
            <v-col>
              <h4>Data inicial</h4>
              <TextField v-mask="['##/##/####']"
                         :rules="[this.$rules.data_valida]"
                         v-model="dataInicial"
                         hide-details="auto"
                         outlined />
            </v-col>

            <v-col>
              <h4>Data final</h4>
              <TextField v-mask="['##/##/####']"
                         :rules="[this.$rules.data_valida]"
                         v-model="dataFinal"
                         hide-details="auto"
                         outlined />
            </v-col>
          </v-row>
          <span v-if="filtroVencimento" class="mt-1">Máximo 90 dias</span>

          <v-row v-if="filtroStatus" class="mt-2">
            <v-col>
              <h4>Status: </h4>
            </v-col>
            <v-col>
              <v-checkbox
                  v-model="status"
                  color="primaria"
                  label="Pendentes"
                  value="Pendente" />
              <v-checkbox
                  v-model="status"
                  color="primaria"
                  label="Recusadas"
                  value="Recusada" />
            </v-col>
            <v-col>
              <v-checkbox
                  color="primaria"
                  v-model="todosStatus"
                  @click="selecionarTodos"
                  label="Todos os status" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <Button
                outlined
                block
                @click="limpar"
            >
              Limpar
            </Button>
          </v-col>
          <v-col cols="6">
            <Button
                block
                @click="filtrar"
            >
              Filtrar
            </Button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { stringToDate } from '@/plugins/formatters'
  import { unmask } from '@/plugins/helpers'

  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      },
      filtroCpfCnpj: {
        type: Boolean,
        default: false
      },
      filtroVencimento: {
        type: Boolean,
        default: false
      },
      filtroStatus: {
        type: Boolean,
        default: false
      },
      filtroNumeroNota: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      status: [],
      todosStatus: false,
      dataInicial: '',
      dataFinal: '',
      cpfCnpj: '',
      nmrNota: null
    }),
    watch: {
      status (value) {
        this.todosStatus = value.includes('Pendente') && value.includes('Recusada')
      },
      exibir (value) {
        if (value) {
          if (this.filtroVencimento) {
            this.dataInicial = this.calcularRangeInicialDoFiltro()[0]
            this.dataFinal = this.calcularRangeInicialDoFiltro()[1]
          }
          if (this.filtroStatus && !this.todosStatus) {
            this.selecionarTodos()
          }
        }
      }
    },
    methods: {
      calcularRangeInicialDoFiltro () {
        let hoje = new Date()
        return [
          new Date(hoje.getFullYear(), hoje.getMonth() - 1, hoje.getDate()).toLocaleDateString(),
          hoje.toLocaleDateString()
        ]
      },
      selecionarTodos () {
        this.status = this.status.length === 2 ? [] : ['Pendente', 'Recusada']
      },
      filtrar () {
        var filtro = ''

        if (this.cpfCnpj || this.dataInicial || (this.dataFinal && this.dataInicial) || this.nmrNota || this.status.length > 0) {
          filtro = '?filtro=true'

          if (this.cpfCnpj !== '') {
            filtro += '&cpfCnpj=' + this.cpfCnpj
          }
          if (this.dataInicial !== '' && this.dataFinal !== '') {
            filtro += `&dataInicial=${this.dataInicial}&dataFinal=${this.dataFinal}`
          }
          if (this.nmrNota !== null) {
            filtro += '&nota=' + this.nmrNota
          }
          if (this.status.length > 0) {
            filtro += '&status=' + this.status
          }
        }

        this.$emit('filtro', filtro)
        this.fechar()
      },
      filtrarPorCpfCnpj (nota) {
        if (unmask(nota.cpfCnpj) === unmask(this.cpfCnpj)) {
          return nota
        }
      },
      filtrarPorVencimento (nota) {
        if (stringToDate(this.dataFinal).getMonth() - stringToDate(this.dataInicial).getMonth() > 3) {
          this.$toast.error('Por favor, insira um período menor que 90 dias')
        } else {
          if (stringToDate(nota.vencimento.split('T')[0]) >= stringToDate(this.dataInicial) && stringToDate(nota.vencimento.split('T')[0]) <= stringToDate(this.dataFinal)) {
            return nota
          }
        }
      },
      filtrarPeloNmrNota (nota) {
        if (nota.numeroNota === Number(this.nmrNota)) {
          return nota
        }
      },
      filtrarPorStatus (nota) {
        if (this.status.includes(nota.status)) {
          return nota
        }
      },
      limpar () {
        this.cpfCnpj = ''
        this.dataInicial = ''
        this.dataFinal = ''
        this.nmrNota = null
        this.status = []
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>
