import Vue from 'vue'
import VueRouter from 'vue-router'
import session from './session'
import Login from '@/views/Login'
import Callback from '@/views/Callback'
import Sistema from '@/views/Sistema/Sistema'

import Comissionado from '@/views/Comissionado'
import TerceiroUsuario from '@/views/TerceiroUsuario'
import InformeRendimento from '@/views/Sistema/Parceiro/InformeRendimento'
import DatasBase from '@/views/DatasBase'

import TelaInicial from '@/views/Sistema/Gestor/TelaInicial'
import GestorNotaFiscal from '@/views/Sistema/Gestor/GestorNotaFiscal'
import ListarParcerias from '@/views/Sistema/ListarParcerias'
import ListarTerceiros from '@/views/Sistema/ListarTerceiros'
import CadastroUnidade from '@/views/Sistema/Gestor/CadastroUnidade'
import VisaoAdministrativa from '@/views/Sistema/Gestor/VisaoAdministrativa'
import PoliticaUso from '@/views/Sistema/Gestor/PoliticaUso'
import ParametroInvalido from '@/views/Sistema/ParametroInvalido'
import CadastroParceria from '@/views/Sistema/CadastroParceria'
import CadastroTerceiro from '@/views/Sistema/CadastroTerceiro'
import ParceiroNotaFiscal from '@/views/Sistema/ParceiroNotaFiscal'
import MeusClientes from '@/views/Sistema/MeusClientes'
import JornadaDaCota from '@/views/Sistema/JornadaDaCota/JornadaDaCota'
import Detalhes from '@/views/Sistema/JornadaDaCota/Detalhes'
import Pesquisar from '@/views/Sistema/Pesquisar'
import ParceriasAlterar from '@/views/ParceriasAlterar'
import Sublogins from '@/views/Sublogins'
import Sublogin from '@/views/Sublogin'
import SubloginsAlterar from '@/views/SubloginsAlterar'
import DataBase from '@/views/DataBase'
import MapaComissao from '@/views/MapaComissao'
import Root from '@/views/Root'
import Logout from '@/views/Logout'
import ExcecaoUsuario from '@/views/ExcecaoUsuario'
import { TipoPerfil } from '@/plugins/constants'
import Acompanhar from '@/views/Sistema/JornadaDaCota/Acompanhar'

var user
Vue.use(VueRouter)

export const rotasMenu = [
  {
    path: '/inicio',
    name: 'Inicio',
    component: TelaInicial,
    menu: {
      titulo: 'Tela inicial',
      icone: 'pc-casa'
    },
    meta: {
      perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
    }
  },
  {
    menu: {
      titulo: 'Convert+',
      icone: 'pc-celular',
      url: ''
    },
    meta: {
      bloquearVisao: true,
      perfil: [TipoPerfil.PARCEIRO, TipoPerfil.SUBLOGIN]
    }
  },
  {
    path: '/meus-clientes',
    name: 'MeusClientes',
    component: MeusClientes,
    menu: {
      titulo: 'Meus Clientes',
      icone: 'pc-pessoas'
    },
    meta: {
      perfil: [TipoPerfil.PARCEIRO, TipoPerfil.MASTER]
    }
  },
  {
    path: '/meus-clientes/jornada-cota',
    name: 'JornadaDaCota',
    component: JornadaDaCota,
    meta: {
      perfil: [TipoPerfil.PARCEIRO, TipoPerfil.MASTER]
    }
  },
  {
    path: '/meus-clientes/jornada-cota/detalhes',
    name: 'Detalhes',
    component: Detalhes,
    meta: {
      perfil: [TipoPerfil.PARCEIRO, TipoPerfil.MASTER]
    }
  },
  {
    path: '/meus-clientes/jornada-cota/acompanhar',
    name: 'Acompanhar',
    component: Acompanhar,
    meta: {
      perfil: [TipoPerfil.PARCEIRO, TipoPerfil.MASTER]
    }
  },
  {
    path: '/pesquisar',
    name: 'Pesquisar',
    component: Pesquisar,
    menu: {
      titulo: 'Pesquisar',
      icone: 'pc-pesquisa'
    },
    meta: {
      perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
    }
  },
  {
    menu: {
      titulo: 'Newcon Web',
      icone: 'pc-site',
      url: ''
    },
    meta: {
      perfil: [TipoPerfil.PARCEIRO]
    }
  },
  {
    path: '/sublogins',
    name: 'Sublogins',
    component: Sublogins,
    menu: {
      titulo: 'Cadastro de Sub Logins',
      icone: 'pc-pessoas'
    },
    meta: {
      perfil: [TipoPerfil.PARCEIRO],
      sublogin: true
    }
  },
  {
    path: '/notas-fiscais',
    name: 'GestorNotaFiscal',
    component: GestorNotaFiscal,
    menu: {
      titulo: 'Notas Fiscais',
      icone: 'pc-documento-escrito'
    },
    meta: {
      perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
    }
  },
  {
    path: '/parceiro/notas-fiscais',
    name: 'ParceiroNotaFiscal',
    component: ParceiroNotaFiscal,
    menu: {
      titulo: 'Notas Fiscais',
      icone: 'pc-documento-escrito'
    },
    meta: {
      perfil: [TipoPerfil.PARCEIRO],
      principal: true
    }
  },
  {
    path: '/datas-base',
    name: 'DatasBase',
    component: DatasBase,
    menu: {
      titulo: 'Mapas de Comissões',
      icone: 'pc-grafico'
    },
    meta: {
      perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
    }
  },
  {
    path: '/mapas-comissao',
    name: 'MapaComissao',
    component: MapaComissao,
    menu: {
      titulo: 'Mapas de Comissões',
      icone: 'pc-grafico'
    },
    meta: {
      perfil: [TipoPerfil.PARCEIRO]
    }
  },
  {
    path: '/parcerias',
    name: 'Parcerias',
    component: ListarParcerias,
    menu: {
      titulo: 'Cadastro de Usuário',
      icone: 'pc-adicionar-usuario'
    },
    meta: {
      perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER, TipoPerfil.GESTAO_UNIFICADA]
    }
  },
  {
    path: '/cadastro-unidade',
    name: 'CadastroUnidade',
    component: CadastroUnidade,
    menu: {
      titulo: 'Cadastro de Unidade',
      icone: 'pc-loja'
    },
    meta: {
      perfil: [TipoPerfil.MASTER]
    }
  },
  {
    path: '/cadastro-terceiro',
    name: 'Terceiro',
    component: ListarTerceiros,
    menu: {
      titulo: 'Cadastro de Terceiro',
      icone: 'pc-adicionar-usuario'
    },
    meta: {
      perfil: [TipoPerfil.MASTER, TipoPerfil.MANUTENCAO_TERCEIRO]
    }
  },
  {
    path: '/visao-administrativa',
    name: 'VisaoAdministrativa',
    component: VisaoAdministrativa,
    menu: {
      titulo: 'Visão Administrativa',
      icone: 'pc-senha-on'
    },
    meta: {
      perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
    }
  },
  {
    path: '/politicas',
    name: 'Politicas',
    component: PoliticaUso,
    menu: {
      titulo: 'Políticas de Uso',
      icone: 'pc-escudo'
    },
    meta: {
      perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
    }
  },
  {
    path: '/rendimentos',
    name: 'Rendimentos',
    component: InformeRendimento,
    menu: {
      titulo: 'Informe de Rendimentos',
      icone: 'pc-grafico-barra'
    },
    meta: {
      perfil: [TipoPerfil.PARCEIRO]
    }
  },
  {
    menu: {
      titulo: 'UCE',
      icone: 'pc-livro',
      url: ''
    },
    meta: {
      perfil: [TipoPerfil.SUBLOGIN, TipoPerfil.PARCEIRO, TipoPerfil.GESTOR, TipoPerfil.MASTER]
    }
  },
  {
    menu: {
      titulo: 'CRM / Proposta',
      icone: 'pc-account-tie',
      url: ''
    },
    meta: {
      unidades: [1],
      perfil: [TipoPerfil.PARCEIRO, TipoPerfil.SUBLOGIN, TipoPerfil.MASTER]
    }
  },
  {
    menu: {
      titulo: 'CRM VW',
      icone: 'pc-site',
      url: ''
    },
    meta: {
      unidades: [91],
      perfil: [TipoPerfil.PARCEIRO]
    }
  },
  {
    menu: {
      titulo: 'Notion Embracon',
      icone: 'pc-livro',
      url: ''
    },
    meta: {
      unidades: [1],
      perfil: [TipoPerfil.PARCEIRO, TipoPerfil.SUBLOGIN, TipoPerfil.MASTER]
    }
  },
  {
    menu: {
      titulo: 'Salesforce',
      icone: 'pc-site',
      url: ''
    },
    meta: {
      unidades: [ 30, 33, 40, 42, 47, 52 ],
      perfil: [TipoPerfil.PARCEIRO]
    }
  }
]

const rotas = [
  {
    path: '/:idUnidade',
    children: [
      {
        path: '/',
        name: 'Root',
        component: Root,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER, TipoPerfil.PARCEIRO, TipoPerfil.SUBLOGIN, TipoPerfil.GESTAO_UNIFICADA]
        }
      },
      {
        path: '/parceria/:cnpj',
        name: 'CadastroParceria',
        component: CadastroParceria,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER, TipoPerfil.GESTAO_UNIFICADA]
        }
      },
      {
        path: '/parceria/:cnpj/comissionado/:login?',
        name: 'Comissionado',
        component: Comissionado,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER, TipoPerfil.GESTAO_UNIFICADA]
        }
      },
      {
        path: '/parceria/:cnpj/comissionado/:login/parcerias',
        name: 'ParceriasAlterar',
        component: ParceriasAlterar,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
        }
      },
      {
        path: '/parceria/:cnpj/comissionado/:login/sublogin/:matricula?',
        name: 'SubloginGestor',
        component: Sublogin,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER, TipoPerfil.GESTAO_UNIFICADA]
        }
      },
      {
        path: '/sublogin/:matricula?',
        name: 'Sublogin',
        component: Sublogin,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER, TipoPerfil.PARCEIRO, TipoPerfil.GESTAO_UNIFICADA]
        }
      },
      {
        path: '/parceria/:cnpj/comissionado/:login/sublogin/transferir/:id/:cfo',
        name: 'SubloginsAlterar',
        component: SubloginsAlterar,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER, TipoPerfil.PARCEIRO, TipoPerfil.GESTAO_UNIFICADA]
        }
      },
      {
        path: '/data-base/:id?',
        name: 'DataBase',
        component: DataBase,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
        }
      },
      {
        path: '/excecao-usuario',
        name: 'ExecaoUsuario',
        component: ExcecaoUsuario,
        meta: {
          perfil: [TipoPerfil.GESTOR, TipoPerfil.MASTER]
        }
      },
      {
        path: '/terceiro/:cnpj',
        name: 'CadastroTerceiro',
        component: CadastroTerceiro,
        meta: {
          perfil: [TipoPerfil.MASTER, TipoPerfil.MANUTENCAO_TERCEIRO]
        }
      },
      {
        path: '/terceiro/:cnpj/usuario/:login?',
        name: 'TerceiroUsuario',
        component: TerceiroUsuario,
        meta: {
          perfil: [TipoPerfil.MASTER, TipoPerfil.MANUTENCAO_TERCEIRO]
        }
      },

      ...rotasMenu
    ]
  }
]

const routes = [
  {
    path: '/:idUnidade/login',
    name: 'Login',
    component: Login,
    meta: { public: true }
  },
  {
    path: '/auth/callback',
    name: 'Callback',
    component: Callback,
    meta: { public: true }
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    component: Logout,
    meta: { public: true }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { title: 'Sair' },
    beforeEnter: (to, from, next) => {
      session.destroy()
      next({ name: 'login' })
    }
  },
  {
    path: '/parametro-invalido',
    name: 'ParametroInvalido',
    component: ParametroInvalido,
    meta: { public: true }
  },
  {
    path: '/:idUnidade/',
    name: 'Sistema',
    component: Sistema,
    meta: { title: 'Sistema' },
    children: [
      ...flattenRoutes(rotas)
    ]
  }
]

const router = new VueRouter({ routes, mode: 'history' })

router.beforeEach(async (to, from, next) => {
  const idUnidade = parseInt(from.params.idUnidade ? from.params.idUnidade : to.params.idUnidade)
  if (!/(^0|\.)/.test(idUnidade) && !isNaN(idUnidade)) {
    if (to.name !== 'Login' && !session.exists()) {
      if (to.name !== 'ParametroInvalido') {
        next({
          name: 'Login',
          replace: true,
          params: { idUnidade }
        })
      } else {
        next({
          params: { idUnidade }
        })
      }
    } else {
      const unidade = session.get('unidade')
      const idUnidadeStorage = unidade ? unidade.idUnidade || idUnidade : idUnidade
      if (session.get('visaoAdministrativa').login === '' && (idUnidadeStorage !== idUnidade)) {
        session.destroy()

        if (to.name !== 'ParametroInvalido') {
          next({
            name: 'Login',
            replace: true,
            params: { idUnidade }
          })
        } else {
          next({ idUnidade })
        }
      } else {
        let acessoPermitido = false
        const perfilUsuario = session.get('visaoAdministrativa').perfil || session.get('usuario').perfil

        if ('perfil' in to.meta) {
          to.meta.perfil.forEach(perfilRota => {
            if (perfilUsuario === perfilRota) {
              acessoPermitido = true
            }
          })
        }

        if (to.meta.public || acessoPermitido) {
          next({
            params: {
              ...to.params,
              idUnidade
            }
          })
        } else {
          session.destroy()

          next({
            name: 'Login',
            replace: true,
            params: { idUnidade }
          })
        }
      }
      if (user === undefined && session.get('usuario').login) {
        user = session.get('usuario').login
      }
    }
  } else {
    if (to.name === 'Callback' || to.name === 'Logout') {
      next()
    } else if (to.name !== 'ParametroInvalido') {
      session.destroy()
      next({ name: 'ParametroInvalido' })
    } else {
      next()
    }
  }
})

function flattenRoutes (routes, prefix = null) {
  return routes.flatMap(route => {
    if (prefix) {
      route.path = prefix + route.path
    }
    if (route.children) {
      return flattenRoutes(route.children, route.path)
    }
    return route
  })
}

export default router
