import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function buscarUrlArquivo (id, silent = true) {
  try {
    const { data } = await http.get(`/buscar-arquivo/${id}`, {
      options: { silent: silent }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function buscarNomeArquivoPublico (id, silent = true) {
  try {
    const { data } = await http.get(`/buscar-arquivo-publico/${id}`, {
      options: { silent: silent }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function obterArquivo (url = '') {
  try {
    const data = await http.get(url, {
      options: { silent: true } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}
