<template>
  <v-container fluid>
    <h1 class="titulo-nota">Notas fiscais</h1>
    <v-card class="mt-5" elevation="0">
      <v-tabs
          id="TabNotaFiscal"
          v-model="tab"
          hide-slider
          background-color="transparent"
      >
        <v-tab id="TabPendente">Pendente</v-tab>
        <v-tab id="TabRecebida">Recebida</v-tab>
        <v-tab id="TabConferida">Conferida</v-tab>
        <v-tab id="TabEnviada">Enviada</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="py-3">
        <Checkbox
            hide-details
            :true-value="false"
            :false-value="true"
            class="ml-auto mt-0"
            v-model="contadorBloqueado"
            label="Atualização automática"
        />
        <!-- PENDENTE -->
        <v-tab-item>
          <v-row>
            <!-- eslint-disable-next-line -->
              <v-col align="end" class="mr-2">
              <Button dark @click="filtrar">
                <v-icon size="20">
                  pc-filtro
                </v-icon>
                Filtrar
              </Button>
            </v-col>
          </v-row>
          <DataTable
              :params="params"
              :key="notaPendenteKey"
              :headers="headersPendente"
              :reload="reload"
              item-key="idNotaFiscalParceiro"
              :loading="$store.state.carregandoSilenciosamente"
              :url="urlPendentes"
          >
            <template v-slot:[`item.cpfCnpj`]="item">
              <td>{{ item.cpfCnpj }}</td>
            </template>
            <template v-slot:[`item.nome`]="item">
              <td>{{ item.nome }}</td>
            </template>
            <template v-slot:[`item.vencimento`]="item">
              <td>{{ getVencimento(item.vencimento) }}</td>
            </template>
            <template v-slot:[`item.valorBruto`]="item">
              <td>R$ {{ convertToMoney(item.valorBruto) }}</td>
            </template>
            <template v-slot:[`item.valorImposto`]="item">
              <td>R$ {{ convertToMoney(item.valorImposto) }}</td>
            </template>
            <template v-slot:[`item.valorLiquido`]="item">
              <td>R$ {{ convertToMoney(item.valorLiquido) }}</td>
            </template>
            <template v-slot:[`item.status`]="item">
              <td>
                <span class="capitalize" v-if="item.codigoMovimento === '1.1.22'">
                  <Chip
                      color="#31ce18"
                      texto="Bônus"
                      class="my-1"
                  />
                </span>
                <span class="capitalize">
                  <Chip
                      :id="`ChipStatus_${item.idNotaFiscalParceiro}`"
                      :color="item.status === 'Pendente' ? '#697077' : '#CE181E'"
                      :texto="item.status"
                      class="my-1"
                  />
                </span>
              </td>
            </template>
            <template v-slot:[`item.balao`]="item">
              <td>
                <v-btn
                    v-if="item.status === 'Recusada' && item.comentario !== ''"
                    icon
                    @click="exibirMotivoRecusa(item)"
                >
                  <v-icon
                      color="primaria">
                    pc-mensagem
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </DataTable>
        </v-tab-item>

        <!-- RECEBIDA -->
        <v-tab-item>
          <v-row>
            <!-- eslint-disable-next-line -->
              <v-col align="end" class="mr-2">
              <Button dark @click="filtrar">
                <v-icon size="20">
                  pc-filtro
                </v-icon>
                Filtrar
              </Button>
            </v-col>
          </v-row>
          <DataTable
              :params="params"
              :headers="headersRecebida"
              :reload="reload"
              item-key="idNotaFiscalParceiro"
              :loading="$store.state.carregandoSilenciosamente"
              :url="urlRecebidas"
          >
            <template v-slot:[`item.cpfCnpj`]="item">
              <td>{{ item.cpfCnpj }}</td>
            </template>
            <template v-slot:[`item.nome`]="item">
              <td style="max-width: 130px;">{{ item.nome }}</td>
            </template>
            <template v-slot:[`item.vencimento`]="item">
              <td>{{ getVencimento(item.vencimento) }}</td>
            </template>
            <template v-slot:[`item.valorBruto`]="item">
              <td>R$ {{ convertToMoney(item.valorBruto) }}</td>
            </template>
            <template v-slot:[`item.valorImposto`]="item">
              <td>R$ {{ convertToMoney(item.valorImposto) }}</td>
            </template>
            <template v-slot:[`item.valorLiquido`]="item">
              <td>R$ {{ convertToMoney(item.valorLiquido) }}</td>
            </template>
            <template v-slot:[`item.dataEnvio`]="item">
              <td>{{ getDateTimeBR(item.dataEnvio) }}</td>
            </template>
            <template v-slot:[`item.idUnidade`]="item">
              <td>{{ item.idUnidade }}</td>
            </template>
            <template v-slot:[`item.balao`]="item">
              <td>
                <v-btn
                    v-if="item.comentario && item.comentario !== ''"
                    icon
                    @click="exibirMotivoRecusa(item)"
                >
                  <v-icon
                      color="primaria">
                    pc-mensagem
                  </v-icon>
                </v-btn>
                <v-btn
                    icon
                    @click="conferirNotaFiscal(item)"
                >
                  <v-icon
                      color="primaria">
                    pc-documento
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </DataTable>
        </v-tab-item>

        <!-- CONFERIDA -->
        <v-tab-item>
          <div class="d-flex justify-end">
            <Button
                outlined
                class="font-weight-bold"
                @click="exibirDialogDownloadMultiplo = true, bloquearRefresh = true"
                :disabled="notasConferidasSelecionadas.length <= 0"
            >
              <v-icon
                  left
                  size="20"
              >
                pc-verificado
              </v-icon>
              DOWNLOAD {{ totalNotasConferidasSelecionadas }}
            </Button>
            <Button dark @click="filtrar" class="ml-2">
              <v-icon
                  left
                  size="20"
              >
                pc-filtro
              </v-icon>
              Filtrar
            </Button>
          </div>
          <DataTable
              show-select
              :params="params"
              :headers="headersConferida"
              :reload="reload"
              item-key="idNotaFiscalParceiro"
              v-model="notasConferidasSelecionadas"
              :loading="$store.state.carregandoSilenciosamente"
              :url="urlConferidas"
          >
            <template v-slot:[`item.cpfCnpj`]="item">
              <td>{{ item.cpfCnpj }}</td>
            </template>
            <template v-slot:[`item.nome`]="item">
              <td>{{ item.nome }}</td>
            </template>
            <template v-slot:[`item.vencimento`]="item">
              <td>{{ getVencimento(item.vencimento) }}</td>
            </template>
            <template v-slot:[`item.valorBruto`]="item">
              <td>R$ {{ convertToMoney(item.valorBruto) }}</td>
            </template>
            <template v-slot:[`item.valorImposto`]="item">
              <td>R$ {{ convertToMoney(item.valorImposto) }}</td>
            </template>
            <template v-slot:[`item.valorLiquido`]="item">
              <td>R$ {{ convertToMoney(item.valorLiquido) }}</td>
            </template>
            <template v-slot:[`item.numeroNota`]="item">
              <td
                  v-if="item.falhouDownload"
                  class="numero-nota"
                  :style="{ color: '#CE181E !important' }"
              >
                {{ item.numeroNota }}
              </td>
              <td
                  v-else
                  class="numero-nota"
              >
                {{ item.numeroNota }}
              </td>
            </template>
            <template v-slot:[`item.idUnidade`]="item">
              <td>{{ item.idUnidade }}</td>
            </template>
            <template v-slot:[`item.balao`]="item">
              <td>
                <v-menu offset-x offset-y left top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon color="primaria">
                        pc-pontos
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                      icon
                      @click="conferirNotaFiscal(item, true)"
                  >
                    <v-icon color="primaria">
                      pc-documento
                    </v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      @click="baixarNotaFiscal(item)"
                  >
                    <v-icon color="primaria">
                      pc-download
                    </v-icon>
                  </v-btn>
                  <v-btn
                      v-if="item.comentario && item.comentario !== ''"
                      icon
                      @click="exibirMotivoRecusa(item)"
                  >
                    <v-icon color="primaria">
                      pc-mensagem
                    </v-icon>
                  </v-btn>
                </v-menu>
              </td>
            </template>
          </DataTable>
          <p class="ml-2">Ao trocar de página o check-box será desmarcado</p>
        </v-tab-item>

        <!-- ENVIADA -->
        <v-tab-item>
          <v-row>
            <!-- eslint-disable-next-line -->
              <v-col align="end" class="mr-2">
              <Button dark @click="filtrar">
                <v-icon size="20">
                  pc-filtro
                </v-icon>
                Filtrar
              </Button>
              <Button dark @click="relatorios">
                <v-icon size="20">
                  pc-documento-escrito
                </v-icon>
                Relatório
              </Button>
            </v-col>
          </v-row>
          <DataTable
              :params="params"
              :headers="headersEnviada"
              :reload="reload"
              item-key="idNotaFiscalParceiro"
              :loading="$store.state.carregandoSilenciosamente"
              :url="urlEnviadas"
          >
            <template v-slot:[`item.cpfCnpj`]="item">
              <td>{{ item.cpfCnpj }}</td>
            </template>
            <template v-slot:[`item.nome`]="item">
              <td>{{ item.nome }}</td>
            </template>
            <template v-slot:[`item.vencimento`]="item">
              <td>{{ getVencimento(item.vencimento) }}</td>
            </template>
            <template v-slot:[`item.valorBruto`]="item">
              <td>R$ {{ convertToMoney(item.valorBruto) }}</td>
            </template>
            <template v-slot:[`item.valorImposto`]="item">
              <td>R$ {{ convertToMoney(item.valorImposto) }}</td>
            </template>
            <template v-slot:[`item.valorLiquido`]="item">
              <td>R$ {{ convertToMoney(item.valorLiquido) }}</td>
            </template>
            <template v-slot:[`item.numeroNota`]="item">
              <td
                  v-if="item.falhouDownload"
                  class="numero-nota"
                  :style="{ color: '#CE181E !important' }"
              >
                {{ item.numeroNota }}
              </td>
              <td
                  v-else
                  class="numero-nota"
              >
                {{ item.numeroNota }}
              </td>
            </template>
            <template v-slot:[`item.idUnidade`]="item">
              <td>{{ item.idUnidade }}</td>
            </template>
            <template v-slot:[`item.balao`]="item">
              <td>
                <v-menu offset-x offset-y left top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon color="primaria">
                        pc-pontos
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                      icon
                      @click="conferirNotaFiscal(item, true)"
                  >
                    <v-icon color="primaria">
                      pc-documento
                    </v-icon>
                  </v-btn>
                  <v-btn
                      icon
                      @click="baixarNotaFiscal(item)"
                  >
                    <v-icon color="primaria">
                      pc-download
                    </v-icon>
                  </v-btn>
                  <v-btn
                      v-if="item.comentario && item.comentario !== ''"
                      icon
                      @click="exibirMotivoRecusa(item)"
                  >
                    <v-icon color="primaria">
                      pc-mensagem
                    </v-icon>
                  </v-btn>
                </v-menu>
              </td>
            </template>
          </DataTable>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <DialogFiltrarNotasFiscais :exibir="exibirFiltro"
                               :filtro-cpf-cnpj="filtrarCpfCnpj"
                               :filtro-vencimento="filtrarVencimento"
                               :filtro-status="filtrarStatus"
                               :filtro-numero-nota="filtrarNmrNota"
                               @filtro="aplicarFiltro($event)"
                               @exibir="exibir => exibirFiltro = exibir" />
    <DialogMotivoRecusa ref="dialogMotivoRecusa" :nota-fiscal="notaSelecionada" />
    <DialogConferenciaNotaFiscal :exibir="exibirDialogConferencia" :nota-fiscal="notaSelecionada" @exibir="value => exibirDialogConferencia = value" @proxima="proximaNota" />
    <DialogBaixarMultiplasNotas
        :exibir="exibirDialogDownloadMultiplo"
        :bloquear="bloquearRefresh"
        :lista-ids="listaIds"
        @exibir="valor => exibirDialogDownloadMultiplo = valor"
    />
    <DialogRelatorioNotas
        :exibir="exibirDialogRelatorio"
        @exibir="exibir => exibirDialogRelatorio = exibir"
    />
  </v-container>
</template>

  <script>
  import DialogFiltrarNotasFiscais from '@/components/dialog/DialogFiltrarNotasFiscais'
  import DialogBaixarMultiplasNotas from '@/components/dialog/DialogBaixarMultiplasNotas'
  import DialogRelatorioNotas from '@/components/dialog/DialogRelatorioNotas'
  import { enviarNotaConferida, buscarUrlDownload, buscarNotasPorStatus } from '@/service/notasFiscais'
  import { money, toDateBR, toDateTimeBR } from '@/plugins/formatters'

  export default {
    name: 'GestorNotaFiscal',
    components: {
      DialogFiltrarNotasFiscais,
      DialogBaixarMultiplasNotas,
      DialogRelatorioNotas
    },
    data: () => ({
      bloquearRefresh: false,
      contador: undefined,
      contadorCronometro: 0,
      contadorBloqueado: true,
      notasConferidasSelecionadas: [],
      exibirDialogDownloadMultiplo: false,
      exibirDialogRelatorio: false,
      tab: 0,
      atualizarLista: false,
      exibirFiltro: false,
      exibirDialogConferencia: false,
      reload: false,
      filtrarCpfCnpj: false,
      filtrarVencimento: false,
      filtrarStatus: false,
      filtrarNmrNota: false,
      notaPendenteKey: 0,
      notaSelecionada: {},
      proximasNotasConferencia: [],
      headersPendente: [
        { text: 'CPF/CNPJ', value: 'cpfCnpj' },
        { text: 'Nome parceiro', value: 'nome' },
        { text: 'Vencimento', value: 'vencimento', width: 130 },
        { text: 'Valor Bruto', value: 'valorBruto' },
        { text: 'Imposto renda', value: 'valorImposto' },
        { text: 'Valor líquido', value: 'valorLiquido' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'balao', align: 'center', width: 50 }
      ],
      headersRecebida: [
        { text: 'CPF/CNPJ', value: 'cpfCnpj' },
        { text: 'Nome parceiro', value: 'nome' },
        { text: 'Vencimento', value: 'vencimento', width: 130 },
        { text: 'Valor Bruto', value: 'valorBruto' },
        { text: 'Imposto renda', value: 'valorImposto' },
        { text: 'Valor líquido', value: 'valorLiquido' },
        { text: 'Arquivo anexado em', value: 'dataEnvio' },
        { text: 'ID unidade', value: 'idUnidade' },
        { text: '', value: 'balao', align: 'center', width: 50 }
      ],
      headersConferida: [
        { text: 'CPF/CNPJ', value: 'cpfCnpj' },
        { text: 'Nome parceiro', value: 'nome' },
        { text: 'Vencimento', value: 'vencimento', width: 130 },
        { text: 'Valor Bruto', value: 'valorBruto' },
        { text: 'Imposto renda', value: 'valorImposto', width: 90 },
        { text: 'Valor líquido', value: 'valorLiquido' },
        { text: 'Nota', value: 'numeroNota', width: 90 },
        { text: 'ID unidade', value: 'idUnidade' },
        { text: '', value: 'balao', align: 'center', width: 50 }
      ],
      headersEnviada: [
        { text: 'CPF/CNPJ', value: 'cpfCnpj' },
        { text: 'Nome parceiro', value: 'nome' },
        { text: 'Vencimento', value: 'vencimento', width: 130 },
        { text: 'Valor Bruto', value: 'valorBruto' },
        { text: 'Imposto renda', value: 'valorImposto' },
        { text: 'Valor líquido', value: 'valorLiquido' },
        { text: 'Nota', value: 'numeroNota' },
        { text: 'ID unidade', value: 'idUnidade' },
        { text: '', value: 'balao', align: 'center', width: 50 }
      ],
      pagination: {
        sortBy: [ 'updatedAt' ], sortDesc: [ true ]
      },
      urlPendentes: '/listar-notas-pendentes',
      urlRecebidas: '/listar-notas-status/Recebida',
      urlConferidas: '/listar-notas-status/Conferida',
      urlEnviadas: '/listar-notas-status/Enviada'
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      },
      totalNotasConferidasSelecionadas () {
        return this.notasConferidasSelecionadas.length > 0 ? `(${this.notasConferidasSelecionadas.length})` : ''
      },
      listaIds () {
        return this.notasConferidasSelecionadas.map(nota => nota.idNotaFiscalParceiro)
      }
    },
    watch: {
      async tab (value) {
        this.filtrarCpfCnpj = false
        this.filtrarVencimento = false
        this.filtrarStatus = false
        this.filtrarNmrNota = false

        switch (value) {
          case 0:
            break
          case 1:
            this.proximasNotasConferencia = await buscarNotasPorStatus('Recebida', 100, true)
            break
          case 2:
            this.proximasNotasConferencia = await buscarNotasPorStatus('Conferida', 100, true)
            this.botaoDownload++
            break
          case 3:
            this.proximasNotasConferencia = await buscarNotasPorStatus('Enviada', 100, true)
            break
        }
      },
      atualizarLista () {
        this.buscarNotas()
      },
      exibirDialogConferencia (valor) {
        if (!valor) {
          this.buscarNotas()
        }
      },
      exibirDialogDownloadMultiplo (valor) {
        if (!valor) {
          this.buscarNotas()
        }
      }
    },
    destroyed () {
      clearInterval(this.contador)
    },
    async mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)
      this.cronometro()
      this.contadorBloqueado = false
      switch (this.tab) {
        case 0:
          break
        case 1:
          this.proximasNotasConferencia = await buscarNotasPorStatus('Recebida', 100, true)
          break
        case 2:
          this.proximasNotasConferencia = await buscarNotasPorStatus('Conferida', 100, true)
          break
        case 3:
          this.proximasNotasConferencia = await buscarNotasPorStatus('Enviada', 100, true)
          break
      }
    },
    methods: {
      getDateTimeBR (data) {
        return toDateTimeBR(data)
      },
      cronometro () {
        this.contador = setInterval(() => {
          if (!this.contadorBloqueado) {
            if (this.contadorCronometro < this.$session.get('configuracoes').segundosRefreshNotas) {
              this.contadorCronometro += 1
            } else {
              if (this.bloquearRefresh === true) return
              this.buscarNotas()
              this.contadorCronometro = 0
            }
          } else {
            this.contadorCronometro = 0
          }
        }, 1000)
      },
      filtrar () {
        switch (this.tab) {
          case 0:
            this.filtrarCpfCnpj = true
            this.filtrarStatus = true
            break
          case 1:
            this.filtrarCpfCnpj = true
            break
          case 2:
            this.filtrarCpfCnpj = true
            this.filtrarNmrNota = true
            break
          case 3:
            this.filtrarVencimento = true
            this.filtrarCpfCnpj = true
            this.filtrarNmrNota = true
            break
        }
        this.exibirFiltro = true
      },
      relatorios () {
        this.exibirDialogRelatorio = true
      },
      aplicarFiltro (filtro) {
        this.urlPendentes = '/listar-notas-pendentes'
        this.urlRecebidas = '/listar-notas-status/Recebida'
        this.urlConferidas = '/listar-notas-status/Conferida'
        this.urlEnviadas = '/listar-notas-status/Enviada'
        switch (this.tab) {
          case 0:
            this.urlPendentes += filtro
            break
          case 1:
            this.urlRecebidas += filtro
            break
          case 2:
            this.urlConferidas += filtro
            break
          case 3:
            this.urlEnviadas += filtro
            break
        }
        this.buscarNotas()
      },
      exibirMotivoRecusa (nota) {
        this.notaSelecionada = nota
        this.$refs.dialogMotivoRecusa.show()
      },
      getVencimento (vencimento) {
        return toDateBR(vencimento.split('T')[0])
      },
      conferirNotaFiscal (nota) {
        this.notaSelecionada = nota
        this.exibirDialogConferencia = true
      },
      async baixarNotaFiscal (nota) {
        try {
          const url = await buscarUrlDownload(nota.idNotaFiscalParceiro)
          window.open(process.env.VUE_APP_WS_ROOT + url)

          if (nota.status === 'Conferida') {
            await enviarNotaConferida(nota.idNotaFiscalParceiro)
          }

          this.buscarNotas()
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      proximaNota (idNotaSelecionada) {
        this.buscarNotas()
        this.exibirDialogConferencia = false
        this.proximasNotasConferencia.splice(this.proximasNotasConferencia.findIndex(nota => {
          return nota.idNotaFiscalParceiro === idNotaSelecionada
        }), 1)
        this.notaSelecionada = this.proximasNotasConferencia[0]

        if (this.notaSelecionada) {
          this.exibirDialogConferencia = true
        } else if (this.tab === 1) {
          this.$toast.success('Bom trabalho, todas as notas recebidas foram conferidas!')
        }
        this.buscarNotas()
      },
      convertToMoney (value) {
        return money(value)
      },
      buscarNotas () {
        this.reload = !this.reload
        this.notasConferidasSelecionadas = []
      }
    }
  }
  </script>

  <style>
    .botao-filtrar {
      padding: 0 2rem !important;
    }

    .v-tab {
      background: var(--white);
      color: var(--darkgray) !important;
      text-transform: capitalize;
      padding: 16px 64px;
      border: 1px solid #DEE2E6;
      box-sizing: border-box;
      border-radius: 4px 4px 0px 0px;
    }

    .v-tab--active {
      background: var(--darkgray);
      color: var(--white) !important;
    }

    .v-tabs-items {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 0px 8px 8px 8px;
    }

    .capitalize {
      text-transform: capitalize;
    }

    .icon-center {
      text-align: center;
    }

    .titulo-nota {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: var(--black);
      margin-top: 25px;
    }

    .subtitulo {
      font-size: 14px;
    }

    .numero-nota {
      font-weight: 700;
    }
    button {
      margin: 5px;
    }

  </style>
