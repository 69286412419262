export const Mensagens = {
  ERRO: 'Algo deu errado. O servidor não está respondendo conforme esperado. Tente novamente mais tarde.'
}

export const TipoPerfil = {
  SUBLOGIN: 'SUBLOGIN',
  PARCEIRO: 'PARCEIRO',
  GESTOR: 'GESTOR',
  MASTER: 'MASTER',
  MANUTENCAO_TERCEIRO: 'MANUTENCAO_TERCEIRO',
  GESTAO_UNIFICADA: 'GESTAO_UNIFICADA'
}

export const TipoPerfilNewcon = {
  PARCEIRO: 'parceiro',
  COLABORADOR: 'colaborador',
  SUBLOGIN: 'sublogin'
}
