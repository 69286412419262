<template>
  <v-dialog
      persistent
      max-width="200"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="exibir"
  >
    <v-card class="px-1 pb-3">
      <v-card-title class="d-flex justify-end">
        <v-btn
            icon
            @click="fechar"
        >
          <v-icon size="10">
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formulario">
          <v-row>
            <v-col cols="12">
              <TextField
                  label="Nome"
                  v-model="nome"
                  maxlength="30"
              />
            </v-col>
            <v-col cols="12">
              <TextField
                  label="CPF/CNPJ"
                  v-model="cpfCnpj"
                  hide-details="auto"
                  v-mask="['###.###.###-##','##.###.###/####-##']"
                  :rules="[this.$rules.documento]"
              />
              <h4>Apenas números</h4>
            </v-col>
            <v-col cols="12">
              <TextField
                  label="Usuario"
                  hide-details="auto"
                  v-model="login"
                  maxlength="20"
              />
              <h4>Login vinculado à revenda</h4>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <Button
                small
                outlined
                block
                @click="limpar"
            >
              Limpar
            </Button>
          </v-col>
          <v-col cols="6">
            <Button
                small
                block
                @click="filtrar"
            >
              Filtrar
            </Button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { unmask } from '@/plugins/helpers'
  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      valido: false,
      nome: undefined,
      cpfCnpj: undefined,
      login: undefined
    }),
    watch: {
      exibir () {
        this.nome = undefined
        this.cpfCnpj = undefined
        this.login = undefined
      }
    },
    methods: {
      filtrar () {
        let filtro = {
          'nome': this.nome,
          'cpf_cnpj': unmask(this.cpfCnpj),
          'login': this.login
        }
        this.$emit('filtro', filtro)
        this.fechar()
      },
      limpar () {
        this.nome = undefined
        this.cpfCnpj = undefined
        this.login = undefined
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>
