import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function listarNotificacoes (login, dataAtual, horaAtual) {
  try {
    const { data } = await http.get(`jornada-cota/listar-notificacao-login-datachegada?login=${login}&dataChegada=${dataAtual} ${horaAtual}`, { options: { silent: true } })
    return data
  } catch (erro) {
    console.error(erro)
    // throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function notificacoesIniciais (login) {
  try {
    const { data } = await http.get(`jornada-cota/listar-ultimas-notificacao?login=${login}`, { options: { silent: true } })
    return data
  } catch (erro) {
    // throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function meusClientespdf (filtrosUrl) {
  try {
    const { data } = await http.get('/jornada-cota/cotas/pdf' + filtrosUrl + '&titulo=JornadaDaCota', {
      responseType: 'blob'
    })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function meusClientesexcel (filtrosUrl) {
  try {
    const { data } = await http.get('/jornada-cota/cotas/excel' + filtrosUrl + '&titulo=JornadaDaCota', {
      responseType: 'blob'
    })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function unidadesLogin (login) {
  try {
    const { data } = await http.get(`/jornada-cota/unidades-login?login=${login}&loginPrincipal=`, { options: { silent: true } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}
