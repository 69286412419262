<template>
  <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      color="primaria"
      :class="botaoDelineado + ' rounded-lg font-weight-bold'"
  >
    <slot />
  </v-btn>
</template>

<script>

  export default {
    name: 'Button',
    computed: {
      botaoDelineado () {
        return this.$attrs.outlined === undefined ? 'textosecundario--text' : ''
      }
    }
  }

</script>

<style scoped>

  .v-btn {
    /* text-transform: uppercase; */
    text-transform: unset;
    height: 2.5rem !important;
    /* border-radius: 8px; */
  }
  @media screen and (max-width: 960px){
    .v-btn {
      height: 3rem !important;
    }
  }
/*
  .v-btn:not(.v-btn--round).v-size--small {
    height: 38px !important;
  } */

</style>
