<template>
  <v-container fluid>
    <h1 class="titulo">Exceção de cadastro</h1>
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-row class="mb-5 mb-md-0" no-gutters>
            <div class="horizontal">
              <div>
                <TextField
                    :disabled="this.$route.params.login ? true : false"
                    label="CPF/CNPJ*"
                    v-model="cpfCnpj"
                    v-mask="['###.###.###-##','##.###.###/####-##']"
                    :rules="[this.$rules.required, this.$rules.documento]"
                >
                </TextField>
              </div>
              <div>
                <Button
                    id="ButtonSalvar"
                    small
                    class="primaria"
                    :disabled="disableSalvar"
                    @click="confimarInclusao"
                >
                  SALVAR
                </Button>
              </div>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card>
        <template>
          <v-data-table
              dense
              :headers="headers"
              :items="registro"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="id"
              show-expand
              class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Registro:</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div>
                  <div v-if="item.comissionados" class="info-cont">
                    <p>Comissionados Afetados:</p>
                    <div v-for=" i in item.comissionados" :key="i" class="chip-vermelho">
                      <div>{{ i }}</div>
                    </div>
                  </div>
                  <div v-if="item.sublogins" class="info-cont">
                    <p>Sublogins Afetados:</p>
                    <div v-for=" i in item.sublogins" :key="i" class="chip-vermelho">
                      <div>{{ i }}</div>
                    </div>
                  </div>
                </div>
              </td>
            </template>
            <template v-slot:item.documento="{ item }">
              <div>
                {{ mascaraDocumento(String(item.documento)) }}
              </div>
            </template>
            <template v-slot:item.nome="{ item }">
              <div>
                {{ String(item.nome).toLocaleUpperCase() }}
              </div>
            </template>
            <template v-slot:item.ex_colaborador="{ item }">
              <div :class="{'chip-vermelho': item.ex_colaborador, 'chip-verde': !item.ex_colaborador}">
                {{ item.ex_colaborador ? 'Sim' : 'Não' }}
              </div>
            </template>
            <template v-slot:item.executado="{ item }">
              <div :class="{'chip-verde': item.executado, 'chip-vermelho': !item.executado}">
                {{ item.executado ? 'Sim' : 'Não' }}
              </div>
            </template>
            <template v-slot:item.login_executor="{ item }">
              <div>
                {{ item.login_executor ? item.login_executor : '--' }}
              </div>
            </template>
            <template v-slot:item.sublogins="{ item }">
              <div>
                {{ item.sublogins !== null ? item.sublogins.length : '0' }}
              </div>
            </template>
            <template v-slot:item.comissionados="{ item }">
              <div>
                {{ item.comissionados !== null ? item.comissionados.length : '0' }}
              </div>
            </template>
            <template v-slot:item.data_execucao="{ item }">
              <div>
                {{ item.data_execucao !== "" ? ajustarData(item.data_execucao) : "--" }}
              </div>
            </template>
          </v-data-table>
        </template>
      </v-card>
      <v-card-actions>
        <div
            class="font-weight-bold text-button"
            style="cursor: pointer"
        >
          <v-icon
              color="black"
              class="pr-2"
          >
            pc-seta-esquerda
          </v-icon>
          <span>VOLTAR</span>
        </div>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <DialogIncluirExcecao :documento="cpfCnpj" :exibir="exibirDialogConfirmacao" @exibir="exibir => exibirDialogConfirmacao = exibir" @reload="reload" />
  </v-container>
</template>

  <script>
  import { buscarExcecaoUsuarios } from '@/service/comissionado'
  import DialogIncluirExcecao from '../components/dialog/DialogIncluirExcecao.vue'
  export default {
    name: 'GestorExcecaoUsuario',
    components: { DialogIncluirExcecao },

    data: () => ({
      exibirDialogConfirmacao: false,
      cpfCnpj: '',
      expanded: [],
      singleExpand: true,
      registro: [],
      headers: [
        { text: 'documento', align: 'start', sortable: true, value: 'documento' },
        { text: 'nome', sortable: true, value: 'nome' },
        { text: 'colaborador', sortable: false, value: 'ex_colaborador' },
        { text: 'sublogins', sortable: false, value: 'sublogins' },
        { text: 'comissionados', sortable: false, value: 'comissionados' },
        { text: 'executado', sortable: true, value: 'executado' },
        { text: 'executor', value: 'login_executor' },
        { text: 'data', value: 'data_execucao' }
      ]
    }),
    computed: {
      disableSalvar () {
        if (this.cpfCnpj.length >= 14) {
          return false
        }
        return true
      }
    },
    async beforeMount () {
      try {
        this.registro = await buscarExcecaoUsuarios()
      } catch (error) {
        console.error(error)
      }
    },
    async mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)
    },
    methods: {
      getIsex_colaborador (data = false) {
        if (data === false) {
          return 'red'
        } else {
          return 'green'
        }
      },
      getCount (dataArray = []) {
        return dataArray.length
      },
      mascaraDocumento (documento) {
        if (documento.length < 13) {
          documento = documento.replace(/\D/g, '') // Remove todos os caracteres não numéricos
          documento = documento.replace(/(\d{3})(\d)/, '$1.$2') // Insere o primeiro ponto
          documento = documento.replace(/(\d{3})(\d)/, '$1.$2') // Insere o segundo ponto
          documento = documento.replace(/(\d{3})(\d{1,2})$/, '$1-$2') // Insere o hífen
          return documento
        } else {
          documento = documento.replace(/\D/g, '') // Remove todos os caracteres não numéricos
          documento = documento.replace(/(\d{2})(\d)/, '$1.$2') // Insere o primeiro ponto
          documento = documento.replace(/(\d{3})(\d)/, '$1.$2') // Insere o segundo ponto
          documento = documento.replace(/(\d{3})(\d)/, '$1/$2') // Insere a barra
          documento = documento.replace(/(\d{4})(\d{1,2})$/, '$1-$2') // Insere o hífen
          return documento
        }
      },

      confimarInclusao () {
        this.exibirDialogConfirmacao = true
      },
      async reload () {
        this.registro = []
        this.registro = await buscarExcecaoUsuarios()
      },
      ajustarData (data = '') {
        let dia = new Date(data)
        let d = dia.getDate()
        let m = dia.getMonth() + 1
        let a = dia.getFullYear()
        let h = dia.getHours()
        let mn = dia.getMinutes()
        return `${this.padTo2Digits(d)}/${this.padTo2Digits(m)}/${a} ${this.padTo2Digits(h)}:${this.padTo2Digits(mn)}`
      },
      padTo2Digits (num) {
        return num.toString().padStart(2, '0')
      }
    }
  }
  </script>

  <style scoped>
  .subtitulo {
    font-size: 14px;
  }

  .horizontal {
    display: flex;
    align-items: center;
  }

  .chip-verde {
    background-color: green;
    color: white;
    border-radius: 999px;
    padding: 4px 8px;
    display: inline-block;
  }
  .info-cont{
    margin: 5px;
  }

  .chip-vermelho {
    background-color: red;
    color: white;
    border-radius: 999px;
    padding: 4px 8px;
    display: inline-block;
    margin: 2px;
  }
  </style>
