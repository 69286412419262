export let state
state = {
  erroUnicred: false
}
export const getters = {
  getErroUnicred: state => {
    return state.erroUnicred
  }
}

export const mutations = {
  setErroUnicred (state, erro) {
    state.erroUnicred = erro
  }
}

export const actions = {
  async setErroUnicred ({ commit }, payload) {
    commit('setErroUnicred', payload)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
