import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function buscarInformacoesVisao (login, idUnidade) {
  try {
    const { data } = await http.get(`/visao-administrativa/${login}?idUnidade=${idUnidade}`)
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}
