<template>
  <v-container fluid>
    <v-col class="d-sm-none" cols="3">
      <ButtonIcon
          id="ButtonVoltar"
          texto="VOLTAR"
          icone="pc-seta-esquerda"
          color="iconcolor"
          small
          block
          icon
          @click="irParaCadastroUsuario"
      />
    </v-col>
    <v-row
        no-gutters
        class="mb-5 mb-md-0"
    >
      <h1
          v-if="!cadastro"
          class="titulo mr-5"
      >
        Parceiro: {{ parceria.nomeFantasia }} <br>
        CNPJ: {{ parceria.cpfCnpj }}
      </h1>
      <h1
          v-else
          class="titulo mr-5"
      >
        Inclusão de Parceria
      </h1>
    </v-row>
    <v-card class="mt-5" v-if="!gestaoUnificada">
      <v-card-text>
        <v-form v-model="valido">
          <v-row>
            <v-col
                md="6"
                cols="12"
                class="d-flex align-center"
            >
              <p id="titulo-comissionado">
                <span v-if="!cadastro">Cadastrado em </span>
                <span v-else>Data de cadastro </span>
                <b>{{ parceria.dataCadastro }}</b>
              </p>
            </v-col>
            <v-col
                md="6"
                cols="12"
                class="d-flex justify-end"
            >
              <Checkbox
                  label="ativo"
                  v-model="parceiro.ativo"
                  :disabled="cadastro"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <SelectField
                  disabled
                  v-model="parceria.coligada"
                  label="Coligada"
                  :items="$session.get('configuracoes').coligadas"
                  item-text="nome"
                  item-value="id"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  disabled
                  label="CPF/CNPJ"
                  v-model="parceria.cpfCnpj"
                  v-mask="['###.###.###-##','##.###.###/####-##']"
              />
            </v-col>
            <v-col
                md="6"
                cols="12"
            >
              <TextField
                  label="Nome"
                  v-model="parceria.nomeFantasia"
                  disabled
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
            >
              <TextField
                  label="E-mail gerente regional"
                  v-model="parceria.loginRegional"
                  maxlength="65"
                  :disabled="!cadastro"
                  :rules=[$rules.required,$rules.email]
              />
            </v-col>
            <v-col
                md="7"
                cols="12"
            >
              <textField
                  maxlength="65"
                  label="E-mail do gerente comercial"
                  v-model="parceria.emailGererenteComercial"
                  tooltip="Múltiplos e-mails separados por “;”"
                  :rules=[$rules.required,$rules.emailPontoVirgula]
              />
            </v-col>
            <v-col
                md="2"
                cols="12"
                class="d-flex align-center"
            >
              <Checkbox
                  label="Atualização global"
                  v-model="parceria.atualizacaoGlobalGerente"
                  :disabled="cadastro"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
            >
              <TextField
                  disabled
                  label="CFO"
                  v-model="parceria.cfo"
              />
            </v-col>
            <v-col
                md="7"
                cols="12"
            >
              <TextField
                  maxlength="140"
                  label="E-mail responsável administrativo"
                  tooltip="Múltiplos e-mails separados por “;”"
                  v-model="parceria.emailResponsavelAdmnistrativo"
                  :rules=[$rules.required,$rules.emailPontoVirgula]
              />
            </v-col>
            <v-col
                md="2"
                cols="12"
                class="d-flex align-center"
            >
              <Checkbox
                  label="Atualização global"
                  v-model="parceria.atualizacaoGlobalResponsavelAdmnistrativo"
                  :disabled="cadastro"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="!gestaoUnificada">
        <v-row class="justify-sm-space-between px-2">
          <v-col class="d-none d-sm-block" cols="12" md="2">
            <Button
                small
                block
                icon
                @click="irParaCadastroUsuario"
                id="botaoVoltar"
            >
              <v-icon
                  left
                  color="iconcolor"
              >
                pc-seta-esquerda
              </v-icon>
              <span class="iconcolor--text">VOLTAR</span>
            </Button>
          </v-col>
          <v-col cols="12" md="2">
            <Button
                id="ButtonSalvar"
                icone="pc-seta-esquerda"
                small
                block
                @click="salvarParceria"
                :disabled="!valido"
            >
              SALVAR
            </Button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card
        v-if="!cadastro"
        class="mt-5"
    >
      <v-card-text>
        <span class="titulo-comissionado">Comissionados</span>
        <DataTable
            :filter="filters"
            :headers="headers"
            :params="params"
            item-key="id"
            :itens-externos="comissionados"
            :show-pagination="false"
            :loading="$store.state.carregandoSilenciosamente"
            elevation="0"
        >
          <template v-slot:[`item.login`]="item">
            <td>{{ item.login }}</td>
          </template>
          <template v-slot:[`item.cpfCnpj`]="item">
            <td>{{ item.cpfCnpj }}</td>
          </template>
          <template v-slot:[`item.ativo`]="item">
            <td>
              {{ item.ativo ? 'Sim' : 'Não' }}
            </td>
          </template>
          <template v-slot:[`item.editar`]=item>
            <td class="icon-center">
              <v-btn
                  icon
                  @click="detalhesComissionado(item.login)"
              >
                <v-icon
                    color="primaria">
                  pc-editar
                </v-icon>
              </v-btn>
            </td>
          </template>
        </DataTable>
      </v-card-text>
      <v-card-actions v-if="!gestaoUnificada">
        <v-col
            cols="12"
            class="pa-0"
        >
          <v-col
              md="4"
              lg="3"
              xl="2"
              cols="12"
              class="ml-auto"
          >
            <ButtonIcon
                block
                small
                icone="pc-mais"
                color="primaria"
                texto="NOVO COMISSIONADO"
                @click="novoComissionado"
            />
          </v-col>
        </v-col>
      </v-card-actions>
    </v-card>
    <DialogAlertaSair
        :exibir="exibirDialogSair"
        @exibir="valor => exibirDialogSair = valor"
    />
  </v-container>
</template>

<script>

  import { formatarDataAnoMesDia, formatarObjetoDataAnoMesDia } from '@/plugins/helpers'
  import { consultarParceria, atualizar, cadastrar } from '@/service/parceria'
  import { buscarComissionado } from '@/service/comissionado'
  import DialogAlertaSair from '@/components/dialog/DialogAlertaSair'
  import { gestaoUnificada } from '@/directives/gestaoUnificada'

  export default {
    components: { DialogAlertaSair },
    data: () => ({
      exibirDialogSair: false,
      valido: false,
      gestaoUnificada: false,
      cadastro: false,
      parceria: {
        cfo: '',
        dataCadastro: '',
        nomeFantasia: '',
        cpfCnpj: '',
        loginRegional: '',
        status: false,
        emailGererenteComercial: '',
        tipoCadastro: '',
        emailResponsavelAdmnistrativo: '',
        coligada: undefined,
        atualizacaoGlobalGerente: false,
        atualizacaoGlobalResponsavelAdmnistrativo: false
      },
      filtro: {
        parceria: ''
      },
      headers: [
        { text: 'Login', value: 'login' },
        { text: 'CPF/CNPJ', value: 'cpfCnpj' },
        { text: 'Ativo', value: 'ativo' },
        { text: '', value: 'editar', align: 'center', width: 50 }
      ],
      filters: {},
      pagination: {
        sortBy: [ 'titulo1' ], sortDesc: [ true ]
      },
      parceiro: {
        ativo: false
      },
      comissionados: [],
      emailAdmInicial: '',
      emailComercialInicial: ''
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    async mounted () {
      this.$store.commit('setRotaAtual', 'Parcerias')

      this.gestaoUnificada = gestaoUnificada()

      try {
        if (this.$route.query.coligada) {
          this.cadastro = true
          this.parceiro.ativo = true
        }

        this.parceria = await consultarParceria(this.$route.params.cnpj, this.$route.query.coligada)
        this.parceria.dataCadastro = !this.cadastro ? formatarDataAnoMesDia(this.parceria.dataCadastro) : formatarObjetoDataAnoMesDia(Date.now())
        this.emailAdmInicial = this.parceria.emailResponsavelAdmnistrativo
        this.emailComercialInicial = this.parceria.emailGererenteComercial

        if (!this.parceria.cadastro) {
          this.parceiro.ativo = this.parceria.status

          if (this.cadastro) {
            this.$toast.error('Revenda já cadastrada!')
          }

          this.cadastro = false
          this.comissionados = await buscarComissionado(this.parceria.cfo)
        }
      } catch (erro) {
        this.$toast.error(erro)
      }
    },
    methods: {
      irParaCadastroUsuario (botao = false) {
        if (botao) {
          this.exibirDialogSair = true
        } else {
          this.$router.go(-1)
        }
      },
      detalhesComissionado (login) {
        this.$router.push({
          name: 'Comissionado',
          params: {
            cnpj: this.$route.params.cnpj,
            login
          }
        })
      },
      async salvarParceria () {
        if (this.$route.query.coligada) {
          try {
            const parceria = {
              coligada: this.parceria.coligada,
              cpfCnpj: this.parceria.cpfCnpj.replace(/(\\|-|,|\.)/g, ''),
              nomeRevenda: this.parceria.nomeFantasia,
              emailResponsavelAdmnistrativo: this.parceria.emailResponsavelAdmnistrativo,
              emailGerenteComercial: this.parceria.emailGererenteComercial,
              emailGeranteRegional: this.parceria.loginRegional
            }

            const { mensagem } = await cadastrar(parceria)
            this.$router.push({ query: {} })
            this.$router.go()
            this.$toast.success(mensagem)
          } catch (erro) {
            this.$toast.error(erro)
          }
        } else {
          try {
            const usuarios = {
              novoEmailResponsavelAdministrativo: this.parceria.emailResponsavelAdmnistrativo,
              novoEmailGerenteComercial: this.parceria.emailGererenteComercial,
              emailAtualResponsavelAdministrativo: this.emailAdmInicial,
              emailAtualGerenteComercial: this.emailComercialInicial,
              status: this.parceiro.ativo,
              atualizarEmailResponsavelAdministrativoGlobalmente: this.parceria.atualizacaoGlobalGerente,
              atualizarEmailGerenteComercialGlobalmente: this.parceria.atualizacaoGlobalResponsavelAdmnistrativo
            }

            const resposta = await atualizar(this.parceria.cfo, usuarios)
            this.$toast.success(resposta.mensagem)
            this.$router.go()
          } catch (erro) {
            this.$toast.error(erro)
          }
        }
      },
      novoComissionado () {
        this.$router.push({
          name: 'Comissionado',
          query: {
            cfo: this.parceria.cfo,
            emailResponsavelAdministrativo: this.parceria.emailResponsavelAdmnistrativo,
            emailGenrenteComercial: this.parceria.emailGererenteComercial
          },
          params: {
            cnpj: this.$route.params.cnpj
          }
        })
      }
    }
  }

</script>

<style>

  #titulo-comissionado {
    font-size: 16px;
    font-weight: normal;
    margin: 0 !important;
  }

  .subtitulo,
  .titulo-parceiro {
    font-size: 14px;
    font-weight: normal;
  }

  .texto-informativo-sublogin {
    font-size: 14px;
    color: #697077;
  }

  .sublogin-ativo {
    font-size: 14px;
    color: var(--success);
    font-weight: bold;
  }

  #botaoVoltar span {
    margin-right: auto !important;
  }

</style>
