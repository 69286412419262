import { TipoPerfil } from '@/plugins/constants'
import session from '@/plugins/session'

export function gestaoUnificada () {
  let empresa = session.get('usuario').perfil

  switch (empresa) {
    case TipoPerfil.GESTAO_UNIFICADA:
      return true
    default:
      return false
  }
}
