import Vue from 'vue'
import { mask } from 'vue-the-mask'

import Rules from '@/plugins/validation'

import TextField from '@/components/TextField'
import SelectField from '@/components/SelectField'
import DecimalTextField from '@/components/DecimalTextField'
import Button from '@/components/Button'
import PasswordField from '@/components/PasswordField'
import Loading from '@/components/Loading'
import DataTable from '@/components/DataTable'
import FilterTextField from '@/components/FilterTextField'
import AjudaTooltip from '@/components/AjudaTooltip'
import Checkbox from '@/components/Checkbox'
import Alert from '@/components/Alert'
import ColorPicker from '@/components/ColorPicker'
import DatePicker from '@/components/DatePicker'
import YearPicker from '@/components/YearPicker'
import Chip from '@/components/Chip'
import TextArea from '@/components/TextArea'
import ButtonIcon from '@/components/ButtonIcon'
import FileInput from '@/components/FileInput'
import DialogCadastrarDocumento from '@/components/dialog/DialogCadastrarDocumento'
import DialogCadastrarVersaoDocumento from '@/components/dialog/DialogCadastrarVersaoDocumento'
import DialogMotivoRecusa from '@/components/dialog/DialogMotivoRecusa'
import DialogConferenciaNotaFiscal from '@/components/dialog/DialogConferenciaNotaFiscal'
import CardComissao from '@/components/card/CardComissao'
import CardRendimento from '@/components/card/CardRendimento'
import Combobox from '@/components/Combobox'
import DialogConfirmaEnvioDocumentos from '@/components/dialog/DialogConfirmaEnvioDocumentos.vue'

const components = {
  Loading,
  TextField,
  SelectField,
  DecimalTextField,
  Button,
  PasswordField,
  DataTable,
  FilterTextField,
  AjudaTooltip,
  Checkbox,
  Alert,
  ColorPicker,
  DatePicker,
  Chip,
  TextArea,
  ButtonIcon,
  FileInput,
  DialogCadastrarDocumento,
  DialogCadastrarVersaoDocumento,
  DialogMotivoRecusa,
  DialogConfirmaEnvioDocumentos,
  YearPicker,
  CardComissao,
  CardRendimento,
  DialogConferenciaNotaFiscal,
  Combobox
}

Vue.mixin({
  components,
  directives: { mask },
  computed: {
    $rules: () => Rules
  }
})
