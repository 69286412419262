<template>
  <v-container fluid>
    <h1 class="titulo">Cadastro de parceiros</h1>
    <v-row>
      <v-spacer />
      <v-col v-if="!gestaoUnificada"
             lg="2"
             md="3"
             cols="13">
        <ButtonIcon
            small
            block
            outlined
            icone="pc-adicionar-usuario"
            color="primaria"
            texto="EXCEÇÃO USARIO"
            @click="execaoUsuario"
        />
      </v-col>
      <v-col v-if="!gestaoUnificada"
             lg="2"
             md="3"
             cols="13"
      >
        <ButtonIcon
            small
            block
            outlined
            icone="pc-mais"
            color="primaria"
            texto="NOVA PARCERIA"
            @click="cadastrarParceiro"
        />
      </v-col>
      <v-col
          lg="2"
          md="3"
          cols="12"
      >
        <ButtonIcon
            small
            block
            icone="pc-filtro"
            color="primaria"
            texto="FILTRAR"
            @click="filtrar"
        />
      </v-col>
    </v-row>
    <DataTable
        silent
        class="mt-5"
        item-key="cnpj"
        :params="params"
        :filter="filters"
        :headers="headers"
        :url="urlDataTable"
        :loading="$store.state.carregandoSilenciosamente"
        :key="dataTableKey"
    >
      <template v-slot:[`item.revenda`]="item">
        <td>{{ item.revenda }}</td>
      </template>
      <template v-slot:[`item.cnpj`]="item">
        <td>{{ item.cnpj }}</td>
      </template>
      <template v-slot:[`item.cfo`]="item">
        <td>{{ parseInt(item.cfo) }}</td>
      </template>
      <template v-slot:[`item.ativo`]="item">
        <td v-if="item.ativo">Sim</td>
        <td v-else>Não</td>
      </template>
      <template v-slot:[`item.editar`]="item">
        <td class="icon-center">
          <v-btn
              icon
              @click="editarParceiro(item)"
          >
            <v-icon color="primaria">
              pc-editar
            </v-icon>
          </v-btn>
        </td>
      </template>
    </DataTable>
    <DialogCadastroParceria
        :exibir="exibirDialogCadastro"
        @exibir="exibir => exibirDialogCadastro = exibir"
    />
    <DialogFiltrarParceria
        :exibir="exibirDialogFiltro"
        @exibir="exibir => exibirDialogFiltro = exibir"
        @filtro="aplicarFiltro"
    />
  </v-container>
</template>

<script>
  import { pushRoute } from '@/plugins/helpers'
  import DialogCadastroParceria from '@/components/dialog/DialogCadastroParceria'
  import DialogFiltrarParceria from '@/components/dialog/DialogFiltrarParceria'
  import { gestaoUnificada } from '@/directives/gestaoUnificada'

  export default {
    name: 'ListaParceria',
    components: { DialogCadastroParceria, DialogFiltrarParceria },
    data: () => ({
      gestaoUnificada: false,
      urlDataTable: '',
      dataTableKey: 1,
      exibirDialogCadastro: false,
      exibirDialogFiltro: false,
      filtro: {
        nome: '',
        cpfCnpj: '',
        usuario: ''
      },
      headers: [
        { text: 'Parceria', value: 'revenda' },
        { text: 'CPF/CNPJ', value: 'cnpj' },
        { text: 'CFO', value: 'cfo' },
        { text: 'Ativo', value: 'ativo', width: 10 },
        { value: 'editar', width: 10, sortable: false }
      ],
      filters: {},
      pagination: {
        sortBy: [], sortDesc: [ true ]
      }
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)
      this.gestaoUnificada = gestaoUnificada()
      this.urlDataTable = this.gestaoUnificada ? '/gestao-unificada/listar' : '/listar-parcerias'
    },
    methods: {
      editarParceiro ({ cnpj }) {
        this.$router.push({
          name: 'CadastroParceria',
          params: {
            cnpj: cnpj.replace(/(\/|-|\.)/g, '')
          }
        })
      },
      execaoUsuario () {
        pushRoute('ExecaoUsuario')
      },
      cadastrarParceiro () {
        this.exibirDialogCadastro = true
      },
      filtrar () {
        this.exibirDialogFiltro = true
      },
      aplicarFiltro (filtro) {
        this.urlDataTable = this.gestaoUnificada ? `/gestao-unificada/listar${filtro}` : `/listar-parcerias${filtro}`
        this.dataTableKey++
      }

    }
  }

</script>
