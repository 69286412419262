import SecureLS from 'secure-ls'

let storage = window.localStorage

if (process.env.VUE_APP_SECURED === 'true') {
  const secure = new SecureLS({ encodingType: 'aes' })

  storage = {
    getItem (key) {
      return secure.get(key)
    },
    setItem (key, value) {
      secure.set(key, value)
    },
    removeItem (key) {
      secure.remove(key)
    },
    clear () {
      secure.clear()
    },
    get length () {
      return secure.getAllKeys().length
    },
    key (i) {
      return secure.getAllKeys()[i]
    }
  }
}

export default storage
