<template>
  <v-dialog
      persistent
      width="300"
      v-model="exibir"
  >
    <v-card class="pb-1">
      <v-card-title>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              color="primaria"
              size="10"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text><h3>Atenção:</h3></v-card-text>
      <v-card-text><p>Você esta prestes a inativar todos os usuários vinculados ao documento: </p><p class="alert">{{ documento }}<br> Esta ação é irreversível!</p></v-card-text>
      <v-card-actions>
        <Button
            small
            @click="fechar"
        >
          Cancelar
        </Button>
        <v-spacer />
        <Button
            small
            @click="salvar"
        >
          OK
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { incluirExcecaoUsuario } from '@/service/comissionado'
  import { pushRoute } from '@/plugins/helpers'
  export default {
    props: {
      documento: String,
      exibir: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      async salvar () {
        try {
          await incluirExcecaoUsuario(this.documento.replace(/[^0-9]/g, ''))
          this.sucesso()
        } catch (error) {
          this.$toast.error(error)
          this.$emit('exibir', false)
        }
      },
      sucesso () {
        this.$toast.success('Documento incluido com sucesso!')
        this.$emit('exibir', false)
        this.$emit('reload')
        pushRoute('ExecaoUsuario')
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>
<style scoped>
    h3{
        color: red;

    }
    p{
        color: #000;
    }
    .alert{
        color: red;
        font-weight: bold;
    }
</style>
