<template>
  <v-card>
    <v-card-text>
      <v-icon color="primaria">
        pc-calendario
      </v-icon>
      <span class="mes">{{ titulo }}</span>
      <div class="mt-2">
        <span class="descricao">{{ descricao }}</span>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
          icon
          class="ml-auto"
          color="primaria"
      >
        <v-icon>pc-download</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'CardComissao',
    props: {
      titulo: String,
      descricao: String
    }
  }
</script>

<style scoped>
  .mes {
    text-transform: capitalize;
    font-weight: 600 !important;
    font-size: 18px;
    margin-left: 10px;
    color: var(--black);
  }

  .descricao {
    margin-top: 10px;
    color: var(--black);
  }
</style>
