<template>
  <v-dialog
      persistent
      max-width="650"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="exibir"
  >
    <v-card>
      <v-card-title>
        <span class="titulo-dialog">Filtrar</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              size="10"
              color="primaria"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
            v-model="valido"
            @submit.prevent="filtrar"
        >
          <v-row style="position: relative">
            <v-divider vertical class="divider" v-show="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"></v-divider>
            <v-col cols="12" md="5" class="py-0">
              <SelectField
                  label="Tipo"
                  maxlength="100"
                  item-text="text"
                  hide-details="auto"
                  class="mb-2"
                  v-model="opcaoFiltro"
                  :items="opcoesFiltro"
                  placeholder="Selecione"
              />
              <TextField
                  v-if="opcaoFiltro === 'Nome'"
                  label="Nome"
                  item-text="text"
                  hide-details="auto"
                  v-model="textoFiltro"
              />
              <TextField
                  v-if="opcaoFiltro === 'Grupo/Cota'"
                  :label="'Grupo'"
                  item-text="text"
                  type="number"
                  hide-details="auto"
                  v-model="textoFiltro"
                  :rules="[$rules.required, $rules.numeroObrigatorio]"
              />
              <TextField
                  v-if="opcaoFiltro === 'Grupo/Cota'"
                  :label="'Cota'"
                  item-text="text"
                  type="number"
                  hide-details="auto"
                  v-model="textoFiltroCota"
                  :rules="[$rules.required, $rules.numeroObrigatorio]"
              />
            </v-col>
            <v-col cols="12" md="7" class="py-0">
              <v-divider class="my-3" v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"></v-divider>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      Situação da Cota
                    </v-col>
                    <v-col class="text-end">
                      <a class="links" @click="limparFiltro()">Limpar</a>
                    </v-col>
                  </v-row>
                  <SelectField
                      maxlength="100"
                      item-text="nome"
                      hide-details="auto"
                      class="mb-2"
                      v-model="opcaoStatus"
                      :items="opcoesStatus"
                      placeholder="Selecione"
                      return-object
                  />
                </v-col>
              </v-row>
              <v-divider class="mt-4" v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"></v-divider>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="10" md="5">
                  <span class="subtitle" style="color:black; font-weight: bolder">Período*</span>
                </v-col>
                <v-col></v-col>
              </v-row>
              <v-row class="mt-1">
                <v-col cols="2" md="1" style="display: flex; align-items: center">
                  De
                </v-col>
                <v-col cols="10" md="4" class="pl-0">
                  <TextField
                      item-text="text"
                      hide-details="auto"
                      v-model="dt_inicio"
                      v-mask="['##/##/####']"
                      :rules="[$rules.required, $rules.data_valida]"
                      append-icon="pc-calendario"
                      maxlength="10"
                      v-uppercase
                      @keydown.delete="deleteInput()"
                      @keydown.backspace="deleteInput()"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-1">
                <v-col cols="2" md="1" style="display: flex; align-items: center">
                  Até
                </v-col>
                <v-col cols="10" md="4" class="pl-0">
                  <TextField
                      item-text="text"
                      hide-details="auto"
                      v-model="dt_fim"
                      v-mask="['##/##/####']"
                      :rules="[$rules.required, $rules.data_valida, menorQue30dias, maiorQueDataInicial]"
                      append-icon="pc-calendario"
                      maxlength="10"
                      v-uppercase
                      @keydown.delete="deleteInput()"
                      @keydown.backspace="deleteInput()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end" align-self="center">
              <Button
                  type="submit"
                  :disabled="!valido"
                  block
              >
                Aplicar filtros
              </Button>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

  import moment from 'moment/moment'
  import session from '../../plugins/session'
  import { mapGetters } from 'vuex'
  import http from '@/plugins/http'
  import { Mensagens } from '@/plugins/constants'

  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      status: [],
      fases: [],
      valido: false,
      nome: undefined,
      textoFiltro: '',
      textoFiltroCota: '',
      opcaoFiltro: 'Nome',
      opcoesFiltro: ['Nome', 'Grupo/Cota'],
      opcaoStatus: '',
      opcoesStatus: [
        {
          nome: 'Uso parcial do crédito',
          valor: 'pagamento_bem_parcial'
        },
        {
          nome: 'Pagamento do bem realizado',
          valor: 'pagamento_bem_total'
        },
        {
          nome: 'Análise de crédito automática aprovada',
          valor: 'analise_credito_automatica_aprovada'
        },
        {
          nome: 'Análise de crédito automática reprovada',
          valor: 'analise_credito_automatica_reprovada'
        },
        {
          nome: 'Análise de crédito manual aprovada',
          valor: 'analise_credito_manual_aprovada'
        },
        {
          nome: 'Análise de crédito manual reprovada',
          valor: 'analise_credito_manual_reprovada'
        },
        {
          nome: 'Análise de crédito manual em andamento',
          valor: 'analise_credito_manual_iniciada'
        },
        {
          nome: 'Reanálise em andamento',
          valor: 'analise_credito_manual_reanalise_iniciada'
        },
        {
          nome: 'Pendência do cliente na análise de crédito',
          valor: 'analise_credito_manual_aguardando_cliente'
        },
        {
          nome: 'Aguardando pagamento do lance',
          valor: 'pagamento_lance_aguardando_cliente'
        },
        {
          nome: 'Cota contemplada',
          valor: 'aviso_contemplacao_enviado'
        },
        {
          nome: 'Cota alocada',
          valor: 'cota_alocada'
        },
        {
          nome: 'Uso parcial do crédito',
          valor: 'pagamento_bem_parcial'
        },
        {
          nome: 'Cota descontemplada',
          valor: 'cota_desclassificada'
        },
        {
          nome: 'Pagamento de lance confirmado',
          valor: 'pagamento_lance_realizado'
        }
      ],
      cpf_cnpj: undefined,
      dt_inicio: undefined,
      dt_fim: undefined,
      id_comissionado: undefined,
      login_comissionado: undefined,
      unidade: undefined,
      usuario: undefined,
      id_empresa: undefined
    }),
    computed: {
      ...mapGetters({
        filtroMeusClientes: 'getFiltroMeusClientes'
      }),
      isMobile () {
        return window.innerWidth < 960
      }
    },
    watch: {
      exibir () {
        this.nome = undefined
        this.cpfCnpj = undefined
        this.login = undefined
        this.id_comissionado = undefined
        this.login_comissionado = undefined
      },
      opcaoFiltro () {
        this.textoFiltro = ''
      },
      dt_inicio (novoValor, valorAntigo) {
        if (novoValor.length === 10) {
          if (this.dtInicialMenor1Mes()) {
            this.dt_fim = moment(this.dt_inicio, 'DD/MM/YYYY').add(1, 'month').format('DD/MM/YYYY').toString()
          }
        }
      }
    },
    mounted () {
      this.unidade = session.get('unidade')
      this.dt_inicio = this.calcularRangeInicialDoFiltro()[1]
      this.dt_fim = this.calcularRangeInicialDoFiltro()[0]
    },
    methods: {
      deleteInput () {
        this.dt_inicio = ''
        this.dt_fim = ''
      },
      dtInicialMenor1Mes () {
        let valorMes = moment(this.dt_fim, 'DD/MM/YYYY').subtract(1, 'month')
        return !moment(this.dt_inicio, 'DD/MM/YYYY').isBetween(valorMes, moment(this.dt_fim, 'DD/MM/YYYY'))
      },
      filtrar () {
        if (this.filtroMeusClientes.idComissionadoPrincipal === '') {
          this.buscarEmpresa()
        }
        let filtro = `?idUnidade=${this.unidade.idUnidade}`
        if (this.filtroMeusClientes.tipo && this.filtroMeusClientes.tipo === 'revenda') {
          filtro = `?idUnidade=${this.filtroMeusClientes.idUnidade}`
        }
        filtro += `&dataIni=${this.dt_inicio.replaceAll('/', '-')}`
        filtro += `&dataFim=${this.dt_fim.replaceAll('/', '-')}`
        filtro += '&limit=100'
        filtro += '&offset=1'
        filtro += `&idEmpresa=${this.filtroMeusClientes.idEmpresa}`
        filtro += `&idComissionado=${this.filtroMeusClientes.idComissionadoPrincipal}`
        if (this.filtroMeusClientes.tipo && this.filtroMeusClientes.tipo === 'vendedor') {
          filtro += `&subLogin=${this.filtroMeusClientes.login}`
        }
        filtro += this.opcaoStatus ? `&status=${this.opcaoStatus.valor}` : `&status=`
        switch (this.opcaoFiltro) {
          case 'Nome': filtro += `&nome=${this.textoFiltro}`.trim()
                       break
          case 'Grupo/Cota': filtro += `&grupo=${this.textoFiltro}&cota=${this.textoFiltroCota}`.trim()
                             break
          default:
            break
        }
        this.$emit('filtro', filtro)
        this.fechar()
      },
      limparFiltro () {
        this.opcaoFiltro = 'Nome'
        this.opcaoStatus = ''
        this.textoFiltro = ''
        this.dt_inicio = ''
        this.dt_fim = ''

        this.dt_inicio = this.calcularRangeInicialDoFiltro()[1]
        this.dt_fim = this.calcularRangeInicialDoFiltro()[0]
      },
      fechar () {
        this.$emit('exibir', false)
      },
      calcularRangeInicialDoFiltro () {
        let hoje = new Date()
        return [
          hoje.toLocaleDateString(),
          new Date(hoje.getFullYear(), hoje.getMonth() - 1, hoje.getDate()).toLocaleDateString()
        ]
      },
      menorQue30dias () {
        let dt_inicio = moment(this.dt_inicio, 'DD-MM-YYYY')
        let dt_final = moment(dt_inicio).add(1, 'month')
        return moment(this.dt_fim, 'DD-MM-YYYY').isSameOrBefore(dt_final) || 'Data maior que 1 mês'
      },
      maiorQueDataInicial () {
        let dt_inicio = moment(this.dt_inicio, 'DD-MM-YYYY')
        return moment(this.dt_fim, 'DD-MM-YYYY').isSameOrAfter(dt_inicio) || 'Data inferior ou igual a data inicial'
      },
      async buscarEmpresa () {
        await http.get(`/jornada-cota/unidades-login?login=${this.usuarioLogado.login}&loginPrincipal=`, { options: { silent: true } })
          .then((response) => {
            let resposta = response.data
            let idEmpresa = resposta.unidades.find(e => e.idUnidade === this.unidade.idUnidade).idEmpresa
            this.setFiltroMeusClientes({
              idEmpresa: idEmpresa,
              idComissionadoPrincipal: resposta.usuario.idComissionado
            })
            this.idEmpresa = idEmpresa
            this.idComissionadoPrincipal = resposta.usuario.idComissionado
          })
          .catch(erro => {
            if (!/(cancelRequest)/.test(erro.message)) {
              this.$toast.error(Mensagens.ERRO)
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .limpar {
    color: var(--primaria);
  }
  .subtitle {
    font-weight: bold;
  }
  .links
  {
    color: var(--primaria);
  }
  .divider {
    position: absolute;
    margin-left: 42%;
  }
</style>
