<template>
  <v-dialog
      persistent
      v-model="$store.state.exibirDialogVisao"
  >
    <v-card class="pb-1">
      <v-card-title>
        <span>Atenção!</span>
        <v-btn
            icon
            class="ml-auto"
            @click="fechar"
        >
          <v-icon
              color="primaria"
              size="10"
          >
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>Ação não permitida durante o uso da visão administrativa ativa</v-card-text>
      <v-card-actions>
        <v-spacer />
        <Button
            small
            @click="fechar"
        >
          OK
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    methods: {
      fechar () {
        this.$store.commit('toggleExibirDialogVisao')
      }
    }
  }
</script>
