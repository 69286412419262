<template>
  <!-- TODO:
      inserir tooltips cinza 30%
      inserir sombra no v-card
      remover sombra dos v-btns
   -->
  <v-container fluid class="pa-1 ma-0">
    <v-skeleton-loader
        v-if="loadingSkeleton"
        type="image"
        width="100%"
        :height="!isMobile ? '80' : '120'"
        class="mb-2"
    />
    <v-skeleton-loader
        v-if="loadingSkeleton"
        type="image"
        width="100%"
        :height="!isMobile ? '80' : '120'"
        class="mb-2"
    />
    <v-skeleton-loader
        v-if="loadingSkeleton"
        type="image"
        width="100%"
        :height="!isMobile ? '80' : '120'"
        class="mb-2"
    />
    <div v-else v-for="item in items" :key="item.id" width="99%" style="width: 100%; min-height:80px;">
      <div style="margin-bottom: 6px;padding-top: 6px;">
        <span class="elapsedTimeText">
          {{ getElapsedTime(item) }}
        </span>
      </div>

      <v-card
          class="d-flex card"
          min-height="80"
          elevation="1"
          width="99.5%"
      >
        <v-card-title class="d-flex flex-column align-center justify-center py-0" style="padding-left: 11px;!important">
          <v-sheet :color="getUpperLineColor(item)" :class="hasButton(item) ? 'lineButton' : 'line'" />
          <v-sheet class="step d-flex align-center justify-center" :style="'background-color: ' + getStepColor(item)">
            {{ item.id }}
          </v-sheet>
          <v-sheet :color="getLowerLineColor(item)" :class="hasButton(item) ? 'lineButton' : 'line'" />
        </v-card-title>

        <v-card-text class="d-flex align-center px-2 py-3">
          <v-row class="d-flex align-center py-3">
            <v-col md="9" sm="12" class="d-flex align-center pa-0">
              <h3 class="stepName">
                {{ item.nome }}
                <v-tooltip top :max-width="isMobile ? '95vw' : '400px'" color="#9EA1A4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#9EA1A4" v-bind="attrs" v-on="on" class="icon-tooltip">mdi-help-circle</v-icon>
                  </template>
                  <span class="disable_pre">
                    <span v-html="formatTooltip(item.tooltip)"></span>
                  </span>
                </v-tooltip>
              </h3>
            </v-col>
            <v-col md="3" sm="12" :class="isMobile ? 'd-flex align-center pa-0 pt-2' : 'd-flex align-center pa-0'" style="padding-right: 28px!important" v-if="item.id === items[0].id">
              <v-btn
                  v-if="item.flagLiberarCriacaoCaso && eventAfterProd(item.dataEvento)"
                  @click="goToDetails(item)"
                  class="actionButton"
                  color="primary"
                  block
              >
                Iniciar análise manual
                <img src="@/assets/icons/portal-corretora/svg/dsAvancar.svg" alt="" class="ml-1" />
              </v-btn>
              <v-btn
                  v-if="item.evento === 'analise_credito_manual_iniciada' || item.evento === 'analise_credito_manual_reanalise_iniciada'"
                  @click="followUp(item)"
                  class="actionButton"
                  color="primary"
                  block
              >
                Acompanhar
              </v-btn>
              <v-btn
                  v-if="item.flagAtualizarCaso"
                  @click="followUp(item)"
                  class="actionButton"
                  color="primary"
                  block
              >
                Resolver pendência
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import { mapActions } from 'vuex'

  export default {
    name: 'JornadaDaCotaTimeline',
    props: {
      items: Array
    },
    data: () => ({
      loadingSkeleton: false
    }),
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      }
    },
    mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
      }, 6000)
    },
    methods: {
      ...mapActions(['setFaseAnalise']),
      goToDetails (item) {
        this.setFaseAnalise(item)
        this.$router.push({
          name: 'Detalhes'
        })
      },
      hasButton (item) {
        if ((item.evento === 'analise_credito_manual_iniciada' || item.evento === 'analise_credito_manual_reanalise_iniciada') || (item.flagLiberarCriacaoCaso) || (item.flagAtualizarCaso)) {
          if (Number(item.id) === this.items.length) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      followUp (item) {
        this.setFaseAnalise(item)
        this.$router.push({
          name: 'Acompanhar'
        })
      },
      eventAfterProd (dataEvento) {
        let dataProd = moment('16-06-2018', 'DD-MM-YYYY')
        return moment(dataEvento, 'DD-MM-YYYY').isAfter(dataProd)
      },
      getStepColor (step) {
        if (Number(step.id) === this.items.length) {
          return 'var(--primaria)'
        }

        return '#9EA1A4'
      },
      getElapsedTime (item) {
        let days = moment().diff(moment(item.dataEvento, 'DD-MM-YYYY'), 'days')
        if (days > 30) {
          return moment(item.dataEvento, 'DD-MM-YYYY').format('DD/MM/YYYY')
        }

        return days === 1 ? 'Há 1 dia' : `Há ${days} dias`
      },

      /*
      |  É mais legível e prático deixarmos a v-sheet invisível do que removê-la com um v-if.
      |  Isso porque, removendo, o layout precisa ser recalculado para apenas 2 items ou apenas 1...
      |  Uma vez calculado o layout e os tamahos para 3 itens fixos, não precisamos mais calculá-lo,
      */
      getUpperLineColor (item) {
        return '#E7E7E8'
      },
      getLowerLineColor (item) {
        return '#E7E7E8'
      },
      formatTooltip (html) {
        return html.replace(/<pre>/g, '<span>').replace(/<\/pre>/g, '</span>')
      }
    }
  }
</script>

<style scoped>
  .card {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.76);
    border-radius: 9px;
  }

  .line {
    transform: scaleY(2.2);
    height: 14%;
    width: 8px;
    z-index: 1;
  }

  .lineButton {
    transform: scaleY(2.2);
    height: 14%;
    width: 8px;
    z-index: 1;
  }

  .step {
    border: 5px solid #E7E7E8;
    border-radius: 100%;
    height: 45px;
    width: 45px;

    font-family: Inter;
    font-style: normal;
    line-height: 21px;
    font-weight: 600;
    font-size: 14px;
    color: white;

    z-index: 2;
  }

  .stepName {
    font-family: Inter;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
  }

  .actionButton {
    font-family: Inter;
    height: 48px !important;
    text-transform: none;
    text-align: center;
    font-style: normal;
    border-radius: 8px;
    line-height: 21px;
    font-weight: 600;
    font-size: 14px;
    box-shadow: none !important;
    width: 100%;
  }

  .elapsedTimeText {
    font-family: Inter;
    line-height: normal;
    font-style: normal;
    color: #5E6267;
    font-weight: 500;
    font-size: 12px;
  }

  .tooltip {
    color: rgba(0, 0, 0, 0.3);
    padding-left: 5px;
  }

  .actionButton:hover, .actionButton:active, .actionButton:focus {
    background-color: var(--primariaDarken) !important;
  }

  .v-application .elevation-1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }

  .icon-tooltip {
    padding-left: 10px;
    padding-right: 12px;
    height: 16px !important;
    width: 16px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
  }
  .v-tooltip__content {
    opacity: 1 !important;
  }

  @media screen and (max-width: 960px) {
    .actionButton {
      height: 36px !important;
      font-weight: 400;
      font-size: 12px;
      margin: 3px 0;
    }

    .elapsedTimeText {
      font-weight: 400;
      font-size: 10px;
    }

    .line {
      transform: scaleY(2.2);
    }

    .lineButton {
      transform: scaleY(2.4);
      height: 18%;
      width: 8px;
      z-index: 1;
    }

    .stepName {
      font-family: Inter;
      font-style: normal;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }
  @media screen and (max-width: 414px) {
    .stepName {
      font-family: Inter;
      font-style: normal;
      font-size: 0.9rem;
      line-height: 1.5;
      width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
