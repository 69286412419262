<template>
  <v-container>
    <v-row>
      <h1 class="titulo">Mapa de comissões</h1>
      <v-spacer />
      <YearPicker
          id="seletorData"
          hide-details
          v-model="anoDataBase"
          :items="anosDisponiveis"
      />
    </v-row>
    <v-row>
      <v-col
          cols="12"
          :key="dataBase.idDataBase"
          v-for="dataBase in datasBase"
      >
        <v-card
            class="data-card py-1"
            style="height: 100%"
        >
          <v-container
              style="height: 100%; display: block;"
          >
            <v-row
                align="center"
                justify="center"
                style="height: 100%"
            >
              <v-col
                  cols="12"
                  md="2"
              >
                <h3 class="font-weigth-bold">{{ dataBase.descricao }}</h3>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <div v-if="dataBase.solicitacaoMapaComissao">
                  <div v-if="dataBase.solicitacaoMapaComissao.status === 'PROCESSANDO'">
                    <v-card-text class="red--text">Relatório solicitado em: {{ dataBase.solicitacaoMapaComissao.dataHoraCriacao }}</v-card-text>
                  </div>
                  <div v-else-if="dataBase.solicitacaoMapaComissao.status === 'SEM_DADOS'">
                    <v-card-text class="red--text">Não há comissão no período apurado</v-card-text>
                  </div>
                  <div v-else-if="dataBase.solicitacaoMapaComissao.status === 'GERADO'">
                    <v-card-text class="red--text">Último relatório emitido em {{ dataBase.solicitacaoMapaComissao.dataHoraAlteracao }}</v-card-text>
                  </div>
                  <div v-else-if="dataBase.solicitacaoMapaComissao.status === 'ERRO'">
                    <v-card-text class="red--text">Houve um erro ao tentar gerar este mapa. Clique em solicitar para uma nova tentativa</v-card-text>
                  </div>
                </div>
                <div v-else>
                  <v-card-text class="red--text">Relatório não gerado</v-card-text>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <div v-if="dataBase.solicitacaoMapaComissao">
                  <div v-if="dataBase.solicitacaoMapaComissao.status === 'PROCESSANDO'">
                    <v-card-text>Assim que o arquivo for gerado você será notificado por e-mail e ao acessar a Área Restrita.</v-card-text>
                  </div>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-card-actions class="justify-end">
                  <Button
                      small
                      :disabled="desabilitarBotaoSolicitar(dataBase.solicitacaoMapaComissao)"
                      @click="confirmaSolicitar(dataBase)"
                  >
                    SOLICITAR
                  </Button>
                  <Button
                      small
                      outlined
                      :disabled="desabilitarBotaoImprimir(dataBase.solicitacaoMapaComissao)"
                      @click="buscarArquivoAzure(dataBase.solicitacaoMapaComissao.idArquivoAzure)"
                  >
                    <v-icon
                        left
                    >
                      pc-documento-escrito
                    </v-icon>
                    IMPRIMIR
                  </Button>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <PopUpConfirmacao
        :exibir="exibirConfirmacao"
        titulo="Atenção"
        mensagem="Este mapa já foi gerado e pode ser impresso através do botão ''Imprimir''. Só solicite um novo arquivo se houver divergência de valores. Se desejar continuar um novo mapa será gerado gerado nos próximos minutos e uma notificação por e-mail será enviada assim que o documento estiver pronto"
        @confirmou="solicitar(idDataBaseAtual)"
        @cancelou="esconderConfirmacao()" />
  </v-container>
</template>

<script>

  import PopUpConfirmacao from '@/components/PopUpConfirmacao'
  import { listarDatasBase, solicitarMapa } from '@/service/mapa-comissao'
  import { buscarUrlArquivo } from '@/service/arquivo'

  export default {
    components: {
      PopUpConfirmacao
    },
    data: () => ({
      anoPrimeiraVenda: undefined,
      anoDataBase: undefined,
      idDataBaseAtual: undefined,
      exibirConfirmacao: false,
      datasBase: [],
      anosDisponiveis: []
    }),
    watch: {
      anoDataBase () {
        this.buscarDatasBase()
      }
    },
    mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)

      if (this.$route.query.ano) {
        this.anoDataBase = Number.parseInt(this.$route.query.ano)
      } else {
        this.anoDataBase = new Date(Date.now()).getFullYear()
      }
    },
    methods: {
      async buscarDatasBase () {
        try {
          var login = this.$session.get('visaoAdministrativa').login || this.$session.get('usuario').login
          const { datasBase, anoPrimeiraVenda } = await listarDatasBase(this.$session.get('unidade').idUnidade, this.anoDataBase, login)
          this.anoPrimeiraVenda = anoPrimeiraVenda
          this.datasBase = datasBase
          this.contarAnos()
        } catch (error) {
          this.$router.go(-1)
          this.$toast.error(error)
        }
      },
      esconderConfirmacao () {
        this.idDataBaseAtual = undefined
        this.exibirConfirmacao = false
      },
      async confirmaSolicitar (database) {
        if (this.$session.get('visaoAdministrativa').login) {
          this.$store.commit('toggleExibirDialogVisao')
        } else if (database.solicitacaoMapaComissao?.status === 'GERADO') {
          this.idDataBaseAtual = database.idDataBase
          this.exibirConfirmacao = true
        } else {
          await this.solicitar(database.idDataBase)
        }
      },
      async solicitar (idDataBase) {
        try {
          await solicitarMapa(this.$session.get('unidade').idUnidade, idDataBase)
          await this.buscarDatasBase()
          this.esconderConfirmacao()
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      contarAnos () {
        const anoAtual = new Date(Date.now()).getFullYear()

        for (let i = anoAtual; i >= this.anoPrimeiraVenda; i--) {
          this.anosDisponiveis.push(i)
        }
      },
      async buscarArquivoAzure (idArquivo) {
        try {
          const urlArquivo = await buscarUrlArquivo(idArquivo, false)
          window.open(urlArquivo)
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      desabilitarBotaoSolicitar (mapaComissao) {
        if (mapaComissao) {
          switch (mapaComissao.status) {
            case 'PROCESSANDO':
              return true

            default:
              return false
          }
        }

        return false
      },
      desabilitarBotaoImprimir (mapaComissao) {
        if (mapaComissao) {
          switch (mapaComissao.status) {
            case 'PROCESSANDO':
            case 'ERRO':
            case 'SEM_DADOS':
              return true

            default:
              return false
          }
        }

        return true
      }
    }
  }

</script>

<style lang="scss" scoped>

 #seletorData {
   max-width: 8rem;
 }
 .mt-27 {
   margin-top: 27%;
 }
 .mt-32 {
   margin-top: 32.8%;
 }
  p {
    margin-bottom: 0;
  }

</style>
