<template>
  <v-container>
    <h1
        class="mr-5 titulo"
        v-if="$route.params.login"
    >
      Comissionado: {{ comissionado.nome }}
    </h1>
    <h1
        v-else
        class="mr-5 titulo"
    >
      Cadastro de Comissionado
    </h1>
    <span class="subtitulo">
      Parceria:
      <b>
        CFO {{ comissionado.cfo }}
        <span v-if="$route.params.login">- {{ comissionado.nomeResponsavelRevenda }}</span>
      </b>
    </span>
    <v-form
        ref="form"
        :key="chaveFormulario"
    >
      <v-card class="my-5 pb-1" :disabled="gestaoUnificada">
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
            >
              <v-flex
                  row
                  align-end
              >
                <v-spacer />
                <Checkbox
                    class="mx-md-3"
                    label="Gestão Unificada"
                    v-model="comissionado.gestaoUnificada"
                />
                <Checkbox
                    class="mx-md-3"
                    label="Usuário de sistemas"
                    v-model="comissionado.usuarioSistema"
                />
                <Checkbox
                    class="mx-md-3"
                    label="Comissionado principal"
                    v-model="comissionado.comissionadoPrincipal"
                />
                <Checkbox
                    class="mx-md-3"
                    label="Enviar para Newcon"
                    v-model="formulario.enviarNewcon"
                />
                <Checkbox
                    class="mx-md-3"
                    label="Ativo"
                    v-model="comissionado.ativo"
                />
              </v-flex>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  maxlength="20"
                  label="Login*"
                  @blur="validarExistenciaLogin"
                  @keyup="validaCaractere($event)"
                  v-model="comissionado.login"
                  :rules="validacaoLogin"
                  :loading="carregandoLogin && !$route.params.login"
                  :disabled="this.$route.params.login ? true : false || carregandoLogin"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  :disabled="this.$route.params.login ? true : false"
                  label="CPF/CNPJ*"
                  v-model="comissionado.cpfCnpj"
                  :rules="[$rules.required,$rules.documento]"
                  v-mask="['###.###.###-##','##.###.###/####-##']"
              />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <TextField
                  label="Nome*"
                  v-model="comissionado.nome"
                  :rules=[$rules.required]
                  maxlength="50"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  label="Comissionado*"
                  v-mask="'##########'"
                  :rules=[$rules.required]
                  v-model="comissionado.codigo"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  label="Telefone*"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  :rules=[$rules.required]
                  v-model="comissionado.telefone"
              />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <TextField
                  maxlength="70"
                  label="E-mail alternativo*"
                  :rules=[$rules.required,$rules.email]
                  v-model="comissionado.email"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  v-mask="'##/##/####'"
                  :rules=[$rules.required]
                  label="Data de nascimento*"
                  v-model="comissionado.dataNascimento"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <SelectField
                  label="Empresa*"
                  :items="seletorEmpresa"
                  :rules=[$rules.required]
                  v-model="comissionado.empresa"
              />
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <TextField
                  v-mask="'##'"
                  :rules="regrasValidacaoSublogins"
                  label="Quantidade de sub logins*"
                  v-model="comissionado.quantidadeSublogins"
                  :disabled="!comissionado.permiteSublogin"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
            >
              <TextField
                  label="ID SSO Externo"
                  :rules="comissionado.ssoExterno ? [$rules.required, $rules.menorQue250]: []"
                  v-model="comissionado.idSso"
                  :disabled="!comissionado.ssoExterno"
              />
            </v-col>
            <v-col
                cols="6"
                class="pt-0"
                v-if="!$vuetify.breakpoint.mobile"
            />
            <v-col
                md="3"
                cols="12"
                class="pt-0"
            >
              <Checkbox
                  label="Permite sub login"
                  v-model="comissionado.permiteSublogin"
              />
            </v-col>
            <v-col
                md="3"
                cols="12"
                class="pt-0"
            >
              <Checkbox
                  label="SSO Externo"
                  v-model="comissionado.ssoExterno"
              />
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>Responsável pela revenda</v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <TextField
                      label="Nome*"
                      maxlength="50"
                      :rules=[$rules.required]
                      v-model="comissionado.nomeResponsavelRevenda"
                  />
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <TextField
                      maxlength="70"
                      label="E-mail*"
                      :rules=[$rules.required,$rules.email]
                      v-model="comissionado.emailResponsavelRevenda"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions v-if="!gestaoUnificada">
          <v-row class="justify-sm-space-between">
            <v-col
                class="d-none d-sm-block pl-5 mr-auto"
                cols="12"
                md="2"
            >
              <Button
                  small
                  block
                  icon
                  @click="navegarParceria"
                  id="botaoVoltar"
              >
                <v-icon
                    left
                    color="iconcolor"
                >
                  pc-seta-esquerda
                </v-icon>
                <span class="iconcolor--text">VOLTAR</span>
              </Button>
            </v-col>
            <v-col
                cols="12"
                md="3"
                class="text-right"
            >
              <v-btn
                  plain
                  color="black"
                  @click="transferirParceiro"
                  v-if="this.$route.params.login"
                  :disabled="comissionadoFixo.ssoExterno"
              >
                TRANSFERIR PARCEIRO
              </v-btn>
            </v-col>
            <v-col
                cols="12"
                md="2"
            >
              <Button
                  v-if="$route.params.login"
                  icone="pc-seta-esquerda"
                  color="primaria"
                  small
                  block
                  outlined
                  @click="resetar"
                  :disabled="comissionadoFixo.ssoExterno"
              >
                RESETAR SENHA
              </Button>
            </v-col>
            <v-col
                cols="12"
                md="2"
            >
              <Button
                  small
                  block
                  @click="alterar"
                  color="primaria"
                  icone="pc-seta-esquerda"
              >
                SALVAR
              </Button>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-card
        class="pb-1"
        v-if="this.$route.params.login"
    >
      <v-card-title>Sub Logins</v-card-title>
      <v-card-text>
        <DataTable
            item-key="id"
            elevation="0"
            :headers="tabela.cabecalho"
            :show-pagination="false"
            :itens-externos="sublogins"
            :loading="$store.state.carregandoSilenciosamente"
        >
          <template v-slot:[`item.id`]="item">
            <td>{{ item.id }}</td>
          </template>
          <template v-slot:[`item.nome`]="item">
            <td>{{ item.nome }}</td>
          </template>
          <template v-slot:[`item.login`]="item">
            <td>{{ item.login }}</td>
          </template>
          <template v-slot:[`item.status`]="item">
            <td>
              <v-chip
                  v-if="item.status"
                  label
                  class="text--white"
                  :color="corStatus(item.status)"
              >
                {{ item.status ? item.status.replace('_', ' ') : '' }}
              </v-chip>
            </td>
          </template>
          <template v-slot:[`item.acao`]="item">
            <td>
              <v-btn
                  icon
                  @click="editarSublogin(item.id)"
              >
                <v-icon color="primaria">pc-editar</v-icon>
              </v-btn>
            </td>
          </template>
        </DataTable>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <div>
          <ButtonIcon
              small
              icone="pc-mais"
              color="primaria"
              @click="novoSublogin"
              texto="NOVO SUB LOGIN"
              :disabled="!permiteSublogin"
          />
          <p>
            Sublogins ativos: {{ subloginsAtivos }} de {{ comissionado.quantidadeSublogins }}
          </p>
        </div>
      </v-card-actions>
    </v-card>
    <v-dialog
        persistent
        v-model="exibirDialogReset"
    >
      <v-card class="pb-1">
        <v-card-title>
          <v-btn
              icon
              class="ml-auto"
              @click="exibirDialogReset = false"
          >
            <v-icon
                color="primaria"
                size="10"
            >
              pc-fechar
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>Senha redefinida com sucesso. Nova senha: {{ novaSenha }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <Button
              small
              @click="exibirDialogReset = false"
          >
            OK
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import { validarLogin } from '@/service/usuario'
  import { listarSublogins } from '@/service/sublogin'
  import { consultarComissionado, cadastrarComissionado, alterarComissionado } from '@/service/comissionado'
  import { resetarSenha } from '@/service/senha'
  import { gestaoUnificada } from '@/directives/gestaoUnificada'

  export default {
    data: () => ({
      carregandoLogin: false,
      exibirDialogReset: false,
      novaSenha: undefined,
      gestaoUnificada: false,
      seletorEmpresa: ['EMBRACON', 'RCI', 'VOLKSWAGEN', 'HIBRIDA'],
      tabela: {
        cabecalho: [
          { text: 'ID', value: 'id' },
          { text: 'Nome', value: 'nome' },
          { text: 'Login', value: 'login' },
          { text: 'Status', value: 'status' },
          { value: 'acao', width: 10, sortable: false }
        ]
      },
      loginInvalido: false,
      parceria: {
        emailResponsavelAdministrativo: undefined,
        emailGenrenteComercial: undefined
      },
      formulario: {
        enviarNewcon: false
      },
      comissionado: {
        usuarioSistema: false,
        idSso: undefined,
        ssoExterno: false,
        login: undefined,
        telefone: undefined,
        nomeResponsavelRevenda: undefined,
        id: undefined,
        emailResponsavelRevenda: undefined,
        cpfCnpj: undefined,
        cfo: undefined,
        nome: undefined,
        ativo: false,
        comissionadoPrincipal: false,
        permiteSublogin: undefined,
        gestaoUnificada: false,
        quantidadeSublogins: 0,
        codigoComissionado: undefined,
        emailAlternativo: undefined,
        dataNascimento: undefined,
        tipoEmpresa: undefined,
        emailResponsavelAdministrativo: undefined
      },
      comissionadoFixo: {
        ssoExterno: false
      },
      emailComissionado: undefined,
      sublogins: [],
      subloginsAtivos: undefined,
      chaveFormulario: 0,
      maiorQueZero: v => Number(v) > 0 || 'O número precisa ser maior que zero'
    }),
    computed: {
      validacaoLogin () {
        return [
          v => !!v || 'Campo obrigatório!',
          v => /^\w+\.\w+$/.test(v) || 'Formato inválido!',
          v => !this.loginInvalido || 'Login já utilizado. Por favor, informe outro.'
        ]
      },
      regrasValidacaoSublogins () {
        return this.comissionado.permiteSublogin ? [this.$rules.required, this.maiorQueZero] : () => true
      },
      permiteSublogin () {
        return this.comissionado.permiteSublogin && (this.subloginsAtivos < this.comissionado.quantidadeSublogins)
      }
    },
    watch: {
      'comissionado.ssoExterno': function (valor) {
        if (!valor) {
          this.comissionado.idSso = null
        }
      },
      'comissionado.permiteSublogin': function (valor) {
        if (!valor) {
          this.comissionado.quantidadeSublogins = 0
        }
      }
    },
    mounted () {
      if (this.$route.params.login) {
        this.consultarComissionado()
      } else {
        this.comissionado.ativo = true
        this.formulario.enviarNewcon = true
        this.comissionado.cfo = this.$route.query.cfo
        this.parceria.emailResponsavelAdministrativo = this.$route.query.emailResponsavelAdministrativo
        this.parceria.emailGenrenteComercial = this.$route.query.emailGenrenteComercial
      }
      this.gestaoUnificada = gestaoUnificada()
    },
    methods: {
      validaCaractere (value) {
        let login = this.comissionado.login
        if (value.key === '.') {
          if (login.indexOf('.') === 0) {
            login = login.substring(1)
          }
          if (/\w+\.\w+/.test(login) && login.charAt(login.length - 1) === '.') {
            login = login.substring(0, login.length - 1)
          }
          if (/\.{2}/.test(login)) {
            login = login.substring(0, login.length - 1)
          }
        }
        if (/^\d/.test(login)) {
          login = login.replace(/\d/g, '')
        }
        login = login.toLowerCase().replace(/[^a-z0-9.]/g, '')
        this.comissionado.login = login
      },
      navegarParceria () {
        this.$router.go(-1)
      },
      async consultarComissionado () {
        try {
          this.comissionado = await consultarComissionado(this.$route.params.login)
          this.emailComissionado = this.comissionado.email
          this.comissionadoFixo.ssoExterno = this.comissionado.ssoExterno
          await this.listarSublogins()
        } catch (erro) {
          this.$toast.error(erro)
        }

        this.chaveFormulario++
      },
      async listarSublogins () {
        try {
          this.sublogins = await listarSublogins(this.comissionado.id)
          this.subloginsAtivos = 0

          this.sublogins.forEach(sublogin => {
            if (sublogin.status === 'ATIVO') {
              this.subloginsAtivos++
            }
          })
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async alterar () {
        if (this.$refs.form.validate()) {
          try {
            if (this.$route.params.login) {
              const { mensagem } = await alterarComissionado(this.$route.params.login, {
                codigoComissionado: this.comissionado.codigo,
                emailAlternativo: this.comissionado.email,
                emailResponsavelRevenda: this.comissionado.emailResponsavelRevenda,
                comissionadoPrincipal: this.comissionado.comissionadoPrincipal,
                nomeResponsavelRevenda: this.comissionado.nomeResponsavelRevenda,
                nomeUsuario: this.comissionado.nome,
                permiteSublogin: this.comissionado.permiteSublogin,
                quantidadeSublogins: this.comissionado.quantidadeSublogins,
                telefone: this.comissionado.telefone.replace(/(\(|\)|-|\s)/g, ''),
                tipoEmpresa: this.comissionado.empresa,
                dataNascimento: this.comissionado.dataNascimento,
                enviarNewcon: this.formulario.enviarNewcon,
                codigoInscricaoNacional: this.comissionado.cpfCnpj,
                login: this.comissionado.login,
                usuarioSistema: this.comissionado.usuarioSistema,
                idSso: this.comissionado.idSso,
                ssoExterno: this.comissionado.ssoExterno,
                gestaoUnificada: !!this.comissionado.gestaoUnificada
              })

              this.$toast.success(mensagem)
              this.consultarComissionado()
            } else {
              const { mensagem } = await cadastrarComissionado({
                cfo: this.comissionado.cfo,
                emailResponsavelAdministrativo: this.parceria.emailResponsavelAdministrativo,
                emailGerenteComercial: this.parceria.emailGenrenteComercial,
                codigoComissionado: this.comissionado.codigo,
                cpfCnpj: this.comissionado.cpfCnpj,
                dataNascimento: this.comissionado.dataNascimento,
                emailAlternativo: this.comissionado.email,
                nomeResponsavelRevenda: this.comissionado.nomeResponsavelRevenda,
                emailResponsavelRevenda: this.comissionado.emailResponsavelRevenda,
                comissionadoPrincipal: this.comissionado.comissionadoPrincipal,
                login: this.comissionado.login,
                permiteSublogin: this.comissionado.permiteSublogin,
                quantidadeSublogins: this.comissionado.quantidadeSublogins,
                telefone: this.comissionado.telefone,
                enviarNewcon: this.formulario.enviarNewcon,
                tipoEmpresa: this.comissionado.empresa,
                nomeUsuario: this.comissionado.nome,
                codigoInscricaoNacional: this.comissionado.cpfCnpj,
                usuarioSistema: this.comissionado.usuarioSistema,
                idSso: this.comissionado.idSso,
                ssoExterno: this.comissionado.ssoExterno,
                gestaoUnificada: this.comissionado.gestaoUnificada
              })

              this.$router.push({
                name: 'Comissionado',
                params: {
                  cnpj: this.$route.params.cnpj,
                  login: this.comissionado.login
                }
              })

              this.$toast.success(mensagem)
              this.consultarComissionado()
            }
          } catch (erro) {
            this.$toast.error(erro)
          }
        }
      },
      transferirParceiro () {
        this.$router.push({
          name: 'ParceriasAlterar',
          params: { login: this.$route.params.login }
        })
      },
      corStatus (status) {
        switch (status) {
          case 'INATIVO':
            return '#B9BDC4'

          case 'ATIVO':
            return '#008836'

          case 'BLOQUEADO':
            return '#CE181E'

          case 'AGUARDANDO_LIBERACAO':
            return '#000000'

          case 'SUBSTITUIDO':
            return '#222222'

          case 'FERIAS':
            return '#38b8f3'

          case 'AFASTADO':
            return '#ce5b18'

          case 'DESLIGADO':
            return '#B9BDC4'
        }
      },
      editarSublogin (id) {
        this.$router.push({
          name: 'SubloginGestor',
          params: {
            matricula: this.comissionado.id
          },
          query: {
            id,
            comissionado: this.comissionado.login,
            cfo: this.comissionado.cfo
          }
        })
      },
      async validarExistenciaLogin () {
        if (this.comissionado.login.length > 0) {
          this.carregandoLogin = true

          try {
            const { existe } = await validarLogin(this.comissionado.login)
            this.loginInvalido = existe
          } catch (erro) {
            this.$toast.erro(erro)
          }
          this.carregandoLogin = false
        }
      },
      async resetar () {
        try {
          const { senhaNova } = await resetarSenha(this.comissionado.login, this.emailComissionado)
          this.novaSenha = senhaNova
          this.$toast.success('Senha redefinida com sucesso!')
          this.exibirDialogReset = true
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      novoSublogin () {
        this.$router.push({
          name: 'SubloginGestor',
          params: {
            cnpj: this.$route.params.cnpj,
            login: this.$route.params.login
          },
          query: {
            cfo: this.comissionado.cfo
          }
        })
      }
    }
  }

</script>
