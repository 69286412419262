<template>
  <v-dialog
      persistent
      v-model="exibir"
      width="343px"
      height="323px"
      max-width="343px"
  >
    <v-card v-if="!loading && !enviado" class="d-flex flex-column justify-center align-center pb-0">
      <v-btn icon @click="fechar" class="fechar-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="d-flex flex-column align-center">
        <div class="d-flex justify-center">
          <v-icon class="icon mb-2 mt-5" :style="getIconColorStyles" color="white">{{ getPhaseIcon }}</v-icon>
        </div>
        <div :style="getColor" class="tituloModal">Atenção!</div>
      </v-card-text>
      <v-card-text class="texto">Só clique em enviar se todos os documentos corretos foram anexados. Erros podem zerar o prazo de retorno do setor de crédito e gerar morosidade no processo.</v-card-text>
      <v-card-text>
        <v-row>
          <v-btn
              class="actionButton"
              color="primaria"
              @click="enviar"
              width="100px"
              block>
            Enviar
          </v-btn>
        </v-row>
        <v-row>
          <v-btn
              class="btn-fechar"
              @click="fechar"
              width="100px"
              block>
            Cancelar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else-if="loading" class="d-flex justify-center align-center" :height="300">
      <v-card-text class="pb-0">
        <div>
          <div class="text-center"><Spinner /></div>
          <p class="my-6 text--secundaria text-h5 text-center">Enviando</p>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading && enviado" :height="320" class="d-flex flex-column">
      <v-card-text class="d-flex flex-column align-center">
        <div class="d-flex justify-center">
          <v-icon class="icon mb-2 mt-5" :style="getColorAssertSytles" color="white">mdi-check</v-icon>
        </div>
        <div :style="getColorAssert" class="tituloModal">Enviado!</div>
      </v-card-text>
      <v-card-text class="texto">Analisaremos e retornaremos o mais breve possível! Se todos os documentos foram incluídos corretamente sua análise será respondida em até 1 dia últil.</v-card-text>
      <v-card-text>
        <v-btn
            class="btn-fechar"
            @click="voltar"
            width="300px"
            block>
          Fechar
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import Spinner from '../Spinner.vue'
  import http from '@/plugins/http'
  import { Mensagens } from '../../plugins/constants'
  import { mapGetters } from 'vuex'
  import { events } from '@/plugins/events'
  import { getThemeByStep } from '@/plugins/color'

  export default {
    components: { Spinner },
    props: {
      exibir: {
        type: Boolean,
        default: false
      },
      observacao: {
        default: ''
      }
    },
    data: () => ({
      loading: false,
      enviado: false
    }),
    computed: {
      ...mapGetters({
        infocliente: 'getMeusClientes',
        documentos: 'getDocumentosMeusClientes',
        fase: 'getFaseAnaliseMeusClientes'
      }),
      getPhaseColorLight () {
        return getThemeByStep(this.fase.evento).light
      },
      getPhaseColor () {
        return getThemeByStep(this.fase.evento).primaria
      },
      getPhaseIcon () {
        return getThemeByStep(this.fase.evento).icone
      },
      getIconColorStyles () {
        return `
          background-color: ${getThemeByStep(this.fase.evento).terciaria};
          border-color: ${getThemeByStep(this.fase.evento).secundaria};
        `
      },
      getColorAssertSytles () {
        return `
        background-color: ${getThemeByStep('sucesso').terciaria};
        border-color: ${getThemeByStep('sucesso').secundaria};
        `
      },
      getColor () {
        return `
          color: ${getThemeByStep(this.fase.evento).primaria};
        `
      },
      getColorAssert () {
        return `
          color: ${getThemeByStep('sucesso').primaria};
        `
      }
    },
    methods: {
      fechar () {
        this.$emit('exibir', false)
      },
      enviar: async function () {
        let documentosEnviar = []
        this.documentos.forEach(doc => {
          documentosEnviar.push({ url: doc.url, tipo: doc.documento })
        })
        events.$emit('changeTituloLoading', 'Enviando...')
        if (!this.fase.flagAtualizarCaso) {
          let dados = {
            'idCota': this.infocliente.idCota,
            'grupo': this.infocliente.grupo,
            'cota': this.infocliente.cota,
            'fase': 'Tratativa não Iniciada',
            'observacao': this.observacao,
            'documentos': documentosEnviar
          }
          this.loading = true
          await http.post(`/jornada-cota/casos`, dados, { options: { silent: true } })
            .then((response) => {
              this.enviado = true
              events.$emit('changeTituloLoading', 'Carregando...')
            })
            .catch(erro => {
              if (!/(cancelRequest)/.test(erro.message)) {
                this.$toast.error(Mensagens.ERRO)
                events.$emit('changeTituloLoading', 'Carregando...')
              }
            })
        } else {
          let dados = {
            'idCota': this.infocliente.idCota,
            'grupo': this.infocliente.grupo,
            'cota': this.infocliente.cota,
            'fase': 'Retorno Cliente',
            'observacao': this.observacao,
            'documentos': documentosEnviar
          }
          this.loading = true
          await http.post(`/jornada-cota/casos`, dados, { options: { silent: true } })
            .then((response) => {
              this.enviado = true
              events.$emit('changeTituloLoading', 'Carregando...')
            })
            .catch(erro => {
              events.$emit('changeTituloLoading', 'Carregando...')
              if (!/(cancelRequest)/.test(erro.message)) {
                this.$toast.error(Mensagens.ERRO)
              }
            })
        }
        this.loading = false
      },
      voltar () {
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped>
  .icon {
    border: 8px solid;
    border-radius: 100%;
    height: 50px;
    width: 50px;
  }
  .fechar-btn {
    position: absolute;
    top: 1px;
    right: 1px;
    z-index: 999;
    color: #191E26 !important;
  }
  button.btn-fechar
  {
    font-family: Inter;
    letter-spacing: 0!important;
    text-transform: none;
    text-align: center;
    font-style: normal;
    border-radius: 8px;
    line-height: 21px;
    font-weight: 600;
    font-size: 14px;
    box-shadow: none !important;
    height: 34px!important;
    border: 1px solid #CFD0D1 !important;
    color: #191E26 !important;
    background-color: white !important;
    width: 100px;
    margin-left: 0px;
  }

  button.btn-fechar:active
  {
    border: 1px solid var(--primariaDarken) !important;
    color: var(--primariaDarken) !important;
    background-color: #FFFFFF!important;
  }
  button.btn-fechar::before
  {
    background-color: transparent !important;
  }

  .tituloModal {
    /* Título/H8 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .texto {
    color: #191E26 !important;
    /* Text sm / Regular */
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px !important; /* 142.857% */
    margin-top: -11px;
    margin-left: -10px;
    text-align: center;
  }
  .batatinha {
    width: 343px;
    height: 323px;
    top: 20px;
    left: 20px;
  }

  .actionButton {
    font-family: Inter;
    letter-spacing: 0!important;
    height: 34px !important;
    text-transform: none;
    text-align: center;
    font-style: normal;
    border-radius: 8px;
    line-height: 21px;
    font-weight: 600;
    font-size: 14px;
    box-shadow: none !important;
    width: 100px;
    color: var(--white) !important;
    margin-left: 0px;
  }

  .actionButton:hover, .actionButton:active, .actionButton:focus {
    background-color: var(--primariaDarken) !important;
  }

  @media screen and (min-width: 960px){
    button.btn-fechar:hover
    {
      border: 1px solid var(--primariaDarken)!important;
      color: var(--primariaDarken)!important;
      background-color: #FFFFFF!important;
    }

    .icon {
      border: 8px solid;
      border-radius: 100%;
      height: 45px;
      width: 45px;
    }
  }
</style>
