<template>
  <div>
    <v-container fluid>
      <h1 class="titulo">Cadastro de unidade</h1>
      <DataTable
          :key="tableKey"
          class="mt-5"
          :filter="filters"
          :headers="headers"
          :params="params"
          item-key="idUnidade"
          url="/listar-unidade"
      >
        <template v-slot:[`item.urlLogotipo`]="item">
          <td>
            <v-img
                contain
                :src="item.urlLogotipo"
                max-height="50"
                max-width="70"
            />
          </td>
        </template>
        <template v-slot:[`item.idUnidade`]="item">
          <td>{{ item.idUnidade }}</td>
        </template>
        <template v-slot:[`item.nomeUnidade`]="item">
          <td>{{ item.nomeUnidade }}</td>
        </template>
        <template v-slot:[`item.corPrimaria`]="item">
          <td>
            <Chip
                :id="`ChipCorPrimaria_${item.idUnidade}`"
                :color="item.corPrimaria"
                class="chip_cor"
                :text-color="item.corTextoPrimario"
                texto="A"
                @click="selecionarCor(item, 'primarias')"
            />
          </td>
        </template>
        <template v-slot:[`item.corSecundaria`]="item">
          <td>
            <Chip
                :id="`ChipCorSecundaria_${item.idUnidade}`"
                :color="item.corSecundaria"
                class="chip_cor"
                :text-color="item.corTextoSecundario"
                texto="A"
                @click="selecionarCor(item, 'secundarias')"
            />
          </td>
        </template>
        <template v-slot:[`item.corDestaque`]="item">
          <td>
            <Chip
                :id="`ChipCorDestaque_${item.idUnidade}`"
                :color="item.corDestaque"
                class="chip_cor"
                :text-color="item.corTextoDestaque"
                texto="A"
                @click="selecionarCor(item, 'destaque')"
            />
          </td>
        </template>
        <template v-slot:[`item.ativa`]="item">
          <td>
            <v-switch
                inset
                color="primaria"
                v-model="item.ativa"
                :id="`Switch_${item.idUnidade}`"
                @change="ativarDesativarUnidade(item)"
            />
          </td>
        </template>
        <template v-slot:[`item.sso`]="item">
          <td>
            <v-switch
                inset
                color="primaria"
                v-model="item.sso"
                :id="`Switch_${item.idUnidade}`"
                @change="valor => ativarDesativarSSO(item.idUnidade, item.linkSso, valor)"
            />
          </td>
        </template>
        <template v-slot:[`item.linkSso`]="item">
          <td>
            {{ item.linkSso }}
          </td>
        </template>
      </DataTable>
      <v-dialog
          v-on="$listeners"
          :scrollable="true"
          content-class="app-content"
          v-model="dialog"
          persistent
      >
        <v-card>
          <v-card-title>
            <span class="titulo-dialog">Cores {{ tipoDialog }}</span>
            <v-btn
                id="ButtonDialogReclassificarReserva"
                icon
                class="ml-auto"
                @click="dialog = false"
            >
              <v-icon color="primaria" size="10">pc-fechar</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pb-0">
            <div class="mt-2" v-if="cores.length > 0">
              <slot>Selecione a cor de fundo</slot>
              <ColorPicker
                  id="ColorPickerCorFundo"
                  v-model="corSelecionada"
                  hide-mode-switch
                  mode="hexa"
              />
            </div>
            <div>
              <slot>Selecione a cor de texto</slot>
              <ColorPicker
                  id="ColorPickerCorFundo"
                  v-model="corTextoSelecionado"
                  hide-mode-switch
                  mode="hexa"
              />
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <Button
                id="ButtonConfirmarCorPrimaria"
                small
                class="ml-auto secundaria"
                @click="confirmarCorPrimaria"
            >
              Confirmar
            </Button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <DialogSSO
        :url-sso="urlSso"
        :exibir="dialogSso"
        :id-unidade-sso="idUnidadeSso"
        @exibir="exibir => dialogSso = exibir"
    />
  </div>
</template>

<script>
  import { atualizarUnidade, ativarDesativarSSO } from '@/service/unidade'
  import DialogSSO from '@/components/dialog/DialogSSO'

  export default {
    name: 'CadastroUnidade',
    components: {
      DialogSSO
    },
    data: () => ({
      tableKey: 0,
      dialog: false,
      dialogSso: false,
      idUnidadeSso: 0,
      urlSso: '',
      headers: [
        { text: 'Logo da unidade', value: 'urlLogotipo' },
        { text: 'Identificador da unidade', value: 'idUnidade' },
        { text: 'Nome da unidade de negócio', value: 'nomeUnidade' },
        { text: 'Cor primária', value: 'corPrimaria' },
        { text: 'Cor secundária', value: 'corSecundaria' },
        { text: 'Cor destaque', value: 'corDestaque' },
        { text: 'Ativo', value: 'ativa' },
        { text: 'SSO Externo', value: 'sso' },
        { text: 'Link SSO', value: 'linkSso' }
      ],
      filters: {},
      pagination: {
        sortBy: [ 'idUnidade' ], sortDesc: [ false ]
      },
      cores: [
        ['#CE171E'], ['#008836'], ['#1624A6'], ['#048ABF'], ['#FACC00'], ['#246A86'], ['#F786FA'],
        ['#F24F1D'], ['#C4C4C4'], ['#152536'], ['#F08185'], ['#7C95BF'], ['#7B61FF'], ['#78E821']
      ],
      corSelecionada: '#000000',
      corTextoSelecionado: '#000000',
      unidade: null,
      tipoDialog: null
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    watch: {
      corPrimariaSelecionada (value) {
        if (value.toString().match(/#[a-zA-Z0-9]{8}/)) {
          this.color = value.substr(0, 7)
        }
      },
      dialogSso (value) {
        if (!value) {
          this.tableKey++
        }
      }
    },
    methods: {
      async ativarDesativarUnidade (unidade) {
        try {
          unidade.ativa = !!unidade.ativa
          await atualizarUnidade(unidade.idUnidade, unidade)
        } catch (erro) {
          console.error(erro)
        }

        this.filters = { ...this.filters }
      },
      selecionarCor (unidade, tipo) {
        this.corSelecionada = '#000000'
        this.corTextoSelecionado = '#000000'
        this.tipoDialog = tipo
        this.unidade = unidade
        this.dialog = true
      },
      async confirmarCorPrimaria () {
        try {
          switch (this.tipoDialog) {
            case 'primarias':
              this.unidade.corPrimaria = this.corSelecionada
              this.unidade.corTextoPrimario = this.corTextoSelecionado
              break
            case 'secundarias':
              this.unidade.corSecundaria = this.corSelecionada
              this.unidade.corTextoSecundario = this.corTextoSelecionado
              break
            case 'destaque':
              this.unidade.corDestaque = this.corSelecionada
              this.unidade.corTextoDestaque = this.corTextoSelecionado
              break
          }

          await atualizarUnidade(this.unidade.idUnidade, this.unidade)
          this.dialog = false
          this.filters = { ...this.filters }
          this.$toast.success('Cor alterada com sucesso!')
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async ativarDesativarSSO (idUnidade, urlSso, valor) {
        if (valor) {
          this.urlSso = urlSso
          this.idUnidadeSso = idUnidade
          this.dialogSso = true
        } else {
          var { mensagem } = await ativarDesativarSSO(idUnidade, {
            sso: false,
            link: null
          })

          this.tableKey++
          this.$toast.success(mensagem)
        }
      }
    }
  }
</script>

<style scoped>

.chip_cor {
  border: 1px solid black;
  width: 34px;
  border-radius: 6px;
}

</style>
