<template>
  <v-dialog
      persistent
      width="500px"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="exibir">
    <v-card>
      <v-card-title class="text-h5">
        <span>{{ titulo }}</span>
        <v-btn icon class="ml-auto" @click="cancelar">
          <v-icon color="primaria" size="10">
            pc-fechar
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        {{ mensagem }}
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <Button dark outlined @click="cancelar">
          Cancelar
        </Button>
        <Button dark @click="confirmar">
          Confirmar
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      },
      podeFechar: {
        type: Boolean,
        default: false
      },
      titulo: String,
      mensagem: String
    },
    methods: {
      confirmar () {
        this.$emit('confirmou')
      },
      cancelar () {
        this.$emit('cancelou')
      }
    }
  }
</script>
