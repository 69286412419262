import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'

export async function buscarConfiguracoes () {
  try {
    const { data } = await http.get('/configuracoes')
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}
