<template>
  <div>
    <v-menu
        rounded
        offset-y
        :close-on-content-click="false"
        v-model="notificacoesDropdown"
        :min-width="isDeviceMobile ? '100%' : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="grey lighten-4"
               icon
               v-bind="attrs"
               v-on="on"
               stacked
               @click="organizaNotificacao()"
        >
          <v-badge :value="numeroNotificacao" :content="numeroNotificacao" color="purple">
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card max-height="400" :max-width="isDeviceMobile ? null : 300" class="mt-1">
        <div v-for="(notificacao, index) in allNotifications" :key="index" class="border-card">
          <div class="flex-notification px-4 pt-1">
            <div style="line-height: 1.4rem">
              <v-icon size="small" class="mr-2" :color="alertaNotificacoes > index ? 'purple' : 'gray'">mdi-circle</v-icon>
            </div>
            <div style="width:100%">
              <div class="status-titulo">
                <span class="tituloTexto">{{ notificacao.nomeStatus }}</span>
              </div>
              <div>
                <div class="padraoTexto">
                  {{ notificacao.nome }}, {{ notificacao.cota }}
                </div>
                <div class="flex-notification-subtitle mt-1">
                  <div style="display: flex; line-height: 2rem">
                    <div>
                      <v-icon size="small">mdi-calendar</v-icon>
                    </div>
                    <div>
                      <span class="textoData">{{ formatarData(notificacao.data) }}</span>
                    </div>
                  </div>
                  <div class="container-link">
                    <a class="links align-end visualizar" @click="visualizarNotif(notificacao)">Visualizar</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
  import session from '@/plugins/session'
  import moment from 'moment'
  import { mapActions, mapGetters } from 'vuex'
  import { notificacoesIniciais, listarNotificacoes } from '@/service/jornada-cota'
  export default {
    name: 'Notificacao',
    components: {
    },
    data: () => ({
      notificacoesUsuarios: [],
      conexao: null,
      visibilidade: false,
      visualizarNotificacao: false,
      notificacaoLogin: false,
      checkbox: false,
      escolhido: {
        id: null,
        nome: null,
        mensagemNotificacao: null,
        dataHoraCadastro: null,
        usuario: null
      },
      notificacoesDropdown: false,
      tempoNotificacao: 2.5,
      alertaNotificacoes: 0
    }),
    computed: {
      ...mapGetters({
        numeroNotificacao: 'getNumeroNotificacao',
        allNotifications: 'getNotificacoes'
      })
    },
    mounted () {
      if (this.allNotifications.length === 0) {
        this.listarNotificacoesInicial()
      }
      setInterval(async () => {
        let dataAtual = await moment().format('DD/MM/YYYY')
        let horaAtual = await moment().subtract(this.tempoNotificacao, 'minutes').format('HH:mm')
        this.listaNotificacoes(dataAtual, horaAtual)
      }, this.tempoNotificacao * 60 * 1000)
    },
    methods: {
      ...mapActions([
        'addNumeroNotificacao', 'setNumeroNotificacao', 'setNotificacoes', 'addInfoNotificacao', 'removeInfoNotificacao'
      ]),
      async listaNotificacoes (dataAtual, horaAtual) {
        const usuario = session.get('usuario')
        try {
          const req = await listarNotificacoes(usuario.login, dataAtual, horaAtual)
          const data = req.sort((a, b) => (b.id > a.id) ? 1 : -1)
          if (data) {
            data.forEach((cota) => {
              this.addInfoNotificacao(cota)
              this.addNumeroNotificacao()
            })
          }
          while (this.allNotifications.length > 25) {
            this.removeInfoNotificacao()
          }
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async listarNotificacoesInicial () {
        const usuario = session.get('usuario')
        try {
          const req = await notificacoesIniciais(usuario.login)
          this.setNotificacoes([])
          const data = req.sort((a, b) => (b.id > a.id) ? 1 : -1)
          this.setNotificacoes(data.sort((a, b) => (b.id > a.id) ? 1 : -1))
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      formatarData (data) {
        return moment(data, 'DD/MM/YYYY').format('DD/MM/YYYY').toString()
      },
      visualizarNotif (item) {
        const parametros = item
        this.notificacoesDropdown = false
        if (this.$router.currentRoute.name === 'JornadaDaCota') {
          this.$router.go()
        }
        this.$router.push({
          name: 'JornadaDaCota', params: parametros
        })
      },
      organizaNotificacao () {
        this.notificacoesDropdown = !this.notificacoesDropdown
        if (this.notificacoesDropdown) {
          this.alertaNotificacoes = this.numeroNotificacao
        } else {
          this.alertaNotificacoes = 0
        }
        this.setNumeroNotificacao(0)
      }
    }
  }
</script>

<style scoped>
  .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 12px;
  }
  .links {
    color: var(--primaria);
    text-decoration-line: underline;
  }
  .flex-notification {
    display: flex;
    background-color: white;
  }
  .flex-notification-subtitle{
    display: flex;
    background-color: white;
  }
  .status-titulo{
    flex: 1;
    display: flex;
    background-color: white;
  }
  .border-card{
    border-top: 1px solid #B9BDC4;
  }

  .tituloTexto {
    font-size: 16px;
    font-weight: bolder;
  }
  .padraoTexto {
    font-weight: 400;
    font-size: 14px;
  }
  .textoData {
    font-weight: lighter;
    font-size: 12px;
  }
  .visualizar {
    margin-left: 7rem;
    font-size: 12px;
  }

  .container-link{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 1rem;
    width: 100%;
    float: right;
  }

</style>
