<template>
  <div class="container-chip">
    <v-chip
        label
        small
        :text-color="textColor"
        v-bind="$attrs"
        v-on="$listeners"
    >
      {{ texto }}
    </v-chip>
  </div>
</template>

<script>
  export default {
    name: 'Chip',
    props: {
      texto: String,
      textColor: {
        type: String,
        default: 'white'
      }
    }
  }
</script>

<style scoped>
  .container-chip {
    display:flex;
    align-items: center;
  }
</style>
