<template>
  <div>
    <label class="d-flex mb-1">
      <span>{{ label }}</span>
    </label>
    <v-file-input
        :key="key"
        ref="field"
        v-bind="$attrs"
        v-on="$listeners"
        v-model="file"
        outlined
        dense
        prepend-icon=''
        class="text-field transition"
        @change="alterandoArquivo">
      <template v-slot:append-outer>
        <div style="margin-top: -8px">
          <Button
              color="primaria"
              small
              block
              @click="$refs.field.$refs.input.click()">
            <v-icon left>
              pc-mais
            </v-icon>
            ADD Arquivo
          </Button>
        </div>
      </template>
    </v-file-input>
  </div>
</template>

<script>
  import { isArray } from 'lodash'

  const TAMANHO_MAXIMO_ARQUIVO = 10
  const TAMANHO_MAXIMO_ARQUIVO_EM_MB = TAMANHO_MAXIMO_ARQUIVO * 1024 * 1024
  const TIPO_ARQUIVO_PERMITIDO = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

  export default {
    name: 'FileInput',
    props: {
      label: String,
      accept: Array,
      limpar: Boolean,
      msgArquivoGrande: {
        type: String,
        default: `Arquivo muito grande. O arquivo deve conter no máximo ${TAMANHO_MAXIMO_ARQUIVO_EM_MB}MB`
      },
      msgExtensaoInvalida: {
        type: String,
        default: `Arquivo com extensão não permitida. São permitidos somente arquivos com as extensões: ${TIPO_ARQUIVO_PERMITIDO.join(', ')}`
      }
    },
    data: () => ({
      file: [],
      key: 0
    }),
    watch: {
      limpar (limpa) {
        if (limpa) {
          this.limparArquivo()
        }
      }
    },
    methods: {
      isTamanhoArquivoValido (file) {
        return file && file.size <= TAMANHO_MAXIMO_ARQUIVO_EM_MB
      },
      isExtensaoArquivoValido (file) {
        let arquivo_permitido = (this.accept) && isArray(this.accept) ? this.accept : TIPO_ARQUIVO_PERMITIDO
        return file && arquivo_permitido.includes(file.type)
      },
      alterandoArquivo (file) {
        if (!file) {
          return
        }
        if (!this.isTamanhoArquivoValido(file)) {
          this.limparArquivo()
          this.$toast.error(this.msgArquivoGrande)
          file = null
        } else if (!this.isExtensaoArquivoValido(file)) {
          this.limparArquivo()
          this.$toast.error(this.msgExtensaoInvalida)
          file = null
        }

        this.$emit('arquivo', file)
      },
      extensaoAccept (arquivo) {
        return arquivo.map(function (arquivo) {
          let split = arquivo.split('/')
          return '.' + split[split.length - 1]
        })
      },
      limparArquivo () {
        this.file = null
        this.key++
      }
    }
  }
</script>

<style lang="scss" scoped>
  label {
    color: var(--labelcolor);
    &.label-error {
      color: var(--secundaria);
    }
  }
</style>

<style lang="scss">
  .text-field.v-input {
    fieldset {
      border-color: var(--gray);
      background: var(--white);
    }
    &.v-input--is-focused fieldset {
      border-width: 1px;
      border-color: #048ABF;
      filter: drop-shadow(0 0 4px #55AFFC)
    }
    &.v-input--is-disabled fieldset {
      background-color: var(--lightgray);
    }
    .v-input__slot {
      min-height: 38px !important;
      padding: 0 20px !important;
      color: var(--inputcolor) !important;
    }
    input {
      color: var(--inputcolor);
      &::placeholder {
        text-transform: none;
        color: var(--placeholdercolor);
      }
    }

    &.error--text {
      fieldset {
        border-width: 1px;
        border-color: var(--secundaria);
      }
    }
  }
  .v-application .text-field .error--text {
    color: var(--secundaria) !important;
  }
</style>
