import http from '@/plugins/http'
import session from '@/plugins/session'
import { Mensagens } from '@/plugins/constants'

export async function alterarSenha (dados) {
  try {
    const { data } = await http.put('/api/alterar-senha', dados, { baseURL: process.env.VUE_APP_WS_ROOT })
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function resetarSenha (login, email) {
  try {
    const unidade = session.get('unidade')
    const { data } = await http.post(`/usuario/resetar-senha/${login}?idUnidade=${unidade.idUnidade}&urlLogin=${process.env.VUE_APP_AREA_RESTRITA_URL}/${unidade.idUnidade}/login&email=${email}`, { baseURL: process.env.VUE_APP_WS_ROOT })
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}
