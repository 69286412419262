import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'

export async function buscarInformesRendimento (login = null) {
  try {
    const { data } = await http.get(`/informe-rendimento/${login}`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }
    throw Mensagens.ERRO
  }
}
