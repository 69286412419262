const Color = require('color')

export function getDarkenColor (color, darkenRatio = 0.3) {
  return Color(color).darken(darkenRatio).hex()
}

export function getLightenColor (color, lightenRatio = 0.2) {
  return Color(color).lighten(lightenRatio).hex()
}

export function applyTransparency (color, transparencyRatio = 0.1) {
  return Color(color).alpha(transparencyRatio).hexa()
}

export function getThemeByStep (evento) {
  const sucesso = ['analise_credito_automatica_aprovada', 'analise_credito_manual_aprovada', 'aviso_contemplacao_enviado', 'pagamento_lance_realizado', 'cota_quitada', 'sucesso']
  const informativo = ['pagamento_bem_parcial', 'pagamento_bem_total', 'analise_credito_manual_iniciada', 'analise_credito_manual_reanalise_iniciada', 'pagamento_lance_aguardando_cliente', 'cota_alocada', 'cota_contemplada_lance', 'informativo']
  const pendencia = ['analise_credito_automatica_reprovada', 'analise_credito_manual_aguardando_cliente', 'cota_contemplada_inadimplente', 'cota_inadimplente', 'cota_contemplada_inadimplente', 'pendencia']
  const atencao = ['analise_credito_manual_reprovada', 'cota_cancelada_atraso', 'cota_cancelada_pedido', 'cota_desclassificada']

  if (sucesso.includes(evento)) {
    return {
      'primaria': '#3FD47B',
      'secundaria': '#5fed97',
      'terciaria': '#5ecb8a',
      'texto': '#FFF',
      'icone': 'mdi-check',
      'light': getLightenColor('#3FD47B', 0.5)
    }
  }

  if (informativo.includes(evento)) {
    return {
      'primaria': '#768DDD',
      'secundaria': '#B7C7FF',
      'terciaria': '#768DDD',
      'texto': '#FFF',
      'icone': 'mdi-information-variant',
      'light': getLightenColor('#768DDD', 0.5)
    }
  }

  if (pendencia.includes(evento)) {
    return {
      'primaria': '#9349f4',
      'secundaria': '#c293ff',
      'terciaria': '#ab6bff',
      'texto': '#FFF',
      'icone': 'mdi-minus',
      'light': getLightenColor('#9349f4', 0.5)
    }
  }

  if (atencao.includes(evento)) {
    return {
      'primaria': '#ee4784',
      'secundaria': '#ff6ba1',
      'terciaria': '#ed578e',
      'texto': '#FFF',
      'icone': 'mdi-exclamation',
      'light': getLightenColor('#ee4784', 0.5)
    }
  }

  return {
    'primaria': '#3FD47B',
    'secundaria': '#5fed97',
    'terciaria': '#5ecb8a',
    'texto': '#FFF',
    'icone': 'mdi-check',
    'light': getLightenColor('#3FD47B', 0.5)
  }
}

export default Color
