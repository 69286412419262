<template>
  <div class="ml-6">
    <v-menu
        rounded
        offset-y
        :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            class="grey lighten-4"
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>pc-perfil</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item @click="exibirDadosCadastrais">
            <v-list-item-icon>
              <v-icon>pc-perfil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $session.get('visaoAdministrativa').nome || usuario.nome }}</v-list-item-title>
              <v-list-item-subtitle>{{ $session.get('visaoAdministrativa').login || usuario.login }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item v-if="usuario.perfil !== 'MASTER' && usuario.perfil !== 'GESTOR' && verificarUnidadeSublogin" @click="exibirSenha">
            <v-list-item-icon>
              <v-icon>pc-chave</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Alterar senha</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>pc-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <DialogAtualizarDadosCadastrais
        :exibir="exibirDialogDadosCadastrais"
        @exibir="exibir => exibirDialogDadosCadastrais = exibir"
    />
    <DialogAlterarSenha
        :exibir="exibirDialogSenha"
        @exibir="exibir => exibirDialogSenha = exibir"
    />
    <DialogSair
        :exibir="exibirDialog"
        @exibir="exibir => exibirDialog = exibir"
    />
  </div>
</template>

<script>

  import { mapState } from 'vuex'
  import DialogSair from './dialog/DialogSair.vue'
  import DialogAlterarSenha from './dialog/DialogAlterarSenha'
  import DialogAtualizarDadosCadastrais from './dialog/DialogAtualizarDadosCadastrais.vue'

  export default {
    components: { DialogSair, DialogAlterarSenha, DialogAtualizarDadosCadastrais },
    data: () => ({
      exibirDialog: false,
      exibirDialogSenha: false,
      exibirDialogDadosCadastrais: false
    }),
    computed: {
      ...mapState(['usuario', 'unidade'])
    },
    methods: {
      exibirDadosCadastrais () {
        if (this.$session.get('visaoAdministrativa').idUsuario) {
          this.$store.commit('toggleExibirDialogVisao')
        } else {
          this.exibirDialogDadosCadastrais = this.usuario.perfil === 'PARCEIRO'
        }
      },
      exibirSenha () {
        if (this.$session.get('visaoAdministrativa').idUsuario) {
          this.$store.commit('toggleExibirDialogVisao')
        } else {
          this.exibirDialogSenha = true
        }
      },
      logout () {
        this.exibirDialog = true
      },
      verificarUnidadeSublogin () {
        if (this.unidade.idUnidade === 92) {
          return this.usuario.perfil !== 'SUBLOGIN'
        } else {
          return true
        }
      }
    }
  }

</script>

<style scoped>

  .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 12px;
  }

</style>
