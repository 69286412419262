<template>
  <v-container fluid>
    <h1 class="titulo">Visão administrativa</h1>
    <p>Ative a visão de um usuário com perfil parceiro para visualizar as funcionalidades e dados exibidos para ele. Atenção, não é possível inserir ou atualizar nenhum registro enquanto a visão administrativa estiver ativa.</p>
    <v-form
        @submit.prevent="iniciar"
        v-model="formularioValido"
    >
      <v-row align="center" style="position: relative; max-width=450">
        <v-col
            cols="12"
            sm="2"
            md="2"
        >
          <TextField
              v-model="login"
              label="Login do usuário*"
              :rules=[$rules.required]
              @blur="filtraUnidades()"
          />
        </v-col>
        <v-col
            cols="12"
            sm="2"
            md="2"
        >
          <SelectField
              v-model="idUnidade"
              :items="unidades"
              label="Unidades*"
              :rules=[$rules.required]
          />
        </v-col>

        <v-col
            cols="12"
            sm="2"
            md="2"
        >
          <Button
              small
              block
              type="submit"
              :disabled="!formularioValido"
          >
            Iniciar
          </Button>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import { buscarInformacoesVisao } from '@/service/visao-administrativa'
  import session from '@/plugins/session'
  import { mapActions } from 'vuex'
  import { unidadesLogin } from '@/service/jornada-cota'

  export default {
    data: () => ({
      formularioValido: false,
      login: undefined,
      idUnidade: undefined,
      unidades: []
    }),
    mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)
    },
    methods: {
      ...mapActions([
        'setNotificacoes', 'setNumeroNotificacao'
      ]),
      async filtraUnidades () {
        this.unidades = []
        if (this.login.length > 0) {
          let unidades = await unidadesLogin(this.login)

          unidades.unidades.forEach(x => {
            this.unidades.push(x.idUnidade)
          })
        }
      },
      async iniciar () {
        try {
          const usuario = await buscarInformacoesVisao(this.login, this.idUnidade)

          session.set({
            visaoAdministrativa: {
              nome: usuario.nome,
              login: this.login,
              idUsuario: usuario.idUsuario,
              perfil: usuario.perfil,
              centroCusto: usuario.centroCusto
            }
          })

          const unidadeAnterior = session.get('unidade')
          session.set({ unidadeAnterior })

          session.set({
            unidade: {
              idUnidade: usuario.unidade.idUnidade,
              nomeUnidade: usuario.unidade.nomeUnidade,
              logotipo: usuario.unidade.logotipo,
              urlLogotipo: usuario.unidade.urlLogotipo,
              corPrimaria: usuario.unidade.corPrimaria,
              corSecundaria: usuario.unidade.corSecundaria,
              corDestaque: usuario.unidade.corDestaque,
              corTextoPrimario: usuario.unidade.corTextoPrimario,
              corTextoSecundario: usuario.unidade.corTextoSecundario,
              corTextoDestaque: usuario.unidade.corTextoDestaque,
              autenticacaoMultifator: usuario.unidade.autenticacaoMultifator
            }
          })

          this.$store.state.unidadeAnterior = unidadeAnterior

          this.$vuetify.theme.themes.light.primaria = usuario.unidade.corPrimaria || '#FFFFFF'
          this.$vuetify.theme.themes.light.secundaria = usuario.unidade.corSecundaria || '#000000'
          this.$vuetify.theme.themes.light.destaque = usuario.unidade.corDestaque || '#F0F0F0'
          this.$vuetify.theme.themes.light.textoprimario = usuario.unidade.corTextoPrimario || '#000000'
          this.$vuetify.theme.themes.light.textosecundario = usuario.unidade.corTextoSecundario || '#F0F0F0'
          this.$vuetify.theme.themes.light.textodestaque = usuario.unidade.corTextoDestaque || '#0F0F0F'
          this.$vuetify.theme.themes.light.primary = usuario.unidade.corPrimaria
          this.$vuetify.theme.themes.light.secundaria = usuario.unidade.corSecundaria

          this.setNotificacoes([])
          this.setNumeroNotificacao(0)

          this.$router.push({
            name: 'Rendimentos'
          })

          this.$router.go()
        } catch (erro) {
          this.$toast.error(erro)
        }
      }
    }
  }
</script>
