<template>
  <v-card-text v-if="tipoSelecionado === 'Plano'">
    <div v-if="ajudaPlano" style="padding-left: 6px;">
      <v-row class="mt-3 ml-0">
        <label class="labelDadosPlano">Dados do plano</label>
        <img
            src="@/assets/icons/portal-corretora/svg/dsCircleBack.svg"
            @click="voltaPlano"
            height="17"
            width="17"
            style="cursor: pointer; "
            class="ml-2 mt-2"
        />
      </v-row>
      <v-row class="grupoAjuda">
        <div :class="isMobile ? 'ml-0' : 'ml-3'">
          <span class="topico">Tipo de bem - </span>
          <span class="textoSpan">Tipo de bem escolhido ao entrar no consórcio.</span>
        </div>
        <div :class="isMobile ? 'ml-0' : 'ml-3'">
          <span class="topico">Contrato - </span>
          <span class="textoSpan">Número do contrato firmado entre o cliente e a Embracon.</span>
        </div>
        <div :class="isMobile ? 'ml-0' : 'ml-3'">
          <span class="topico">Plano - </span>
          <span class="textoSpan">O nome do plano contratado pelo cliente.</span>
        </div>
        <div :class="isMobile ? 'ml-0' : 'ml-3'">
          <span class="topico">Prazo do plano - </span>
          <span class="textoSpan">Número de meses que levará para finalizar o plano contratado.</span>
        </div>
        <div :class="isMobile ? 'ml-0' : 'ml-3'">
          <span class="topico">Data de início do plano - </span>
          <span class="textoSpan">Data inicial do plano adquirido pelo cliente.</span>
        </div>
        <div :class="isMobile ? 'ml-0' : 'ml-3'">
          <span class="topico">Data final do plano - </span>
          <span class="textoSpan">Data final do plano adquirido pelo cliente.</span>
        </div>
      </v-row>
    </div>
    <div v-if="informacoesPlano" style="padding-left: 6px;">
      <v-row :class="isMobile ? 'col-12' : 'mt-0 col-12'">
        <label class="labelDadosPlano">Dados do plano</label>
      </v-row>
      <v-row>
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="isMobile ? '99%' : '60%'"
            height="25px"
            class="ml-0"
        />
        <table v-else :class="isMobile ? 'mt-2 mb-2 ml-0' : 'mt-2 mb-2 ml-3'">
          <tbody>
            <tr>
              <td class="columTable">Tipo de bem:</td>
              <td class="valueTable">{{ tipoBem }}</td>
            </tr>
            <tr>
              <td class="columTable">Contrato:</td>
              <td class="valueTable">{{ contrato }}</td>
            </tr>
            <tr>
              <td class="columTable">Plano:</td>
              <td class="valueTable">{{ plano }}</td>
            </tr>
            <tr>
              <td class="columTable">Prazo do plano:</td>
              <td class="valueTable">{{ prazoPlano }}</td>
            </tr>
            <tr>
              <td class="columTable">{{ isMobile ? 'Valor do bem:' : 'Valor do bem contratado:' }}</td>
              <td class="valueTable">{{ valorBemContratado }}</td>
            </tr>
            <tr>
              <td class="columTable">{{ isMobile ? 'Data de início:' : 'Data de início do plano:' }}</td>
              <td class="valueTable">{{ dataInicioPlano }}</td>
            </tr>
            <tr>
              <td class="columTable">{{ isMobile ? 'Data de final:' : 'Data de final do plano:' }}</td>
              <td class="valueTable">{{ dataFinalPlano }}</td>
            </tr>
          </tbody>
        </table>
      </v-row>
      <v-row>
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="isMobile ? '100' : '200'"
            height="12"
            class="ml-0 mt-2"
        />

        <div v-else :class="isMobile ? 'ml-0 mt-2' : 'ml-1 mb-0 mt-9'">
          <a class="linkAbrir" style="padding-left: 2px;" @click="ajudaPlanoCliente">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_579_3944)">
                <path d="M7 13.125C5.37555 13.125 3.81763 12.4797 2.66897 11.331C1.52031 10.1824 0.875 8.62445 0.875 7C0.875 5.37555 1.52031 3.81763 2.66897 2.66897C3.81763 1.52031 5.37555 0.875 7 0.875C8.62445 0.875 10.1824 1.52031 11.331 2.66897C12.4797 3.81763 13.125 5.37555 13.125 7C13.125 8.62445 12.4797 10.1824 11.331 11.331C10.1824 12.4797 8.62445 13.125 7 13.125ZM7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14Z" fill="var(--primaria)" />
                <path d="M4.59813 5.06275C4.59693 5.09101 4.60153 5.11921 4.61163 5.14563C4.62174 5.17204 4.63714 5.19611 4.65689 5.21636C4.67664 5.2366 4.70033 5.25259 4.72649 5.26334C4.75265 5.27409 4.78073 5.27938 4.80901 5.27888H5.53088C5.65163 5.27888 5.74788 5.18 5.76363 5.06013C5.84238 4.48613 6.23613 4.06788 6.93788 4.06788C7.53813 4.06788 8.08763 4.368 8.08763 5.08988C8.08763 5.6455 7.76038 5.901 7.24326 6.2895C6.65438 6.71738 6.18801 7.217 6.22126 8.02813L6.22388 8.218C6.2248 8.27541 6.24825 8.33015 6.28918 8.37042C6.3301 8.41069 6.38522 8.43326 6.44263 8.43325H7.15226C7.21027 8.43325 7.26591 8.4102 7.30694 8.36918C7.34796 8.32816 7.37101 8.27252 7.37101 8.2145V8.12263C7.37101 7.49438 7.60988 7.3115 8.25476 6.82238C8.78763 6.41725 9.34326 5.9675 9.34326 5.02338C9.34326 3.70125 8.22676 3.0625 7.00438 3.0625C5.89576 3.0625 4.68126 3.57875 4.59813 5.06275ZM5.96051 10.1054C5.96051 10.5718 6.33238 10.9165 6.84426 10.9165C7.37713 10.9165 7.74376 10.5718 7.74376 10.1054C7.74376 9.62238 7.37626 9.28288 6.84338 9.28288C6.33238 9.28288 5.96051 9.62238 5.96051 10.1054Z" fill="var(--primaria)" />
              </g>
              <defs>
                <clipPath id="clip0_579_3944">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span style="padding-left: 5px;font-weight: 400;font-size: 12px;font-family: 'Inter'">Preciso de ajuda para entender</span>
          </a>
        </div>
      </v-row>
    </div>
  </v-card-text>
</template>

<script>
  import { cpf, cnpj } from '@/plugins/formatters'
  import moment from 'moment/moment'

  export default {
    name: 'CardInformacoesPlanoCliente',
    props: {
      dados: Object,
      executaSkeleton: Object
    },
    data: () => ({
      loadingSkeleton: false,
      tipoSelecionado: 'Plano',
      tipoBem: '',
      contrato: '',
      plano: '',
      prazoPlano: '',
      valorBemContratado: '',
      dataInicioPlano: '',
      dataFinalPlano: '',
      ajudaPlano: false,
      informacoesPlano: true
    }),
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      getCpfCnpjLabel () {
        return this.dados.cpfCnpj?.length === 11 ? 'CPF' : 'CNPJ'
      },
      getCpfCnpjText () {
        return this.dados.cpfCnpj?.lenght === 11 ? cpf(this.dados.cpfCnpj) : cnpj(this.dados.cpfCnpj)
      },
      getCardsClass () {
        return { cardsBorder: !this.isMobile }
      },
      getMaskedPhone () {
        var result = this.dados?.telefone.replace(/\D/g, '')
        result = result.replace(/^0/, '')
        if (result.length > 10) {
          result = result.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
        } else if (result.length > 5) {
          result = result.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
        } else if (result.length > 2) {
          result = result.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
        } else {
          result = result.replace(/^(\d*)/, '($1')
        }
        return result
      }
    },
    mounted () {
      this.tipoBem = this.dados.extratoCota && this.dados.extratoCota.identificacao.bem ? this.dados.extratoCota.identificacao.bem : '-'
      this.contrato = this.dados.extratoCota && this.dados.extratoCota.identificacao.idDocumento ? this.dados.extratoCota.identificacao.idDocumento : '-'
      this.plano = this.dados.extratoCota && this.dados.extratoCota.identificacao.nomePlanoVenda ? this.dados.extratoCota.identificacao.nomePlanoVenda : '-'
      this.prazoPlano = this.dados.extratoCota && this.dados.extratoCota.identificacao.prazoCota ? this.dados.extratoCota.identificacao.prazoCota + ' meses' : '-'
      this.valorBemContratado = this.dados.extratoCota.identificacao && this.dados.extratoCota.identificacao.valorBem ? this.formatarMoeda(this.dados.extratoCota.identificacao.valorBem) : 0

      if (this.dados.extratoCota.identificacao && this.dados.extratoCota.identificacao.dataAdesao) {
        this.dataInicioPlano = moment(new Date(this.dados.extratoCota.identificacao.dataAdesao)).format('DD/MM/YYYY')
      }

      if (this.dados.extratoCota.identificacao && this.dados.extratoCota.identificacao.dataEncerramento) {
        this.dataFinalPlano = moment(new Date(this.dados.extratoCota.identificacao.dataEncerramento)).format('DD/MM/YYYY')
      }

      if (this.executaSkeleton) {
        this.loadingSkeleton = true
        setTimeout(() => {
          this.loadingSkeleton = false
          this.tipoSelecionado = 'Plano'
        }, 6000)
      }
    },
    methods: {
      alterarTipoSelecionado (tipo) {
        this.tipoSelecionado = tipo
      },
      ajudaPlanoCliente () {
        this.ajudaPlano = true
        this.informacoesPlano = false
      },
      voltaPlano () {
        this.ajudaPlano = false
        this.informacoesPlano = true
      },
      formatarMoeda (valor) {
        if (isNaN(valor)) {
          return '0,00'
        }
        const numero = parseFloat(valor).toFixed(2)
        const formatoMoeda = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
        return formatoMoeda.format(numero)
      }
    }
  }
</script>

<style scoped>

.espacamento-titulo {
  padding-left: 16px!important;
}

.espacamento-esquerda {
  padding-left: 11px!important;
}
.nameLabel {
  font-family: Inter;
  font-style: normal;
  color: #191E26;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.cpfCnpjLabel, .cpfCnpjText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 21px;
  color: #191E26;
  font-weight: 400;
  font-size: 14px;
}

.cpfCnpjText {
  color: #5E6267;
  font-weight: 400;
}

.socialIcon {
  background-color: #E7E7E8;
  border-radius: 42px;
  padding: 10px;
}

.socialLabel {
  font-family: Inter;
  font-style: normal;
  line-height: 18px;
  color: #191E26;
  font-weight: 700;
  font-size: 12px;
}

.socialText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #5E6267;
  font-weight: 400;
  font-size: 14px;
}

.cardsLabel {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #191E26;
  font-weight: 600;
  font-size: 16px;
}

.cardsText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #7E8185;
  font-weight: 400;
  font-size: 16px;
}

.cardsBorder {
  border: 1px solid #E7E7E8 !important;
  border-radius: 8px;
}

.divider {
  transform: scaleY(0.3);
}

.linhaBotoes {
  width: 100%;
  margin-left: -4px;
  margin-top: -4px;
}

.botoesTopoSelecionado {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: var(--primaria);
  width: 100px;
  color: white;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopoSelecionado{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-top: 2px;
}

.botoesTopo {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: white;
  width: 80px;
  color: #7E8185;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopo{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
}

.linkAbrir {
  color: var(--primaria);
  display: flex;
  justify-content: center;
  align-items: center;
}

.columTable {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #191E26;
  padding-right: 10px;
}
.valueTable {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #7E8185;
}
.labelDadosPlano {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #191E26;
}
.topico {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #191E26;
}
.textoSpan {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #7E8185;
}
.grupoAjuda {
  margin-top: 20px;
  padding-bottom: 68px;
}
@media screen and (max-width: 960px) {
  .espacamento-titulo {
    padding-left: 0px!important;
  }
  .espacamento-esquerda {
    padding-left: 0px!important;
  }
  .nameLabel {
    font-size: 20px !important;
    line-height: normal;
    font-style: normal;
    color: black;
    font-weight: 800;
    word-break: keep-all;
  }

  .socialLabel {
    font-style: normal;
    line-height: 150%;
    font-weight: 700;
    font-size: 12px;
  }

  .socialText {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    font-size: 16px;
  }

  .cardsLabel {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 600;
    font-size: 10px;
  }

  .cardsText {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
  }
  .columTable {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: #191E26;
    padding-right: 3px;
    min-width: 100px;
  }
  .valueTable {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #7E8185;
  }
  .labelDadosPlano {
    margin-left: -10px;
  }
}
</style>
