import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'

export async function consultarTerceiro (cnpj, coligada = 0) {
  try {
    const { data } = await http.get(`/consultar-terceiro/${cnpj}?coligada=${coligada}`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function atualizar (cfo, terceiro) {
  try {
    const { data } = await http.put(`/atualizar-terceiro/${cfo}`, terceiro)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function cadastrar (terceiro) {
  try {
    const { data } = await http.post(`/cadastrar-terceiro`, terceiro)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function listarTerceiros () {
  try {
    const { data } = await http.get('/listar-terceiros')
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function consultarLoginTerceiro (login) {
  try {
    const { data } = await http.get(`/consultar-login-terceiro/${login}`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}
