import moment from 'moment'
import { unmask } from '@/plugins/helpers'

export function real (value) {
  return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export function money (value) {
  return parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export function stringToDate (value) {
  if (value === undefined) return 0
  return new Date(value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'))
}

export function cep (value) {
  return value.length === 8 ? `${value.substring(0, 5)}-${value.substring(5, 8)}` : value
}

export function cpf (value) {
  return value && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
}

export function cnpj (value) {
  return value && value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

export function formatarCpfOuCnpj (value) {
  const documento = unmask(value)
  if (documento.length === 11) {
    return cpf(documento)
  }
  return cnpj(documento)
}

export function formatarCelular (value) {
  return value && value.toString().replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3')
}

export function toDateBR (value) {
  return convertToDateBR(value)
}

export function toDateTimeBR (value) {
  return moment(value, 'YYYY-MM-DD HH:mm:ss.uuu').format('DD/MM/YYYY H:mm:ss')
}

export function toDateBrWithHour (value) {
  return moment(toDateBR(value), 'DD/MM/YYYY H:m:s').format('DD/MM/YYYY H:mm:ss')
}

export function formatDateTime (values) {
  return moment(values).format('DD/MM/YYYY HH:mm:ss')
}

export function convertToDateBR (value, withTime = false) {
  let pieces = []
  if (!value) {
    return ''
  }
  if (!value.includes('-')) {
    return value
  }
  if (value.includes('T')) {
    pieces = value.split('T')
  } else {
    pieces = value.split(' ')
  }
  const date_us = pieces[0]
  const date_pieces = date_us.split('-')

  let date_br = `${date_pieces[2]}/${date_pieces[1]}/${date_pieces[0]}`

  if (withTime) {
    date_br += pieces[1]
  }

  return date_br
}
