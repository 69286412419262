<template>
  <v-container fluid>
    <h1 class="titulo">Data base para mapa de comissões</h1>
    <v-row justify="end">
      <v-col cols="12" md="4" lg="3">
        <ButtonIcon
            id="ButtonNovaDataBase"
            texto="Nova data base"
            icone="pc-mais"
            color="primaria"
            small
            block
            @click="adicionarNovaDataBase()"
        />
      </v-col>
    </v-row>
    <DataTable
        class="mt-5"
        sort-by="dataReferencia"
        :filter="filters"
        :headers="headers"
        :params="params"
        item-key="idDataBase"
        url="listar-data-base"
    >
      <template v-slot:[`item.descricao`]="item">
        <td>{{ item.descricao }}</td>
      </template>
      <template v-slot:[`item.dataReferencia`]="item">
        <td>{{ getAno(item.dataReferencia) }}</td>
      </template>
      <template v-slot:[`item.mesReferencia`]="item">
        <td>{{ primeiraMaiuscula(item.mesReferencia) }}</td>
      </template>
      <template v-slot:[`item.dataEncerramento`]="item">
        <td>{{ formatarData(item.dataEncerramento) }}</td>
      </template>
      <template v-slot:[`item.editar`]="item">
        <td class="icon-center">
          <v-btn
              icon
              @click="editarComissao(item.idDataBase)"
          >
            <v-icon
                color="primaria">
              pc-editar
            </v-icon>
          </v-btn>
        </td>
      </template>
    </DataTable>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import session from '@/plugins/session'
  import { primeiraLetraMaiuscula } from '@/plugins/helpers'

  export default {
    name: 'DatasBase',
    data: () => ({
      headers: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'Ano referência', value: 'dataReferencia' },
        { text: 'Mês referência', value: 'mesReferencia' },
        { text: 'Encerramento', value: 'dataEncerramento' },
        { value: 'editar', width: 50, sortable: false }
      ],
      filters: {},
      pagination: {
        sortBy: ['dataReferencia'], sortDesc: [ true ]
      }
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      },
      getIdUnidade () {
        return session.get('unidade').idUnidade
      }
    },
    mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)
    },
    created () {
      const params = this.$route.params

      if (params.hasOwnProperty('mensagem')) {
        this.$toast.success(params.mensagem)
      }
    },
    methods: {
      editarComissao (id) {
        this.$router.push({
          name: 'DataBase',
          params: { id }
        })
      },
      adicionarNovaDataBase () {
        this.irParaCadastroDataBase()
      },
      irParaCadastroDataBase (id) {
        this.$router.push({
          name: 'DataBase',
          params: { id }
        })
      },
      getAno (data) {
        return moment(data, 'MM/YYYY').format('YYYY')
      },
      formatarData (data) {
        return data
      },
      primeiraMaiuscula (string) {
        return primeiraLetraMaiuscula(string)
      }
    }
  }
</script>
