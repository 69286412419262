<template>
  <v-card-text v-if="tipoSelecionado === 'Cliente' || tipoSelecionado === ''">
    <div v-if="ajudaClientes">
      <v-row class="mt-3 ml-0">
        <label class="labelConsorciado">Dados do consorciado</label>
        <img
            src="@/assets/icons/portal-corretora/svg/dsCircleBack.svg"
            @click="voltaClientes"
            height="17"
            width="17"
            style="cursor: pointer; "
            class="ml-2 mt-2"
        />
      </v-row>
      <v-row class="grupoAjuda">
        <div class="ml-3">
          <span class="topico">Nome - </span>
          <span class="textoSpan">Nome completo da pessoa que adquiriu a cota.</span>
        </div>
        <div class="ml-3">
          <span class="topico">CPF/CNPJ - </span>
          <span class="textoSpan">Número do CPF/CNPJ do titular da cota.</span>
        </div>
      </v-row>
    </div>
    <div v-if="informacoesClientes" :class="isMobile ? '' : 'mt-4'">
      <v-skeleton-loader
          v-if="loadingSkeleton"
          type="image"
          :width="isMobile ? '99%' : '60%'"
          height="25px"
          class="ml-0"
      />
      <label v-else class="nameLabel">{{ dados.nome }}</label>
      <v-skeleton-loader
          v-if="loadingSkeleton"
          type="image"
          :width="isMobile ? '50%' : '30%'"
          height="20px"
          class="ml-0 mt-2"
      />
      <div v-else>
        <label class="cpfCnpjLabel mt-4">{{ getCpfCnpjLabel }}<span class="cpfCnpjText ml-1">{{ getCpfCnpjText }}</span></label>
      </div>

      <v-row class="mt-1 espacamento-esquerda mb-0">
        <!-- md e sm não funcionou, o sm não entendeu muito bem quando passa pra mobile... -->
        <v-col cols="12" :class="{ 'pr-0 pl-4': isMobile }">
          <v-row class="pb-2">
            <v-col :cols="isMobile ? 12 : 6">
              <v-row class="mt-1">
                <v-skeleton-loader
                    v-if="loadingSkeleton"
                    type="avatar"
                    class="ml-0"
                />
                <img v-else class="socialIcon" src="@/assets/icons/portal-corretora/svg/dsWhatsapp.svg" />
                <div class="d-flex flex-column ml-5">
                  <v-skeleton-loader
                      v-if="loadingSkeleton"
                      type="image"
                      :width="isMobile ? '100' : '200'"
                      height="12"
                      class="ml-0 mt-2"
                  />
                  <label v-else class="socialLabel">Whatsapp <a class="linkAbrir" @click="whatsapp()">(clique para abrir)</a></label>
                  <v-skeleton-loader
                      v-if="loadingSkeleton"
                      type="image"
                      :width="isMobile ? '100' : '200'"
                      height="12"
                      class="ml-0 mt-1"
                  />
                  <span v-else class="socialText">{{ getMaskedPhone }}</span>
                </div>
              </v-row>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="linhaCabecalho">
              <v-row class="mt-1">
                <v-skeleton-loader
                    v-if="loadingSkeleton"
                    type="avatar"
                    class="ml-0"
                />
                <v-icon v-else class="socialIcon" color="#CE181E" size="21">mdi-email-outline</v-icon>
                <div class="d-flex flex-column ml-5">
                  <v-skeleton-loader
                      v-if="loadingSkeleton"
                      type="image"
                      :width="isMobile ? '100' : '200'"
                      height="12"
                      class="ml-0 mt-2"
                  />
                  <label v-else class="socialLabel">E-mail <a class="linkAbrir" @click="abrirEmail">(clique para abrir)</a></label>
                  <v-skeleton-loader
                      v-if="loadingSkeleton"
                      type="image"
                      :width="isMobile ? '100' : '200'"
                      height="12"
                      class="ml-0 mt-1"
                  />
                  <span v-else class="socialText">{{ dados.email }}</span>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="isMobile ? 12 : 12" class="linha">
          <v-row :class="isMobile ? 'px-0 pr-1 pl-0 linhaAcima' : 'linhaAcima'">
            <v-col :cols="isMobile ? 12 : 4" :class="{ 'px-0 py-1': isMobile }" style="padding-left: 0px;">
              <v-skeleton-loader
                  v-if="loadingSkeleton"
                  type="image"
                  :width="!isMobile ? '100%' : '91%'"
                  :height="!isMobile ? 78 : 40"
                  :class="!isMobile ? '' : 'mt-5 ml-2'"
              />
              <v-card v-else :class="getCardsClass" elevation="0" color="transparent">
                <v-card-text class="mobileInfo">
                  <label class="cardsLabel">Cota</label>
                  <div class="d-flex" style="padding-top: 8px;">
                    <v-icon color="#7E8185" class="mb-1 mr-3 imagem">pc-cotas-contempladas</v-icon>
                    <span class="cardsText">{{ dados.cota }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4" :class="{ 'px-0 py-1': isMobile }">
              <v-skeleton-loader
                  v-if="loadingSkeleton"
                  type="image"
                  :width="!isMobile ? '100%' : '91%'"
                  :height="!isMobile ? 78 : 40"
                  :class="!isMobile ? '' : 'mt-5 ml-2'"
              />
              <v-card v-else :class="getCardsClass" elevation="0" color="transparent">
                <v-card-text class="mobileInfo">
                  <label class="cardsLabel">Grupo</label>
                  <div class="d-flex" style="padding-top: 8px;">
                    <img src="@/assets/img/IconGrupo.svg" class="mr-3" size="23">
                    <span class="cardsText">{{ dados.grupo }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4" :class="{ 'px-0 py-1': isMobile }">
              <v-skeleton-loader
                  v-if="loadingSkeleton"
                  type="image"
                  :width="!isMobile ? '100%' : '91%'"
                  :height="!isMobile ? 78 : 40"
                  :class="!isMobile ? '' : 'mt-5 ml-2'"
              />
              <v-card v-else :class="getCardsClass" elevation="0" color="transparent">
                <v-card-text class="mobileInfo">
                  <label class="cardsLabel">Proposta</label>
                  <div class="d-flex" style="padding-top: 8px;">
                    <v-icon color="#7E8185" class="imagem">pc-documento-escrito</v-icon>
                    <span class="cardsText" style="padding-left: 10px;">{{ dados.idDocumento }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row style="padding-left: 13px;padding-bottom: 5px;">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="isMobile ? '100' : '200'"
            height="12"
            class="ml-0 mt-2"
        />

        <div v-else :class="isMobile ? '' : 'ml-3 mb-2 mt-8'">
          <a class="ajuda" style="padding-left: 5px;" @click="ajudaTipoCliente">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_579_3944)">
                <path d="M7 13.125C5.37555 13.125 3.81763 12.4797 2.66897 11.331C1.52031 10.1824 0.875 8.62445 0.875 7C0.875 5.37555 1.52031 3.81763 2.66897 2.66897C3.81763 1.52031 5.37555 0.875 7 0.875C8.62445 0.875 10.1824 1.52031 11.331 2.66897C12.4797 3.81763 13.125 5.37555 13.125 7C13.125 8.62445 12.4797 10.1824 11.331 11.331C10.1824 12.4797 8.62445 13.125 7 13.125ZM7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14Z" fill="var(--primaria)" />
                <path d="M4.59813 5.06275C4.59693 5.09101 4.60153 5.11921 4.61163 5.14563C4.62174 5.17204 4.63714 5.19611 4.65689 5.21636C4.67664 5.2366 4.70033 5.25259 4.72649 5.26334C4.75265 5.27409 4.78073 5.27938 4.80901 5.27888H5.53088C5.65163 5.27888 5.74788 5.18 5.76363 5.06013C5.84238 4.48613 6.23613 4.06788 6.93788 4.06788C7.53813 4.06788 8.08763 4.368 8.08763 5.08988C8.08763 5.6455 7.76038 5.901 7.24326 6.2895C6.65438 6.71738 6.18801 7.217 6.22126 8.02813L6.22388 8.218C6.2248 8.27541 6.24825 8.33015 6.28918 8.37042C6.3301 8.41069 6.38522 8.43326 6.44263 8.43325H7.15226C7.21027 8.43325 7.26591 8.4102 7.30694 8.36918C7.34796 8.32816 7.37101 8.27252 7.37101 8.2145V8.12263C7.37101 7.49438 7.60988 7.3115 8.25476 6.82238C8.78763 6.41725 9.34326 5.9675 9.34326 5.02338C9.34326 3.70125 8.22676 3.0625 7.00438 3.0625C5.89576 3.0625 4.68126 3.57875 4.59813 5.06275ZM5.96051 10.1054C5.96051 10.5718 6.33238 10.9165 6.84426 10.9165C7.37713 10.9165 7.74376 10.5718 7.74376 10.1054C7.74376 9.62238 7.37626 9.28288 6.84338 9.28288C6.33238 9.28288 5.96051 9.62238 5.96051 10.1054Z" fill="var(--primaria)" />
              </g>
              <defs>
                <clipPath id="clip0_579_3944">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span style="padding-left: 5px;">Preciso de ajuda para entender</span>
          </a>
        </div>
      </v-row> -->
    </div>
  </v-card-text>
</template>

<script>
  import { cpf, cnpj } from '@/plugins/formatters'
  export default {
    name: 'CardInformacoesTipoCliente',
    props: {
      dados: Object,
      executaSkeleton: Object
    },
    data: () => ({
      loadingSkeleton: false,
      tipoSelecionado: '',
      ajudaClientes: false,
      informacoesClientes: true
    }),
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      getCpfCnpjLabel () {
        return this.dados.cpfCnpj?.length === 11 ? 'CPF' : 'CNPJ'
      },
      getCpfCnpjText () {
        return this.dados.cpfCnpj?.lenght === 11 ? cpf(this.dados.cpfCnpj) : cnpj(this.dados.cpfCnpj)
      },
      getCardsClass () {
        return { cardsBorder: true }
      },
      getMaskedPhone () {
        var result = this.dados?.telefone.replace(/\D/g, '')
        result = result.replace(/^0/, '')
        if (result.length > 10) {
          result = result.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
        } else if (result.length > 5) {
          result = result.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
        } else if (result.length > 2) {
          result = result.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
        } else {
          result = result.replace(/^(\d*)/, '($1')
        }
        return result
      }
    },
    mounted () {
      if (this.executaSkeleton) {
        this.loadingSkeleton = true
        setTimeout(() => {
          this.loadingSkeleton = false
          this.tipoSelecionado = 'Cliente'
        }, 6000)
      }
    },
    methods: {
      alterarTipoSelecionado (tipo) {
        this.tipoSelecionado = tipo
      },
      whatsapp () {
        const telefone = `https://web.whatsapp.com/send/?phone=55${this.dados?.telefone}&type=phone_number&app_absent=0`
        // existe um parametro chamado text, que contem uma mensagem (Olá! Quero ter um atendimento), pode ser trocada.
        window.open(telefone, '_blank')
      },
      abrirEmail () {
        const enderecoEmail = 'mailto:' + this.dados?.email
        // para enviar com assunto tambem
        // const enderecoEmail2 = 'mailto:' + this.dados?.email + '?subject=Assunto do Email'
        window.open(enderecoEmail, '_blank')
      },
      ajudaTipoCliente () {
        this.ajudaClientes = true
        this.informacoesClientes = false
      },
      voltaClientes () {
        this.ajudaClientes = false
        this.informacoesClientes = true
      }
    }
  }
</script>

<style scoped>

.linhaAcima {
  margin-top: 3px;
}

.imagem {
  font-size: 20px;
}

.espacamento-titulo {
  padding-left: 16px!important;
}

.espacamento-esquerda {
  padding-left: 23px!important;
  padding-right: 9.39px!important;
}
.nameLabel {
  font-family: Inter;
  font-style: normal;
  color: #191E26;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  padding-left: 10px;
}

.cpfCnpjLabel, .cpfCnpjText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 21px;
  color: #191E26;
  font-weight: 400;
  font-size: 14px;
  padding-left: 10px;
}

.cpfCnpjText {
  color: #5E6267;
  font-weight: 400;
}

.socialIcon {
  background-color: #E7E7E8;
  border-radius: 42px;
  padding: 13px;
}

.socialLabel {
  font-family: Inter;
  font-style: normal;
  line-height: 18px;
  color: #191E26;
  font-weight: 700;
  font-size: 12px;
}

.socialText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #5E6267;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}

.cardsLabel {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #191E26;
  font-weight: 600;
  font-size: 16px;
}

.cardsText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #7E8185;
  font-weight: 400;
  font-size: 16px;
}

.cardsBorder {
  border: 1px solid #E7E7E8 !important;
  border-radius: 8px;
}

.divider {
  transform: scaleY(0.3);
}

.linhaBotoes {
  width: 100%;
  margin-left: -4px;
  margin-top: -4px;
}

.botoesTopoSelecionado {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: var(--primaria);
  width: 100px;
  color: white;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopoSelecionado{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-top: 2px;
}

.botoesTopo {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: white;
  width: 80px;
  color: #7E8185;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopo{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
}

.linkAbrir {
  color: var(--primaria);
}
.ajuda {
  color: var(--primaria);
}
.labelConsorciado {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #191E26;
}
.topico {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #191E26;
}
.textoSpan {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #7E8185;
}
.grupoAjuda {
  margin-top: 20px;
  padding-bottom: 157px;
}

.linhaCabecalho {
  margin-left: -17px;
}

@media screen and (max-width: 960px) {
  .mobileInfo {
    padding-top: 7px;
    padding-bottom: 13px;
  }
  .imagem {
    font-size: 17px;
    margin-right: 12px !important;
  }
  .nameLabel {
    font-family: Inter;
    font-style: normal;
    color: #191E26;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    padding-left: 0px;
  }

  .cpfCnpjLabel, .cpfCnpjText {
    font-family: Inter;
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 21px;
    color: #191E26;
    font-weight: 400;
    font-size: 14px;
    padding-left: 0px;
  }

  .linhaCabecalho {
    margin-top: -5px;
    margin-left: 0px;
  }
  .linha {
    margin-top: -8px!important;
  }
  .espacamento-titulo {
    padding-left: 0px!important;
  }
  .espacamento-esquerda {
    padding-left: 0px!important;
    padding-top: -5px;
  }
  .nameLabel {
    font-size: 20px !important;
    line-height: normal;
    font-style: normal;
    color: black;
    font-weight: 800;
    word-break: keep-all;
  }

  .socialLabel {
    font-style: normal;
    line-height: 150%;
    font-weight: 700;
    font-size: 12px;
  }

  .socialText {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    font-size: 16px;
  }

  .cardsLabel {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 600;
    font-size: 10px;
  }

  .cardsText {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
  }
  .socialLabel {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #191E26;
  }
  .linkAbrir {
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }
  .ajuda {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .socialText {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .linhaAcima {
    padding-top: 6px!important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1700px) {
  .socialText {
    font-family: Inter;
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    color: #5E6267;
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
  }
}
</style>
