<template>
  <span class="erros-container">
    <h4>Notas não incluidas no lote:</h4>
    <v-spacer />
    <v-data-table
        :headers="headers"
        :items="notasErro"
        :items-per-page="50"
        class="elevation-1"
    ></v-data-table>
  </span>
</template>
<style>
    .erros-container{
        text-align: center;
        color: red;
    }
</style>
<script>
  export default {
    props: {
      notas: Array
    },
    data: () => ({
      headers: [
        { text: 'CPF/CNPJ', value: 'cnpj' },
        { text: 'Nome parceiro', value: 'nome' },
        { text: 'Nota', value: 'numeroNota', width: 90 }
      ]
    }),
    computed: {
      notasErro () {
        return this.notas
      }
    }
  }
</script>
