<template>
  <div>
    <v-layout v-if="!exibirLoadingInicial">
      <v-flex class="flex-basis-0 d-flex align-center">
        <v-form
            v-if="!$store.state.unidade.autenticacaoMultifator"
            ref="form"
            class="d-flex flex-column flex col-esquerda"
            @submit.prevent="entrar"
        >
          <div class="d-flex flex justify-center mb-16">
            <v-img
                containexibir-loading-inicial
                :src="logoUnidade"
                max-height="120"
                max-width="220"
            />
          </div>
          <div v-if="!exibirRecuperacaoSenha">
            <TextField
                v-model="login"
                placeholder="Digite o seu login"
                label="Login"
                maxlength="150"
                :rules="[$rules.required]"
                @keydown.space.prevent
                :disabled="loading"
            />
            <PasswordField
                v-model="senha"
                placeholder="Digite a sua senha"
                label="Senha"
                maxlength="30"
                :rules="[$rules.required]"
                :disabled="loading"
            />
            <v-row class="mt-16">
              <v-col cols="12" sm="6" class="d-flex justify-center align-center">
                <a
                    @click="exibirFormulatorioRecuperacao"
                >Recuperar senha
                </a>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex justify-center">
                <Button
                    id="botao-entrar"
                    type="submit"
                >
                  Entrar
                </Button>
              </v-col>
            </v-row>
          </div>
          <div v-if="exibirRecuperacaoSenha">
            <TextField
                v-model="recuperacao.login"
                placeholder="Digite o seu login"
                label="Login*"
                maxlength="150"
                :disabled="desabilitarLogin"
                :rules="[$rules.required]"
                @keydown.space.prevent
                validate-on-blur
            />
            <TextField
                v-if="exibirCpfCnpjRecuperacao"
                label="CPF/CNPJ*"
                v-model="recuperacao.cpfCnpj"
                v-mask="['###.###.###-##','##.###.###/####-##']"
                :rules="[this.$rules.required, this.$rules.documento]"
                placeholder="Digite o seu CPF/CNPJ"
                validate-on-blur
            />
            <TextField
                v-if="exibirEmailRecuperacao"
                label="E-mail*"
                v-model="recuperacao.email"
                maxlength="150"
                :rules="[this.$rules.required, this.$rules.email]"
                placeholder="Digite o seu e-mail"
                validate-on-blur
            />
            <v-row class="mt-16">
              <v-col cols="12" sm="6" class="d-flex justify-center align-center">
                <Button
                    outlined
                    @click="voltarLogin"
                >
                  Voltar
                </Button>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex justify-center">
                <Button
                    @click="iniciarRecuperacaoSenha"
                >
                  Próximo
                </Button>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <div
            class="text-center mx-auto"
            v-else
        >
          <div class="d-flex flex justify-center mb-8">
            <v-img
                containexibir-loading-inicial
                :src="logoUnidade"
                max-height="100"
                max-width="200"
                alt="Área Restrita"
            />
          </div>
          <Button
              color="secondary"
              @click="loginAzure"
          >
            Logar no Área Restrita
          </Button>
          <div class="pa-2">
            <button
                v-if="!sso"
                color="red"
                @click="recuperarSenhaEmbracon"
            >
              Esqueceu sua senha?
            </button>
          </div>
        </div>
      </v-flex>
      <v-flex
          class="col-direita flex-basis-0 d-none d-sm-block"
      >
        <div id="imagem-lateral"></div>
      </v-flex>
    </v-layout>
    <div
        id="erro"
        v-else
    >
      <Loading visible />
    </div>
    <DialogAtualizarSenha
        :login="login"
        :exibir="exibirDialogAtualizarSenha"
        @exibir="exibir => exibirDialogAtualizarSenha = exibir"
    />
    <DialogErroLoginUnicred
        :exibir="getErroUnicred"
    />
    <DialogPoliticaEmbracon
        :exibir="exibirDialogPolitica"
        :politica="politica"
        @cancelar="cancelarLogin"
        @exibir="dialogFechado"
    />
    <Loading
        :visible="exibirLoading"
        :background-opacity="1"
    />
    <v-dialog
        persistent
        v-model="exibirDialogConectar"
    >
      <v-card class="pb-1">
        <v-card-title>
          <v-btn
              icon
              class="ml-auto"
              @click="exibirDialogConectar = false"
          >
            <v-icon
                color="primaria"
                size="10"
            >
              pc-fechar
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          Efetue o reset de senha através do Portal Conectar
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <Button
              small
              outlined
              @click="exibirDialogConectar = false"
          >
            Cancelar
          </Button>
          <a :href="urlConectar" target="_blank" class="ml-5" :style="{ textDecoration: 'none' }">
            <Button small>
              Acessar
            </Button>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

  <script>
  import { datadogRum } from '@datadog/browser-rum'
  import auth from '@/mixins/auth'
  import { resetarSenha } from '@/service/usuario'
  import { TipoPerfilNewcon, TipoPerfil } from '@/plugins/constants'
  import { unmask } from '@/plugins/helpers'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import DialogPoliticaEmbracon from '@/components/dialog/DialogPoliticaEmbracon'
  import { consultarUnidade } from '@/service/unidade'
  import session from '@/plugins/session'
  import DialogAtualizarSenha from '@/components/dialog/DialogAtualizarSenha'
  import DialogErroLoginUnicred from '@/components/dialog/DialogErroLoginUnicred'
  import { buscarConfiguracoes } from '@/service/configuracao'
  import { buscarPoliticaNaoAceita } from '@/service/politica'
  import { buscarUrl } from '@/service/convertmais'
  import { notificacoesMapa } from '@/service/mapa-comissao'

  export default {
    name: 'Login',
    components: {
      DialogPoliticaEmbracon,
      DialogAtualizarSenha,
      DialogErroLoginUnicred
    },
    mixins: [auth],
    data: () => ({
      exibirDialogConectar: false,
      exibirDialogAtualizarSenha: false,
      exibirLoading: false,
      exibirLoadingInicial: true,
      urlConectar: '',
      login: '',
      senha: '',
      recuperacao: {
        login: '',
        email: '',
        cpfCnpj: '',
        perfil: ''
      },
      desabilitarLogin: false,
      exibirRecuperacaoSenha: false,
      exibirCpfCnpjRecuperacao: false,
      exibirEmailRecuperacao: false,
      perfilValidado: false,
      exibirDialogPolitica: false,
      logoUnidade: '',
      sso: true,
      idUnidade: 0,
      politica: {
        idVersao: undefined,
        idAzure: undefined,
        titulo: undefined
      }
    }),
    computed: {
      ...mapState(['loading']),
      ...mapGetters([
        'getErroUnicred'
      ])
    },
    async mounted () {
      await this.setNotificacoes([])
      await this.setNumeroNotificacao(0)

      if (this.$route.params.idUnidade) {
        this.exibirLoading = true

        try {
          this.carregarUnidade()

          if (this.$route.params.senhaExpirada) {
            this.exibirDialogAtualizarSenha = true
          } else if (this.$route.params.autenticado) {
            await this.verificarPolitica()

            if (session.get('politicasPendentes').length === 0) {
              // start data dog
              let user = session.get('usuario')
              const perfil = user.perfil
              datadogRum.setUser({
                email: `perfil : ${user.perfil} login: ${user.login}`,
                id: user.id,
                name: user.nome
              })
              datadogRum.startSessionReplayRecording()
              // end data dog
              if (perfil === TipoPerfil.MASTER || perfil === TipoPerfil.GESTOR) {
                this.$router.push({
                  name: 'Inicio'
                })
              } else if (perfil === TipoPerfil.PARCEIRO) {
                if (this.$route.params.idUnidade !== 90) {
                  this.$router.push({
                    name: 'MeusClientes'
                  })
                } else {
                  this.$router.push({
                    name: 'Sublogins'
                  })
                }
              } else if (perfil === TipoPerfil.SUBLOGIN) {
                this.$router.push({
                  name: 'Root'
                })
              } else if (perfil === TipoPerfil.MANUTENCAO_TERCEIRO) {
                this.$router.push({
                  name: 'Terceiro'
                })
              }
            }
          }
        } catch (erro) {

        }

        this.exibirLoading = false
      }
    },
    methods: {
      ...mapActions([
        'setNotificacoes', 'setNumeroNotificacao'
      ]),

      async carregarUnidade () {
        try {
          let idUnidade = this.$route.params.idUnidade

          if (this.$session.get('unidade').idUnidade !== '') {
            idUnidade = this.$store.state.unidade.idUnidade
          }

          const unidade = await consultarUnidade(idUnidade)

          this.idUnidade = unidade.idUnidade
          this.logoUnidade = unidade.urlLogotipo
          this.sso = unidade.sso

          this.$vuetify.theme.themes.light.primaria = unidade.corPrimaria
          this.$vuetify.theme.themes.light.secundaria = unidade.corSecundaria
          this.$vuetify.theme.themes.light.destaque = unidade.corDestaque
          this.$vuetify.theme.themes.light.textoPrimario = unidade.corTextoPrimario
          this.$vuetify.theme.themes.light.textoSecundario = unidade.corTextoSecundario
          this.$vuetify.theme.themes.light.textoDestaque = unidade.corTextoDestaque

          this.$vuetify.theme.themes.light.primary = unidade.corPrimaria
          this.$vuetify.theme.themes.light.secundaria = unidade.corSecundaria

          session.set({ unidade })
          this.exibirLoadingInicial = false

          session.set({
            visaoAdministrativa: {
              login: '',
              idUsuario: '',
              perfil: '',
              centroCusto: ''
            }
          })
        } catch (erro) {
          console.error(erro)

          this.$router.push({
            name: 'ParametroInvalido',
            replace: true
          })
        }
      },
      async loginAzure () {
        if (!this.$store.state.unidade.sso) {
          const session = await this.$msalInstance.handleRedirectPromise()

          if (session) {
            await this.$msalInstance.logoutRedirect({
              onRedirectNavigate: url => false
            })
          }

          this.$msalInstance.loginRedirect(this.$store.state.msalAuthRequest)
        } else {
          window.location.href = this.$store.state.unidade.linkSso + this.$store.state.unidade.identificadorLoginSso
        }
      },
      recuperarSenhaEmbracon () {
        let url = process.env.VUE_APP_RECUPERAR_SENHA_URL + '/parceiro/' + this.idUnidade + '/solicitar'
        window.location.href = url
      },
      cancelarLogin () {
        this.exibirDialogPolitica = false
        session.set({ politicasPendentes: null })
      },
      async entrar () {
        if (!this.exibirRecuperacaoSenha) {
          if (this.$refs.form.validate()) {
            try {
              await this.autenticar(this.login, this.senha, this.$route.params.idUnidade)
              await this.verificarPolitica()
            } catch (erro) {
              const senhaExpirada = /(senha expirada)/.test(erro.message.toLowerCase())

              if (senhaExpirada) {
                this.exibirDialogAtualizarSenha = true
              }

              this.$toast.error(erro.message)
            }
          }
        }
      },
      async verificarPolitica () {
        try {
          const politicaNaoAceita = await buscarPoliticaNaoAceita(this.$route.params.idUnidade)

          session.set({
            politicasPendentes: politicaNaoAceita
          })

          this.exibirDialogPoliticaPendente()
        } catch (erro) {
          this.exibirDialogPolitica = false
          this.$toast.error(erro.message)
        }
      },
      async exibirDialogPoliticaPendente () {
        const politicasPendentes = session.get('politicasPendentes')

        if (politicasPendentes.length !== 0) {
          this.politica = politicasPendentes[0]
          this.exibirDialogPolitica = true
        } else {
          this.exibirDialogPolitica = false

          const configuracoes = await buscarConfiguracoes()
          const urlConvertMais = await buscarUrl(this.idUnidade)

          session.set({ configuracoes })
          session.set({ urlConvertMais })

          const perfil = session.get('usuario').perfil

          if (perfil === TipoPerfil.MASTER || perfil === TipoPerfil.GESTOR) {
            this.$router.push({
              name: 'Inicio'
            })
          } else if (perfil === TipoPerfil.PARCEIRO) {
            try {
              const solicitacaoMapaComissao = await notificacoesMapa(this.$route.params.idUnidade)

              session.set({
                solicitacaoMapaComissao
              })
            } catch (erro) {
              this.$toast.error(erro)
            }

            let name_rota = this.idUnidade !== 92 ? 'MeusClientes' : 'Root'

            this.$router.push({
              name: name_rota
            })
          } else if (perfil === TipoPerfil.SUBLOGIN) {
            this.$router.push({
              name: 'Root'
            })
          } else if (perfil === TipoPerfil.MANUTENCAO_TERCEIRO) {
            this.$router.push({
              name: 'Terceiro'
            })
          } else if (perfil === TipoPerfil.GESTAO_UNIFICADA) {
            this.$router.push({
              name: 'Parcerias'
            })
          }
        }
      },
      dialogFechado () {
        this.exibirDialogPolitica = false
        setTimeout(() => {
          this.exibirDialogPoliticaPendente()
        }, 200)
      },
      async iniciarRecuperacaoSenha () {
        if (this.$refs.form.validate()) {
          try {
            const resposta = await this.recuperarSenha()

            if (resposta.portalConectar) {
              this.urlConectar = resposta.urlPortalConectar
              this.exibirDialogConectar = true
            } else {
              this.$toast.success(resposta.mensagem)
              this.voltarFormularioLogin()
            }
          } catch (erro) {

          }
        }
      },
      async recuperarSenha () {
        try {
          const payload = {
            login: this.recuperacao.login,
            cpfCnpj: unmask(this.recuperacao.cpfCnpj),
            email: this.recuperacao.email
          }

          return await resetarSenha(payload)
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      exibirFormulatorioRecuperacao () {
        this.exibirRecuperacaoSenha = true
        this.exibirCpfCnpjRecuperacao = true
        this.exibirEmailRecuperacao = true
        this.recuperacao.login = this.login
      },
      voltarFormularioLogin () {
        this.exibirRecuperacaoSenha = false
        this.exibirCpfCnpjRecuperacao = false
        this.exibirEmailRecuperacao = false
        this.recuperacao = {}
        this.exibirCpfCnpjRecuperacao = false
        this.exibirEmailRecuperacao = false
        this.perfilValidado = false
        this.desabilitarLogin = false
      },
      podeDesabilitarLoginRecuperacao () {
        return this.recuperacao.login !== ''
      },
      podeExibirEmailRecuperacaoRecuperacao () {
        return [ TipoPerfilNewcon.PARCEIRO, TipoPerfilNewcon.SUBLOGIN ].includes(this.recuperacao.perfil.toLowerCase())
      },
      getMensagemInformativa () {
        return this.recuperacao.perfil === TipoPerfilNewcon.PARCEIRO
          ? 'E-mail alternativo da conta.'
          : 'E-mail principal da conta.'
      },
      voltarLogin () {
        this.voltarFormularioLogin()
      }
    }
  }

  </script>

  <style lang="scss" scoped>
    .layout {
      min-height: 100vh;
    }

    .col-esquerda {
      padding: 0 15%;
    }
    .col-direita {
      background: var(--primaria);
    }
    .img-login {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      padding: 0 25%;
    }

    #erro {
      min-height: 100vh;
      display: flex;
      align-items: center;
      text-align: center;
    }

    #imagem-lateral {
      min-height: 100vh;
      width: 100% !important;
      background-image: url(~@/assets/img/img-login.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: inset 0 0 0 1000px rgba(var(--primaria), 0.2);
    }

    #botao-entrar {
      padding: 0 3rem !important;
    }
  </style>
