import { login } from '@/service/login'
import session from '@/plugins/session'

export default {
  methods: {
    async autenticar (username, password, idUnidade) {
      const usuario = await login({ login: username, senha: password }, idUnidade)
      session.set({ usuario })
    },
    async autenticarAzure (acessToken, idUnidade) {
      const usuario = await login({ acessToken }, idUnidade)
      session.set({ usuario })
      return usuario
    }
  }
}
