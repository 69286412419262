<template>
  <div>
    <v-app-bar app clipped-left color="white" elevation="2">
      <div class="logo">
        <v-img
            contain
            alt="Logo"
            class="mr-1 mx-lg-auto"
            :src="$session.get('unidade').urlLogotipo"
            :max-width="tamanhoLogo"
            :max-height="tamanhoLogo"
        />
      </div>
      <v-app-bar-nav-icon @click.stop="exibir = !exibir" />
      <v-spacer />
      <Notificacao />
      <Perfil />
    </v-app-bar>
    <v-navigation-drawer app clipped v-model="exibir" floating class="primaria">
      <v-list nav dense>
        <v-list-item
            link
            target="_blank"
            :key="rota.name"
            v-for="rota in rotasFiltradas"
            :href="$session.get('visaoAdministrativa').login && rota.meta.bloquearVisao ? undefined : rota.menu.url"
            :class="`rounded-0 py-1 ${destaqueMenu(rota.name)}`"
            @click="navegar(rota)"
        >
          <v-list-item-icon>
            <v-icon :color="textoDestaqueMenu(rota.name)">
              {{ rota.menu.icone }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :class="`${textoDestaqueMenu(rota.name)}--text`">{{ rota.menu.titulo }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="version" v-if="build !== undefined">
        <p> ⓘ build {{ build }}</p>
      </div>
      <v-spacer />
    </v-navigation-drawer>
  </div>
</template>

  <script>
  import session from '@/plugins/session'
  import Perfil from '@/components/Perfil'
  import { rotasMenu } from '@/plugins/router'
  import Notificacao from '@/components/Notificacao.vue'

  export default {
    components: {
      Notificacao,
      Perfil
    },
    data: () => ({
      exibir: false,
      selecionado: '',
      perfil: '',
      principal: false,
      urlConvert: undefined,
      unidade: {},
      configuracoes: {},
      rotas: [],
      build: ''
    }),
    computed: {
      tamanhoLogo () {
        return this.$vuetify.breakpoint.mobile ? '70' : '95'
      },
      rotasFiltradas () {
        return this.rotas.filter(rota => this.filtrarRota(rota))
      }
    },
    mounted () {
      this.exibir = !this.$vuetify.breakpoint.mobile
      this.urlConvert = session.get('urlConvertMais') || process.env.VUE_APP_UCE_URL
      this.perfil = session.get('visaoAdministrativa').perfil || session.get('usuario').perfil
      this.principal = session.get('usuario').principal
      this.unidade = session.get('unidade')
      this.configuracoes = session.get('configuracoes')
      this.rotas = this.prepararRotas(rotasMenu)
      this.versao()
    },
    methods: {
      destaqueMenu (name) {
        return this.$store.state.rotaAtual === name ? 'destaque' : 'primaria'
      },
      textoDestaqueMenu (name) {
        return this.$store.state.rotaAtual === name ? 'textodestaque' : 'textoprimario'
      },
      navegar (rota) {
        if (this.$session.get('visaoAdministrativa').login && rota.meta.bloquearVisao) {
          this.$store.commit('toggleExibirDialogVisao')
        } else {
          if (rota.name) {
            this.$router.push({ name: rota.name })
            this.$store.commit('setRotaAtual', rota.name)
          }
        }
      },
      versao () {
        let frontend = process.env.VUE_APP_FRONTEND_VERSION
        let backend = this.configuracoes
        this.build = `${frontend} | ${backend.versao}`
      },
      prepararRotas (rotas) {
        return rotas.map(rota => {
          if (rota.menu) {
            if (rota.meta && rota.meta.perfil.includes(this.perfil)) {
              rota.menu.bloquearVisao = false
              if (!rota.menu.url) {
                rota.menu.url = this.definirUrl(rota.menu.titulo)
              }
              return rota
            }
          }
          return null
        }).filter(rota => rota)
      },
      definirUrl (titulo) {
        switch (titulo) {
          case 'Convert+':
            return this.urlConvert
          case 'Newcon Web':
            return this.configuracoes.newconUrl || process.env.VUE_APP_NEWCON_URL
          case 'UCE':
            return this.configuracoes.uceUrl || process.env.VUE_APP_UCE_URL
          case 'CRM / Proposta':
            return 'https://parceiro.embracon.online/auth/login'
          case 'CRM VW':
            return process.env.VUE_APP_CRM_VW_URL
          case 'Salesforce':
            return process.env.VUE_APP_URL_SALESFORCE
          case 'Notion Embracon':
            return process.env.VUE_APP_URL_NOTION_EMBRACON
          default:
            return '#'
        }
      },
      filtrarRota (rota) {
        console.info(rota.path)
        if (rota.meta.sublogin && this.unidade.idUnidade === 92) return false
        if (rota.meta.principal && !this.principal) return false
        if (rota.meta.unidades && !rota.meta.unidades.includes(this.unidade.idUnidade)) return false
        if (rota.path.indexOf('/mapas-comissao') > -1 && this.principal === false) return false
        if (rota.path.indexOf('/meus-clientes') > -1 && this.principal === false && this.unidade.idUnidade === 90) return false
        return true
      }
    }
  }
  </script>

  <style scoped>
  .logo {
    padding: 7px 0;
    min-height: 64px;
    display: flex;
    align-items: center;
    min-width: 239px;
  }

  .v-list {
    padding-right: 0;
    padding-left: 0;
  }

  .v-list-item {
    color: #FFF !important;
  }

  .version {
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 7px;
  }

  .version p {
    color: #FFFFFF;
    font-size: smaller;
    opacity: 0.6;
  }

  @media (max-width: 1264px) {
    .logo {
      min-width: 0;
    }
  }
  </style>
