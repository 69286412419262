<template>
  <v-card color="#FFFFFF" elevation="1" width="auto" style="border-radius: 8px;height: 357px;">
    <v-card-title class="d-flex flex-column align-start pr-0 ml-1 espacamento-titulo">
      <v-row class="linhaBotoes">
        <div :class="tipoSelecionado === 'Cliente' ? 'botoesTopoSelecionado' : 'botoesTopo'" @click="alterarTipoSelecionado('Cliente')">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              :width="isMobile ? '99%' : '60%'"
              height="25px"
              class="ml-0"
          />
          <label v-else :class="tipoSelecionado === 'Cliente' ? 'labelBotoesTopoSelecionado' : 'labelBotoesTopo'">Cliente</label>
        </div>
        <div :class="tipoSelecionado === 'Cota' ? 'botoesTopoSelecionado' : 'botoesTopo' " style="margin-left: 20px;" @click="alterarTipoSelecionado('Cota')">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              :width="isMobile ? '99%' : '60%'"
              height="25px"
              class="ml-0"
          />
          <label v-else :class="tipoSelecionado === 'Cota' ? 'labelBotoesTopoSelecionado' : 'labelBotoesTopo'">Cota</label>
        </div>
        <div :class="tipoSelecionado === 'Plano' ? 'botoesTopoSelecionado' : 'botoesTopo' " style="margin-left: 20px;" @click="alterarTipoSelecionado('Plano')">
          <v-skeleton-loader
              v-if="loadingSkeleton"
              type="image"
              :width="isMobile ? '99%' : '60%'"
              height="25px"
              class="ml-0"
          />
          <label v-else :class="tipoSelecionado === 'Plano' ? 'labelBotoesTopoSelecionado' : 'labelBotoesTopo'">Plano</label>
        </div>
      </v-row>
    </v-card-title>
    <CardInformacoesTipoCliente :executa-skeleton="executaSkeleton" :dados="dados" v-if="tipoSelecionado === 'Cliente' || tipoSelecionado === ''" />
    <CardInformacoesCotaCliente :executa-skeleton="executaSkeleton" :dados="dados" v-if="tipoSelecionado === 'Cota'" />
    <CardInformacoesPlanoCliente :executa-skeleton="executaSkeleton" :dados="dados" v-if="tipoSelecionado === 'Plano'" />
  </v-card>
</template>

<script>
  import CardInformacoesTipoCliente from '@/components/JornadaDaCota/CardInformacoesTipoCliente.vue'
  import CardInformacoesCotaCliente from '@/components/JornadaDaCota/CardInformacoesCotaCliente.vue'
  import CardInformacoesPlanoCliente from '@/components/JornadaDaCota/CardInformacoesPlanoCliente.vue'

  export default {
    name: 'CardInformacoesCliente',
    components: {
      CardInformacoesTipoCliente,
      CardInformacoesCotaCliente,
      CardInformacoesPlanoCliente
    },
    props: {
      dados: Object
    },
    data: () => ({
      loadingSkeleton: false,
      tipoSelecionado: '',
      executaSkeleton: true
    }),
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      }
    },
    mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
        this.tipoSelecionado = 'Cliente'
        this.executaSkeleton = false
      }, 6000)
    },
    methods: {
      alterarTipoSelecionado (tipo) {
        this.tipoSelecionado = tipo
      }
    }
  }
</script>

<style scoped>

.espacamento-titulo {
  padding-left: 16px!important;
}

.espacamento-esquerda {
  padding-left: 11px!important;
}

.linhaBotoes {
  width: 100%;
  margin-left: 4px;
  margin-top: 8px;
}

.botoesTopoSelecionado {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: var(--primaria);
  width: 100px;
  color: white;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopoSelecionado{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-top: 2px;
}

.botoesTopo {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: white;
  width: 80px;
  color: #7E8185;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopo{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
}

@media screen and (max-width: 960px) {
  .espacamento-titulo {
    padding-left: 0px!important;
  }
  .espacamento-esquerda {
    padding-left: 0px!important;
  }
}
</style>
