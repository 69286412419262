import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function getUsuario (usuario) {
  try {
    const { data } = await http.get(`/usuario-newcon/${usuario}`)
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function pesquisar (termo, silent = true) {
  try {
    if (termo.includes('-') || termo.includes('/')) {
      termo = termo.replaceAll('.', '')
      termo = termo.replace('/', '')
      termo = termo.replace('-', '')
    }

    const { data } = await http.get(`/usuario/pesquisar?query=${termo}`, {
      options: { silent }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function resetarSenha (usuario) {
  try {
    const { data } = await http.patch(`/usuario/recuperar-senha`, usuario)
    return data
  } catch (erro) {
    const msg = get(erro, 'response.data.mensagem', 'Ops! Não foi possível recuperar sua senha. Tente novamente mais tarde.')
    throw Error(msg)
  }
}

export async function validarLogin (login, silent = true) {
  try {
    const { data } = await http.get(`/usuario/validar/${login}`, {
      options: { silent }
    })

    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}
