import { get } from 'lodash'
import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'

export async function obterUnidadesPolitica (silent = true) {
  try {
    const { data } = await http.get('/obter-unidades-politica', { options: { silent: silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function salvarDocumentoPolitica (documento, silent = false) {
  try {
    const { data } = await http.post('/cadastrar-politica', documento, { options: { silent: silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function alterarStatusDocumentoPolitica (idDocumento, silent = false) {
  try {
    const { data } = await http.put(`/cadastrar-politica/${idDocumento}`, { options: { silent: silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function excluirDocumentoPolitica (idDocumento, silent = false) {
  try {
    const { data } = await http.delete(`/deletar-politica/${idDocumento}`, { options: { silent: silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function editarDocumentoPolitica (documento, silent = false) {
  try {
    const { data } = await http.put('/cadastrar-politica', documento, { options: { silent: silent } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function buscarPoliticaNaoAceita (idUnidade) {
  try {
    const { data } = await http.get(`/buscar-politicas-pendentes/${idUnidade}`)
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}

export async function aceitar (idPolitica, corpo) {
  try {
    const { data } = await http.post(`/aceitar-politica/${idPolitica}`, corpo)
    return data
  } catch (erro) {
    throw get(erro, 'response.data.mensagem', Mensagens.ERRO)
  }
}
