<template>
  <v-card-text>
    <v-row class="titulo mb-4">
      <v-skeleton-loader
          v-if="loadingSkeleton"
          type="image"
          width="100px"
          height="25px"
          class="ml-0"
      />
      <label v-else>Atualização</label>
    </v-row>
    <v-row class="ml-0 mt-5">
      <v-col :class="isMobile ? 'col-12' : 'col-6'">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="isMobile ? '99%' : '223px'"
            height="223px"
        />
        <div v-else class="text-center">
          <v-progress-circular
              :rotate="-90"
              :size="223"
              :width="20"
              :value="getValue()"
              color="primary"
              class="custom-progress"
          >
            <span :class="contemplado ? 'custom-value-contemplado' : 'custom-value'">{{ getValue() }}</span>
          </v-progress-circular>
        </div>
      </v-col>
      <v-col class="col-12">
        <div class="text-left">
          <div>
            <div v-if="contemplado">
              <v-skeleton-loader
                  v-if="loadingSkeleton"
                  type="image"
                  width="100px"
                  height="15px"
                  class="ml-0 mb-1"
              />
              <div v-else style="display: flex; align-items: center;">
                <label class="label-credito-atualizado">Crédito atualizado</label>
              </div>
              <v-skeleton-loader
                  v-if="loadingSkeleton"
                  type="image"
                  width="200px"
                  height="25px"
                  class="ml-0 mb-4"
              />
              <div v-else class="valor-credito-atualizado">{{ infocliente.extratoCota.cota && infocliente.extratoCota.cota.vlrCreditoCorrigido ? formatarMoeda(infocliente.extratoCota.cota.vlrCreditoCorrigido) : 'R$ 0,00' }}</div>
            </div>
            <v-skeleton-loader
                v-if="loadingSkeleton"
                type="image"
                width="100px"
                height="15px"
                class="ml-0 mb-1"
            />
            <div v-else style="display: flex; align-items: center;">
              <div class="elipse-pago"></div>
              <label class="label-pago">Pago</label>
            </div>
            <v-skeleton-loader
                v-if="loadingSkeleton"
                type="image"
                width="200px"
                height="25px"
                class="ml-0 mb-1"
            />
            <div v-else class="valor-pago-total">{{ infocliente.extratoCota.identificacao && infocliente.extratoCota.identificacao.valorPago ? formatarMoeda(infocliente.extratoCota.identificacao.valorPago) : 'R$ 0,00' }}</div>
            <v-skeleton-loader
                v-if="loadingSkeleton"
                type="image"
                width="100px"
                height="15px"
                class="ml-0 mb-1"
            />
            <div v-else class="qtd-parcelas-pagas">{{ infocliente.extratoCota.identificacao && infocliente.extratoCota.identificacao.parcelasPagas ? infocliente.extratoCota.identificacao.parcelasPagas : 0 }} Parcelas</div>
            <v-skeleton-loader
                v-if="loadingSkeleton"
                type="image"
                width="100px"
                height="15px"
                class="ml-0 mb-1 mt-4"
            />
            <div v-else style="display: flex; align-items: center; margin-top: 10px;">
              <div class="elipse-vencer"></div>
              <label class="label-pagar">A vencer</label>
            </div>
            <v-skeleton-loader
                v-if="loadingSkeleton"
                type="image"
                width="200px"
                height="25px"
                class="ml-0 mb-1"
            />
            <div v-else class="valor-a-vencer">{{ infocliente.extratoCota.cota && infocliente.extratoCota.cota.vlrAPagar ? formatarMoeda(infocliente.extratoCota.cota.vlrAPagar ) : 'R$ 0,00' }}</div>
            <v-skeleton-loader
                v-if="loadingSkeleton"
                type="image"
                width="100px"
                height="15px"
                class="ml-0 mb-1"
            />
            <div v-else class="qtd-parcelas-pagar">{{ infocliente.extratoCota.identificacao && infocliente.extratoCota.identificacao.parcelasRestantes ? infocliente.extratoCota.identificacao.parcelasRestantes : 0 }} Parcelas</div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="linhaBotoes mt-6">
      <div :class="tipoSelecionado === 'Cliente' ? 'botoesTopoSelecionado' : 'botoesTopo'" @click="alterarTipoSelecionado('Cliente')">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="isMobile ? '99%' : '60%'"
            height="25px"
            class="ml-0"
        />
        <label v-else :class="tipoSelecionado === 'Cliente' ? 'labelBotoesTopoSelecionado' : 'labelBotoesTopo'">Cliente</label>
      </div>
      <div :class="tipoSelecionado === 'Cota' ? 'botoesTopoSelecionado' : 'botoesTopo' " style="margin-left: 20px;" @click="alterarTipoSelecionado('Cota')">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="isMobile ? '99%' : '60%'"
            height="25px"
            class="ml-0"
        />
        <label v-else :class="tipoSelecionado === 'Cota' ? 'labelBotoesTopoSelecionado' : 'labelBotoesTopo'">Cota</label>
      </div>
      <div :class="tipoSelecionado === 'Plano' ? 'botoesTopoSelecionado' : 'botoesTopo' " style="margin-left: 20px;" @click="alterarTipoSelecionado('Plano')">
        <v-skeleton-loader
            v-if="loadingSkeleton"
            type="image"
            :width="isMobile ? '99%' : '60%'"
            height="25px"
            class="ml-0"
        />
        <label v-else :class="tipoSelecionado === 'Plano' ? 'labelBotoesTopoSelecionado' : 'labelBotoesTopo'">Plano</label>
      </div>
      <CardInformacoesTipoCliente :executa-skeleton="executaSkeleton" :dados="dados" v-if="tipoSelecionado === 'Cliente' || tipoSelecionado === ''" />
      <CardInformacoesCotaCliente :executa-skeleton="executaSkeleton" :dados="dados" v-if="tipoSelecionado === 'Cota'" />
      <CardInformacoesPlanoCliente :executa-skeleton="executaSkeleton" :dados="dados" v-if="tipoSelecionado === 'Plano'" />
    </v-row>
  </v-card-text>
</template>

<script>
  import moment from 'moment/moment'
  import { mapGetters } from 'vuex'
  import CardInformacoesTipoCliente from '@/components/JornadaDaCota/CardInformacoesTipoCliente.vue'
  import CardInformacoesCotaCliente from '@/components/JornadaDaCota/CardInformacoesCotaCliente.vue'
  import CardInformacoesPlanoCliente from '@/components/JornadaDaCota/CardInformacoesPlanoCliente.vue'
  export default {
    name: 'CardExtratoTipoClienteMobile',
    components: {
      CardInformacoesTipoCliente,
      CardInformacoesCotaCliente,
      CardInformacoesPlanoCliente
    },
    props: {
      dados: Object,
      executaSkeleton: Object
    },
    data: () => ({
      loadingSkeleton: true,
      interval: {},
      value: 0,
      parcelasPagas: 0,
      parcelasVencer: 0,
      valorPagoTotal: '0,00',
      valorVencer: '0,00',
      creditoAtualizado: '0,00',
      contemplado: false,
      tipoSelecionado: '',
      executaSkeleton: true
    }),
    computed: {
      ...mapGetters({
        infocliente: 'getMeusClientes'
      }),
      isMobile () {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      getCardsClass () {
        return { cardsBorder: !this.isMobile }
      }
    },
    beforeDestroy () {
      clearInterval(this.interval)
    },
    mounted () {
      this.loadingSkeleton = true
      setTimeout(() => {
        this.loadingSkeleton = false
        this.tipoSelecionado = 'Cliente'
      }, 6000)
    },
    methods: {
      formatarMoeda (valor) {
        if (isNaN(valor)) {
          return '0,00'
        }
        const numero = parseFloat(valor).toFixed(2)
        const formatoMoeda = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
        return formatoMoeda.format(numero)
      },
      formatarPorcentagem (valor) {
        if (Number.isInteger(valor)) {
          return valor.toString() + '%'
        } else {
          return valor.toFixed(2) + '%'
        }
      },
      getValue () {
        const identificacao = this.infocliente.extratoCota.identificacao || {}
        const valuePercent = identificacao.percentualPago || 0
        const dataContemplacao = identificacao.dataContemplacao ? moment(new Date(identificacao.dataContemplacao)).format('DD/MM/YYYY') : '01/01/1900'
        let contemplado = false
        if (dataContemplacao !== '01/01/1900') {
          const days = moment().diff(moment(dataContemplacao, 'DD-MM-YYYY'), 'days')
          contemplado = days >= 2
        }
        this.contemplado = contemplado
        return contemplado ? 'CONTEMPLADO!★⭐️★' : this.formatarPorcentagem(valuePercent)
      },
      alterarTipoSelecionado (tipo) {
        this.tipoSelecionado = tipo
      }
    }
  }
</script>

<style scoped>

.espacamento-titulo {
  padding-left: 16px!important;
}

.espacamento-esquerda {
  padding-left: 11px!important;
}
.nameLabel {
  font-family: Inter;
  font-style: normal;
  color: #191E26;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.cpfCnpjLabel, .cpfCnpjText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 21px;
  color: #191E26;
  font-weight: 400;
  font-size: 14px;
}

.cpfCnpjText {
  color: #5E6267;
  font-weight: 400;
}

.socialIcon {
  background-color: #E7E7E8;
  border-radius: 42px;
  padding: 10px;
}

.socialLabel {
  font-family: Inter;
  font-style: normal;
  line-height: 18px;
  color: #191E26;
  font-weight: 700;
  font-size: 12px;
}

.socialText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #5E6267;
  font-weight: 400;
  font-size: 12px;
}

.cardsLabel {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #191E26;
  font-weight: 600;
  font-size: 16px;
}

.cardsText {
  font-family: Inter;
  letter-spacing: 0.2px;
  font-style: normal;
  line-height: 20px;
  color: #7E8185;
  font-weight: 400;
  font-size: 16px;
}

.cardsBorder {
  border: 1px solid #E7E7E8 !important;
  border-radius: 8px;
}

.divider {
  transform: scaleY(0.3);
}

.linhaBotoes {
  width: 100%;
  margin-left: -4px;
  margin-top: -4px;
}

.botoesTopoSelecionado {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: var(--primaria);
  width: 100px;
  color: white;
  padding-top: 0px;
  cursor: pointer;
  margin-top: -8px;
}

.labelBotoesTopoSelecionado{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-top: 8px;
}

.botoesTopo {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: white;
  width: 80px;
  color: #7E8185;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopo{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
}
.v-application .text-center {
    text-align: center !important;
}

.custom-progress .v-progress-circular__overlay {
  border-radius: 50% !important; /* nao funcionando ainda */
}

.custom-value {
  color: rgba(75, 75, 75, 1);
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
}
.custom-value-contemplado {
  color: #FACC00;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  margin: 32px;
}
.titulo {
  margin-top: -5px;
  margin-bottom: 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #7E8185;
}
.elipse-pago {
  width: 8.67px;
  height: 8.67px;
  top: 4.33px;
  left: 1.79px;
  gap: 0px;
  opacity: 0px;
  border-radius: 50%;
  background-color: var(--primary);
  margin-right: 5px;
}
.valor-pago-total {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  text-align: left;
  color: var(--primary);
}
.valor-a-vencer {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #9EA1A4
}
.elipse-vencer {
  width: 8.67px;
  height: 8.67px;
  left: 1.33px;
  border-radius: 50%;
  background-color: #9EA1A4;
  margin-right: 5px;
}
.label-pago {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #191E26;
}
.label-pagar {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #191E26;
}
.qtd-parcelas-pagas {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #191E26;
}
.qtd-parcelas-pagar {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #191E26;
}
.valor-credito-atualizado {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: center;
  color: #191E26;
  text-align: left;
  padding-bottom: 10px;
}
.label-credito-atualizado {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #191E26;
  text-align: left;
}
.espacamento-titulo {
  padding-left: 16px!important;
}

.espacamento-esquerda {
  padding-left: 11px!important;
}

.linhaBotoes {
  width: 100%;
  margin-left: -4px;
  margin-top: -4px;
}

.botoesTopoSelecionado {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: var(--primaria);
  width: 100px;
  color: white;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopoSelecionado{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
}

.botoesTopo {
  height: 36px;
  gap: 0px;
  border-radius: 8px;
  border: 1px;
  background-color: white;
  width: 80px;
  color: #7E8185;
  padding-top: 0px;
  cursor: pointer;
}

.labelBotoesTopo{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: block;
}

@media screen and (max-width: 960px) {
  .espacamento-titulo {
    padding-left: 0px!important;
  }
  .espacamento-esquerda {
    padding-left: 0px!important;
  }
  .nameLabel {
    font-size: 20px !important;
    line-height: normal;
    font-style: normal;
    color: black;
    font-weight: 800;
    word-break: keep-all;
  }

  .socialLabel {
    font-style: normal;
    line-height: 150%;
    font-weight: 700;
    font-size: 12px;
  }

  .socialText {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    font-size: 16px;
  }

  .cardsLabel {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 600;
    font-size: 10px;
  }

  .cardsText {
    letter-spacing: 0.2px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
