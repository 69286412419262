import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'

export async function consultarParceria (cnpj, coligada = 0) {
  try {
    const { data } = await http.get(`/consultar-parceria/${cnpj}?coligada=${coligada}`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function atualizar (cfo, parceria) {
  try {
    const { data } = await http.put(`/atualizar-parceria/${cfo}`, parceria)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function cadastrar (parceria) {
  try {
    const { data } = await http.post(`/cadastrar-parceria`, parceria)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function listarParcerias () {
  try {
    const { data } = await http.get('/listar-parcerias')
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}

export async function consultarLoginParceria (login) {
  try {
    const { data } = await http.get(`/consultar-login-revenda/${login}`)
    return data
  } catch (erro) {
    if (erro.response.data.mensagem) {
      throw erro.response.data.mensagem
    }

    throw Mensagens.ERRO
  }
}
