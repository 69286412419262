<template>
  <Loading :visible="true" />
</template>

<script>
  import auth from '@/mixins/auth'
  import { Mensagens } from '@/plugins/constants'
  import { get } from 'lodash'
  import { mapActions } from 'vuex'

  export default {
    mixins: [auth],
    mounted () {
      this.autenticar()
    },
    methods: {
      ...mapActions(['setErroUnicred']),
      async autenticar () {
        const idUnidade = this.$store.state.unidade.idUnidade

        try {
          if (!this.$store.state.unidade.sso) {
            const resposta = await this.$msalInstance.handleRedirectPromise()
            if (resposta) {
              try {
                var usuario = await this.autenticarAzure(resposta.idToken, idUnidade)

                if (usuario.usuarioExpirado) {
                  this.$toast.warning(usuario.usuarioExpirado)
                }
              } catch (erro) {
                const senhaExpirada = /(senha expirada)/.test(erro.message)

                if (senhaExpirada) {
                  this.$router.push({
                    name: 'Login',
                    params: {
                      idUnidade,
                      senhaExpirada
                    }
                  })
                } else {
                  this.$toast.error(erro.message)

                  this.$router.push({
                    name: 'Login',
                    params: {
                      idUnidade
                    }
                  })
                }
              }
            }
          } else {
            await this.autenticarAzure(this.$route.query.token, idUnidade)
          }

          this.$session.set({
            politicasPendentes: []
          })
          await this.setErroUnicred(false)
          this.$router.push({
            name: 'Login',
            params: {
              idUnidade,
              autenticado: true
            }
          })
        } catch (erro) {
          const mensagem = get(erro, 'message', Mensagens.ERRO)

          if (idUnidade === 92) {
            await this.setErroUnicred(true)
            this.$router.push({ name: 'Login', params: { idUnidade } })
          } else {
            this.$router.push({ name: 'Login', params: { idUnidade } })
            this.$toast.error(mensagem)
          }
        }
      }
    }
  }

</script>
