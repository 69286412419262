<template>
  <v-container fluid>
    <h1 class="titulo">Cadastro de Fornecedor</h1>
    <v-row>
      <v-spacer />
      <v-col
          lg="2"
          md="3"
          cols="13"
      >
        <ButtonIcon
            small
            block
            outlined
            icone="pc-mais"
            color="secundaria"
            texto="NOVO"
            @click="cadastrarTerceiro"
        />
      </v-col>
      <v-col
          lg="2"
          md="3"
          cols="12"
      >
        <ButtonIcon
            small
            block
            icone="pc-filtro"
            color="secundaria"
            texto="FILTRAR"
            @click="filtrar"
        />
      </v-col>
    </v-row>
    <DataTable
        silent
        class="mt-5"
        item-key="cnpj"
        :params="params"
        :filter="filters"
        :headers="headers"
        :url="urlDataTable"
        :loading="$store.state.carregandoSilenciosamente"
        :key="dataTableKey"
    >
      <template v-slot:[`item.revenda`]="item">
        <td>{{ item.revenda }}</td>
      </template>
      <template v-slot:[`item.cnpj`]="item">
        <td>{{ item.cnpj }}</td>
      </template>
      <template v-slot:[`item.cfo`]="item">
        <td>{{ parseInt(item.cfo) }}</td>
      </template>
      <template v-slot:[`item.ativo`]="item">
        <td v-if="item.ativo">Sim</td>
        <td v-else>Não</td>
      </template>
      <template v-slot:[`item.editar`]="item">
        <td class="icon-center">
          <v-btn
              icon
              @click="editarTerceiro(item)"
          >
            <v-icon color="secundaria">
              pc-editar
            </v-icon>
          </v-btn>
        </td>
      </template>
    </DataTable>
    <DialogCadastroTerceiro
        :exibir="exibirDialogCadastro"
        @exibir="exibir => exibirDialogCadastro = exibir"
    />
    <DialogFiltrarTerceiro
        :exibir="exibirDialogFiltro"
        @exibir="exibir => exibirDialogFiltro = exibir"
        @filtro="aplicarFiltro"
    />
  </v-container>
</template>

<script>
  import { pushRoute } from '@/plugins/helpers'

  import DialogFiltrarTerceiro from '../../components/dialog/DialogFiltrarTerceiro.vue'
  import DialogCadastroTerceiro from '../../components/dialog/DialogCadastroTerceiro.vue'

  export default {
    name: 'ListaTerceiros',
    components: { DialogFiltrarTerceiro, DialogCadastroTerceiro },
    data: () => ({
      urlDataTable: '/listar-terceiros',
      dataTableKey: 1,
      exibirDialogCadastro: false,
      exibirDialogFiltro: false,
      filtro: {
        nome: '',
        cpfCnpj: '',
        usuario: ''
      },
      headers: [
        { text: 'Terceiro', value: 'revenda' },
        { text: 'CPF/CNPJ', value: 'cnpj' },
        { text: 'CFO', value: 'cfo' },
        { text: 'Ativo', value: 'ativo', width: 10 },
        { value: 'editar', width: 10, sortable: false }
      ],
      filters: {},
      pagination: {
        sortBy: [], sortDesc: [ true ]
      }
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    mounted () {
      this.$store.commit('setRotaAtual', this.$route.name)
    },
    methods: {
      editarTerceiro ({ cnpj }) {
        this.$router.push({
          name: 'CadastroTerceiro',
          params: {
            cnpj: cnpj.replace(/(\/|-|\.)/g, '')
          }
        })
      },
      execaoUsuario () {
        pushRoute('ExecaoUsuario')
      },
      cadastrarTerceiro () {
        this.exibirDialogCadastro = true
      },
      filtrar () {
        this.exibirDialogFiltro = true
      },
      aplicarFiltro (filtro) {
        this.urlDataTable = `/listar-terceiros${filtro}`
        this.dataTableKey++
      }

    }
  }

</script>
