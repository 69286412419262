import Vue from 'vue'
import { isEmpty, isNumber } from 'lodash'
import { isObject as _isObject } from 'vuetify/es5/util/helpers'
import moment from 'moment'
import router from './router'

export function getFilters (value) {
  if (isDateFormat(value)) {
    return { filtro_data: toDateFormatFiltro(value) }
  }

  let filtro = {}
  if (isDecimal(value)) {
    const decimalValue = parseFloat(value.replace('.', '').replace(',', '.'))
    return { filtro_numero: decimalValue }
  }
  if (isNumeric(value)) {
    filtro = { filtro_numero: value }
  }
  return { ...filtro, filtro_texto: value }
}

function isDateFormat (value) {
  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/
  return value.match(dateRegex)
}

function isDecimal (value) {
  const decimalRegex = /^\d{1,3}(?:\.\d{3})*,\d{2}$/
  return value.match(decimalRegex)
}

function isNumeric (value) {
  return /^\d+$/.test(value)
}

export function toDateFormatFiltro (data) {
  if (!isEmpty(data)) {
    if (isNumber(data)) {
      data = data.toString().replace(/^([0-9]{4})([0-9]{2})([0-9]{2})$/, '$1/$2/$3')
    } else {
      data = toDate(data)
    }

    if (data.indexOf('/') !== -1) {
      let d = data.split('/')

      data = d[2] +
        '-' + d[1] +
        '-' + d[0] + 'T00:00:00.000-03:00'
    }
  }

  return data
}

export function toDate (data) {
  if (data.indexOf(' ') !== -1) {
    let d = data.split(' ')
    data = d[0]
  } else {
    if (data.indexOf('T') !== -1) {
      let d = data.split('T')
      data = d[0]
    }
  }

  return data
}

export const unmask = v => {
  if (!v) {
    return ''
  }
  return v.replace(/[^\d]+/g, '')
}

export function primeiraLetraMaiuscula (value) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }
  return value
}

export const isObject = _isObject

export const alert = (options) => {
  const alert = Vue.prototype.$alert
  alert.show(options)
  return alert
}

export function validaCPF (cpf) {
  cpf = unmask(cpf)
  let numeros, digitos, soma, i, resultado, digitos_iguais
  digitos_iguais = 1
  if (cpf.length < 11) {
    return false
  }
  for (i = 0; i < cpf.length - 1; i++) {
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      digitos_iguais = 0
      break
    }
  }
  if (!digitos_iguais) {
    numeros = cpf.substring(0, 9)
    digitos = cpf.substring(9)
    soma = 0
    for (i = 10; i > 1; i--) {
      soma += numeros.charAt(10 - i) * i
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    // eslint-disable-next-line eqeqeq
    if (resultado != digitos.charAt(0)) {
      return false
    }
    numeros = cpf.substring(0, 10)
    soma = 0
    for (i = 11; i > 1; i--) {
      soma += numeros.charAt(11 - i) * i
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    // eslint-disable-next-line eqeqeq
    return resultado == digitos.charAt(1)
  }
  return false
}

export function validaCNPJ (cnpj) {
  cnpj = unmask(cnpj)

  if (cnpj === '') return false

  if (cnpj.length !== 14) {
    return false
  }

  // Elimina CNPJs invalidos conhecidos
  if ([
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999'
  ].includes(cnpj)) {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  // eslint-disable-next-line eqeqeq
  if (resultado != digitos.charAt(0)) {
    return false
  }

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  // eslint-disable-next-line eqeqeq
  return resultado == digitos.charAt(1)
}

export function validaCelular (celular) {
  return /^\([1-9]{2}\) 9[2-9][0-9]{3}-[0-9]{4}$/.test(celular)
}

export function validaTelefone (telefone) {
  return /^\([1-9]{2}\) [2-8][0-9]{3}-[0-9]{4}$/.test(telefone)
}

export function validaEmail (email) {
  return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i.test(email)
}

// Converte 2022-12 para 12/2022
export function formatarDataAnoMes (data) {
  return moment(data, 'YYYY-MM').format('MM/YYYY')
}

// Converte 2022-12-25 para 25/12/2022
export function formatarDataAnoMesDia (data) {
  return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')
}

// Converte 2022-12-25 00:00:00 para 25/12/2022
export function formatarDataAnoMesDiaHoraMinutoSegundo (data) {
  return moment(data, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
}

// Converte 12/2022 para 2022-12
export function formatarDataMesAnoISO (data) {
  return moment(data, 'MM/YYYY').format('YYYY-MM')
}

// Converte 25/12/2022 para 2022-12-25
export function formatarDataMesAnoDiaISO (data) {
  return moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD')
}

// Converte objeto data para 25/12/2022
export function formatarObjetoDataAnoMesDia (data) {
  return moment(data).format('DD/MM/YYYY')
}

export function pushRoute (name, route = { params: {}, query: {}, replace: false }) {
  router.push(
    {
      name,
      params: {
        ...route.params,
        idUnidade: router.currentRoute.params.idUnidade
      },
      query: route.query,
      replace: route.replace
    })
}

export function formatarCpfCnpj (cpfCnpj) {
  const cpfCnpjLimpo = cpfCnpj.replace(/([./-])/g, '')

  if (cpfCnpjLimpo.length === 14) {
    const caracteres = cpfCnpjLimpo.split('')
    let cnpjFormatado = ''

    for (let i = 0; i < 14; i++) {
      if (i === 2) {
        cnpjFormatado += '.'
      } else if (i === 5) {
        cnpjFormatado += '.'
      } else if (i === 8) {
        cnpjFormatado += '/'
      } else if (i === 12) {
        cnpjFormatado += '-'
      }

      cnpjFormatado += caracteres[i]
    }

    return cnpjFormatado
  } else if (cpfCnpj.length === 11) {
    const caracteres = cpfCnpjLimpo.split('')
    let cpfFormatado = ''

    for (let i = 0; i < 11; i++) {
      if (i === 3) {
        cpfFormatado += '.'
      } else if (i === 6) {
        cpfFormatado += '.'
      } else if (i === 9) {
        cpfFormatado += '-'
      }

      cpfFormatado += caracteres[i]
    }

    return cpfFormatado
  } else {
    return cpfCnpj
  }
}
