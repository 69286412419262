<template>
  <v-container fluid>
    <h1 class="titulo">Políticas de uso</h1>
    <v-row justify="end">
      <v-col cols="12" md="4" lg="3">
        <ButtonIcon
            id="ButtonNovoDocumento"
            texto="NOVO DOCUMENTO"
            icone="pc-mais"
            small
            block
            @click="exibirDialogNovaPolitica"
        />
      </v-col>
    </v-row>
    <v-card>
      <DataTable
          ref="politicas"
          class="mt-5"
          :filter="filters"
          :headers="headers"
          :params="params"
          item-key="id"
          url="listar-politica"
      >
        <template v-slot:[`item.id`]="item">
          <td>{{ item.id }}</td>
        </template>
        <template v-slot:[`item.tituloDocumento`]="item">
          <td>{{ item.tituloDocumento }}</td>
        </template>
        <template v-slot:[`item.idUnidade`]="item">
          <td>{{ item.idUnidade }}</td>
        </template>
        <template v-slot:[`item.dataCriacaoDocumento`]="item">
          <td>{{ item.dataCriacaoDocumento | dia_mes_ano_hora_min }}</td>
        </template>
        <template v-slot:[`item.usuario.nome`]="item">
          <td>{{ item.usuario.nome }}</td>
        </template>
        <template v-slot:[`item.versao.dataHoraCriacao`]="item">
          <td>{{ item.versao.dataHoraCriacao | dia_mes_ano_hora_min }}</td>
        </template>
        <template v-slot:[`item.versao.usuario.nome`]="item">
          <td>{{ item.versao.usuario.nome }}</td>
        </template>
        <template v-slot:[`item.versao.versao`]="item">
          <td>{{ item.versao.versao }}</td>
        </template>
        <template v-slot:[`item.ativo`]="item">
          <td>
            <v-switch
                :id="`Switch_${item.id}`"
                :ref="`Switch_${item.id}`"
                inset
                color="primaria"
                v-model="item.ativo"
                @change="alterarStatus(item.id, item.ativo)"
            />
          </td>
        </template>
        <template v-slot:[`item.acao`]="item">
          <td>
            <v-row class="justify-space-around">
              <v-btn
                  :id="`ButtonVisualizar_${item.id}`"
                  icon
                  @click="abrirDocumentoNovaPagina(item.versao.idArquivoServidor)"
              >
                <v-icon
                    color="primaria">
                  pc-olho
                </v-icon>
              </v-btn>
              <v-btn
                  :id="`ButtonAdicionarVersao_${item.id}`"
                  icon
                  @click="adicionarVersao(item)"
              >
                <v-icon
                    color="primaria">
                  pc-documento-adicionar
                </v-icon>
              </v-btn>
              <v-btn
                  :id="`ButtonDeletar_${item.id}`"
                  icon
                  @click="pedirConfirmacaoExclusao(item.id)"
              >
                <v-icon
                    color="primaria">
                  pc-lixeira
                </v-icon>
              </v-btn>
            </v-row>
          </td>
        </template>
      </DataTable>
    </v-card>
    <DialogCadastrarDocumento :exibir="exibirDialogCadastro" :unidades="listaUnidades" :documentos="tamanhoLista" @close="atualizarLista" />
    <DialogCadastrarVersaoDocumento ref="dialogNovaVersao" :unidade="unidadeDocumentoSelecionado" :documento="documentoSelecionado" @close="atualizarLista" />
    <PopUpConfirmacao
        :exibir="exibirConfirmacao"
        titulo="Atenção"
        :mensagem="mensagemConfirmacao"
        @confirmou="confirmou"
        @cancelou="esconderConfirmacao()" />
  </v-container>
</template>

  <script>
  import { alterarStatusDocumentoPolitica, excluirDocumentoPolitica, obterUnidadesPolitica } from '@/service/politica'
  import { buscarUrlArquivo } from '@/service/arquivo'
  import { formatDateTime } from '@/plugins/formatters'
  import PopUpConfirmacao from '@/components/PopUpConfirmacao'

  export default {
    name: 'PoliticaUso',
    components: {
      PopUpConfirmacao
    },
    filters: {
      dia_mes_ano_hora_min: formatDateTime
    },
    data: () => ({
      dialogNovoDocumento: false,
      dialogNovaVersao: false,
      exibirDialogCadastro: false,
      exibirConfirmacao: false,
      mensagemConfirmacao: undefined,
      idDocumento: undefined,
      tamanhoLista: undefined,
      headers: [
        { text: 'ID', value: 'id', width: 50 },
        { text: 'Título do documento', value: 'tituloDocumento' },
        { text: 'Unidade', value: 'idUnidade', width: 50 },
        { text: 'Documento criado em', value: 'dataCriacaoDocumento' },
        { text: 'Documento criado por', value: 'usuario.nome' },
        { text: 'Versão criada em', value: 'versao.dataHoraCriacao' },
        { text: 'Versão criada por', value: 'versao.usuario.nome' },
        { text: 'Versão atual', value: 'versao.versao', width: 50 },
        { text: 'Ativo', value: 'ativo', width: 90 },
        { text: '', value: 'acao', sortable: false, width: 150 }
      ],
      filters: {},
      pagination: {
        sortBy: [ 'id' ], sortDesc: [ false ]
      },
      pageablePolitica: {},
      documentoSelecionado: {},
      unidadeDocumentoSelecionado: {},
      documentos: [],
      listaUnidades: [],
      mensagens: {
        exclusao: 'Está certo que deseja remover este registro? Esta ação não poderá ser desfeita',
        exclusaoUltimo: 'Não haverá nenhum documento em vigor após esta ação. Está certo que deseja continuar?',
        novoDocumento: 'Após esta ação haverá mais de um documento em vigor e durante o login será solicitado\num aceite para cada um dos documentos ativos. Está certo que deseja continuar?'
      }
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    async mounted () {
      this.listaUnidades = await obterUnidadesPolitica()
    },
    methods: {
      exibirDialogNovaPolitica () {
        this.unidades = this.listaUnidades
        this.getTamanhoLista()
        this.exibirDialogCadastro = true
      },
      atualizarLista () {
        this.filters = { ...this.filters }
        this.exibirDialogCadastro = false
      },
      getTamanhoLista () {
        this.tamanhoLista = this.$refs.politicas?.items?.length
        return this.tamanhoLista
      },
      async alterarStatus (idDocumento, ativo) {
        if (ativo && this.getTamanhoLista() >= 1) {
          this.mensagemConfirmacao = this.mensagens.novoDocumento
          this.idDocumento = idDocumento
          this.exibirConfirmacao = true
        } else {
          try {
            await alterarStatusDocumentoPolitica(idDocumento)
            this.atualizarLista()
            this.$toast.success('Registro atualizado com sucesso!')
          } catch (e) {
            this.$toast.error(e)
          }
        }
      },
      adicionarVersao (documento) {
        this.documentoSelecionado = documento
        this.documentoSelecionado.versaoDocumento = documento.versao.versao
        this.unidadeDocumentoSelecionado = this.listaUnidades.find(i => i.idUnidade === documento.idUnidade)
        this.$refs.dialogNovaVersao.show()
      },
      pedirConfirmacaoExclusao (idDocumento) {
        this.idDocumento = idDocumento
        this.mensagemConfirmacao = this.getTamanhoLista() === 1 ? this.mensagens.exclusaoUltimo : this.mensagens.exclusao
        this.exibirConfirmacao = true
      },
      async confirmou () {
        if (this.mensagemConfirmacao === this.mensagens.novoDocumento) {
          try {
            await alterarStatusDocumentoPolitica(this.idDocumento)
            this.atualizarLista()
            this.$toast.success('Registro atualizado com sucesso!')
          } catch (e) {
            this.$toast.error(e)
          }
        } else {
          try {
            await excluirDocumentoPolitica(this.idDocumento)
            this.atualizarLista()
          } catch (e) {
            this.$toast.error(e)
          }
        }
        this.esconderConfirmacao()
      },
      esconderConfirmacao () {
        this.exibirConfirmacao = false
      },
      async abrirDocumentoNovaPagina (idDocumentoAzure) {
        try {
          const url = await buscarUrlArquivo(idDocumentoAzure, false)
          window.open(url, 'pdf-frame')
        } catch (erro) {
          this.$toast.error(erro)
        }
      }
    }
  }
  </script>
