<template>
  <v-dialog
      v-model="exibir"
      width="70%"
      max-width="1024px"
      persistent
  >
    <v-card>
      <v-card-title>
        <span>{{ politica.titulo }}</span>
        <v-btn icon class="ml-auto" @click="fechar">
          <v-icon dark size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <iframe
            v-if="url"
            class="pdf"
            :src="url"
        />
        <div
            v-else
            class="pdf"
        >
          <v-spacer />
          <v-img
              class="spinner"
              src="@/assets/img/spinner.png"
              alt="Carregando..."
              max-height="35"
              max-width="35"
          />
          <v-spacer />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-container fluid>
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <Checkbox
                  :label="`Eu aceito ${politica.titulo}`"
                  color="primaria"
                  v-model="aceito"
              />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-container fluid class="text-end">
                <Button
                    color="primaria"
                    class="text-capitalize dialog-button"
                    outlined
                    small
                    @click="cancelar"
                >
                  Cancelar
                </Button>
                <Button
                    small
                    @click="aceitar"
                    color="primaria"
                    :disabled="!aceito"
                    class="text-capitalize dialog-button ml-md-4"
                >
                  Aceitar
                </button>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import moment from 'moment'
  import { aceitar } from '@/service/politica'
  import { buscarNomeArquivoPublico } from '@/service/arquivo'
  import session from '@/plugins/session'

  export default {
    props: {
      exibir: {
        type: Boolean,
        default: false
      },
      politica: {
        idVersao: '',
        idAzure: '',
        titulo: ''
      }
    },
    data: () => ({
      aceito: false,
      url: ''
    }),
    watch: {
      exibir (valor) {
        if (valor) {
          this.aceito = false
          this.url = ''

          this.buscarPDF()
        }
      }
    },
    mounted () {
      if (this.politica.idAzure) {
        this.fechar()
      }
    },
    methods: {
      async aceitar () {
        if (this.aceito) {
          try {
            const dataHoraAtual = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')

            const resposta = await aceitar(this.politica.idVersao, {
              dataHoraNavegador: dataHoraAtual
            })

            let politicasPendentes = session.get('politicasPendentes')
            politicasPendentes.shift()
            session.set({ politicasPendentes })

            this.$toast.success(resposta.mensagem)
            this.fechar()
          } catch (erro) {
            this.$toast.error(erro)
          }
        }
      },
      async buscarPDF () {
        try {
          this.url = process.env.VUE_APP_WS_ROOT + await buscarNomeArquivoPublico(this.politica.idAzure)
        } catch (erro) {
          this.fechar()
          this.$toast.error(erro)
        }
      },
      cancelar () {
        this.fechar()
        this.$emit('cancelar')
      },
      fechar () {
        this.$emit('exibir', false)
      }
    }
  }

</script>

<style scoped>

.v-btn {
  width: 8rem;
}

.pdf {
  width: 100%;
  height: 30rem;
}

</style>
